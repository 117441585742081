import React, { useContext, useEffect, useState } from 'react'
import StoreScreenStyle from "./storestyle";
import { FlatList, ImageBackground,Dimensions, Linking, ScrollView, StatusBar, Text, View, TouchableOpacity } from 'react-native';
import BackGroundImage from "../../../assets/farm.jpeg"
import {Logo,Label, SpaceBottom, Button, Lang, RadioButton, TextInputed, Dialogue, LabelSmall, LangText, Avatar, MenuButton, GoBack, ProductImage, SearchLabel, Office, Scientist} from '../../../Components';
import {igbo,english,hausa,yoruba} from '../../../Lang';
import PickLang from '../../../Modals/Language/PickLang';
import { updateStateArray, _retrieveData, _retrieveToken, _storeData } from '../../../Service/function';
import { checkProfile, disableBackButton, generateGreetings, pickLang } from '../../../Auth';
import { AuthContext } from '../../../context/authContext/AuthContext';
import { baseUrl, imageUrl } from '../../../env';
import MenuImage from '../../../Components/MenuImage';
import axios from "axios";
import ProductSearch from '../../../Modals/Search/ProductSearch';
import SearchStore from '../../components/SearchStore/SearchStore';
import Icon from 'react-native-vector-icons/FontAwesome';
const {width,height}=Dimensions.get('screen');

export default function ScientistListScreen({navigation,route}) {
    const [isSecure, setSecure] = useState(true);
    const [getLangModal,setLangModal] = useState(false);
    const [modalSetting,setModalSetting] = useState(false);
    const [getLang,setLang] = useState(0);
    const [LangFile,setLangFile] = useState(english);
    const [getProduct, setProduct] = useState([]);
    const [getSearch, setSearch] = useState(false);
    const [category, setCategory] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [getGreetings,setGreetings]=useState(null);
    const [getBannerTab, setBannerTab] = useState(1);
    const [messageDialogue, setmessageDialogue] = useState("Hello");
    const [isDialogueVisible, setisDialogueVisible] = useState(false);
    const [getState, setState] = useState(null);
    const [getLGA, setLGA] = useState(null);
    const [start,setStart]=useState(0);
    const [page,setPage]=useState(10);
    //Context
    const {user,isFetching,dispatch}=useContext(AuthContext);

    const resData=[
        {
          key:0,
          text:'English'
      },
      {
          key:1,
          text:'Hausa'
      },
      {
          key:2,
          text:'Igbo'
      },
      {
          key:3,
          text:'Yoruba'
      }
    ];
  
    useEffect(() => {
        pickLang(setLangFile,english,hausa,igbo,yoruba);
        generateGreetings(LangFile,setGreetings);
        disableBackButton()
      },[]);

      useEffect(() => {
        currentLocation();
        LoadBusiness()
      },[]);

      const onCloseDialoguePress=()=>{
        setisDialogueVisible(false);
       }

      const onClosePress=()=>{
        // pickLang(setLangFile,english,hausa,igbo,yoruba);
        setLangModal(false);
       }

      const showLangModal=()=>{
        setLangModal(true);
       }

    const onSelectValue=async(val)=>{
        setLangFile(val==0?english:val==1?hausa:val==2?igbo:yoruba)
        setLang(val);
       await _storeData(val,'lang')
        setLangModal(false); 
    }

    const currentLocation=async()=>{
        let location=await _retrieveData("business");
        setState(location.live_state);
        setLGA(location.live_lga);
        
      }
  

    const LoadBusiness=async()=>{
        const token = await _retrieveData("token");
        setLoading(true);
        setStart(0);
        try {
            const resp=await axios.get(baseUrl+`businesses?business_type=rssmember&scientist_categories=${route.params.datars?.id}&confirm=true&_limit=${page}`,{
                headers: {
                   Authorization: `Bearer ${token}`
                }
            });
              setProduct(resp.data);
              setLoading(false);
            //   setStart(start+1)
        } catch (error) {
            setLoading(false);
            setisDialogueVisible(true);
            setmessageDialogue(error.message);
        }
    }

    

    const retrieveScientist=async()=>{
        const token = await _retrieveData("token");
        
        try {
            setLoading(true);
            // console.log(start);
            const resp=await axios.get(baseUrl+`businesses?business_type=rssmember&confirm=true&scientist_categories=${route.params.datars?.id}&_limit=_limit=${page}&_start=${start}`,{
                headers: {
                   Authorization: `Bearer ${token}`
                }
            });
           await updateStateArray(getProduct,resp.data,setProduct);
            setStart(start+page);
        } catch (error) {
            setLoading(false);
            // errorMessage(toast)
        }
    }

  const setSearched=async(state)=>{
    console.log(state)
    const token = await _retrieveData("token");
    setStart(0);
    setLoading(true);
    try {
        const resp=await axios.get(baseUrl+`businesses?business_type=rssmember&confirm=true&scientist_categories=${route.params.datars?.id}&live_state=${state?.state}`,{
            headers: {
               Authorization: `Bearer ${token}`
            }
        });
          setProduct(resp.data);
          setLoading(false);
    } catch (error) {
        setLoading(false);
        // setisDialogueVisible(true);
        // setmessageDialogue(error.message);
    }
  }
      
    

    const renderItem = ({ item,index }) => (
        <Scientist bizshow={1} onPress={()=>navigation.navigate('StoreDetail', { datars: item,lang:LangFile })} icontitle={item.name} title={item.name} subtitle={item.live_state} /> 
          );
         
      return (
        <View style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
        <ImageBackground source={BackGroundImage} style={StoreScreenStyle.parent}>
        <View style={StoreScreenStyle.child}>
        <GoBack onPress={() => navigation.goBack()} buttonText={route.params.datars?.name} />
    
      <View style={{flexDirection:'column',backgroundColor:"rgba(255,255,255,0.7)",height:height}}>
   <SearchStore stateChange={(e)=>{setSearched({state:e});setState(e)}} lga={true} lgaChange={(e)=>{setSearched({lgs:e});setLGA(e)}} productChange={(e)=>{setSearched({product:e,state:getState,lga:getLGA});setState(e)}} LangFile={LangFile}  />
   <View style={{display:'flex',flexDirection:'column',marginRight:10}}>
     <Text style={{color:'black',fontWeight:'bold',fontSize:15,marginLeft:10,marginTop:10}}>{LangFile.tabinfo?LangFile.tabinfo.moreinfo:"Soil Scientist"}</Text>
     <ScrollView style={{ marginBottom:height*0.32}}>
     <FlatList
        // contentContainerStyle={{alignSelf: 'flex-start'}}
        // columnWrapperStyle={{alignItems: 'space-between'}}
        numColumns={2}
        showsVerticalScrollIndicator={true}
        showsHorizontalScrollIndicator={false}
        data={getProduct}
        renderItem={renderItem}
        keyExtractor={item => item.id} 
        />  
        <Button isLoading={isLoading} altStyle={{padding:5}} onPress={()=>retrieveScientist()} altText={{fontSize:14}} buttonText={LangFile.naming?.loadmore} />
     </ScrollView>
   </View>
  
     </View>
     
     
      <Dialogue
        modalVisible={isDialogueVisible}
        close={LangFile.login?LangFile.login.close:''}
        onClosePress={(e) => onCloseDialoguePress()}
        message={messageDialogue}
      />
      <PickLang modalView={getLangModal} 
       close={LangFile.login?LangFile.login.close:""}
      render={ <RadioButton resData={resData} value={getLang} onSelectValue={onSelectValue}/>}
      onClosePress={onClosePress}/>

            </View>
            </ImageBackground>
        </View>
      )

}
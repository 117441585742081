import React, { Component,useState } from 'react';
import { View, TouchableOpacity, Text, StyleSheet } from 'react-native';

export default function RadioButton(props) {
	
		return (
			<View>
				{props.resData.map((res,i) => {
					return (
						<View key={i} style={styles.container}>
							<Text style={styles.radioText}
                            onPress={() => props.onSelectValue(res.key)}
                            >{res.text}</Text>
							<TouchableOpacity
								style={styles.radioCircle}
								onPress={() => props.onSelectValue(res.key)}>
                                  { props.value === res.key && <View style={styles.selectedRb} />}
							</TouchableOpacity>
						</View>
					);
				})}
				
			</View>
		);
	}

const styles = StyleSheet.create({
	container: {
        marginBottom: 15,
        alignItems: 'center',
        flexDirection: 'row',
		justifyContent: 'space-between',
	},
    radioText: {
		marginRight: 35,
		width:150,
        fontSize: 15,
        color: '#000',
        fontWeight: '700'
    },
	radioCircle: {
		height: 30,
		width: 30,
		borderRadius: 100,
		borderWidth: 2,
		borderColor: '#006400',
		alignItems: 'center',
		justifyContent: 'center',
	},
	selectedRb: {
		width: 15,
		height: 15,
		borderRadius: 50,
		backgroundColor: '#006400',
    },
    result: {
        marginTop: 20,
        color: 'white',
        fontWeight: '600',
        backgroundColor: '#F3FBFE',
    },
});
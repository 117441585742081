import React, { useEffect, useState } from 'react'
import { ImageBackground, ScrollView,DatePickerAndroid, View, Text, Alert, Platform } from 'react-native';
import AddExpenseStyle from "./passwordinfostyle";
import BackGroundImage from "../../../assets/farm.jpeg";
import { Button, GoBack, RadioSelect, SelectInputed, TextInputed } from '../../../Components';
import { checkProfile, pickLang, userProfile } from '../../../Auth';
import {igbo,english,hausa,yoruba} from '../../../Lang';
import moment from "moment";
import { _deleteData, _retrieveData, _storeData } from '../../../Service/function';
import axios from 'axios';
import { baseUrl, naira } from '../../../env';
import Toast from 'react-native-toast-message';
import stateLGA from '../../../State/state.json';

if(Platform.OS!=="web"){
  const KeyboardSpacer=require('react-native-keyboard-spacer');
}

export default function PasswordInfo({navigation,route}) {
    const [LangFile,setLangFile] = useState(english);
    const [inputs,setInputs]=useState({});
    const [isLoading,setLoading]=useState(false)
    const [total,setTotal]=useState(0);
    const [getUser,setUser]=useState({});
    const [date,setDate]=useState(moment());
    const [getState, setState] = useState(null);
    const [getStater, setStater] = useState(null);
    const [getLGA, setLGA] = useState(null);
    const [getLGAR, setLGAR] = useState(null);
    const [getJsonLGA, setJsonLGA] = useState([]);
    const [getJsonLGAR, setJsonLGAR] = useState([]);

    useEffect(() => {
        pickLang(setLangFile,english,hausa,igbo,yoruba);
        checkUser();
      },[]);

     
      const checkUser=async()=>{
        const resp=  await _retrieveData("userauth");
        setUser(resp);
      }

      const handleChange=(e)=>{
        const value= e.value;
        setInputs({...inputs, [e.name]:value});
    }
    useEffect(()=>{
      transInp();   
  },[])

const transInp=async()=>{
let bizdata=await userProfile(setUser);
  setInputs(bizdata['business']);
}

const changeState=(e)=>{
  setState(JSON.parse(e).name);
  setJsonLGA(JSON.parse(e).lgas);
  // console.log(e);
}

const changeStater=(e)=>{
  setStater(JSON.parse(e).name);
  setJsonLGAR(JSON.parse(e).lgas);
  // console.log(e);
}

const submitRecord=async()=>{
  const token = await _retrieveData("token");
  if(inputs?.password!==inputs?.repassword){
    alert(LangFile.error?.passwordnotmatch);
    return false;
  }
  setLoading(true);
             try {
               let datat={
                 identifier:getUser.username,
                 password:inputs?.password
               }
               console.log(datat)
                  const resp= await axios.post(baseUrl+`auth/reset-password/`,datat,{
                      headers:{
                          'Authorization':`Bearer ${token}`,
                          'Content-Type': 'application/json'
                      }
                  });
                  await  _storeData(resp.data?.user,"userauth");
                  await  _storeData(resp.data?.jwt,"token");
                 await userProfile(setUser); 
                 Toast.show({
                  type: 'success',
                  text1: LangFile.success_message?.success,
                  text2: '👋'
                });
                navigation.goBack();
                 setLoading(false);
             } catch (error) {
                 setLoading(false);
                 alert(error.message);
             }      
}

    
      const deleteData=()=>{
         
      }
const ind=route.params?.index;
    return (
      <View style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
        <ImageBackground source={BackGroundImage} style={AddExpenseStyle.parent}>
            <View style={AddExpenseStyle.child}>
            <GoBack onPress={() => navigation.goBack()}  buttonText={LangFile.profile?.change_password} add={ind!=undefined?true:false} addText={''} iconLeft={"delete"} size={30} iconLeftColor={'red'}/>
            <ScrollView style={AddExpenseStyle.scrollView}>
    <View style={{display:'flex',flexDirection:'column',marginTop:20}}>
    <TextInputed
          showIcon1={true}
          showIcon2={false}
          iconName1={'plus-square'}
          // iconName2={'eye'}
          label={LangFile.forget_password?.new_password}
          placeholder={LangFile.forget_password?.new_password}
          onChangeText={(e) => handleChange({value:e,name:'password'})}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          // defaultValue={inputs?.name}
          Secure={true}
        //   keyboardType={'numeric'}
        />
        <TextInputed
          showIcon1={true}
          showIcon2={false}
          iconName1={'plus-square'}
          label={LangFile.profile?.confirm_password}
          placeholder={LangFile.profile?.confirm_password}
          onChangeText={(e) => handleChange({value:e,name:'repassword'})}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          Secure={true}
        />
         <Button
        buttonText={LangFile.sell_now?.submit}
        onPress={submitRecord}
        isLoading={isLoading}
        message={LangFile.login?LangFile.login.moment:''}
      />
     {(Platform.OS!=="web")&&<KeyboardSpacer topSpacing={40} />}
      </View>
      </ScrollView>
            </View>
        </ImageBackground>
        </View>
    )
}

import { StyleSheet,Dimensions,Platform } from 'react-native';
const {width,height}=Dimensions.get('screen');
const isSmallDevice = Dimensions.get('window').width < 768
export default StyleSheet.create({
    container:{
        display:'flex',
        flexDirection:'column',
    },
    rowContainer:{
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        justifyContent:'center',
        marginLeft:0,
        ...Platform.select({
            ios: {
                width:width
              },
              android: {
               width:width
              },
              default: {
                width:isSmallDevice?width:width/2.5
              }
            }),
    },
    selectInput:{
        marginRight:5,
        paddingLeft:20,
        backgroundColor: 'white',
        marginBottom:10,
         ...Platform.select({
            ios: {
                width:width/1.9,
              },
              android: {
                width:width/1.9,
              },
              default: {
                width:isSmallDevice?width/1.9:width/5
              }
            }),
    },
    selectInputLong:{
        marginRight:5,
        paddingLeft:20,
        marginLeft:10,
        marginRight:0,
        marginBottom:0,
    },
   
   
  
});
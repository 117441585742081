import { StyleSheet,Dimensions } from 'react-native';
const {width,height}=Dimensions.get('screen');
export default StyleSheet.create({
    parent:{
        width: width,
        height: height,
        backgroundColor:"#000"
    },
    child: {
        flex: 1,
        backgroundColor: 'rgba(255,255,255,0.7)'
      },

      container:{
    marginBottom:height*0.1
     },
     bottomView:{
        flexDirection:'row',
        justifyContent:'center',
        margin:20
     },
     scrollView:{
      marginBottom:height*0.11
  },
  weatherContainer:{
    display:'flex',
    margin:10,
    borderRadius:10,
    flexDirection:'row',
    alignItems:'center',
    justifyContent:'space-evenly',
    backgroundColor:'rgba(255,255,255,0.7)'
  },
  tableContainer:{
    display:'flex',
    flexDirection:'column',
    height:height*0.7,
    // alignItems:'center',
    // justifyContent:'center',
    margin:10,
    backgroundColor:'rgba(255,255,255,0.7)',
    padding:10,
    borderTopRightRadius:10,
    borderBottomRightRadius:10,
    borderBottomLeftRadius:10,
    marginBottom:40,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10
},
itemContainer:{
  display:'flex',
  flexDirection:'row',
  alignItems:'center', 
  backgroundColor:'rgba(0,0,0,0.1)',
  padding:10, 
  marginBottom:10,
  borderRadius: 10,
},
itemContainerActive:{
  display:'flex',
  flexDirection:'row',
  alignItems:'center', 
  backgroundColor:'#0d503a',
  padding:10, 
  marginBottom:10,
  borderRadius: 10,
},
itemIcon:{
  color: '#0d503a',
  fontSize: 30,
  paddingRight: 0
},
itemIconActive:{
  color: '#fff',
  fontSize: 30,
  paddingRight: 0
},
itemMini:{
  display:'flex',
  flexDirection:'column',
  padding:10
},
itemTitle:{
  color:"black",
  fontWeight:'bold',
 
},
itemTitleActive:{
  color:"white",
  fontWeight:'bold'
},
dateContainer:{
  display:'flex',
  flexDirection:'row',
  padding:10,
  alignItems:'center'
},
  
});
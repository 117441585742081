import React, { useContext, useEffect, useRef, useState } from 'react'
import ChatListStyle from "./replydetailstyle";
import { FlatList,PermissionsAndroid, ImageBackground,Dimensions, Linking, SafeAreaView, ScrollView, StatusBar, Text, View, KeyboardAvoidingView, Alert, Image,Platform } from 'react-native';
import BackGroundImage from "../../../assets/farm.jpeg"
import {Logo,Label, SpaceBottom, Button, Lang, RadioButton, TextInputed, Dialogue, LabelSmall, LangText, Avatar, MenuButton, GoBack, TabDetail, DescriptionText, ModalMd, ModalLg} from '../../../Components';
import {igbo,english,hausa,yoruba} from '../../../Lang';
import PickLang from '../../../Modals/Language/PickLang';
import { reversed, _deleteData, _retrieveData, _retrieveToken, _storeData } from '../../../Service/function';
import { checkProfile,userProfile, disableBackButton, generateGreetings, pickLang } from '../../../Auth';
import { AuthContext } from '../../../context/authContext/AuthContext';
import { baseUrl, imageUrl } from '../../../env';
import axios from "axios";
import ChatWidget from '../../components/ChatWidget/ChatWidget';
import ChatBottom from '../../components/ChatBottom/ChatBottom';
import * as ImagePicker from 'expo-image-picker';
import moment from "moment";
import Geolocation from 'react-native-geolocation-service';
import Constants from 'expo-constants';

import { ActivityIndicator, Colors } from 'react-native-paper';
import WebView from 'react-native-webview';

if(Platform.OS==="ios"||Platform.OS==="android"){
  const KeyboardSpacer=require('react-native-keyboard-spacer');
}

const {width,height}=Dimensions.get('screen');
export default function ReplyDetailScreen({navigation,route}) {
    const [isSecure, setSecure] = useState(true);
    const [getLangModal,setLangModal] = useState(false);
    const [modalShow,setModalShow] = useState(false);
    const [modalShow2,setModalShow2] = useState(false);
    const [getLang,setLang] = useState(0);
    const [LangFile,setLangFile] = useState(english);
    const [getChatStep,setChatStep]=useState(0);
    const [getUser, setUser] = useState({});
    const [isLoading, setLoading] = useState(false);
    const [getGreetings,setGreetings]=useState(null);
    const [chatmessage,setChatMessage]=useState(null);
    const [defaultMessage,setDefaultMessage]=useState([]);
    const [messageDialogue, setmessageDialogue] = useState("");
    const [isDialogueVisible, setisDialogueVisible] = useState(false);
    const [parseData,setParseData]=useState({});
   
    const [chatBot, setChatBot] = useState([]);

    let listViewRef;
    //Context
    const {user,isFetching,dispatch}=useContext(AuthContext);

    const richText = React.useRef();
    const resData=[
        {
          key:0,
          text:'English'
      },
      {
          key:1,
          text:'Hausa'
      },
      {
          key:2,
          text:'Igbo'
      },
      {
          key:3,
          text:'Yoruba'
      }
    ];
    useEffect(() => {
        pickLang(setLangFile,english,hausa,igbo,yoruba);
        generateGreetings(LangFile,setGreetings);
        disableBackButton()
      },[]);

      useEffect(() => {
        // LoadDefault();
       
        // defaultStep(-1);
        // setChatList();
        userProfile(setUser); 
      },[]);

      useEffect(()=>{
        LoadMessages();
      },[])

      const onCloseDialoguePress=()=>{
        setisDialogueVisible(false);
       }

    const OpenHref= async ()=>{
        await Linking.openURL('https://niss.gov.ng');
      }

      const onClosePress=()=>{
        // pickLang(setLangFile,english,hausa,igbo,yoruba);
        setLangModal(false);
       }

      const showLangModal=()=>{
        setLangModal(true);
       }

    const onSelectValue=async(val)=>{
        setLangFile(val==0?english:val==1?hausa:val==2?igbo:yoruba)
        setLang(val);
       await _storeData(val,'lang')
        setLangModal(false); 
    }

    const getChatBot=async()=>{
        let chatbot=await _retrieveData("chatbot");
        setChatBot(chatbot);
    }

    const LoadMessages=async()=>{
        var storeMessage=[];
        var questionData=route.params.datar.question;
        var replyData=route.params.datar.replies;
        let respondR=[...replyData].slice(5);
        let lastReply=[...replyData].slice(0,5); //Get the first chatbot replies
        let dap=lastReply.reverse();
        var revReverse=dap;
        // console.log(revReverse.length)
        var revQuestion=[...JSON.parse(questionData)].reverse();
        for (let i = 0; i < revReverse.length; i++) {  
        //    console.log(revReverse[i])
                    try {
                        
                        if(revReverse[i].status==1){
                            storeMessage.push({
                            type:1,
                            image:revReverse[i].type==="text"?0:revReverse[i].type==="image"?1:2,
                            step:i,
                            message:revReverse[i].reply,
                            imageUrl:revReverse[i].image?imageUrl+revReverse[i].image.url:'',
                            imagedata:{},
                            long:revReverse[i].type==="location"?JSON.parse(revReverse[i].reply.split(",")[0]):'',
                            lat:revReverse[i].type==="location"?JSON.parse(revReverse[i].reply.split(",")[1]):'',
                            cat:revReverse[i].type,
                            time:moment(revReverse[i].time).format('YYYY-MM-DD HH:mm:ss'),
                            status:true
                        });
                    }
                            storeMessage.push({
                                type:revQuestion[i].type,
                                image:revQuestion[i].image,
                                step:revQuestion[i].step,
                                message:revQuestion[i].message,
                                imageUrl:revQuestion[i].image,
                                time:revQuestion[i].time,
                                status:revQuestion[i].status
                            });
                       
                        // await _storeData(storeMessage,'chatmessage');
                    } 
                    catch (error) {
                            // setChatList();
                                   
                                }                                          
    }
    for (let j = 0; j < respondR.length; j++) {
        storeMessage.push({
                    type:2,
                    image:respondR[j].type==="text"?0:respondR[j].type==="image"?1:2,
                    step:respondR.length,
                    message:respondR[j].reply,
                    imageUrl:respondR[j].image?imageUrl+respondR[j].image.url:'',
                    imagedata:{},
                    long:respondR[j].type==="location"?JSON.parse(respondR[j].reply.split(",")[0]):'',
                    lat:respondR[j].type==="location"?JSON.parse(respondR[j].reply.split(",")[1]):'',
                    cat:respondR[j].type,
                    time:moment(respondR[j].time).format('YYYY-MM-DD HH:mm:ss'),
                    status:true
                });
    }
    setDefaultMessage(storeMessage);
    }

    const sendConversation=async(data)=>{
        const token = await _retrieveData("token");
        const datetime=moment().format('x');
        try {
            var reverseData=reversed(data);
            let datat={
                user_two:getUser.id,
                status:0,
                ref:datetime
            }
          const resp=await axios.put(baseUrl+`conversations/${route.params.datar.id}`,datat,{
              headers:{
                  'Authorization':`Bearer ${token}`,
                  'Content-Type': 'application/json'
              }
          });
           
            var countsubmit=0; var replyData={};
            for (let i = 0; i < reverseData.length; i++) {
                if(countsubmit<11){
                if(reverseData[i].type==2&&reverseData[i].image==0){
                   replyData= await sendReply(reverseData[i].message,datetime,token,route.params.datar.id,null,reverseData[i].cat,reverseData[i].time,'');
                }
                else if(reverseData[i].type==2&&reverseData[i].image==1){
                    const respImg=await uploadImage(token,reverseData[i].imagedata);
                    replyData= await sendReply('',datetime,token,route.params.datar.id,respImg[0].id,reverseData[i].cat,reverseData[i].time,'');
                }
                else if(reverseData[i].type==2&&reverseData[i].image==2){
                    replyData= await sendReply(`${reverseData[i].long},${reverseData[i].lat}`,datetime,token,route.params.datar.id,null,reverseData[i].cat,reverseData[i].time,'');
                }
            }
                countsubmit++
            }
            // await sendReply(reverseData,datetime,token,resp.id);
            LoadMessages();
        } catch (error) {
            console.log(error.message)
        }
    }

    const uploadImage = async(token,image) => {
            var formData = new FormData();
            setLoading(true);
            formData.append('files',
            {
               uri:image.uri,
               name:image.fileName,
               type:image.type
            });
            const resp=await axios.post(baseUrl+"upload/",formData,{
                headers: {
                   Authorization: `Bearer ${token}`,
                   'Content-Type': 'multipart/form-data'
                }
            })
            return resp.data;
      };

    const sendReply=async(message,datetime,token,convid,image,type,time,questId)=>{
        try {
            let datat={
                reply:message,
                ip:Constants.installationId,
                time:time,
                status:0,
                type:type,
                conversation:convid,
                image:image
            }
                const resp=await axios.post(baseUrl+"replies",datat,{
                    headers: {
                        'Authorization':`Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });   
                return resp.data;
        } catch (error) {
            // console.log(error.message);
        }
    }

    const setChatList=async()=>{
        // await _deleteData('chatmessage');
        // await _storeData(0,'chatstep');
    //     let chatmsgd=await _retrieveData("chatmessage");
    //     defaultStep()
    //    setDefaultMessage(chatmsgd!==null?chatmsgd:[]);
      
    }

    const defaultStep=async(step)=>{
        // let chatstep=await _retrieveData("chatstep");
        // setChatStep(chatstep);
        // // alert(chatstep)
        // if(chatstep == null || chatstep == 6){
        //     setChatStep(0);
        //     await _storeData(0,'chatstep');
        // }
    }

      const imagePress=async()=>{  
          let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.All,
            allowsEditing: true,
            aspect: [4, 3],
            quality: 1,
          });
          if (!result.cancelled) {
                const source = { uri: result.uri };
            let message=[...defaultMessage];
            message.push({
                type:2,
                image:1,
                step:getChatStep,
                message:'',
                imageUrl:result.uri,
                imagedata:result,
                cat:'image',
                time:moment().format('YYYY-MM-DD HH:mm:ss'),
                status:false
            });
            setDefaultMessage(message);
            setLangModal(false);
            await  sendConversation(message);
           }
      
      }

      const locationPress=async()=>{
        const hasLocationPermission= await PermissionsAndroid.request(
            PermissionsAndroid.PERMISSIONS.ACCESS_COARSE_LOCATION,
            {
              title: "Pick your current location",
              message:
                " Give the app permission to pick " +
                "your current device location.",
              buttonNeutral: "Ask Me Later",
              buttonNegative: "Cancel",
              buttonPositive: "OK"
            }
          );
          if (hasLocationPermission&&Platform.OS==="android") {
            Alert.alert(
                "Pick Current Location",
                "Send my device current location",
                [
                  {
                    text: "Cancel",
                    onPress: () => console.log("Cancel Pressed"),
                    style: "cancel"
                  },
                  { text: "OK", onPress: () => {
                    Geolocation.getCurrentPosition(
                        async(position) => {
                    let message=[...defaultMessage];
                    message.push({
                        type:2,
                        image:2,
                        step:getChatStep,
                        message:'',
                        imageUrl:null,
                        cat:'location',
                        lat:position.coords.latitude,
                        long:position.coords.longitude,
                        time:moment().format('YYYY-MM-DD HH:mm:ss'),
                        status:false
                    });
                    setDefaultMessage(message);
                    await  sendConversation(message);
                        },
                        (error) => {
                          // See error code charts below.
                          console.log(error.code, error.message);
                        },
                        { enableHighAccuracy: true, timeout: 30000, maximumAge: 10000 }
                    );
                  } }
                ]
              );
           
          }
          else if(Platform.OS==="ios"){
            Alert.alert(
              "Pick Current Location",
              "Send my device current location",
              [
                {
                  text: "Cancel",
                  onPress: () => console.log("Cancel Pressed"),
                  style: "cancel"
                },
                { text: "OK", onPress: () => {
                  Geolocation.getCurrentPosition(
                    async(position) => {
                let message=[...defaultMessage];
                message.push({
                    type:2,
                    image:2,
                    step:getChatStep,
                    message:'',
                    imageUrl:null,
                    cat:'location',
                    lat:position.coords.latitude,
                    long:position.coords.longitude,
                    time:moment().format('YYYY-MM-DD HH:mm:ss'),
                    status:false
                });
                setDefaultMessage(message);
                await  sendConversation(message);
                    },
                    (error) => {
                      // See error code charts below.
                      console.log(error.code, error.message);
                    },
                    { enableHighAccuracy: true, timeout: 30000, maximumAge: 10000 }
                );
              } }
            ]
          );
          }
          else{
            Alert.alert(
              "Pick Current Location",
              "Send my device current location",
              [
                {
                  text: "Cancel",
                  onPress: () => console.log("Cancel Pressed"),
                  style: "cancel"
                },
                { text: "OK", onPress: () => {
          navigator.geolocation.getCurrentPosition(async function(position) {
            let message=[...defaultMessage];
                message.push({
                    type:2,
                    image:2,
                    step:getChatStep,
                    message:'',
                    imageUrl:null,
                    cat:'location',
                    lat:position.coords.latitude,
                    long:position.coords.longitude,
                    time:moment().format('YYYY-MM-DD HH:mm:ss'),
                    status:false
                });
                setDefaultMessage(message);
                await  sendConversation(message);
              })
              } }
            ]
          );
          
          }
      
      }

    // const addBotMessage=async(step,messaged)=>{
    //     let chatbott=await _retrieveData("chatbot");
    //     let chatmsgd=await _retrieveData("chatmessage");
    //     let message=chatmsgd;
    //     if( chatmsgd !== null ){
    //         message.push({
    //             type:0,
    //             image:1,
    //             step:step,
    //             message:step==0?chatbott[step].question+"\n"+chatbott[step+1].question:chatbott[step].question,
    //             imageUrl:'',
    //             time:moment().format('YYYY-MM-DD HH:mm:ss'),
    //             status:false
    //         });
    //         await _storeData(message,'chatmessage');
    //         let chatmsg=await _retrieveData("chatmessage");
    //         setTimeout(async() => {  setDefaultMessage(chatmsg); 
    //             await _storeData(step==0?2:step+1,'chatstep');
    //             defaultStep();
    //         }, 1000);
    //     }
    //     let chatbottn=await _retrieveData("chatstep");
    //     if(step==5){
    //         await _storeData(0,'chatstep');
    //         let chatbott=await _retrieveData("chatstep");
    //         setChatStep(0);
          
    //     }

    // }

      const submitChat=async()=>{
         
          if(!chatmessage){
              alert(LangFile.error.entermessage);
              return false;
          }
          else{
            let message=[...defaultMessage];
            message.push({
                type:2,
                image:0,
                step:getChatStep,
                message:chatmessage,
                cat:'text',
                imageUrl:null,
                time:moment().format('YYYY-MM-DD HH:mm:ss'),
                status:false
            });
            setChatMessage("");
            setDefaultMessage(message);
            await  sendConversation(message);
            // await addBotMessage(getChatStep,chatmessage);
          }
      }

    //   const checkMessage=async(messaged,step)=>{
    //        let chatbott=await _retrieveData("chatbot");
    //     let chatmsgd=await _retrieveData("chatmessage");
    //     if(chatmsgd&&chatmsgd.length>1&&chatbott[step-1]?.expect=='number'&&isNaN(messaged)==true){
    //         alert('Please message should be a number');
    //         return true;
    //     }
    //     if(chatmsgd&&chatmsgd.length>1&&chatbott[step-1]?.expect=='text'&&isNaN(messaged)==false){
    //         alert('Please message should be a text and not number');
    //         return true;
    //     }
    //     if(chatmsgd&&chatmsgd.length>1&&chatbott[step-1]?.expect=='location'&&messaged!=null){
    //         alert('Please pick your location and not text');
    //         return true;
    //     }
    //     if(chatmsgd&&chatmsgd.length>1&&chatbott[step-1]?.expect=='image'&&messaged!=null){
    //         alert('Please select image and not text');
    //         return true;
    //     }
    //     // console.log(messaged)
    //   }

     const viewData=async(data)=>{
         if(data.type==0){ setModalShow(true);}
         else if(data.type==1){ setModalShow2(true);}
       
        setParseData(data);
     }
     

      const renderItem = ({ item,index }) => (
       <ChatWidget key={index} statud={item.status} modalShow={viewData} message={item.message} support={item.support} supported={getUser.name} lat={item.lat} time={moment(item.time).format("LL")+" "+moment(item.time).format("hh:mm A")} long={item.long} type={item.type} image={item.image?item.image:false} imageUrl={item.image?item.imageUrl:null} />
          // <MenuImage onPress={()=>navigation.navigate('Detail', { datars: item,lang:getLang })} key={index} imageUrl={item.image?baseUrl+item.image.url:''} colorIcon={'#006400'} buttonText={item.name} /> 
          );
      return (
        <View style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
        <ImageBackground source={BackGroundImage} style={ChatListStyle.parent}>
        <View style={ChatListStyle.child}>
        <GoBack onPress={() => navigation.navigate('ReplyList')} buttonText={`${JSON.parse(route.params.datar.question)[4].message.split("\n")[route.params.datar.replies[3].reply].substr(11).replace(",","")}` } />
        {/* <TabDetail  onPress={selectedTab} text1={LangFile.chat?LangFile.chat.newchat:"New Chat"} text2={LangFile.chat?LangFile.chat.previouschat:'Previous Chat'}  indexSelect={1} /> */}
      <SafeAreaView style={{height:height*0.83,marginTop: 0,backgroundColor: 'rgba(255,255,255,0.7)' }}>
      <ScrollView style={{ flex: 1, margin: 10 }}>
      <View style={ChatListStyle.containertop}>
      <Text style={ChatListStyle.senderTxt}>{LangFile.chat.welcome}</Text>
      </View>
      <FlatList
        inverted
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        data={defaultMessage}
        renderItem={renderItem}
        keyExtractor={item => item.id} 
        />  
  </ScrollView>
  <ChatBottom value={chatmessage} placeholder={LangFile.chat?LangFile.chat.placeholder:""} onChangeText={(e)=>{setChatMessage(e)}} locationPress={locationPress} imagePress={imagePress} submitChat={submitChat} />
  {Platform.OS==="ios"||Platform.OS==="android"&&<KeyboardSpacer topSpacing={40} />}
  </SafeAreaView>
      <Dialogue
        modalVisible={isDialogueVisible}
        close={LangFile.login?LangFile.login.close:''}
        onClosePress={(e) => onCloseDialoguePress()}
        message={messageDialogue}
      />
      <PickLang modalView={getLangModal} 
       close={LangFile.login?LangFile.login.close:""}
      render={ <RadioButton resData={resData} value={getLang} onSelectValue={onSelectValue}/>}
      onClosePress={onClosePress}/>

<ModalLg
modalView={{ margin: 5,
    width:width/1.1,
    padding: 0,
    height:height/1.2,}}
          modalVisible={modalShow}
          render={
              <View>
                  <DescriptionText message={"Google Map"} />
                
                  <SafeAreaView style={{ flex: 1 ,width:width/1.2,height:300}}>
             {isLoading&&
                            <View style={{display:'flex',flexDirection:'column', justifyContent:'center',alignItems:'center'}}>
                            <ActivityIndicator animating={true} color={Colors.red800} />
                            <Text style={{ color: "#01122e",fontSize: 20,fontWeight:'bold',}}>... a moment</Text>
                          </View>}
                  <WebView
                  style={[{ flex: 1 ,height:height}]}
                      javaScriptEnabled={true}
                      javaScriptEnabledAndroid={true}
                      scalesPageToFit={false}
                      allowFileAccess={false}
                      originWhitelist={['*']}
                      source={{ uri:`https://www.google.com/maps/@${parseData.lat},${parseData.long},15z`}}
                      onLoadStart={()=>setLoading(true)}
                      onLoadEnd={()=>setLoading(false)}
                    />
                          
                    </SafeAreaView>
            <View >
            <Button  
      buttonText={LangFile.login.close}
      onPress={(e) => setModalShow(false)}
    />

    
    </View> 
    </View>
          }
        />

<ModalMd
modalView={{
    width:width/1.2,
    height:height/1.3,}}
          modalVisible={modalShow2}
          render={
              <View>
                  <DescriptionText message={"Image View"} />
                
                  <SafeAreaView style={{ flex: 1 ,width:width/1.2,height:300}}>
            
                            <View style={{display:'flex',flexDirection:'column', justifyContent:'center',alignItems:'center'}}>
                           <Image style={{width:width/1.5,height:height/2.3}}  source={{uri:`${parseData.url}`}}  />
                          </View>
                    </SafeAreaView>
            <View >
            <Button  
      buttonText={LangFile.login.close}
      onPress={(e) => setModalShow2(false)}
    />

    
    </View> 
    </View>
          }
        />
            </View>
            </ImageBackground>
          </View>
      )

}
import React, { useContext, useEffect, useState } from 'react'
import RegisterStyle from "./registerscreenstyle";
import { ImageBackground, Linking, ScrollView, StatusBar, View } from 'react-native';
import BackGroundImage from "../../../assets/farm.jpeg"
import {Logo,Label, SpaceBottom, Button, Lang, RadioButton, TextInputed, Dialogue} from '../../../Components';
import {igbo,english,hausa,yoruba} from '../../../Lang';
import PickLang from '../../../Modals/Language/PickLang';
import { _retrieveData, _storeData } from '../../../Service/function';
import { checkProfile, pickLang } from '../../../Auth';
import { AuthContext } from '../../../context/authContext/AuthContext';
import Constants from 'expo-constants';
import { register } from '../../../context/authContext/apiCalls';
export default function RegisterScreen({navigation,route}) {
    const [LangFile,setLangFile] = useState(english);
    const [getPhoneNumber, setPhoneNumber] = useState(null);
    const [getPassword, setPassword] = useState("");
    const [getUserName, setUserName] = useState(null);
    const [user, setUser] = useState({});
    const [isLoading, setLoading] = useState(false);
    const [isSecure, setSecure] = useState(true);
    const [secureIcon, setSecureIcon] = useState("eye");

    //Dialogue Box
    const [messageDialogue, setmessageDialogue] = useState("Hello");
    const [isDialogueVisible, setisDialogueVisible] = useState(false);

    //Language
    const [getLang,setLang] = useState(0);
    const [getLangModal,setLangModal] = useState(false);

    const {dispatch,isFetching}=useContext(AuthContext);

    const resData=[
        {
          key:0,
          text:'English'
      },
      {
          key:1,
          text:'Hausa'
      },
      {
          key:2,
          text:'Igbo'
      },
      {
          key:3,
          text:'Yoruba'
      }
    ];
    useEffect(() => {
        pickLang(setLangFile,english,hausa,igbo,yoruba);
      },[]);

      useEffect(() => {
        checkProfile(navigation,setUser); 
      },[]);

    const OpenHref= async ()=>{
        await Linking.openURL('https://niss.gov.ng');
      }

      const onClosePress=()=>{
        // pickLang(setLangFile,english,hausa,igbo,yoruba);
        setLangModal(false);
       }

      const showLangModal=()=>{
        setLangModal(true);
       }

    const onSelectValue=(val)=>{
        setLangFile(val==0?english:val==1?hausa:val==2?igbo:yoruba)
        setLang(val);
        _storeData(val,'lang')
        setLangModal(false); 
    }

    const changeSecure = () => {
        setSecure(isSecure ? false : true)
        setSecureIcon(isSecure ? 'eye-slash' : 'eye');
      }

    const RegisterUser=async()=>{
        await register({
            password:getPassword,
            phone:getPhoneNumber,
            name:getUserName,
            app_auth:Constants.installationId,
            web_auth:'',
            confirmed:true},dispatch)
       .then(async(res)=>{
        if(res.jwt){
           await _storeData(res.jwt,'token')
           await _storeData(res.user,'userauth')
           checkProfile(navigation,setUser); 
            // navigation.navigate('Home');
        }
       })
      }
    return (
      <View style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
         <ImageBackground source={BackGroundImage} style={RegisterStyle.register}>
              <View style={RegisterStyle.child}>
              {getLang==0&&
   <Lang message="ENGLISH" onPress={()=>showLangModal()}/>
      }
     {getLang==1&&
   <Lang message="HAUSA" onPress={()=>showLangModal()}/>
      }
      {getLang==2&&
   <Lang message="IGBO" onPress={()=>showLangModal()}/>
      }
      {getLang==3&&
   <Lang message="YORUBA" onPress={()=>showLangModal()}/>
      }
     <Logo />
      <Label color="#000" message={LangFile.register?LangFile.register.title:''} fonts={16} />
      <ScrollView style={RegisterStyle.container}>
      <TextInputed
          showIcon1={true}
          showIcon2={false}
          iconName1={'user'}
          // iconName2={'eye'}
          placeholder={LangFile.register?LangFile.register.name_holder:''}
          onChangeText={(e) => setUserName(e)}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          Secure={false}
        />
        <TextInputed
          showIcon1={true}
          showIcon2={false}
          iconName1={'phone'}
          // iconName2={'eye'}
          placeholder={LangFile.login?LangFile.login.phone:''}
          onChangeText={(e) => setPhoneNumber(e)}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          Secure={false}
          keyboardType={'numeric'}
        />
        <TextInputed
          showIcon1={true}
          showIcon2={true}
          iconName1={'lock'}
          iconName2={secureIcon}
          placeholder={LangFile.login?LangFile.login.password:''}
          onPress={() => changeSecure()}
          onChangeText={(e) => setPassword(e)}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          Secure={isSecure}
        // keyboardType={'numeric'}
        />
         <Button
        buttonText={LangFile.register?LangFile.register.register:''}
        onPress={RegisterUser}
        isLoading={isFetching}
        message={LangFile.login?LangFile.login.moment:''}
      />
        <View style={RegisterStyle.bottomView} >
         
          <Label click={true} color="#000" message={LangFile.welcome?LangFile.welcome.login:''} onPress={() => navigation.navigate('Login')} fonts={16} />
          <Label click={true} color="#000" message={LangFile.login?LangFile.login.forgot_password:''} onPress={() => navigation.navigate('ForgetPassword')} fonts={16} />
        </View>
        <Label fonts={12} click={true} color="#000" onPress={OpenHref} message={LangFile.welcome?LangFile.welcome.project+"Nigeria Institute of Soil Science (NISS)":''}/>
       
      </ScrollView>
     
      <PickLang modalView={getLangModal} 
       close={LangFile.login?LangFile.login.close:''}
      render={ <RadioButton resData={resData} value={getLang} onSelectValue={onSelectValue}/>}
      onClosePress={onClosePress}/>
       <Dialogue
        modalVisible={isDialogueVisible}
        close={LangFile.login?LangFile.login.close:''}
        onClosePress={(e) => onCloseDialoguePress()}
        message={messageDialogue}
      />
              </View>
        </ImageBackground>
        </View>
    )
}

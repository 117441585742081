import React from 'react';
import Icon from 'react-native-vector-icons/FontAwesome';
import { Text, View,Image,StyleSheet,ActivityIndicator,Dimensions, TouchableOpacity } from 'react-native';
import {imageUrl} from '../env';
const {width,height}=Dimensions.get('screen');
export default function ImageSelect(props) {
    return (
        <TouchableOpacity onPress={() => props.onPress()} style={styles.container}>
           {props.imgUrl!=''&&
            <Image
        style={{ width:width*0.6,height:250}}
        source={{uri:props.imgUrl}}
         /> 
}
{props.srcUrl&&
            <Image
        style={{ width:width*0.6,height:250}}
        source={{uri:imageUrl+props.srcUrl}}
         /> 
}
      <Text style={{color: "#006400",
        fontSize: 15,
        fontWeight:'bold'}}
        >{props.srcUrl?'':props.imgUrl!=''?"":props.message}</Text>
           
      </TouchableOpacity>
    );
}
const styles = StyleSheet.create({
    container: {
      backgroundColor: "#dec",
      width:width*0.85,
      height:300,
      marginLeft:width*0.06,
      marginRight:width*0.06,
      marginBottom:20,
      flexDirection: "row",
      justifyContent:'center',
      alignItems: "center",
      shadowColor: "#000",
      shadowOffset: { width: 0, height: 1 },
      shadowRadius: 5,
      shadowOpacity: 5,
      elevation: 10,
      borderRadius: 4,
      borderColor: '#000',
    },
});






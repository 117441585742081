import React from 'react';
import Icon from 'react-native-vector-icons/FontAwesome';
import { Text, View,Image,StyleSheet,ActivityIndicator, TouchableOpacity } from 'react-native';

export default function SpaceBottom(props) {
    return (
        <View style={{marginBottom:props.height}}>
      
      </View>
    );
}






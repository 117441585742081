import React, { useEffect, useState } from 'react'
import { ImageBackground, ScrollView, View,DatePickerAndroid, Text } from 'react-native';
import { pickLang } from '../../../Auth';
import SaleListStyle from "./salesliststyle";
import {igbo,english,hausa,yoruba} from '../../../Lang';
import { Button, GoBack, Label } from '../../../Components';
import BackGroundImage from "../../../assets/farm.jpeg";
import { DataTable,FAB } from 'react-native-paper';
import moment from 'moment';
import { naira } from '../../../env';
import { addComma, getDataDateDiff, _retrieveData } from '../../../Service/function';
import { useIsFocused } from '@react-navigation/native';

const numberOfItemsPerPageList = [2, 3, 4];

const items = [
  {
    key: 1,
    name: 'Page 1',
  },
  {
    key: 2,
    name: 'Page 2',
  },
  {
    key: 3,
    name: 'Page 3',
  },
];
export default function SalesList({navigation,route}) {
    const [getLang,setLang] = useState(0);
    const [datefrom,setDateFrom]=useState(moment().subtract(7, "days"));
    const [dateto,setDateTo]=useState(moment());
    const [LangFile,setLangFile] = useState(english);
    const [sales,setSales]=useState([]);
    const [page, setPage] = React.useState(0);
  const [numberOfItemsPerPage, onItemsPerPageChange] = React.useState(numberOfItemsPerPageList[0]);
  const from = page * numberOfItemsPerPage;
  const to = Math.min((page + 1) * numberOfItemsPerPage, items.length);
    const isFocused = useIsFocused();

  React.useEffect(() => {
    setPage(0);
  }, [numberOfItemsPerPage]);

    useEffect(() => {
        getAllSales();
        pickLang(setLangFile,english,hausa,igbo,yoruba);
       
      },[isFocused]);

const DateFrom=async()=>{
    try {
        const {
          action,
          year,
          month,
          day
        } = await DatePickerAndroid.open({
          // Use `new Date()` for current date.
          // May 25 2020. Month 0 is January.
          date: datefrom.toDate()
        });
        if (action !== DatePickerAndroid.dismissedAction) {
            setDateFrom(moment(`${year}-${month.length>1?month + 1:"0"+(month+1)}-${day}`))
        }
      } catch ({ code, message }) {
        // console.warn('Cannot open date picker', message);
      }
}

const DateTo=async()=>{
    try {
        const {
          action,
          year,
          month,
          day
        } = await DatePickerAndroid.open({
          // Use `new Date()` for current date.
          // May 25 2020. Month 0 is January.
          date: dateto.toDate()
        });
        if (action !== DatePickerAndroid.dismissedAction) {
            setDateTo(moment(`${year}-${month.length>1?month + 1:"0"+(month+1)}-${day}`))
          // Selected year, month (0-11), day
        }
      } catch ({ code, message }) {
        // console.warn('Cannot open date picker', message);
      }
}

const getAllSales=async()=>{
    const retSales=await _retrieveData("SaleRecord");
    if(retSales){
    setSales(retSales.reverse());
    }
}

const onSearch=async()=>{
    const retSales=await _retrieveData("SaleRecord");
const filterData=await getDataDateDiff(retSales,datefrom,dateto);
setSales(filterData);
}


    return (
        <ImageBackground source={BackGroundImage} style={SaleListStyle.parent}>
            <View style={SaleListStyle.child}>
            <GoBack onPress={() => navigation.goBack()} onAddPress={()=>navigation.navigate('AddSaleList')} buttonText={LangFile.servicemenu?.salesrecord} add={true} addText={LangFile.records?.add} iconLeft={"plus"} iconLeftColor={'green'} />
            <Label fonts={15} color="#000" click={false} message={LangFile.records?.aboutSale}/>
            <View style={SaleListStyle.dateFilter}>
               <Button
               altStyle={SaleListStyle.buttonFilter}
               altText={SaleListStyle.altText}
                buttonText={LangFile.records?.datefrom+" "+datefrom.format("DD-MM-YYYY")}
                onPress={DateFrom}
      />
      <Button
      altStyle={SaleListStyle.buttonFilter}
      altText={SaleListStyle.altText}
                buttonText={LangFile.records?.dateto+" "+dateto.format("DD-MM-YYYY")}
                onPress={DateTo}
      />
       <Button
               altStyle={SaleListStyle.submitbuttonFilter}
               altText={SaleListStyle.altText}
                buttonText={LangFile.records?.search}
                onPress={onSearch}
      />
               </View>
               <ScrollView style={SaleListStyle.scrollView}>
           <View style={SaleListStyle.tableContainer}>
               
           <ScrollView horizontal>
            <DataTable style={SaleListStyle.Datatable} >
        <DataTable.Header>
          <DataTable.Title style={SaleListStyle.Titletable} >{LangFile.salerecord?.date}</DataTable.Title>
          <DataTable.Title style={SaleListStyle.Titletable}>{LangFile.salerecord?.product}</DataTable.Title>
          <DataTable.Title style={SaleListStyle.Titletable}>{LangFile.salerecord?.quantity}</DataTable.Title>
          <DataTable.Title style={SaleListStyle.Titletable}>{LangFile.salerecord?.value}({naira})</DataTable.Title>
          <DataTable.Title style={SaleListStyle.Titletable} numeric>{LangFile.salerecord?.total}({naira})</DataTable.Title>
        </DataTable.Header>
        {sales.map((item,i)=>(
             <DataTable.Row key={i} onPress={()=>navigation.navigate('AddSaleList',{datar:item,index:i})}>
             <DataTable.Cell >{item.date}</DataTable.Cell>
               <DataTable.Cell>{item.product}</DataTable.Cell>
               <DataTable.Cell>{item.quantity}</DataTable.Cell>
               <DataTable.Cell>{item.value}/{item.units}</DataTable.Cell>
               <DataTable.Cell numeric>{addComma(item.total)}</DataTable.Cell>
             </DataTable.Row>
        ))}
       
       <DataTable.Pagination
        page={page}
        numberOfPages={Math.ceil(sales.length / numberOfItemsPerPage)}
        onPageChange={page => setPage(page)}
        label={`${from + 1}-${to} of ${items.length}`}
        showFastPaginationControls
        numberOfItemsPerPageList={numberOfItemsPerPageList}
        numberOfItemsPerPage={numberOfItemsPerPage}
        onItemsPerPageChange={onItemsPerPageChange}
        selectPageDropdownLabel={'Rows per page'}
      />
      </DataTable>
      </ScrollView>
      </View>
      </ScrollView>
            </View>
        </ImageBackground>
    )
}

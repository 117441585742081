import React from 'react';
import Icon from 'react-native-vector-icons/FontAwesome';
import Iconed from 'react-native-vector-icons/MaterialCommunityIcons';
import { Text, View,Image,StyleSheet,Dimensions,ActivityIndicator, TouchableOpacity } from 'react-native';
const {width,height}=Dimensions.get('screen');
import ImageBack from "../assets/user.png"
import { imageUrl } from '../env';

export default function Scientist(props) {
    return (
       <TouchableOpacity
        style={styles.button1}
        onPress={() => props.onPress()}
      >
          {/* {props.type&&
           <Iconed name={props.iconName} style={{color: props.colorIcon,fontSize: 35,padding: 5}}></Iconed>
          }
           {!props.type&&
           <Icon name={props.iconName} style={{color: props.colorIcon,fontSize: 35,padding: 5}}></Icon>
           } */}
             <Image style={{height: height*0.08,width:width*0.46,padding: 0,resizeMode:'contain',borderRadius:200}} source={props.image?{
          uri:imageUrl+props.image}:ImageBack}/>
        <Text style={styles.btnText}>{props.title}</Text>
        <Text style={styles.btnsmText}>{props.subtitle}</Text>
      </TouchableOpacity>
    );
}
const styles=StyleSheet.create({
    button1: {
        flex:0.5,
        borderColor: "rgba(255,255,255,0.7)",
        display:'flex',
        flexDirection:'column',
        alignItems: "center",
        padding: 10,
        margin: 10,
        backgroundColor:'rgba(255,255,255,0.5)',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 15,
        borderBottomRightRadius: 10,
        borderBottomLeftRadius: 10,
        borderColor:'white',
        borderWidth:5,
        marginLeft: 10,
        marginRight: 0,
        // borderWidth: 2
      },
      btnText: {
        color: "#000",
        bottom:0,
        padding:5,
        maxWidth:width/2.5,
        textAlign: 'center',
        fontSize: 13,
        fontWeight:'bold',
      }, 
      btnsmText: {
        color: "gray",
        bottom:0,
        padding:1,
        textAlign: 'center',
        maxWidth:width/2.5,
        fontSize: 12,
        fontWeight:'bold',
      }, 
      iconStyle1: {
        color: "#000",
        fontSize: 15,
        paddingLeft: 12
      },
})






import React, { useState } from 'react'
import { ImageBackground,Dimensions, View, SafeAreaView, Text } from 'react-native';
import { GoBack } from '../../Components';
import BrowserStyle from "./browserstyle";
import BackGroundImage from "../../assets/farm.jpeg"
import { WebView } from "react-native-webview";
import { ActivityIndicator, Colors } from 'react-native-paper';
const {width,height}=Dimensions.get('screen');

export default function Browser({navigation,route}) {
    const [isLoading,setLoading]=useState(true);
    return (
        <ImageBackground source={BackGroundImage} style={BrowserStyle.parent}>
             <View style={BrowserStyle.child}>
             <GoBack onPress={() => navigation.goBack()} buttonText={route.params.datars?.name} />
             <SafeAreaView style={{ flex: 1 ,width:width}}>
             {isLoading&&
                            <View style={{display:'flex',flexDirection:'column', justifyContent:'center',alignItems:'center'}}>
                            <ActivityIndicator animating={true} color={Colors.red800} />
                            <Text style={{ color: "#01122e",fontSize: 20,fontWeight:'bold',}}>... a moment</Text>
                          </View>}
                  <WebView
                  style={[{ flex: 1 }]}
                      javaScriptEnabled={true}
                      javaScriptEnabledAndroid={true}
                      originWhitelist={['*']}
                      source={{ uri:route.params.datars?.url}}
                      onLoadStart={()=>setLoading(true)}
                      onLoadEnd={()=>setLoading(false)}
                    />
                          
                    </SafeAreaView>
             </View>
        </ImageBackground>
    )
}

import { StyleSheet,Dimensions,Platform } from 'react-native';
const {width,height}=Dimensions.get('screen');
const isSmallDevice = Dimensions.get('window').width < 768

export default StyleSheet.create({
    parent:{
      ...Platform.select({
        ios: {
            height:height,
            width:width
          },
          android: {
           height:height,
           width:width
          },
          default: {
            // other platforms, web for example
            height:height/1.2,
            width:isSmallDevice?width:width/2.5
          }
        }),
    },
    child: {
        flex: 1,
        backgroundColor: 'rgba(255,255,255,0.7)'
      },

      container:{
    ...Platform.select({
      ios: {
        marginBottom:height*0.1
        },
        android: {
          marginBottom:height*0.1
        },
        default: {
          // other platforms, web for example
          marginBottom:0
        }
      }),
     },
     bottomView:{
        flexDirection:'row',
        justifyContent:'center',
        margin:20
     },
     flatContainer:{
        flex: 1,
        flexDirection: 'column',
        alignItems:'center',
        justifyContent: 'space-between',
        padding: 10,
        marginBottom: 40
      },
  
});
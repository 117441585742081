
// import styles from '../Styles/login';
import React,{useEffect, useState} from 'react';
import Icon from 'react-native-vector-icons/FontAwesome';
import { Text, View,Image,Modal,StyleSheet,Dimensions, TouchableOpacity } from 'react-native';
import {Button,ModalMd,RadioButton,DescriptionText} from '../../Components'
import { pickLang } from '../../Auth';
import {igbo,english,hausa,yoruba} from '../../Lang';
// import styles from '../Styles/login';

const {width,height}=Dimensions.get('screen');

export default function PickLang(props) {
  const [getLang,setLang] = useState(0);
  const [LangFile,setLangFile] = useState(english);

   useEffect(()=>{
    pickLang(setLangFile,english,hausa,igbo,yoruba);

   },[])
   
    return (
       <View>
       <ModalMd
          modalVisible={props.modalView}
          render={
              <View>
                  <DescriptionText message={LangFile.naming.selectlanguage} />
          {props.render}
            <View style={{marginTop: 100}}>
            <Button  
      buttonText={props.close}
      onPress={(e) => props.onClosePress()}
    />
    </View> 
    </View>
          }
        />
        </View> 
    );
}
const styles = StyleSheet.create({
    centeredView: {
      flex: 1,
      height:height,
      width:width,
      backgroundColor:'#07090a8c',
      justifyContent: "center",
      alignItems: "center",
      marginTop: 22
    },
    modalView: {
      margin: 20,
      width:300,
      height:300,
      backgroundColor: "#dec",
      borderRadius: 20,
      padding: 35,
      alignItems: "center",
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 2
      },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      elevation: 5
    },
    button: {
      borderRadius: 20,
      padding: 10,
      elevation: 2
    },
    buttonOpen: {
      backgroundColor: "#F194FF",
    },
    buttonClose: {
      backgroundColor: "#2196F3",
    },
    textStyle: {
      color: "white",
      fontWeight: "bold",
      textAlign: "center"
    },
    modalText: {
      marginBottom: 15,
      fontSize:15,
      textAlign: "center"
    }
  });






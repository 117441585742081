import React, { useState } from 'react';
import ChatBottomStyle from "./chatbottomstyle";
import Icon from 'react-native-vector-icons/FontAwesome';
import { KeyboardAvoidingView, Text, TextInput, View } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';

export default function ChatBottom({placeholder,onChangeText,keyboardType,defaultValue,editable,imagePress,locationPress,submitChat,value}) {
    const [show,setShow]=useState(false);
    return (
        <>
        {show&&
         <View style={ChatBottomStyle.containerTop}>
         <Icon onPress={imagePress} style={ChatBottomStyle.statusTop} name={'camera'} ></Icon>  
         <Icon onPress={locationPress} style={ChatBottomStyle.statusTop} name={'map-marker'} ></Icon>  
         </View>}
        <View style={ChatBottomStyle.container}>
           <Icon onPress={()=>setShow(show?false:true)} style={ChatBottomStyle.status} name={'paperclip'} ></Icon>
           <TextInput 
      placeholder={placeholder} 
      onChangeText={text => onChangeText(text)}
      placeholderTextColor={'gray'}
      color={'black'}
      value={value}
      keyboardType = {keyboardType}
      defaultValue={defaultValue}
      editable={editable}
      style={ChatBottomStyle.inputStyle}></TextInput>
      <TouchableOpacity onPress={submitChat} style={ChatBottomStyle.sendButton} >
      <Icon style={ChatBottomStyle.statusLeft} name={'send-o'} ></Icon>
      </TouchableOpacity>
        </View>
        </>
    )
}

import React, { useEffect, useState } from 'react'
import { ImageBackground, Linking, Platform, SafeAreaView, ScrollView, View } from 'react-native';
import { Button, GoBack } from '../../Components';
import ViewPdfStyle from "./viewpdfstyle";
import {igbo,english,hausa,yoruba} from '../../Lang';
import { pickLang } from '../../Auth';
import BackGroundImage from "../../assets/farm.jpeg";
import { baseUrl, imageUrl } from '../../env';
import { Document, Page,pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;
import AllPagesPDFViewer from "../components/pdf/all-pages";
import ClipLoader from "react-spinners/ClipLoader";
export default function ViewPdf({navigation,route}) {
    const [loading,setLoading] = useState(true);
    const [LangFile,setLangFile] = useState(english);
    const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
    useEffect(() => {
        pickLang(setLangFile,english,hausa,igbo,yoruba);
      },[]);

      const downloadFile=(url)=>{
          setLoading(true);
          
      }

      function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
      }
console.log(imageUrl+route.params.datars.image.url);
    return (
        <View style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
        <ImageBackground source={BackGroundImage} style={ViewPdfStyle.parent}>
        <View style={ViewPdfStyle.child}>
             <GoBack onPress={() => navigation.goBack()} buttonText={LangFile.records.viewpdf} />
             {/* <Button
             isLoading={loading}
               altStyle={ViewPdfStyle.buttonFilter}
               altText={ViewPdfStyle.altText}
                buttonText={LangFile.records?.openfolder}
            onPress={()=>route.params.type?downloadFile(`${imageUrl+route.params.datars.image.url}`):FileViewer.open(`file:///${route.params.url}`)}
      /> */}
      <SafeAreaView style={ViewPdfStyle.containerArea}>
     
      <ScrollView >
          {route.params.type==="online"&&
          <View style={ViewPdfStyle.documentArea}>
          <AllPagesPDFViewer 
          pdf={imageUrl+route.params.datars.image.url}
          onDocumentLoadSuccess={()=>setLoading(false)}
           />
           </View>
        }
         {!route.params.type&&
            <Document file={`file:///${route.params.url}`} onLoadSuccess={onDocumentLoadSuccess}>
          </Document>}
        </ScrollView>
       
        </SafeAreaView>
        </View>
        </ImageBackground>
        </View>
    )
}


import React, { useState,useEffect } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import WelcomeScreen from './src/screens/Auth/HomeScreen/HomeScreen';
import LoginScreen from './src/screens/Auth/LoginScreen/LoginScreen';
import RegisterScreen from './src/screens/Auth/RegisterScreen/RegisterScreen';
import ForgetPasswordScreen from './src/screens/Auth/ForgetPasswordScreen/ForgetPasswordScreen';
import AuthorizationScreen from './src/screens/Steps/AuthorizationScreen/AuthorizationScreen';;
import LocationScreen from './src/screens/Steps/LocationScreen/LocationScreen';
import PermissionScreen from './src/screens/Steps/PermissionScreen/PermissionScreen';
import HomeScreen from './src/screens/Home/Home';
import CropScreen from './src/screens/Library/CropScreen/CropScreen';
import LivestockScreen from './src/screens/Library/LiveStockScreen/LiveStockScreen';
import DetailScreen from './src/screens/Library/DetailScreen/DetailScreen';
import StoreScreen from './src/screens/Buy/StoreScreen/StoreScreen';
import StoreDetailScreen from './src/screens/Buy/StoreDetail/StoreDetail';
import OfficeDetail from "./src/screens/Buy/StoreScreen/OfficeDetail";
import ScientistList from "./src/screens/Buy/StoreScreen/ScientistList";
import FarmServiceScreen from './src/screens/FarmService/FarmService';
import ServiceSearchScreen from './src/screens/FarmService/FarmServiceSearch/ServiceSearch';

import ChatListScreen from './src/screens/Chatscreen/ChatListScreen/ChatListScreen';
import ReplyListScreen from './src/screens/Chatscreen/ReplyListScreen/ReplyListScreen';
import ReplyDetailScreen from './src/screens/Chatscreen/ReplyDetailScreen/ReplyDetailScreen';

import StateListInspection from './src/screens/SoilInspection/StateList/StateListScreen';
import StateDetailInspection from "./src/screens/SoilInspection/StateDetail/StateDetailScreen";

import FarmRecordScreen from "./src/screens/FarmRecord/FarmRecordScreen";
import SaleListScreen from "./src/screens/FarmRecord/SalesRecord/SalesList";
import AddSaleListScreen from "./src/screens/FarmRecord/SalesRecord/AddSalesRecord/AddSales";
import ExpenseListScreen from "./src/screens/FarmRecord/ExpenseRecord/ExpenseList";
import AddExpenseListScreen from "./src/screens/FarmRecord/ExpenseRecord/AddExpenseRecord/AddExpense";
import InputListScreen from "./src/screens/FarmRecord/InputRecord/InputList";
import AddInputListScreen from "./src/screens/FarmRecord/InputRecord/AddInputRecord/AddInput";
import ProductionListScreen from "./src/screens/FarmRecord/ProductionRecord/ProductionList";
import AddProductionListScreen from "./src/screens/FarmRecord/ProductionRecord/AddProductionRecord/AddProduction";

import IncomeListScreen from "./src/screens/FarmRecord/IncomeRecord/IncomeList";
import AddIncomeListScreen from "./src/screens/FarmRecord/IncomeRecord/AddIncomeRecord/AddIncome";
import StockListScreen from "./src/screens/FarmRecord/StockRecord/StockList";
import AddStockListScreen from "./src/screens/FarmRecord/StockRecord/AddStockRecord/AddStock";

import SummaryScreen from "./src/screens/FarmRecord/SummaryRecord/SummaryScreen";
import ReportScreen from "./src/screens/FarmRecord/Reports/ReportScreen";
import BrowserScreen from "./src/screens/Browser/Browser";
import TaskScreen from "./src/screens/TaskScreen/TaskList";
import AddTaskScreen from "./src/screens/TaskScreen/AddTask/AddTask";
import CalendarScreen from "./src/screens/TaskScreen/Calendar/Calendar";
import ViewPdf from "./src/screens/ViewPdf/ViewPdf";
import ListScreen from './src/screens/ProfileScreen/ProfileList';
import UserInfo from "./src/screens/ProfileScreen/UserInfo/UserInfo";
import BusinessInfo from "./src/screens/ProfileScreen/BusinessInfo/BusinessInfo";
import LocationInfo from "./src/screens/ProfileScreen/LocationInfo/LocationInfo";
import PasswordInfo from "./src/screens/ProfileScreen/PasswordInfo/PasswordInfo";

import InvoiceList from "./src/screens/Invoice/InvoiceList";
import AddInvoiceList from "./src/screens/Invoice/AddInvoice/AddInvoice";

import NotificationList from "./src/screens/Notification/NotificationList";

import ProductList from "./src/screens/Product/ProductList/ProductListScreen";
import ProductDetail from "./src/screens/Product/ProductDetail/ProductDetailScreen";




import PublicationDetail from "./src/screens/Publication/PublicationDetail";
import PublicationList from "./src/screens/Publication/PublicationList";


import * as SplashScreen from 'expo-splash-screen';
import Toast from 'react-native-toast-message';
import { Provider as PaperProvider } from 'react-native-paper';


import * as Updates from 'expo-updates';
import { AuthContextProvider } from './src/context/authContext/AuthContext';
import { _retrieveData } from './src/Service/function';



const Stack = createStackNavigator();
// SplashScreen.preventAutoHideAsync();
export default function App() {
 const [user,setUser]=useState(null);
  useEffect(() => {
    SplashScreen.hideAsync();
    // setTimeout(() => SplashScreen.hideAsync(), 500);
    checkUpdate();
  }, []);

const checkUpdate= async ()=>{
  try {
    const update = await Updates.checkForUpdateAsync();
    if (update.isAvailable) {
      await Updates.fetchUpdateAsync();
      // ... notify user of update ...
     await Updates.reloadAsync();
    }
  } catch (e) {
    // handle or log error
  }
}

  return (
  <PaperProvider>
   <AuthContextProvider>
    <NavigationContainer>
      <Stack.Navigator>
        <Stack.Screen
          name="Welcome"
          component={WelcomeScreen}
          options={{ title: 'Welcome', headerShown: false }}
        />
         <Stack.Screen
          name="Login"
          component={LoginScreen}
          options={{ title: 'Login', headerShown: false }}
        />
         <Stack.Screen
          name="Register"
          component={RegisterScreen}
          options={{ title: 'Register', headerShown: false }}
        />
         <Stack.Screen
          name="ForgetPassword"
          component={ForgetPasswordScreen}
          options={{ title: 'Forgot Password', headerShown: false }}
        />
         <Stack.Screen
          name="Authorization"
          component={AuthorizationScreen}
          options={{ title: 'Authorization Step', headerShown: false }}
        />
         <Stack.Screen
          name="Location"
          component={LocationScreen}
          options={{ title: 'Location & Language Step', headerShown: false }}
        />
       <Stack.Screen
          name="Permission"
          component={PermissionScreen}
          options={{ title: 'Permission and Role', headerShown: false }}
        />
         <Stack.Screen
          name="Home"
          component={HomeScreen}
          options={{ title: 'Home page', headerShown: false }}
        />
         <Stack.Screen
          name="Crop"
          component={CropScreen}
          options={{ title: 'Crop Page', headerShown: false }}
        />
          <Stack.Screen
          name="Livestock"
          component={LivestockScreen}
          options={{ title: 'Livestock Page', headerShown: false }}
        />
         <Stack.Screen
          name="Detail"
          component={DetailScreen}
          options={{ title: 'Detail Screen', headerShown: false }}
        />

        
        <Stack.Screen
          name="Product"
          component={StoreScreen}
          options={{ title: 'Store Screen', headerShown: false }}
        />
        <Stack.Screen
          name="StoreDetail"
          component={StoreDetailScreen}
          options={{ title: 'Store Detail', headerShown: false }}
        />
         <Stack.Screen
          name="OfficeDetail"
          component={OfficeDetail}
          options={{ title: 'Office Detail', headerShown: false }}
        />
         <Stack.Screen
          name="ScientistList"
          component={ScientistList}
          options={{ title: 'Scientist List', headerShown: false }}
        />
         <Stack.Screen
          name="FarmService"
          component={FarmServiceScreen}
          options={{ title: 'Farm Service', headerShown: false }}
        />
         <Stack.Screen
          name="ServiceSearch"
          component={ServiceSearchScreen}
          options={{ title: 'Farm Service', headerShown: false }}
        />
         <Stack.Screen
          name="ChatList"
          component={ChatListScreen}
          options={{ title: 'Chat List', headerShown: false }}
        />
         <Stack.Screen
          name="ReplyList"
          component={ReplyListScreen}
          options={{ title: 'ReplyList', headerShown: false }}
        />
            <Stack.Screen
          name="ReplyDetail"
          component={ReplyDetailScreen}
          options={{ title: 'Reply Detail', headerShown: false }}
        />
        <Stack.Screen
          name="Inspection"
          component={StateListInspection}
          options={{ title: 'State Inspection', headerShown: false }}
        />
        <Stack.Screen
          name="DetailInspection"
          component={StateDetailInspection}
          options={{ title: 'State Detail Inspection', headerShown: false }}
        />
         <Stack.Screen
          name="FarmRecord"
          component={FarmRecordScreen}
          options={{ title: 'Farm Record', headerShown: false }}
        />
         <Stack.Screen
          name="SaleList"
          component={SaleListScreen}
          options={{ title: 'Farm Sale Record', headerShown: false }}
        />
         <Stack.Screen
          name="AddSaleList"
          component={AddSaleListScreen}
          options={{ title: 'AddSale Record', headerShown: false }}
        />
         <Stack.Screen
          name="ExpenseList"
          component={ExpenseListScreen}
          options={{ title: 'Farm Sale Record', headerShown: false }}
        />
         <Stack.Screen
          name="AddExpenseList"
          component={AddExpenseListScreen}
          options={{ title: 'AddSale Record', headerShown: false }}
        />
        <Stack.Screen
          name="InputList"
          component={InputListScreen}
          options={{ title: 'Farm Sale Record', headerShown: false }}
        />
         <Stack.Screen
          name="AddInputList"
          component={AddInputListScreen}
          options={{ title: 'AddSale Record', headerShown: false }}
        />
         <Stack.Screen
          name="IncomeList"
          component={IncomeListScreen}
          options={{ title: 'Income Record', headerShown: false }}
        />
         <Stack.Screen
          name="AddIncomeList"
          component={AddIncomeListScreen}
          options={{ title: 'Add  Income Record', headerShown: false }}
        />
         <Stack.Screen
          name="StockList"
          component={StockListScreen}
          options={{ title: 'Stock Record', headerShown: false }}
        />
         <Stack.Screen
          name="AddStockList"
          component={AddStockListScreen}
          options={{ title: 'Add Stock Record', headerShown: false }}
        />
         <Stack.Screen
          name="ProductionList"
          component={ProductionListScreen}
          options={{ title: 'Farm Sale Record', headerShown: false }}
        />
         <Stack.Screen
          name="AddProductionList"
          component={AddProductionListScreen}
          options={{ title: 'AddSale Record', headerShown: false }}
        />
       <Stack.Screen
          name="Summary"
          component={SummaryScreen}
          options={{ title: 'Record Summary', headerShown: false }}
        />
         <Stack.Screen
          name="Reports"
          component={ReportScreen}
          options={{ title: 'Report', headerShown: false }}
        />
         <Stack.Screen
          name="Browser"
          component={BrowserScreen}
          options={{ title: 'Browser', headerShown: false }}
        />
          <Stack.Screen
          name="Task"
          component={TaskScreen}
          options={{ title: 'Task', headerShown: false }}
        />
         <Stack.Screen
          name="AddTaskList"
          component={AddTaskScreen}
          options={{ title: 'Add Task', headerShown: false }}
        />
         <Stack.Screen
          name="CalendarList"
          component={CalendarScreen}
          options={{ title: 'Calendar Task', headerShown: false }}
        />
         <Stack.Screen
          name="ViewPdf"
          component={ViewPdf}
          options={{ title: 'View Pdf', headerShown: false }}
        />
        <Stack.Screen
          name="ProfileList"
          component={ListScreen}
          options={{ title: 'Profile Screen', headerShown: false }}
        />
        <Stack.Screen
          name="UserInfo"
          component={UserInfo}
          options={{ title: 'User Information', headerShown: false }}
        />
         <Stack.Screen
          name="BusinessInfo"
          component={BusinessInfo}
          options={{ title: 'Business Information', headerShown: false }}
        />
         <Stack.Screen
          name="LocationInfo"
          component={LocationInfo}
          options={{ title: 'Location Information', headerShown: false }}
        />
         <Stack.Screen
          name="PasswordInfo"
          component={PasswordInfo}
          options={{ title: 'Password Information', headerShown: false }}
        />
         <Stack.Screen
          name="InvoiceList"
          component={InvoiceList}
          options={{ title: 'Invoice List', headerShown: false }}
        />
        <Stack.Screen
          name="AddInvoiceList"
          component={AddInvoiceList}
          options={{ title: 'Add Invoice List', headerShown: false }}
        />
         <Stack.Screen
          name="NotificationList"
          component={NotificationList}
          options={{ title: 'NotificationList', headerShown: false }}
        />
        <Stack.Screen
          name="ProductList"
          component={ProductList}
          options={{ title: 'Product List', headerShown: false }}
        />
        <Stack.Screen
          name="ProductDetail"
          component={ProductDetail}
          options={{ title: 'Product Detail', headerShown: false }}
        />
        
        

        <Stack.Screen
          name="PublicationDetail"
          component={PublicationDetail}
          options={{ title: 'Publication Detail', headerShown: false }}
        />
         <Stack.Screen
          name="PublicationList"
          component={PublicationList}
          options={{ title: 'Publication List', headerShown: false }}
        /> 
       
       

        
      </Stack.Navigator>
    </NavigationContainer>
    <Toast />
    </AuthContextProvider>
   </PaperProvider>
  );
};
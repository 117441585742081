import React, { useEffect, useState } from 'react';
import { ImageBackground, SafeAreaView, View,Dimensions, ScrollView, Text, Image } from 'react-native';
import StateListStyle from "./replyliststyle";
import {igbo,english,hausa,yoruba} from '../../../Lang';
import BackGroundImage from "../../../assets/farm.jpeg"
import { Button, GoBack } from '../../../Components';
import { disableBackButton, pickLang } from '../../../Auth';
import { FlatList, TouchableOpacity } from 'react-native-gesture-handler';
import stateLGA from '../../../State/state.json';
import Icon from 'react-native-vector-icons/FontAwesome';
import IconEntypo from "react-native-vector-icons/Entypo"
import axios from "axios";
import { baseUrl, imageUrl } from '../../../env';
import { updateStateArray, _retrieveData } from '../../../Service/function';
import { useIsFocused } from '@react-navigation/native';


const {width,height}=Dimensions.get('screen');
export default function ReplyListScreen({navigation,route}) {
    const [LangFile,setLangFile] = useState(english);
    const [conversation, setConversation] = useState([]);
    const [isLoading,setLoading]=useState(false);
    const [start,setStart]=useState(0);
    const [page,setPage]=useState(20);
    const isFocused = useIsFocused();

    useEffect(()=>{ 
        retrieveConversation();
      },[isFocused]);
      
    useEffect(() => {
        pickLang(setLangFile,english,hausa,igbo,yoruba);
        // generateGreetings(LangFile,setGreetings);
       
        disableBackButton()
      },[]);

     

      const retrieveConversation=async()=>{
        const token = await _retrieveData("token");
       
        try {
            setLoading(true);
            const resp=await axios.get(baseUrl+`conversations/verify/reply?limit=${page}&start=${start}`,{
                headers:{
                    'Authorization':`Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            setLoading(false);
            await updateStateArray(conversation,resp.data,setConversation);
            if(resp.data.length>0){setStart(start+page);}
            
        } catch (error) {
            console.log(error.message);
            setLoading(false);
        }
      

      }

      const retrieveMoreChat=async()=>{

      }

      const renderItem = ({ item,index }) => (
     <TouchableOpacity onPress={()=>navigation.navigate("ReplyDetail",{datar:item})} key={index} style={StateListStyle.flatContainer}>
         <View style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
            <Image style={{height: 50,resizeMode:'contain',borderRadius:200, width:50,marginRight: 5}} source={{uri: item.user_one.avatar?imageUrl+item.user_one.avatar.url:""}} />
            <View style={{display:'flex',flexDirection:'column'}}>
     <Text style={StateListStyle.senderTxt}>{JSON.parse(item.question)[4].message.split("\n")[item.replies[3].reply].substr(11).replace(",","")}</Text>
     <Text style={StateListStyle.sendersubTxt}>{item.user_one.name}</Text>
     </View>
     </View>
     <IconEntypo style={item.status==1?StateListStyle.newIcon:StateListStyle.senderTxt} name={'new'} ></IconEntypo>
     </TouchableOpacity>
           );
    return (
        <View style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
        <ImageBackground source={BackGroundImage} style={StateListStyle.parent}>
            <View style={StateListStyle.child}>
        <GoBack onPress={() => navigation.navigate('Home')} buttonText={`${LangFile.menu?" "+LangFile.menu.menu10:"Soil Inspection"}` } />
        <SafeAreaView style={{height:height*0.83,marginTop: 0,backgroundColor: 'rgba(255,255,255,0.7)' }}>
      <ScrollView style={{ flex: 1, margin: 10 }}>
      <View style={StateListStyle.containertop}>
      <Text style={StateListStyle.senderTxt}>{LangFile.inspection.viewlatestchat}</Text>
      </View>
      <FlatList
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        data={conversation}
        renderItem={renderItem}
        keyExtractor={item => item.id} 
        />  
        {conversation.length>=page&&
          <Button isLoading={isLoading} altStyle={{padding:5}} onPress={()=>retrieveConversation()} altText={{fontSize:14}} buttonText={LangFile.naming?.loadmore} /> }
  </ScrollView>
  </SafeAreaView>
            </View>
        </ImageBackground>
        </View>
    )
}

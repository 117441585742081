import { StyleSheet,Dimensions } from 'react-native';
const {width,height}=Dimensions.get('screen');
export default StyleSheet.create({
    parent:{
        width: width,
        height: height,
        backgroundColor:"#000"
    },
    child: {
        flex: 1,
        backgroundColor: 'rgba(255,255,255,0.7)'
      },

      container:{
    marginBottom:height*0.1
     },
     bottomView:{
        flexDirection:'row',
        justifyContent:'center',
        margin:20
     }
  
});
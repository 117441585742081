
// import styles from '../Styles/login';
import React from 'react';
import Icon from 'react-native-vector-icons/FontAwesome';
import { Text, View,Image,Modal,StyleSheet,Dimensions, TouchableOpacity } from 'react-native';
import DescriptionText from './DescriptionText';


const {width,height}=Dimensions.get('screen');
export default function ModalLg(props) {
    return (
       
       <Modal
          animationType="slide"
          transparent={true}
          visible={props.modalVisible}
        >
          <View style={styles.centeredView}>
            <View style={[styles.modalView,props.modalView]}>
           {props.render}
            </View>
          </View>
        </Modal>  
    );
}
const styles = StyleSheet.create({
    centeredView: {
      flex: 1,
      height:height,
      width:width,
      backgroundColor:'#07090a8c',
    //   justifyContent: "center",
      alignItems: "center",
      marginTop: 22
    },
    modalView: {
      margin: 20,
      width:300,
      height:650,
      backgroundColor: "#fff",
      borderRadius: 20,
      padding: 35,
      alignItems: "center",
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 2
      },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      elevation: 5
    },
    button: {
      borderRadius: 20,
      padding: 10,
      elevation: 2
    },
    buttonOpen: {
      backgroundColor: "#F194FF",
    },
    buttonClose: {
      backgroundColor: "#2196F3",
    },
    textStyle: {
      color: "white",
      fontWeight: "bold",
      textAlign: "center"
    },
    modalText: {
      marginBottom: 15,
      fontSize:15,
      textAlign: "center"
    }
  });






import React,{useState} from 'react';
import Icon from 'react-native-vector-icons/FontAwesome';
import { Text, View,Image,Dimensions,ScrollView,StyleSheet,Platform ,Picker, TouchableOpacity } from 'react-native';

// import styles from '../Styles/login';
// import Icon from "react-native-vector-icons/Ionicons";
import Iconed from "react-native-vector-icons/MaterialCommunityIcons";
import ModalLg from './ModalLg';
import DescriptionText from './DescriptionText';
import RadioButton from './RadioButton';
import Button from './Button';
import Label from './Label';
import ModalMd from './ModalMd';
const {width,height}=Dimensions.get('screen');
const isSmallDevice = Dimensions.get('window').width < 768

function SelectInputed(props) {
  const [getSelectModal, setSelectModal] = useState(false);
  const [getSelect, setSelect] = useState(null);

  const openModal=()=>{
    setSelectModal(true);
  }
  const onClosePress=()=>{
    setSelectModal(false);
  }
 
    return (
      <>
      {props.title&&
      <Text style={{fontSize:15,fontWeight:'bold',marginLeft:22,marginBottom:5,color:"black"}}>{props.title}</Text>
      }
        <TouchableOpacity style={[styles.container, props.style]} onPress={openModal}>
             {props.showIcon1&&
      <Icon name={props.iconName1} style={styles.iconStyle1}></Icon>
             }
             {/* {!props.showIcon1&&
      <Iconed name={props.iconName1} style={styles.iconStyle1}></Iconed>
             } */}
              <Label padding={props.padding} onPress={openModal} fonts={13} width={props.textSelect??150} message={props.label} />
      <TouchableOpacity 
      onPress={openModal}
      
      // selectedValue={props.selectedValue}
      // onValueChange={text => props.onChangeText(text)}
      style={[styles.inputStyle,props.inputStyle]}
      >
        {props.Options==1&&
        <ModalLg
          modalVisible={getSelectModal}
          render={
              <View style={styles.columnContainer}>
                
                  <Label fonts={17} message={props.selectLabel} />
                  <ScrollView persistentScrollbar={true} contentContainerStyle={{paddingRight:15}}>
                  {props.render}
                  </ScrollView>
            <View style={{marginTop: 10}}>
            <Button  
      buttonText={'Close'}
      onPress={onClosePress}
    />

    </View>
        </View>
          }
        />
        }
        {props.Options==0&&
        <ModalMd
          modalVisible={getSelectModal}
          render={
              <View>
                  <Label fonts={17} message={props.selectLabel} />
                  <ScrollView persistentScrollbar={true}>
                  {props.render}
                  </ScrollView>
            <View style={{marginTop: 10}}>
            <Button  
      buttonText={'Close'}
      onPress={onClosePress}
    />

    </View>
        </View>
          }
        />
        }
        </TouchableOpacity>
      {props.showIcon2&&
      <Icon name={props.iconName2} onPress={openModal} style={styles.iconStyle}></Icon>
    }
    </TouchableOpacity>
    </>
    );
}
const styles = StyleSheet.create({
    container: {
      backgroundColor: "rgba(253,253,253,0.9)",
      marginLeft:width*0.06,
      marginRight:width*0.06,
      marginBottom:20,
      flexDirection: "row",
      alignItems: "center",
      shadowColor: "#000",
      shadowOffset: { width: 0, height: 1 },
      shadowRadius: 5,
      shadowOpacity: 5,
      elevation: 10,
      borderRadius: 4,
      borderColor: '#000',
    },
    columnContainer:{
      maxHeight:height/1.5,
    },
    iconStyle: {
      color: "#616161",
      fontSize: 24,
      right:0,
      paddingRight: 8
    },
    iconStyle1: {
      color: "#000",
      fontSize: 20,
      paddingLeft: 12
    },
    inputStyle: {
      height: 50,
      backgroundColor: 'rgba(253,253,253,0.9)',
      color: "#000",
      marginLeft: 16,
      paddingRight: 5,
      fontSize: 16,
      alignSelf: "stretch",
      flex: 1,
      paddingTop: 14,
      paddingBottom: 8,
    }
  });

  export default SelectInputed;



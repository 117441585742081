import React, { useEffect, useState } from 'react'
import { ImageBackground, ScrollView,Image, View,DatePickerAndroid, Text, FlatList, TextInput, Dimensions, TouchableOpacity, Alert, PermissionsAndroid, Platform } from 'react-native';
import InvoiceListStyle from "./addinvoicestyle";
import BackGroundImage from "../../../assets/farm.jpeg";
import { useIsFocused } from '@react-navigation/native';
import {igbo,english,hausa,yoruba} from '../../../Lang';
import { Button, GoBack, Label, ModalLg, RadioSelect, SelectInputed, TextInputed } from '../../../Components';
import moment from 'moment';
import Icon from 'react-native-vector-icons/Ionicons';
import { pickLang } from '../../../Auth';
import { DataTable,FAB } from 'react-native-paper';
import { addComma, addCommaN, getDataDateDiff, sumAllValue, sumValue, _retrieveData, _storeData } from '../../../Service/function';
import { naira } from '../../../env';
import Toast from 'react-native-toast-message';
import * as ImagePicker from 'expo-image-picker';
if(Platform.OS!=="web"){
  const KeyboardSpacer=require('react-native-keyboard-spacer');
  const RNHTMLtoPDF=require('react-native-html-to-pdf');
}


const numberOfItemsPerPageList = [2, 3, 4];

const items = [
  {
    key: 1,
    name: 'Page 1',
  },
  {
    key: 2,
    name: 'Page 2',
  },
  {
    key: 3,
    name: 'Page 3',
  },
];

export default function InvoiceList({navigation,route}) {
    const [LangFile,setLangFile] = useState(english);
    const [inputs,setInputs]=useState({});
    const [items,setItems]=useState({});
    const [isLoading,setLoading]=useState(false)
    const [total,setTotal]=useState(0);
    const [getUser,setUser]=useState({});
    const [date,setDate]=useState(moment());
    const [datedue,setDueDate]=useState(moment());
    const [time,setTime]=useState(new Date());
    const [index, setIndex] = useState(null);
    const [show, setShow] = useState(false);
    const [page, setPage] = React.useState(0);
    const [invoice,setInvoice]=useState([]);
    const [isModal,setModal]=useState(false);
    const [numberOfItemsPerPage, onItemsPerPageChange] = React.useState(numberOfItemsPerPageList[0]);
    const from = page * numberOfItemsPerPage;
    const to = Math.min((page + 1) * numberOfItemsPerPage, items.length);
    const isFocused = useIsFocused();

    React.useEffect(() => {
      setPage(0);
    }, [numberOfItemsPerPage]);

    useEffect(() => {
        pickLang(setLangFile,english,hausa,igbo,yoruba);
        checkUser();
      },[]);

      useEffect(()=>{
        const idx=route.params?.datar;
        if(idx){
            setInputs(route.params.datar);
            setDate(moment(route.params.datar?.invoicedate));
            setDueDate(moment(route.params.datar?.invoiceduedate))
        }
    },[])


    // async function checkTotal(){
    //     // if(inputs.items&&inputs.items.length>0){
    //     var tot=await sumAllValue(inputs.items,'amount');
    //     setInputs({...inputs, ["total"]:tot});
    //     setInputs({...inputs, ["subtotal"]:tot});
    //     // }
    //     console.log(inputs.items);
    //   }
     
      const checkUser=async()=>{
        const resp=  await _retrieveData("userauth");
        setUser(resp);
      }

      const handleChange=(e)=>{
        const value= e.value;
        setInputs({...inputs, [e.name]:value});
    }

    const handleItem=(e)=>{
      const value= e.value;
      if(e.name==="rate"){items['amount']=items.quantity&&value?parseFloat(value)*parseFloat(items.quantity):0;}
        if(e.name==="quantity"){items['amount']=items.rate&&value?parseFloat(value)*parseFloat(items.rate):0;}
     
        setItems({...items, [e.name]:value});
    }
    const storeItem=async()=>{
      if(!items.items||!items.quantity||!items.rate){
        alert(LangFile.error?.fillall);
        return false
      }
      setLoading(true);
      let sItem=[];
      if(inputs.items==undefined){
        sItem.push(items);
        setInputs({...inputs, ["items"]:sItem,["total"]:items.amount,["subtotal"]:items.amount});
        // setInputs({...inputs, ["total"]:tot});
        // setInputs({...inputs, ["subtotal"]:tot});
      }
      else{
        sItem=inputs.items;
        sItem.push(items);
        var tot=await sumAllValue(sItem,'amount');
        setInputs({...inputs, ["items"]:sItem,["total"]:tot,["subtotal"]:tot});
      }
      setItems({});
      setModal(false);
      setLoading(false);
      Toast.show({
        type: 'success',
        text1: LangFile.success_message?.success,
        text2: '👋'
      });

    }

    const pressStore=async()=>{
      await storeItem();
    }

    const putItem=async()=>{
     await realPutItem();
    }

    const realPutItem=async()=>{
      const record=await _retrieveData("Invoice");
      record[route.params.index].items.splice(index,1,items);
      await _storeData(record,'Invoice');
      const recordd=await _retrieveData("Invoice");
      setInputs(recordd[route.params.index])
      setModal(false);
    }

    const realDeleteItem=async()=>{
      const record=await _retrieveData("Invoice");
      record[route.params.index].items.splice(index, 1);
      await _storeData(record,'Invoice');
      const recordd=await _retrieveData("Invoice");
      setInputs(recordd[route.params.index])
      setModal(false);
    }

    const deleteItem=async()=>{
      Alert.alert("NissAgro",LangFile.error.deletesure, [
        {
          text: LangFile.error.cancel,
          onPress: () => null,
          style: "cancel"
        },
        { text: LangFile.error.yes, onPress: async() => {
           await realDeleteItem()
           .then(()=>{
            checkTotal();
          })
        } }
      ]);
    }

    const deleteData=()=>{
        Alert.alert("NissAgro",LangFile.error.deletesure, [
     {
       text: LangFile.error.cancel,
       onPress: () => null,
       style: "cancel"
     },
     { text: LangFile.error.yes, onPress: async() => {
         const record=await _retrieveData("Invoice");
             record.splice(route.params.index, 1);
         await _storeData(record,'Invoice');
         navigation.goBack();
     } }
   ]);
   }

   const pickDate=async()=>{
    try {
        const {action,year,month,day} = await DatePickerAndroid.open({
          date:date.toDate()
        });
        if (action !== DatePickerAndroid.dismissedAction) {
            setDate(moment(`${year}-${month.length>1?month + 1:"0"+(month+1)}-${day}`));
        }
      } catch ({ code, message }) {
        // console.warn('Cannot open date picker', message);
      }
}

const pickDueDate=async()=>{
  try {
      const {action,year,month,day} = await DatePickerAndroid.open({
        date:datedue.toDate()
      });
      if (action !== DatePickerAndroid.dismissedAction) {
          setDueDate(moment(`${year}-${month.length>1?month + 1:"0"+(month+1)}-${day}`));
      }
    } catch ({ code, message }) {
      // console.warn('Cannot open date picker', message);
    }
}

   const handleChoosePhoto = async() => {
    const token = await _retrieveData("token");
    let res = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });
    if (!res.cancelled) {
          setInputs({...inputs, ["image"]:res.uri});
      // const resp=await uploadImage(token,res);
      //  await uploadProfile(resp[0].id,token);
      }
  };

  const saveInvoice=async()=>{
    var invoiceRec=await _retrieveData("Invoice");
    if(!inputs.image||!inputs.companyname||!inputs.billto||!inputs.type||!inputs.items||!inputs.invoicenumber){
      alert(LangFile.error.fillall);
      return false;
   }
   try {
    if(invoiceRec){
        const lastKey=invoiceRec.reverse();
        inputs['id']=moment().format('x')+Math.floor(Math.random() * 101);
        inputs['invoicedate']=date.format("YYYY-MM-DD");
        inputs['invoiceduedate']=datedue.format("YYYY-MM-DD");
        invoiceRec.push(inputs);
       await _storeData(invoiceRec,"Invoice");
       Toast.show({
        type: 'success',
        text1: LangFile.success_message?.success,
        text2: '👋'
      });
    }
    else{
      var salesDot=[];
     inputs['id']=moment().format('x')+Math.floor(Math.random() * 101);
     inputs['invoicedate']=date.format("YYYY-MM-DD");
        inputs['invoiceduedate']=datedue.format("YYYY-MM-DD");
     salesDot.push(inputs);
       await _storeData(salesDot,"Invoice");
       Toast.show({
           type: 'success',
           text1: LangFile.success_message?.success,
           text2: '👋'
         });
 
   }
 navigation.goBack();
  } catch (error) {
    Toast.show({
        type: 'error',
        text1: error.message,
        text2: '👋'
      });
  }
  }

  const updateRecord=async()=>{
    const record=await _retrieveData("Invoice");
    record.splice(route.params.index,1,inputs);
    // await sendtoServer(inputs,route.params.index);
    await _storeData(record,'Invoice');
    Toast.show({
      type: 'success',
      text1: LangFile.success_message?.success,
      text2: '👋'
    });
    navigation.goBack();
  }

  const createPDF=async()=>{
    let options = {
      html: `
      <html>
      <head>
        <meta charset="utf-8">
        <title>${inputs.type}</title>
       <style>
       /* reset */

*
{
	border: 0;
	box-sizing: content-box;
	color: inherit;
	font-family: inherit;
	font-size: inherit;
	font-style: inherit;
	font-weight: inherit;
	line-height: inherit;
	list-style: none;
	margin: 0;
	padding: 0;
	text-decoration: none;
	vertical-align: top;
}

/* content editable */

*[contenteditable] { border-radius: 0.25em; min-width: 1em; outline: 0; }

*[contenteditable] { cursor: pointer; }

*[contenteditable]:hover, *[contenteditable]:focus, td:hover *[contenteditable], td:focus *[contenteditable], img.hover { background: #DEF; box-shadow: 0 0 1em 0.5em #DEF; }

span[contenteditable] { display: inline-block; }

/* heading */

h1 { font: bold 100% sans-serif; letter-spacing: 0.5em; text-align: center; text-transform: uppercase; }

/* table */

table { font-size: 75%; table-layout: fixed; width: 100%; }
table { border-collapse: separate; border-spacing: 2px; }
th, td { border-width: 1px; padding: 0.5em; position: relative; text-align: left; }
th, td { border-radius: 0.25em; border-style: solid; }
th { background: #EEE; border-color: #BBB; }
td { border-color: #DDD; }

/* page */

html { font: 16px/1 'Open Sans', sans-serif; overflow: auto; padding: 0.5in; }
html { background: #999; cursor: default; }

body { box-sizing: border-box; height: 11in; margin: 0 auto; overflow: hidden; padding: 0.5in; width: 8.5in; }
body { background: #FFF; border-radius: 1px; box-shadow: 0 0 1in -0.25in rgba(0, 0, 0, 0.5); }

/* header */

header { margin: 0 0 3em; }
header:after { clear: both; content: ""; display: table; }

header h1 { background: #000; border-radius: 0.25em; color: #FFF; margin: 0 0 1em; padding: 0.5em 0; }
header address { float: left; font-size: 75%; font-style: normal; line-height: 1.25; margin: 0 1em 1em 0; }
header address p { margin: 0 0 0.25em; }
header span, header img { display: block; float: right; }
header span { margin: 0 0 1em 1em; max-height: 25%; max-width: 60%; position: relative; }
header img { max-height: 100%; max-width: 100%; }
header input { cursor: pointer; -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"; height: 100%; left: 0; opacity: 0; position: absolute; top: 0; width: 100%; }

/* article */

article, article address, table.meta, table.inventory { margin: 0 0 3em; }
article:after { clear: both; content: ""; display: table; }
article h1 { clip: rect(0 0 0 0); position: absolute; }

article address { float: left; font-size: 125%; font-weight: bold; }

/* table meta & balance */

table.meta, table.balance { float: right; width: 36%; }
table.meta:after, table.balance:after { clear: both; content: ""; display: table; }

/* table meta */

table.meta th { width: 40%; }
table.meta td { width: 60%; }

/* table items */

table.inventory { clear: both; width: 100%; }
table.inventory th { font-weight: bold; text-align: center; }

table.inventory td:nth-child(1) { width: 26%; }
table.inventory td:nth-child(2) { width: 38%; }
table.inventory td:nth-child(3) { text-align: right; width: 12%; }
table.inventory td:nth-child(4) { text-align: right; width: 12%; }
table.inventory td:nth-child(5) { text-align: right; width: 12%; }

/* table balance */

table.balance th, table.balance td { width: 50%; }
table.balance td { text-align: right; }

/* aside */

aside h1 { border: none; border-width: 0 0 1px; margin: 0 0 1em; }
aside h1 { border-color: #999; border-bottom-style: solid; }

/* javascript */

.add, .cut
{
	border-width: 1px;
	display: block;
	font-size: .8rem;
	padding: 0.25em 0.5em;	
	float: left;
	text-align: center;
	width: 0.6em;
}

.add, .cut
{
	background: #9AF;
	box-shadow: 0 1px 2px rgba(0,0,0,0.2);
	background-image: -moz-linear-gradient(#00ADEE 5%, #0078A5 100%);
	background-image: -webkit-linear-gradient(#00ADEE 5%, #0078A5 100%);
	border-radius: 0.5em;
	border-color: #0076A3;
	color: #FFF;
	cursor: pointer;
	font-weight: bold;
	text-shadow: 0 -1px 2px rgba(0,0,0,0.333);
}

.add { margin: -2.5em 0 0; }

.add:hover { background: #00ADEE; }

.cut { opacity: 0; position: absolute; top: 0; left: -1.5em; }
.cut { -webkit-transition: opacity 100ms ease-in; }

tr:hover .cut { opacity: 1; }

@media print {
	* { -webkit-print-color-adjust: exact; }
	html { background: none; padding: 0; }
	body { box-shadow: none; margin: 0; }
	span:empty { display: none; }
	.add, .cut { display: none; }
}

@page { margin: 0; }
       </style>
      </head>
      <body>
        <header>
          <h1>${inputs.type}</h1>
          <address contenteditable>
            <p>${inputs.companyname}</p>
            <p>${inputs.companyaddress?inputs.companyaddress:""}</p>
            <p>${inputs.companyphone?inputs.companyphone:""}</p>
          </address>
          <span><img alt="" src="${inputs.image}"></span>
        </header>
        <article>
          <h1>Recipient</h1>
          <address contenteditable>
            <p>${inputs.billto}</p>
          </address>
          <table class="meta">
            <tr>
              <th><span contenteditable>Invoice #</span></th>
              <td><span contenteditable>${inputs.invoicenumber}</span></td>
            </tr>
            <tr>
              <th><span contenteditable>Date</span></th>
              <td><span contenteditable>${moment(inputs.invoicedate).format("LL")}</span></td>
            </tr>
            <tr>
              <th><span contenteditable>Amount Balance</span></th>
              <td><span id="prefix" contenteditable>${naira}</span><span>${inputs.balance}</span></td>
            </tr>
          </table>
          <table class="inventory">
            <thead>
              <tr>
                <th><span contenteditable>Item</span></th>
                <th><span contenteditable>Rate</span></th>
                <th><span contenteditable>Quantity</span></th>
                <th><span contenteditable>Price</span></th>
              </tr>
            </thead>
            <tbody>
            ${inputs.items?.map((item,i)=>(
              `
              <tr>
                <td><a class="cut">-</a><span contenteditable>${item.items}</span></td>
                <td><span data-prefix></span><span contenteditable>${addComma(item.rate)}</span></td>
                <td><span contenteditable>${item.quantity}</span></td>
                <td><span data-prefix></span><span>${addComma(item.amount)}</span></td>
              </tr>
              `
              ))}
            </tbody>
          </table>
          <a class="add">+</a>
          <table class="balance">
            <tr>
              <th><span contenteditable>Total</span></th>
              <td><span data-prefix></span><span>${addComma(inputs.subtotal!=undefined?inputs.subtotal:0)}</span></td>
            </tr>
            <tr>
              <th><span contenteditable>Amount Paid</span></th>
              <td><span data-prefix></span><span contenteditable>${addComma(inputs.amountpaid?inputs.amountpaid:0)}</span></td>
            </tr>
            <tr>
              <th><span contenteditable>Balance Due</span></th>
              <td><span data-prefix></span><span>${addComma(inputs.balance?inputs.balance:0)}</span></td>
            </tr>
          </table>
        </article>
        <aside>
          <h1><span contenteditable>Additional Notes</span></h1>
          <div contenteditable>
            <p>${inputs.invoicenote!=undefined?inputs.invoicenote:""}</p>
          </div>
        </aside>
      </body>
    </html>
`,
      fileName: inputs?.invoicenumber+"Invoice",
      directory: 'documents',
    };
    try {
      const granted = await PermissionsAndroid.requestMultiple([
        PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE,
        PermissionsAndroid.PERMISSIONS.READ_EXTERNAL_STORAGE,
      ]);
    } catch (err) {
      console.warn(err);
    }
    if(Platform.OS!=="web"){
      let file = await RNHTMLtoPDF.convert(options)
    // console.log(file.filePath);
   navigation.navigate("ViewPdf",{url:file.filePath});
    }
  }

      const ind=route.params?.index;
    return (
        <ImageBackground source={BackGroundImage} style={InvoiceListStyle.parent}>
              <View style={InvoiceListStyle.child}>
              <GoBack onPress={() => navigation.goBack()} onAddPress={deleteData} buttonText={ind!=undefined?LangFile.records?.editinvoice:LangFile.records?.addinvoice} add={ind!=undefined?true:false} addText={'Delete'} iconLeft={"delete"} size={30} iconLeftColor={'red'}/>
              <View style={InvoiceListStyle.columnContainer}>
              <ScrollView style={InvoiceListStyle.scrollView}>
                <View style={InvoiceListStyle.rowHeaderContainer}>
                    <View style={InvoiceListStyle.rightColumn}>
                      <TouchableOpacity onPress={handleChoosePhoto}>
                        <Image  style={InvoiceListStyle.imageLogo} source={inputs.image?{uri:inputs.image}:require("../../../assets/image.jpeg")}  />
                        </TouchableOpacity>  
                        <TextInput
                        style={{marginBottom:Platform.OS === 'ios'?0:-20}}
                        placeholder={LangFile.salerecord?.companyname} 
                        onChangeText={text => handleChange({value:text,name:"companyname"})}
                        placeholderTextColor={'#000'}
                        defaultValue={inputs.companyname}
                        />
                        <TextInput
                        style={{marginBottom:Platform.OS === 'ios'?0:-20}}
                        placeholder={LangFile.salerecord?.companyaddress} 
                        onChangeText={text => handleChange({value:text,name:"companyaddress"})}
                        placeholderTextColor={'#000'}
                        defaultValue={inputs.companyaddress}
                        />
                         <TextInput
                         
                        placeholder={LangFile.salerecord?.companyphone} 
                        onChangeText={text => handleChange({value:text,name:"companyphone"})}
                        placeholderTextColor={'#000'}
                        defaultValue={inputs.companyphone!=undefined?inputs.companyphone:null}
                        />
                        <TextInput
                        placeholder={LangFile.salerecord?.billto} 
                        onChangeText={text => handleChange({value:text,name:"billto"})}
                        placeholderTextColor={'#000'}
                        defaultValue={inputs.billto?inputs.billto:null}
                        />
                    </View>
                    <View style={InvoiceListStyle.rightColumn}>
                    <SelectInputed
          showIcon1={false}
          showIcon2={false}
          title={LangFile.salerecord?.invoicetype}
          iconName1={'pencil-square-o'}
          render={<RadioSelect resData={[{id:"invoice",name:"Invoice"},{id:"receipt",name:"Receipt"}]} value={inputs?.type} onSelectValue={(e) => handleChange({value:JSON.parse(e).name,name:'type'})}/>}
          selectLabel={LangFile.salerecord?.invoicetype}
          label={inputs.type?inputs.type:LangFile.salerecord?.invoicetype}
          iconName2={'angle-down'}
          Options={0}
        />
        <Text style={{color:'gray',marginBottom:Platform.OS === 'ios'?0:-10}}>{LangFile.salerecord?.invoicenumber+": "}</Text>
                    <TextInput
                        placeholder={LangFile.salerecord?.invoicenumber} 
                        onChangeText={text => handleChange({value:text,name:"invoicenumber"})}
                        placeholderTextColor={'#000'}
                        defaultValue={inputs.invoicenumber?inputs.invoicenumber:null}
                        />
                    <TouchableOpacity  onPress={pickDate}>
                      <Text style={{color:"black",margin:5}}>{LangFile.salerecord?.date+": "+date.format("DD-MM-YYYY")}</Text>
                    </TouchableOpacity>
                    <TouchableOpacity  onPress={pickDueDate}>
                      <Text style={{color:"black",margin:5}}>{LangFile.salerecord?.duedate+": "+datedue.format("DD-MM-YYYY")}</Text>
                    </TouchableOpacity>
                    <Text style={{color:'gray',marginBottom:Platform.OS === 'ios'?0:-10}}>{LangFile.salerecord?.balance+": "}</Text>
                        <TextInput
                        placeholder={LangFile.salerecord?.balance} 
                        onChangeText={text => handleChange({value:text,name:"balance"})}
                        placeholderTextColor={'#000'}
                        defaultValue={inputs.balance!=undefined?inputs.balance:null}
                        />
                    </View>
                </View>
                <ScrollView horizontal>
            <DataTable style={InvoiceListStyle.Datatable} >
        <DataTable.Header>
          <DataTable.Title style={InvoiceListStyle.Titletable} >{LangFile.salerecord?.item}</DataTable.Title>
          <DataTable.Title style={InvoiceListStyle.Titletable}>{LangFile.salerecord?.quantity}</DataTable.Title>
          <DataTable.Title style={InvoiceListStyle.Titletable}>{LangFile.salerecord?.rate}({naira})</DataTable.Title>
          <DataTable.Title style={InvoiceListStyle.Titletable} numeric>{LangFile.salerecord?.amount}({naira})</DataTable.Title>
        </DataTable.Header>
        {inputs.items?.map((item,i)=>(
             <DataTable.Row key={i} onPress={()=>{setModal(true);setItems(item);setIndex(i)}}>
             <DataTable.Cell >{item.items}</DataTable.Cell>
               <DataTable.Cell>{item.quantity}</DataTable.Cell>
               <DataTable.Cell>{item.rate}</DataTable.Cell>
               <DataTable.Cell numeric>{addComma(item.amount)}</DataTable.Cell>
             </DataTable.Row>
        ))}
        <Button
      altStyle={InvoiceListStyle.buttonFilter}
      altText={InvoiceListStyle.altText}
      buttonText={LangFile.records?.additem}
      onPress={()=>{setModal(true);setItems({})}}
      />
       <DataTable.Pagination
        page={page}
        numberOfPages={Math.ceil(inputs.items?.length / numberOfItemsPerPage)}
        onPageChange={page => setPage(page)}
        label={`${from + 1}-${to} of ${inputs.items?.length}`}
        showFastPaginationControls
        numberOfItemsPerPageList={numberOfItemsPerPageList}
        numberOfItemsPerPage={numberOfItemsPerPage}
        onItemsPerPageChange={onItemsPerPageChange}
        selectPageDropdownLabel={'Rows per page'}
      />
      </DataTable>
      </ScrollView>
      <View style={InvoiceListStyle.rowHeaderContainer}>
                    <View style={InvoiceListStyle.rightColumn}>
                    <TextInput
                    style={{marginBottom:Platform.OS === 'ios'?0:-20}}
                        placeholder={LangFile.salerecord?.invoicenote} 
                        onChangeText={text => handleChange({value:text,name:"invoicenote"})}
                        placeholderTextColor={'#000'}
                        multiline={true}
                        numberOfLines={3}
                        defaultValue={inputs.invoicenote!=undefined?inputs.invoicenote:null}
                        />
                      </View>
                      <View style={InvoiceListStyle.rightColumn}>
                      <Text style={{color:'gray',marginBottom:Platform.OS === 'ios'?0:-10}}>{LangFile.salerecord?.total+": "}</Text>
                      <TextInput
                      style={{marginBottom:Platform.OS === 'ios'?0:-20}}
                        placeholder={LangFile.salerecord?.total} 
                        editable={false}
                        onChangeText={text => handleChange({value:text,name:"subtotal"})}
                        placeholderTextColor={'#000'}
                       defaultValue={inputs.subtotal!=undefined?addComma(inputs.subtotal):null}
                        />
                        {/* <TextInput
                        placeholder={LangFile.salerecord?.} 
                        onChangeText={text => handleChange(text)}
                        placeholderTextColor={'#000'}
                        multiline={true}
                        numberOfLines={3}
                        
                        /> */}
                         {/* <TextInput
                         style={{marginBottom:-20}}
                        placeholder={LangFile.salerecord?.total} 
                        editable={false}
                        onChangeText={text => handleChange({value:text,name:"total"})}
                        placeholderTextColor={'#000'}
                        defaultValue={inputs.total!=undefined?LangFile.salerecord?.total+": "+addCommaN(inputs.total):null}
                        /> */}
                         <Text style={{color:'gray',marginBottom:Platform.OS === 'ios'?0:-10}}>{LangFile.salerecord?.amountpaid+": "}</Text>
                         <TextInput
                        placeholder={LangFile.salerecord?.amountpaid} 
                        onChangeText={text => handleChange({value:text,name:"amountpaid"})}
                        placeholderTextColor={'#000'}
                        defaultValue={inputs.amountpaid?inputs.amountpaid:null}
                        />
                        </View>
                       
        </View>
        <Button
      altStyle={InvoiceListStyle.buttonSubmit}
      altText={InvoiceListStyle.altTextSubmit}
      buttonText={ind!=undefined?LangFile.records?.updateinvoice:LangFile.records?.createinvoice}
      onPress={ind!=undefined?updateRecord:saveInvoice}
      />
      {ind!=undefined&&
       <Button
      altStyle={InvoiceListStyle.buttondownSubmit}
      altText={InvoiceListStyle.altdownTextSubmit}
      buttonText={LangFile.records?.downloadinvoice}
      onPress={createPDF}
      />}
      {Platform.OS!=="web"&&<KeyboardSpacer topSpacing={20} />}
        </ScrollView>
              </View>
              </View>
              <ModalLg
              modalVisible={isModal}
              render={
                <ScrollView style={InvoiceListStyle.InvoicescrollView}>
                <View style={InvoiceListStyle.columnContainer}>
                  <View style={InvoiceListStyle.rowInvoiceHeaderContainer}>
                    <Text style={InvoiceListStyle.invoiceText}>{LangFile.salerecord?.addinvoiceitem}</Text>
                    <Button
                  altStyle={InvoiceListStyle.buttonFilter}
                altText={InvoiceListStyle.altTextSubmit}
              buttonText={LangFile.login?.close}
                onPress={()=>setModal(false)}
                />
                  </View>
                  <View >
        <TextInputed
          showIcon1={true}
          showIcon2={false}
          iconName1={'plus-square'}
          style={{marginLeft:0,marginRight:0}}
          // iconName2={'eye'}
          label={ind!=undefined?LangFile.records?.edit+" "+LangFile.salerecord?.item:LangFile.records?.add+" "+LangFile.salerecord?.item}
          placeholder={LangFile.records?.add+" "+LangFile.salerecord?.item}
          onChangeText={(e) => handleItem({value:e,name:'items'})}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          defaultValue={items?.items}
          Secure={false}
        //   keyboardType={'numeric'}
        />
        <TextInputed
          showIcon1={true}
          showIcon2={false}
          style={{marginLeft:0,marginRight:0}}
          iconName1={'plus-square'}
          label={ind!=undefined?LangFile.records?.edit+" "+LangFile.salerecord?.quantity:LangFile.records?.add+" "+LangFile.salerecord?.quantity}
          placeholder={LangFile.records?.add+" "+LangFile.salerecord?.quantity}
          onChangeText={(e) => handleItem({value:e,name:'quantity'})}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          defaultValue={items?.quantity}
        keyboardType={'numeric'}
        />
        <TextInputed
          showIcon1={true}
          style={{marginLeft:0,marginRight:0}}
          showIcon2={false}
          iconName1={'plus-square'}
          label={ind!=undefined?LangFile.records?.edit+" "+LangFile.salerecord?.rate:LangFile.records?.add+" "+LangFile.salerecord?.rate}
          placeholder={LangFile.records?.add+" "+LangFile.salerecord?.rate}
          onChangeText={(e) => handleItem({value:e,name:'rate'})}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          defaultValue={items?.rate}
        keyboardType={'numeric'}
        />
         <TextInputed
          showIcon1={true}
          style={{marginLeft:0,marginRight:0}}
          showIcon2={false}
          iconName1={'plus-square'}
          label={LangFile.salerecord?.amount}
          placeholder={LangFile.salerecord?.amount}
          onChangeText={(e) => handleItem({value:e,name:'amount'})}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          defaultValue={JSON.stringify(items?.amount)}
        keyboardType={'numeric'}
        />
         <Button
      altStyle={InvoiceListStyle.buttonSubmit}
      altText={InvoiceListStyle.altTextSubmit}
      buttonText={index!=undefined?LangFile.records?.updateitem:LangFile.records?.createitem}
        onPress={index!=undefined?putItem:pressStore}
      />
      {index!=undefined&&
       <Button
      altStyle={InvoiceListStyle.buttondelSubmit}
      altText={InvoiceListStyle.altTextdelSubmit}
      buttonText={LangFile.records?.deleteitem}
        onPress={deleteItem}
      />}
                  </View>
                  
                </View>
                </ScrollView>
              }
              />
        </ImageBackground>
    )
}

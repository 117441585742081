import React, { useEffect, useState } from 'react'
import { ImageBackground, ScrollView, View,DatePickerAndroid, Text, FlatList, TouchableOpacity } from 'react-native';
import InvoiceListStyle from "./invoiceliststyle";
import BackGroundImage from "../../assets/farm.jpeg";
import { useIsFocused } from '@react-navigation/native';
import {igbo,english,hausa,yoruba} from '../../Lang';
import { Button, GoBack, Label } from '../../Components';
import moment from 'moment';
import Icon from 'react-native-vector-icons/Ionicons';
import { pickLang } from '../../Auth';
import { addComma, getDataDateDiff, _retrieveData } from '../../Service/function';


export default function InvoiceList({navigation,route}) {
    const [getLang,setLang] = useState(0);
    const [datefrom,setDateFrom]=useState(moment().subtract(7, "days"));
    const [dateto,setDateTo]=useState(moment());
    const [LangFile,setLangFile] = useState(english);
    const [invoice,setInvoice]=useState([]);

    const isFocused = useIsFocused();

    useEffect(()=>{
        // getAllSales();
        pickLang(setLangFile,english,hausa,igbo,yoruba);
        getAllSales();
      },[isFocused]);


      const getAllSales=async()=>{
        const retSales=await _retrieveData("Invoice");
        if(retSales){
        setInvoice(retSales.reverse());
        }
    }

      const renderItem = ({ item,index }) => (
        <TouchableOpacity onPress={()=>navigation.navigate("AddInvoiceList",{datar:item,index:index})} key={index} style={InvoiceListStyle.itemContainer}>
         <View style={{padding:10}}>
          <Icon name={'book'} style={InvoiceListStyle.itemIcon}></Icon>
          </View>
           <View style={InvoiceListStyle.itemMini}>
           <Text style={InvoiceListStyle.itemTitle}>{item.companyname}</Text>
           <Text style={InvoiceListStyle.itemDesc}>{item.billto}</Text>
           <View style={InvoiceListStyle.dateContainer}>
           <Icon name={"calendar"} style={InvoiceListStyle.dateIcon}></Icon>
           <Text style={InvoiceListStyle.dateText}>{moment(item.date).format("LL")}</Text>
           </View>
        </View>
        </TouchableOpacity>
      )
    return (
        <ImageBackground source={BackGroundImage} style={InvoiceListStyle.parent}>
              <View style={InvoiceListStyle.child}>
              <GoBack onPress={() => navigation.goBack()} onAddPress={()=>navigation.navigate('AddInvoiceList')} buttonText={LangFile.menu?.menu9} add={true} addText={LangFile.records?.add} iconLeft={"plus"} iconLeftColor={'green'} />
              <View style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between'}}>
              <Label fonts={14} color="#000" click={false} message={LangFile.records?.aboutInvoice}/>
            </View>
            <ScrollView style={InvoiceListStyle.scrollView}>
           <View style={InvoiceListStyle.tableContainer}>
           <FlatList
        numColumns={1}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        data={invoice}
        renderItem={renderItem}
        keyExtractor={item => item.id} 
        />  
      </View>
      </ScrollView>
              </View>
        </ImageBackground>
    )
}

import React from 'react';
import Icon from 'react-native-vector-icons/FontAwesome';
import { Text, View,Image,StyleSheet,Dimensions,ActivityIndicator, TouchableOpacity } from 'react-native';
const {width,height}=Dimensions.get('screen');
export default function Avatar(props) {
    return (
        <TouchableOpacity onPress={()=>props.onPress()} style={{alignItems:'center',flexDirection: "column",paddingTop:10}}>
            {props.image==""&&
        <Icon name={props.iconName} style={{color: props.colorIcon,fontSize: props.iconSize,paddingRight: 5}}></Icon>
            }
             {props.image!=""&&
        <Image
        style={{
            width:70,
            height:70,
            borderColor:'rgba(255,255,255,0.7)',
            borderWidth:2,
           borderRadius:70
        }}
        source={{uri:props.image}}
         />
            }
    <Text style={{color:"#0d503a"}}>{props.editprofile}</Text>
      </TouchableOpacity>
    );
}






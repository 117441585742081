import React, { useEffect, useState } from 'react'
import { ImageBackground, ScrollView,DatePickerAndroid, View, Text, Alert,Platform } from 'react-native';
import AddSaleStyle from "./addtaskstyle";
import BackGroundImage from "../../../assets/farm.jpeg";
import { Button, GoBack, RadioSelect, SelectInputed, SpaceBottom, TextInputed } from '../../../Components';
import { checkProfile, pickLang } from '../../../Auth';
import {igbo,english,hausa,yoruba} from '../../../Lang';
import moment from "moment";
import { _deleteData, _retrieveData, _storeData } from '../../../Service/function';
import axios from 'axios';
import { baseUrl } from '../../../env';
import Toast from 'react-native-toast-message';

if(Platform.OS==="web"){
  const DatePicker =require("react-datepicker");
                   require("react-datepicker/dist/react-datepicker.css");
 }

export default function AddSales({navigation,route}) {
    const [LangFile,setLangFile] = useState(english);
    const [inputs,setInputs]=useState({});
    const [isLoading,setLoading]=useState(false)
    const [total,setTotal]=useState(0);
    const [getUser,setUser]=useState({});
    const [date,setDate]=useState(new Date());
    const [time,setTime]=useState(new Date());
    const [mode, setMode] = useState('time');
    const [show, setShow] = useState(false);

    useEffect(() => {
        pickLang(setLangFile,english,hausa,igbo,yoruba);
        checkUser();
      },[]);

      const checkUser=async()=>{
        const resp=  await _retrieveData("userauth");
        setUser(resp);
      }

      const handleChange=(e)=>{
        const value= e.value;
        setInputs({...inputs, [e.name]:value});
    }

    const onChangeDate = (event, selectedDate) => {
      const currentDate = selectedDate || date;
      setShow(false)
      setDate(currentDate);
    };

    const onChangeTime = (event, selectedDate) => {
      const currentDate = selectedDate || time;
      setShow(false)
      setTime(currentDate);
    };

    useEffect(()=>{
        const idx=route.params?.datar;
        if(idx){
            setInputs(route.params.datar);
            setDate(new Date(route.params.datar.date));
            setTime(new Date(route.params.datar.time))
        }
    },[])
    const pickDate=async()=>{
    
    }
  
      const submitRecord=async()=>{
          var salesRec=await _retrieveData("TaskRecord");
         if(!inputs.title||!inputs.description){
            alert(LangFile.error.fillall);
            return false;
         }
          try {
            if(salesRec){
                const lastKey=salesRec.reverse();
                inputs['id']=moment().format('x');
                inputs['date']=date;
                inputs['time']=time;
                salesRec.push(inputs);
               await _storeData(salesRec,"TaskRecord");
               Toast.show({
                type: 'success',
                text1: LangFile.success_message?.success,
                text2: '👋'
              });
            }
            else{
               var salesDot=[];
              inputs['id']=moment().format('x');
              inputs['date']=date;
                inputs['time']=time;
              salesDot.push(inputs);
                await _storeData(salesDot,"TaskRecord");
                Toast.show({
                    type: 'success',
                    text1: LangFile.success_message?.success,
                    text2: '👋'
                  });
          
            }
          navigation.goBack();
          } catch (error) {
            Toast.show({
                type: 'error',
                text1: error.message,
                text2: '👋'
              });
          }
        
      }

      const updateRecord=async()=>{
        const record=await _retrieveData("TaskRecord");
        inputs['date']=date;
          inputs['time']=time;
        record.splice(route.params.index,1,inputs);
        await _storeData(record,'TaskRecord');
        navigation.goBack();
      }

      const deleteData=()=>{
           Alert.alert("NissAgro",LangFile.error.deletesure, [
        {
          text: LangFile.error.cancel,
          onPress: () => null,
          style: "cancel"
        },
        { text: LangFile.error.yes, onPress: async() => {
            const record=await _retrieveData("TaskRecord");
                record.splice(route.params.index, 1);
            await _storeData(record,'TaskRecord');
            navigation.goBack();
        } }
      ]);
      }
const ind=route.params?.index;

    return (
        <ImageBackground source={BackGroundImage} style={AddSaleStyle.parent}>
            <View style={AddSaleStyle.child}>
            <GoBack onPress={() => navigation.goBack()} onAddPress={deleteData} buttonText={ind!=undefined?LangFile.records?.edittask:LangFile.records?.addstask} add={ind!=undefined?true:false} addText={''} iconLeft={"delete"} size={30} iconLeftColor={'red'}/>
            <ScrollView style={AddSaleStyle.scrollView}>
    <View style={{display:'flex',flexDirection:'column',marginTop:20}}>
    <SpaceBottom height={10}  />
      <Text style={{fontSize:15,fontWeight:'bold',marginLeft:22,marginBottom:5,color:"black"}}>{LangFile.salerecord?.taskdate}</Text>
    <Button
    altStyle={AddSaleStyle.buttonFilter}
    altText={AddSaleStyle.altText}
    buttonText={ind!=undefined?LangFile.salerecord?.taskdate+" "+date.toLocaleDateString("en-US"):LangFile.salerecord?.taskdate+" "+date.toLocaleDateString("en-US")}
    onPress={()=>{setMode("date");setShow(true)}}
      />
      <SpaceBottom height={10}  />
      <Text style={{fontSize:15,fontWeight:'bold',marginLeft:22,marginBottom:5,color:"black"}}>{LangFile.salerecord?.tasktime}</Text>
       <Button
    altStyle={AddSaleStyle.buttonFilter}
    altText={AddSaleStyle.altText}
    buttonText={ind!=undefined?LangFile.salerecord?.tasktime+" "+time.toLocaleTimeString("en-US"):LangFile.salerecord?.tasktime+" "+time.toLocaleTimeString("en-US")}
    onPress={()=>{setMode("time");setShow(true)}}
      />
    {Platform.OS!=="web"&&
      <>
      {/* {mode==="date"&&show&&
    <DateTimePicker
          testID="dateTimePicker"
          value={date}
          mode={"date"}
          is24Hour={true}
          display="default"
          onChange={onChangeDate}
        />
    }
     {mode==="time"&&show&&
    <DateTimePicker
          testID="dateTimePicker"
          value={time}
          mode={"time"}
          is24Hour={true}
          display="default"
          onChange={onChangeTime}
        />
    } */}
    </>}
        <TextInputed
          showIcon1={true}
          showIcon2={false}
          iconName1={'plus-square'}
          // iconName2={'eye'}
          label={ind!=undefined?LangFile.records?.edit+" "+LangFile.salerecord?.tasktitle:LangFile.records?.add+" "+LangFile.salerecord?.tasktitle}
          placeholder={LangFile.records?.add+" "+LangFile.salerecord?.tasktitle}
          onChangeText={(e) => handleChange({value:e,name:'title'})}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          defaultValue={inputs?.title}
          Secure={false}
        //   keyboardType={'numeric'}
        />
        <TextInputed
          showIcon1={true}
          showIcon2={false}
          iconName1={'plus-square'}
          multiline={true}
          numberOfLines={5}
          label={ind!=undefined?LangFile.records?.edit+" "+LangFile.salerecord?.taskdescription:LangFile.records?.add+" "+LangFile.salerecord?.taskdescription}
          placeholder={LangFile.records?.add+" "+LangFile.salerecord?.taskdescription}
          onChangeText={(e) => handleChange({value:e,name:'description'})}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          defaultValue={inputs?.description}
        />
         <SelectInputed
          showIcon1={true}
          showIcon2={true}
          title={LangFile.records?.statustask}
          iconName1={'pencil-square-o'}
          render={<RadioSelect resData={[{id:'completed',name:"Completed"},{id:'incomplete',name:"In Complete"},{id:"suspend",name:"Suspend"}]} value={inputs?.status} onSelectValue={(e) => handleChange({value:JSON.parse(e).name,name:'status'})}/>}
          selectLabel={LangFile.records?.statustask}
          label={inputs.status?inputs.status:LangFile.records?.statustask}
          iconName2={'angle-down'}
          Options={0}
        />
         <Button
        buttonText={LangFile.sell_now?.submit}
        onPress={ind!=undefined?updateRecord:submitRecord}
        isLoading={isLoading}
        message={LangFile.login?LangFile.login.moment:''}
      />
      </View>
      </ScrollView>
            </View>
        </ImageBackground>
    )
}

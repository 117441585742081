import { StyleSheet,Dimensions } from 'react-native';
const {width,height}=Dimensions.get('screen');
const isSmallDevice = Dimensions.get('window').width < 768
export default StyleSheet.create({
  container:{
      display:'flex',
      flexDirection:'row',
      alignItems:'center',
    //   justifyContent:'space-between',
      marginBottom:10,
    //   borderBottomColor:'gray',
    //   paddingBottom:5,
    //   borderBottomWidth:0.9
  },
  leftcontainer:{
    display:'flex',
    flexDirection:'row-reverse',
    alignItems:'center',
    marginBottom:10
  },
  column:{
    display:'flex',
    flexDirection:'column',
    left:20,
  },
  messageContainer:{
    backgroundColor:'#cee2f3',
    padding:10,
    borderTopRightRadius:10,
    borderBottomRightRadius:10,
    borderBottomLeftRadius:10
  },
  leftmessageContainer:{
    backgroundColor:'#fff',
    padding:10,
    borderTopRightRadius:10,
    borderBottomRightRadius:10,
    borderBottomLeftRadius:10
  },
  backContainer:{
    display:'flex',
    width:isSmallDevice?width*0.5:width/4.5,
    height:isSmallDevice?width*0.4:width/4.8,
    flexDirection:'column',
    alignItems:'center',
    justifyContent:'center'
},
child: {
    // flex: 1,
    width:isSmallDevice?width*0.5:width/4.5,
    height:isSmallDevice?width*0.4:width/4.8,
    flexDirection:'column',
    alignItems:'center',
    justifyContent:'center',
    backgroundColor: 'rgba(0,0,0,0.5)'
  },
  leftImg:{
      width:50,
      height:50,
      borderRadius:30,
      resizeMode:'cover'
  },
  senderTxt:{
      color:"gray",
      fontSize:14,
      fontWeight:'bold'

  },
  headerTxt:{
      fontSize:13,
      width:isSmallDevice?width*0.6:width/4.6,
      fontWeight:'bold',
      color:'black'
  },
  locateTxt:{
    fontSize:16,
    fontWeight:'bold',
      color:'white',
      paddingRight:10
  },
  subContainer:{
      display:'flex',
      flexDirection:'row',
      alignItems:'center'
  },
  subTxt:{
      fontSize:12,
      color:'gray'
  },
  status:{
     fontSize:12,
     color:'gray',
     marginLeft:10
     
  },
  delivered:{
    fontSize:12,
    color:'green',
    marginLeft:10
    
 }

});
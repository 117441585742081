import React, { useEffect, useState } from 'react'
import { DatePickerAndroid, Dimensions, ImageBackground, ScrollView, Text, View,Platform } from 'react-native'
import SummaryStyles from "./reportstyles";
import BackGroundImage from "../../../assets/farm.jpeg"
import { Button, GoBack, Label, RadioSelect, SelectInputed } from '../../../Components';
import {igbo,english,hausa,yoruba} from '../../../Lang';
import { pickLang } from '../../../Auth';
import moment from "moment";
import { addCommaN, getDataDateDiff, sumValue, sumValueDay, sumValueMonth, uniqueArray, _retrieveData } from '../../../Service/function';
import { DataTable } from 'react-native-paper';
import { useIsFocused } from '@react-navigation/native';
import { naira } from '../../../env';
import {
  LineChart,
  BarChart,
  PieChart,
  ProgressChart,
  ContributionGraph,
  StackedBarChart
} from "react-native-chart-kit";
if(Platform.OS==="web"){
  const DatePicker =require("react-datepicker");
                   require("react-datepicker/dist/react-datepicker.css");
 }
const numberOfItemsPerPageList = [2, 3, 4,6,10,20];

const weekly = [moment().subtract(6,"days").format("ddd"),moment().subtract(5,"days").format("ddd"),moment().subtract(4,"days").format("ddd"),moment().subtract(3,"days").format("ddd"),moment().subtract(2,"days").format("ddd"),moment().subtract(1,"days").format("ddd"),moment().subtract(0,"days").format("ddd")];

const monthly=[moment().subtract(11,"months").format("MMM"), moment().subtract(10,"months").format("MMM"), moment().subtract(9,"months").format("MMM"), moment().subtract(8,"months").format("MMM"), moment().subtract(7,"months").format("MMM"), moment().subtract(6,"months").format("MMM"),moment().subtract(5,"months").format("MMM"),moment().subtract(4,"months").format("MMM"),moment().subtract(3,"months").format("MMM"),moment().subtract(2,"months").format("MMM"),moment().subtract(1,"months").format("MMM"),moment().subtract(0,"months").format("MMM")]



export default function ReportScreen({navigation,route}) {
    const [getLang,setLang] = useState(0);
    const [LangFile,setLangFile] = useState(english);
    const [datefrom,setDateFrom]=useState(moment().subtract(7, "days"));
    const [dateto,setDateTo]=useState(moment());
    const [inputs,setInputs]=useState({});
    const [data, setData]=useState([]);
    const [textFilter,setTextFilter]=useState("")
    
      const isFocused = useIsFocused();
  
   

    useEffect(() => {
        pickLang(setLangFile,english,hausa,igbo,yoruba);
      },[]);

      const handleChange=(e)=>{
        const value= e.value;
        setInputs({...inputs, [e.name]:value});
        setData([]);
    }

      const DateFrom=async()=>{
        try {
            const {
              action,
              year,
              month,
              day
            } = await DatePickerAndroid.open({
              // Use `new Date()` for current date.
              // May 25 2020. Month 0 is January.
              date: datefrom.toDate()
            });
            if (action !== DatePickerAndroid.dismissedAction) {
                setDateFrom(moment(`${year}-${month.length>1?month + 1:"0"+(month+1)}-${day}`))
            }
          } catch ({ code, message }) {
            // console.warn('Cannot open date picker', message);
          }
    }
    
    const DateTo=async()=>{
        try {
            const {
              action,
              year,
              month,
              day
            } = await DatePickerAndroid.open({
              // Use `new Date()` for current date.
              // May 25 2020. Month 0 is January.
              date: dateto.toDate()
            });
            if (action !== DatePickerAndroid.dismissedAction) {
                setDateTo(moment(`${year}-${month.length>1?month + 1:"0"+(month+1)}-${day}`))
              // Selected year, month (0-11), day
            }
          } catch ({ code, message }) {
            // console.warn('Cannot open date picker', message);
          }
    }
      const onSearch=async()=>{
        var retData;var result=[];
          if(inputs.units!=undefined&&inputs.units==="Sales Record"){
            retData=await _retrieveData("SaleRecord");
          }
         else if(inputs.units!=undefined&&inputs.units==="Expenditure Record"){
            retData=await _retrieveData("ExpenseRecord");
          }
          else if(inputs.units!=undefined&&inputs.units==="Production Record"){
            retData=await _retrieveData("ProductionRecord");
          }
          else{
            alert(LangFile.error.fillall);
              return false;
          }
          if(inputs.type==="Weekly Report"){
            setTextFilter(`${moment().subtract(6,'days').format("LL")} - ${moment().format("LL")}`)
            result=await sumValueDay(retData,'date','total');
            console.log(result)
          }
          else if(inputs.type==="Monthly Report"){
            setTextFilter(`${moment().subtract(11,'months').format("LL")} - ${moment().format("LL")}`)
            result=await sumValueMonth(retData,'date','total');
          }
          setData(result);
       
        }

    return (
        <ImageBackground source={BackGroundImage} style={SummaryStyles.parent}>
            <View style={SummaryStyles.child}>
            <GoBack onPress={() => navigation.goBack()} buttonText={LangFile.servicemenu.reports} />
            <Label fonts={15} color="#000" click={false} message={LangFile.records?.aboutReport}/>
            <View style={SummaryStyles.dateFilter}>
       <SelectInputed
          showIcon1={true}
          showIcon2={true}
        //   title={LangFile.records?.selectquantityunit}
          iconName1={'pencil-square-o'}
          render={<RadioSelect resData={[{id:"sales-record",name:"Sales Record"},{id:"expenditure-record",name:'Expenditure Record'},{id:"production-record",name:'Production Record'}]} value={inputs?.units} onSelectValue={(e) => handleChange({value:JSON.parse(e).name,name:'units'})}/>}
          selectLabel={LangFile.records?.selectrecordtype}
          label={inputs.units!=undefined?inputs.units:LangFile.records?.selectrecordtype}
          iconName2={'angle-down'}
          Options={0}
        />
         <SelectInputed
          showIcon1={true}
          showIcon2={true}
        //   title={LangFile.records?.selectquantityunit}
          iconName1={'pencil-square-o'}
          render={<RadioSelect resData={[{id:"weekly",name:'Weekly Report'},{id:"monthly",name:'Monthly Report'}]} value={inputs?.type} onSelectValue={(e) => handleChange({value:JSON.parse(e).name,name:'type'})}/>}
          selectLabel={LangFile.records?.reporttype}
          label={inputs.type!=undefined?inputs.type:LangFile.records?.reporttype}
          iconName2={'angle-down'}
          Options={0}
        />
       <Button
               altStyle={SummaryStyles.submitbuttonFilter}
               altText={SummaryStyles.altText}
                buttonText={LangFile.records?.getsummary}
                onPress={onSearch}
      />
               </View>
        {data.length>0&&
        <>
          <ScrollView style={SummaryStyles.scrollView}>
          <Label fonts={14} color="#000" click={false} message={inputs?.units+` ${textFilter}`}/>
               <View style={SummaryStyles.tableContainer}>
           <ScrollView horizontal>
           <LineChart
    data={{
      labels: inputs.type==="Weekly Report"?weekly:monthly,
      datasets: [
        {
          data: data
        }
      ]
    }}
    width={Dimensions.get("window").width} // from react-native
    height={220}
    yAxisLabel={naira}
    yAxisSuffix="k"
    yAxisInterval={1} // optional, defaults to 1
    chartConfig={{
      backgroundColor: "green",
      backgroundGradientFrom: "green",
      backgroundGradientTo: "green",
      decimalPlaces: 2, // optional, defaults to 2dp
      color: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
      labelColor: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
      style: {
        borderRadius: 16
      },
      propsForDots: {
        r: "6",
        strokeWidth: "2",
        stroke: "#ffa726"
      }
    }}
    bezier
    style={{
      marginVertical: 8,
      borderRadius: 16
    }}
  />
      </ScrollView>
      </View>
      </ScrollView>
      </>}
            </View>
        </ImageBackground>
    )
}

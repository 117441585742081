import React from 'react';
import Icon from 'react-native-vector-icons/FontAwesome';
import Iconed from 'react-native-vector-icons/MaterialCommunityIcons';
import { Text, View,Image,ImageBackground,StyleSheet,ActivityIndicator,Dimensions, TouchableOpacity } from 'react-native';
// import styles from '../Styles/login';
const {width,height}=Dimensions.get('screen');
export default function Banner(props) {
    return (
    <View style={{height:height*0.30,backgroundColor:"rgba(255,255,255,0.7)",borderBottomRightRadius: 35,borderBottomLeftRadius:35,marginBottom:5}}>
     <ImageBackground source={!props.background?require("../assets/farm.jpeg"):{uri:props.background}} style={styles.image}>
      <View style={styles.child}>
        {props.loading&&
    <View style={{flexDirection:'row',alignItems:'center',justifyContent:'center'}}>
    <ActivityIndicator size="large" color={styles.btnText.color} animating={true}/>
    <Text style={styles.btnText}>{props.moment}</Text>
    </View>
        }
        {!props.loading&&
     <Image
        style={styles.subImage}
        source={props.avatar?{uri:props.avatar}:require("../assets/logo.png")}
        /> 
}
</View>
    </ImageBackground>
      </View>
    );
}
const styles=StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: "column"
      },
      child: {
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        justifyContent:'center',
          flex: 1,
          width:width,
          backgroundColor: 'rgba(0,0,0,0.5)'
        },
      image: {
        flex: 1,
        resizeMode: "cover",
        justifyContent: "center",
        alignItems:'center'
      },
      subImage:{
        width:80,
        height:80,
        borderColor:'#dec',
        borderWidth:2,
        borderTopLeftRadius: 30,
        borderBottomLeftRadius: 30,
        borderTopRightRadius: 30,
        borderBottomRightRadius: 30,
      },
    button1: {
        flexDirection:'row',
      },
      btnText: {
        flexShrink: 1,
        color: "#fff",
        fontSize: 15,
        fontWeight:'bold',
      }, 
      btnPrice:{
        flexShrink: 1,
        paddingLeft:0,
        paddingTop:height*0.010,
        color: "#006400",
        fontSize: 13,
        fontWeight:'bold',
      },
      iconStyle1: {
        color: "#000",
        fontSize: 15,
        paddingTop:5,
        marginRight: 12
      },
})






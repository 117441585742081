import React, { useContext, useEffect, useState } from 'react'
import ForgetPasswordStyle from "./forgetpasswordstyle";
import { ImageBackground, Linking, ScrollView, StatusBar, View } from 'react-native';
import BackGroundImage from "../../../assets/farm.jpeg"
import {Logo,Label, SpaceBottom, Button, Lang, RadioButton, TextInputed, Dialogue} from '../../../Components';
import {igbo,english,hausa,yoruba} from '../../../Lang';
import PickLang from '../../../Modals/Language/PickLang';
import { _retrieveData, _storeData } from '../../../Service/function';
import { checkProfile, pickLang } from '../../../Auth';
import { AuthContext } from '../../../context/authContext/AuthContext';
import forgetpasswordstyle from './forgetpasswordstyle';
import { baseUrl } from '../../../env';

export default function ForgetPasswordScreen({navigation,route}) {
    const [getPhoneNumber, setPhoneNumber] = useState(null);
    const [getPassword, setPassword] = useState("");
    const [getAnswerRecovery, setAnswerRecovery] = useState(null);
    const [getLang,setLang] = useState(0);
    const [getLangModal,setLangModal] = useState(false);
    const [LangFile,setLangFile] = useState(english);
    const [isSecure, setSecure] = useState(true);
    const [isLoading, setLoading] = useState(false);
    const [secureIcon, setSecureIcon] = useState("eye");
    const [messageDialogue, setmessageDialogue] = useState("Hello");
    const [isDialogueVisible, setisDialogueVisible] = useState(false);
    const [getResetQuestion,setResetQuestion]=useState(null);

    //Context
    const {user,isFetching,dispatch}=useContext(AuthContext);

    const resData=[
        {
          key:0,
          text:'English'
      },
      {
          key:1,
          text:'Hausa'
      },
      {
          key:2,
          text:'Igbo'
      },
      {
          key:3,
          text:'Yoruba'
      }
    ];
    useEffect(() => {
        pickLang(setLangFile,english,hausa,igbo,yoruba);
      },[]);

      useEffect(() => {
        checkProfile(navigation); 
      },[]);

    const OpenHref= async ()=>{
        await Linking.openURL('https://niss.gov.ng');
      }

      const onClosePress=()=>{
        // pickLang(setLangFile,english,hausa,igbo,yoruba);
        setLangModal(false);
       }

      const showLangModal=()=>{
        setLangModal(true);
       }

    const onSelectValue=async(val)=>{
        setLangFile(val==0?english:val==1?hausa:val==2?igbo:yoruba)
        setLang(val);
       await _storeData(val,'lang')
        setLangModal(false); 
    }

    const changeSecure = () => {
        setSecure(isSecure ? false : true)
        setSecureIcon(isSecure ? 'eye-slash' : 'eye');
      }

      const CheckPassword=async()=>{
        try {
            const resp=await axios.post(baseUrl+"auth/forgot-password",{ identifier:getPhoneNumber});
            if(resp.data.statusCode){
                setLoading(false);
                setisDialogueVisible(true);
                setmessageDialogue(resp.message[0]?resp.message[0].messages[0].message:'');
                return false;
            }
            setResetQuestion(resp.data.question);
        } catch (error) {
            setLoading(false);
            setisDialogueVisible(true);
            setmessageDialogue(LangFile.error_message?LangFile.error_message.network_error:"");
        }
      }

    const ChangePassword=async()=>{
        try {
            const resp=await axios.post(baseUrl+"auth/reset-password",{
                identifier:getPhoneNumber,
                answer:getAnswerRecovery,
                password:getPassword
              });
            if(resp.data.statusCode){
                setLoading(false);
                setisDialogueVisible(true);
                setmessageDialogue(resp.message[0]?resp.message[0].messages[0].message:'');
                return false;
            }
          await _storeData(resp.jwt,'token');
          await _storeData(resp.user,'user');
            checkProfile(navigation);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setisDialogueVisible(true);
            setmessageDialogue(LangFile.error_message?LangFile.error_message.network_error:"");
        }
      }
    return (
      <View style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
        <ImageBackground source={BackGroundImage} style={ForgetPasswordStyle.parent}>
        <View style={ForgetPasswordStyle.child}>
        {getLang==0&&
<Lang message="ENGLISH" onPress={()=>showLangModal()}/>
}
{getLang==1&&
<Lang message="HAUSA" onPress={()=>showLangModal()}/>
}
{getLang==2&&
<Lang message="IGBO" onPress={()=>showLangModal()}/>
}
{getLang==3&&
<Lang message="YORUBA" onPress={()=>showLangModal()}/>
}
<Logo />
<Label fonts={12} color="#000" onPress={OpenHref} message={LangFile.welcome?LangFile.welcome.project+"Nigeria Institute of Soil Science (NISS)":''}/>
      <Label message={LangFile.forget_password?LangFile.forget_password.title:''} fonts={16} />
      <ScrollView style={forgetpasswordstyle.container}>
      {!getResetQuestion&&
        <TextInputed
          showIcon1={true}
          showIcon2={false}
          iconName1={'phone'}
          // iconName2={'eye'}
          placeholder={LangFile.login?LangFile.login.phone:''}
          onChangeText={(e) => setPhoneNumber(e)}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          Secure={false}
          keyboardType={'numeric'}
        />
      }
        {getResetQuestion&&
        <View>
           <TextInputed
          showIcon1={true}
          showIcon2={false}
          iconName1={'phone'}
          editable={false}
          defaultValue={getPhoneNumber}
          // iconName2={'eye'}
          placeholder={LangFile.login?LangFile.login.phone:''}
          onChangeText={(e) => setPhoneNumber(e)}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          Secure={false}
          keyboardType={'numeric'}
        />
          <Label message={getResetQuestion+"?"} fonts={17} />
          <TextInputed
          showIcon1={true}
          showIcon2={false}
          iconName1={'edit'}
          placeholder={LangFile.forget_password?LangFile.forget_password.add_input:''}
          onChangeText={(e) => setAnswerRecovery(e)}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          Secure={false}
        />
         <TextInputed
          showIcon1={true}
          showIcon2={true}
          iconName1={'key'}
          iconName2={secureIcon}
          onPress={() => changeSecure()}
          placeholder={LangFile.forget_password?LangFile.forget_password.new_password:''}
          onChangeText={(e) => setPassword(e)}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          Secure={isSecure}
        />
        </View>

        }
         {!getResetQuestion&&
        <Button
        buttonText={LangFile.forget_password?LangFile.forget_password.check_account:''}
        onPress={CheckPassword}
        isLoading={isLoading}
        message={LangFile.login?LangFile.login.moment:''}
      />
        }
        {getResetQuestion&&
        <Button
        buttonText={LangFile.forget_password?LangFile.forget_password.change_password:''}
        onPress={ChangePassword}
        isLoading={isLoading}
        message={LangFile.login?LangFile.login.moment:''}
      />
        }
        <View style={ForgetPasswordStyle.bottomView} >
          <Label click={true} color="#000" message={LangFile.login?LangFile.login.create_account:''} onPress={() => navigation.navigate('Register')} fonts={16} />
          <Label click={true} color="#000" message={LangFile.welcome?LangFile.welcome.login:''} onPress={() => navigation.navigate('Login')} fonts={16} />
        </View>
       
       
        
      </ScrollView>
      <Dialogue
        modalVisible={isDialogueVisible}
        close={LangFile.login?LangFile.login.close:''}
        onClosePress={(e) => onCloseDialoguePress()}
        message={messageDialogue}
      />
      <PickLang modalView={getLangModal} 
       close={LangFile.login?LangFile.login.close:''}
      render={ <RadioButton resData={resData} value={getLang} onSelectValue={onSelectValue}/>}
      onClosePress={onClosePress}/>
</View>
</ImageBackground>
</View>
    )
}

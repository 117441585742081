import React, { useEffect, useState } from 'react'
import { DatePickerAndroid, ImageBackground, PermissionsAndroid, ScrollView, Text, View } from 'react-native'
import SummaryStyles from "./summarystyles";
import BackGroundImage from "../../../assets/farm.jpeg"
import { Button, GoBack, Label, RadioSelect, SelectInputed, SpaceBottom } from '../../../Components';
import {igbo,english,hausa,yoruba} from '../../../Lang';
import { pickLang } from '../../../Auth';
import moment from "moment";
import { addCommaN,addComma, getDataDateDiff, sumValue, uniqueArray, _retrieveData } from '../../../Service/function';
import { DataTable } from 'react-native-paper';
import { useIsFocused } from '@react-navigation/native';
import { naira } from '../../../env';
import RNHTMLtoPDF from 'react-native-html-to-pdf';

const numberOfItemsPerPageList = [2, 3, 4,6,10,20];

const items = [
  {
    key: 1,
    name: 'Page 1',
  },
  {
    key: 2,
    name: 'Page 2',
  },
  {
    key: 3,
    name: 'Page 3',
  },
];
export default function SummaryScreen({navigation,route}) {
    const [getLang,setLang] = useState(0);
    const [LangFile,setLangFile] = useState(english);
    const [datefrom,setDateFrom]=useState(moment().subtract(7, "days"));
    const [dateto,setDateTo]=useState(moment());
    const [inputs,setInputs]=useState({});
    const [data, setData]=useState([]);
    const [page, setPage] = React.useState(0);
    const [numberOfItemsPerPage, onItemsPerPageChange] = React.useState(numberOfItemsPerPageList[0]);
    const from = page * numberOfItemsPerPage;
    const to = Math.min((page + 1) * numberOfItemsPerPage, items.length);
      const isFocused = useIsFocused();
  
    React.useEffect(() => {
      setPage(0);
    }, [numberOfItemsPerPage]);

    useEffect(() => {
        pickLang(setLangFile,english,hausa,igbo,yoruba);
      },[]);

      const handleChange=(e)=>{
        const value= e.value;
        setInputs({...inputs, [e.name]:value});
        setData([]);
    }

      const DateFrom=async()=>{
        try {
            const {
              action,
              year,
              month,
              day
            } = await DatePickerAndroid.open({
              // Use `new Date()` for current date.
              // May 25 2020. Month 0 is January.
              date: datefrom.toDate()
            });
            if (action !== DatePickerAndroid.dismissedAction) {
                setDateFrom(moment(`${year}-${month.length>1?month + 1:"0"+(month+1)}-${day}`))
            }
          } catch ({ code, message }) {
            // console.warn('Cannot open date picker', message);
          }
    }
    
    const DateTo=async()=>{
        try {
            const {
              action,
              year,
              month,
              day
            } = await DatePickerAndroid.open({
              // Use `new Date()` for current date.
              // May 25 2020. Month 0 is January.
              date: dateto.toDate()
            });
            if (action !== DatePickerAndroid.dismissedAction) {
                setDateTo(moment(`${year}-${month.length>1?month + 1:"0"+(month+1)}-${day}`))
              // Selected year, month (0-11), day
            }
          } catch ({ code, message }) {
            // console.warn('Cannot open date picker', message);
          }
    }
      const onSearch=async()=>{
        var retData;
          if(inputs.units!=undefined&&inputs.units==="Sales Record"){
            retData=await _retrieveData("SaleRecord");
            var result=[];
            const filterData=await getDataDateDiff(retData,datefrom,dateto);
            let distinctVal=await uniqueArray(filterData,'product');
            for (let i = 0; i < distinctVal.length; i++) {
               result.push({
                   name:distinctVal[i].name,
                   quantity:await sumValue(filterData,'product',distinctVal[i].name,'quantity'),
                   total:await sumValue(filterData,'product',distinctVal[i].name,'total')
               })
            }
            setData(result);
          }
         else if(inputs.units!=undefined&&inputs.units==="Expenditure Record"){
            retData=await _retrieveData("ExpenseRecord");
            var result=[];
            const filterData=await getDataDateDiff(retData,datefrom,dateto);
            let distinctVal=await uniqueArray(filterData,'expense');
            for (let i = 0; i < distinctVal.length; i++) {
               result.push({
                   name:distinctVal[i].name,
                   quantity:await sumValue(filterData,'expense',distinctVal[i].name,'quantity'),
                   total:await sumValue(filterData,'expense',distinctVal[i].name,'total')
               })
            }
            setData(result);
          }
         
          else if(inputs.units!=undefined&&inputs.units==="Production Record"){
            retData=await _retrieveData("ProductionRecord");
            var result=[];
            const filterData=await getDataDateDiff(retData,datefrom,dateto);
            let distinctVal=await uniqueArray(filterData,'produce');
            for (let i = 0; i < distinctVal.length; i++) {
               result.push({
                   name:distinctVal[i].name,
                   quantity:await sumValue(filterData,'produce',distinctVal[i].name,'quantity'),
                   total:await sumValue(filterData,'produce',distinctVal[i].name,'value')
               })
            }
            setData(result);
          }
          else if(inputs.units!=undefined&&inputs.units==="Farm Income"){
            retData=await _retrieveData("IncomeRecord");
            var result=[];
            const filterData=await getDataDateDiff(retData,datefrom,dateto);
            let distinctVal=await uniqueArray(filterData,'source');
            for (let i = 0; i < distinctVal.length; i++) {
               result.push({
                   name:distinctVal[i].name,
                   quantity:0,
                   total:await sumValue(filterData,'source',distinctVal[i].name,'amount')
               })
            }
            setData(result);
          }
          else{
              alert(LangFile.error.fillall);
              return false;
          }
       
        }

        const createPDF=async()=>{
            let options = {
              html: `
              <html>
<head>
<style>
#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers td, #customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers tr:nth-child(even){background-color: #f2f2f2;}

#customers tr:hover {background-color: #ddd;}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04AA6D;
  color: white;
}
</style>
</head>
<body>

<h1>${inputs?.units}</h1>
<h2>Date from: ${datefrom.format('LL')} Date to: ${dateto.format('LL')}</h2>
<table id="customers">
  <tr>
    <th>Name</th>
    <th>Quantity</th>
    <th>Total</td>
  </tr>
  ${data.map((item,i)=>(
    `<tr>
      <td>${item.name}</td>
      <td>${item.quantity}</td>
      <td>${addComma(item.total)}</td>
    </tr>`
  ))}
</table>

</body>
</html>
`,
              fileName: inputs?.units,
              directory: 'documents',
            };
            try {
              const granted = await PermissionsAndroid.requestMultiple([
                PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE,
                PermissionsAndroid.PERMISSIONS.READ_EXTERNAL_STORAGE,
              ]);
            } catch (err) {
              console.warn(err);
            }
            let file = await RNHTMLtoPDF.convert(options)
            // console.log(file.filePath);
           navigation.navigate("ViewPdf",{url:file.filePath});
          }

    return (
        <ImageBackground source={BackGroundImage} style={SummaryStyles.parent}>
            <View style={SummaryStyles.child}>
            <GoBack onPress={() => navigation.goBack()} buttonText={LangFile.servicemenu.recordsummary} />
            <Label fonts={15} color="#000" click={false} message={LangFile.records?.aboutSummary}/>
            <View style={SummaryStyles.dateFilter}>
               <Button
               altStyle={SummaryStyles.buttonFilter}
               altText={SummaryStyles.altText}
                buttonText={LangFile.records?.datefrom+" "+datefrom.format("DD-MM-YYYY")}
                onPress={DateFrom}
      />
      <Button
      altStyle={SummaryStyles.buttonFilter}
      altText={SummaryStyles.altText}
                buttonText={LangFile.records?.dateto+" "+dateto.format("DD-MM-YYYY")}
                onPress={DateTo}
      />
       <SelectInputed
          showIcon1={true}
          showIcon2={true}
        //   title={LangFile.records?.selectquantityunit}
          iconName1={'pencil-square-o'}
          render={<RadioSelect resData={[{id:"sales-record",name:"Sales Record"},{id:"expenditure-record",name:'Expenditure Record'},{id:"production-record",name:'Production Record'},{id:"income",name:'Farm Income'}]} value={inputs?.units} onSelectValue={(e) => handleChange({value:JSON.parse(e).name,name:'units'})}/>}
          selectLabel={LangFile.records?.selectrecordtype}
          label={inputs.units!=undefined?inputs.units:LangFile.records?.selectrecordtype}
          iconName2={'angle-down'}
          Options={0}
        />
       <Button
               altStyle={SummaryStyles.submitbuttonFilter}
               altText={SummaryStyles.altText}
                buttonText={LangFile.records?.getsummary}
                onPress={onSearch}
      />
               </View>
        {data.length>0&&
        <>
        <ScrollView style={SummaryStyles.scrollView}>
          <Label fonts={15} color="#000" click={false} message={inputs?.units}/>
          <Button
               altStyle={SummaryStyles.buttonFilter}
               altText={SummaryStyles.altText}
                buttonText={LangFile.records?.download}
                onPress={createPDF}
      />
               <View style={SummaryStyles.tableContainer}>
           <ScrollView horizontal>
            <DataTable style={SummaryStyles.Datatable} >
        <DataTable.Header>
          <DataTable.Title style={SummaryStyles.Titletable} >{LangFile.register?.name}</DataTable.Title>
          <DataTable.Title style={SummaryStyles.Titletable}>{LangFile.salerecord?.quantity}</DataTable.Title>
          <DataTable.Title style={SummaryStyles.Titletable}>{LangFile.salerecord?.total+` (${naira})`}</DataTable.Title>
        </DataTable.Header>
        {data.map((item,i)=>(
             <DataTable.Row key={i} >
               <DataTable.Cell>{item.name}</DataTable.Cell>
               <DataTable.Cell>{item.quantity}</DataTable.Cell>
               <DataTable.Cell>{addCommaN(item.total)}</DataTable.Cell>
             </DataTable.Row>
        ))}
       
       <DataTable.Pagination
        page={page}
        numberOfPages={Math.ceil(data.length / numberOfItemsPerPage)}
        onPageChange={page => setPage(page)}
        label={`${from + 1}-${to} of ${items.length}`}
        showFastPaginationControls
        numberOfItemsPerPageList={numberOfItemsPerPageList}
        numberOfItemsPerPage={numberOfItemsPerPage}
        onItemsPerPageChange={onItemsPerPageChange}
        selectPageDropdownLabel={'Rows per page'}
      />
      </DataTable>
      </ScrollView>
      </View>
      </ScrollView>
      </>}
            </View>
        </ImageBackground>
    )
}

import React, { useEffect, useState } from 'react'
import { ImageBackground, ScrollView,DatePickerAndroid, View, Text, Alert, Platform } from 'react-native';
import AddExpenseStyle from "./userinfostyle";
import BackGroundImage from "../../../assets/farm.jpeg";
import { Button, GoBack, RadioSelect, SelectInputed, TextInputed } from '../../../Components';
import { checkProfile, pickLang, userProfile } from '../../../Auth';
import {igbo,english,hausa,yoruba} from '../../../Lang';
import moment from "moment";
import { _deleteData, _retrieveData, _storeData } from '../../../Service/function';
import axios from 'axios';
import { baseUrl, naira } from '../../../env';
import Toast from 'react-native-toast-message';
if(Platform.OS!=="web"){
  const KeyboardSpacer=require('react-native-keyboard-spacer');
}


export default function UserInfo({navigation,route}) {
    const [LangFile,setLangFile] = useState(english);
    const [inputs,setInputs]=useState({});
    const [isLoading,setLoading]=useState(false)
    const [total,setTotal]=useState(0);
    const [getUser,setUser]=useState({});
    const [date,setDate]=useState(moment());

    useEffect(() => {
        pickLang(setLangFile,english,hausa,igbo,yoruba);
        checkUser();
      },[]);

     
      const checkUser=async()=>{
        const resp=  await _retrieveData("userauth");
        setUser(resp);
      }

      const handleChange=(e)=>{
        const value= e.value;
        setInputs({...inputs, [e.name]:value});
    }

    useEffect(()=>{
        transInp();   
    },[])
  
const transInp=async()=>{
    setInputs(await userProfile(setUser));
}

    const submitRecord=async()=>{
        const token = await _retrieveData("token");
        setLoading(true);
                   try {
                       let datat={
                           name:inputs?.name,
                           phone:inputs?.phone,
                           address:inputs?.address,
                           email:inputs?.email
                       }
                    const resp= await axios.put(baseUrl+`users/${getUser.id}`,datat,{
                        headers:{
                            'Authorization':`Bearer ${token}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    await  _storeData(resp.data,"userauth");
                   await userProfile(setUser); 
                   Toast.show({
                    type: 'success',
                    text1: LangFile.success_message?.success,
                    text2: '👋'
                  });
                   setLoading(false);
                   } catch (error) {
                       setLoading(false);
                       alert(error.message);
                   }      
    }

    
      const deleteData=()=>{
         
      }
const ind=route.params?.index;
    return (
      <View style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
        <ImageBackground source={BackGroundImage} style={AddExpenseStyle.parent}>
            <View style={AddExpenseStyle.child}>
            <GoBack onPress={() => navigation.goBack()}  buttonText={LangFile.profile?.profile_update} add={ind!=undefined?true:false} addText={''} iconLeft={"delete"} size={30} iconLeftColor={'red'}/>
            <ScrollView style={AddExpenseStyle.scrollView}>
    <View style={{display:'flex',flexDirection:'column',marginTop:20}}>
        <TextInputed
          showIcon1={true}
          showIcon2={false}
          iconName1={'plus-square'}
          // iconName2={'eye'}
          label={LangFile.register?.name}
          placeholder={LangFile.records?.add+" "+LangFile.register?.name}
          onChangeText={(e) => handleChange({value:e,name:'name'})}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          defaultValue={inputs?.name}
          Secure={false}
        //   keyboardType={'numeric'}
        />
        <TextInputed
          showIcon1={true}
          showIcon2={false}
          iconName1={'plus-square'}
          label={LangFile.login?.phone}
          placeholder={LangFile.records?.add+" "+LangFile.login?.phone}
          onChangeText={(e) => handleChange({value:e,name:'phone'})}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          defaultValue={inputs?.phone}
        keyboardType={'numeric'}
        />
        <TextInputed
          showIcon1={true}
          showIcon2={false}
          iconName1={'plus-square'}
          label={LangFile.location?.address}
          placeholder={LangFile.location?.address}
          onChangeText={(e) => {handleChange({value:e,name:'address'});}}
          placeholderColor={'#282a355c'}
          defaultValue={inputs?.address}
          textColor={'#000'}
        />
        <TextInputed
          showIcon1={true}
          showIcon2={false}
          iconName1={'plus-square'}
          label={LangFile.register?.email}
          placeholder={LangFile.register?.email}
          onChangeText={(e) => handleChange({value:e,name:'email'})}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          defaultValue={inputs?.email}
        />
         <Button
        buttonText={LangFile.sell_now?.submit}
        onPress={submitRecord}
        isLoading={isLoading}
        message={LangFile.login?LangFile.login.moment:''}
      />
     {(Platform.OS!=="web")&&<KeyboardSpacer topSpacing={40} />}
      </View>
      </ScrollView>
            </View>
        </ImageBackground>
        </View>
    )
}


// import styles from '../Styles/login';
import React,{useState} from 'react';
import Icon from 'react-native-vector-icons/FontAwesome';
import { Text, View,Image,Modal,StyleSheet,Dimensions, TouchableOpacity } from 'react-native';
import {Button,ModalMd,RadioButton,DescriptionText} from '../../Components'
// import styles from '../Styles/login';

const {width,height}=Dimensions.get('screen');

export default function Setting(props) {
  
   
   
    return (
       <View>
       <ModalMd
          modalVisible={props.modalView}
          render={
              <View>
                  <DescriptionText message={props.title} />
          {props.render}
            <View style={{marginTop: 100}}>
            <Button  
      buttonText={props.close}
      onPress={(e) => props.onClosePress()}
    />
    </View> 
    </View>
          }
        />
        </View> 
    );
}







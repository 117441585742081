import React, { useContext, useEffect, useRef, useState } from 'react'
import ChatListStyle from "./chatliststyle";
import { FlatList,PermissionsAndroid, ImageBackground,Dimensions, Linking, SafeAreaView, ScrollView, StatusBar, Text, View, KeyboardAvoidingView, Alert, Image,Platform } from 'react-native';
import BackGroundImage from "../../../assets/farm.jpeg"
import {Logo,Label, SpaceBottom, Button, Lang, RadioButton, TextInputed, Dialogue, LabelSmall, LangText, Avatar, MenuButton, GoBack, TabDetail, ModalMd, DescriptionText, ModalLg} from '../../../Components';
import {igbo,english,hausa,yoruba} from '../../../Lang';
import PickLang from '../../../Modals/Language/PickLang';
import { _deleteData, _retrieveData, _retrieveToken, _storeData } from '../../../Service/function';
import { checkProfile,userProfile, disableBackButton, generateGreetings, pickLang } from '../../../Auth';
import { AuthContext } from '../../../context/authContext/AuthContext';
import { baseUrl, imageUrl } from '../../../env';
import axios from "axios";
import ChatWidget from '../../components/ChatWidget/ChatWidget';
import ChatBottom from '../../components/ChatBottom/ChatBottom';

import * as ImagePicker from 'expo-image-picker';
import moment from "moment";
import Geolocation from 'react-native-geolocation-service';
import Constants from 'expo-constants';
import WebView from 'react-native-webview';
import { ActivityIndicator, Colors } from 'react-native-paper';

if(Platform.OS==="ios"||Platform.OS==="android"){
    const KeyboardSpacer=require('react-native-keyboard-spacer');
}
const {width,height}=Dimensions.get('screen');
export default function ChatListScreen({navigation,route}) {
    const [isSecure, setSecure] = useState(true);
    const [getLangModal,setLangModal] = useState(false);
    const [modalShow,setModalShow] = useState(false);
    const [modalShow2,setModalShow2] = useState(false);
    const [getLang,setLang] = useState(0);
    const [LangFile,setLangFile] = useState(english);
    const [getChatStep,setChatStep]=useState(0);
    const [getUser, setUser] = useState({});
    const [isLoading, setLoading] = useState(false);
    const [getGreetings,setGreetings]=useState(null);
    const [chatmessage,setChatMessage]=useState(null);
    const [defaultMessage,setDefaultMessage]=useState([]);
    const [messageDialogue, setmessageDialogue] = useState("");
    const [isDialogueVisible, setisDialogueVisible] = useState(false);
    const [parseData,setParseData]=useState({});
   
    const [chatBot, setChatBot] = useState([]);

    let listViewRef;
    //Context
    const {user,isFetching,dispatch}=useContext(AuthContext);

    const richText = React.useRef();
    const resData=[
        {
          key:0,
          text:'English'
      },
      {
          key:1,
          text:'Hausa'
      },
      {
          key:2,
          text:'Igbo'
      },
      {
          key:3,
          text:'Yoruba'
      }
    ];
    useEffect(() => {
        pickLang(setLangFile,english,hausa,igbo,yoruba);
        generateGreetings(LangFile,setGreetings);
        disableBackButton()
      },[]);

      useEffect(() => {
        LoadDefault();
       
        // defaultStep(-1);
        setChatList();
        userProfile(setUser); 
      },[]);

      useEffect(()=>{
        LoadMessages();
      },[])

      const onCloseDialoguePress=()=>{
        setisDialogueVisible(false);
       }

    const OpenHref= async ()=>{
        await Linking.openURL('https://niss.gov.ng');
      }

      const onClosePress=()=>{
        // pickLang(setLangFile,english,hausa,igbo,yoruba);
        setLangModal(false);
       }

      const showLangModal=()=>{
        setLangModal(true);
       }

    const onSelectValue=async(val)=>{
        setLangFile(val==0?english:val==1?hausa:val==2?igbo:yoruba)
        setLang(val);
       await _storeData(val,'lang')
        setLangModal(false); 
    }

    const getChatBot=async()=>{
        let chatbot=await _retrieveData("chatbot");
        setChatBot(chatbot);
    }

    const restartChat=async()=>{
        await _storeData(0,'chatstep');
    }

    const LoadMessages=async()=>{
      
        setChatList();
        const token = await _retrieveData("token");
        try {
            const resp=await axios.get(baseUrl+"conversations?_sort=id:DESC&_limit=2",{
                headers: {
                   Authorization: `Bearer ${token}`
                }
            });
            var storeMessage=[];
            for (let j = 0; j < resp.data.length; j++) {
                var questionData=resp.data[j].question;
                var replyData=resp.data[j].replies;
                let respondR=replyData.slice(5);
                // console.log(respondR);
                let lastReply=replyData.slice(0,5); //Get the first chatbot replies
                let dap=lastReply.reverse();
                var revReverse=dap;
                // console.log(revReverse.length)
                var revQuestion=JSON.parse(questionData).reverse();

                // var revReverse=resp.data[j].replies.reverse();
                //     var revQuestion=JSON.parse(resp.data[j].question).reverse();
                for (let i = 0; i < revReverse.length; i++) {  
                try {
                    if(revReverse[i].status==1){
                        storeMessage.push({
                        type:1,
                        image:revReverse[i].type==="text"?0:revReverse[i].type==="image"?1:2,
                        step:i,
                        message:revReverse[i].reply,
                        imageUrl:revReverse[i].image?imageUrl+revReverse[i].image.url:'',
                        imagedata:{},
                        long:revReverse[i].type==="location"?JSON.parse(revReverse[i].reply.split(",")[0]):'',
                        lat:revReverse[i].type==="location"?JSON.parse(revReverse[i].reply.split(",")[1]):'',
                        cat:revReverse[i].type,
                        time:moment(revReverse[i].time).format('YYYY-MM-DD HH:mm:ss'),
                        status:true
                    });
                }
                        storeMessage.push({
                            type:revQuestion[i].type,
                            image:revQuestion[i].image,
                            step:revQuestion[i].step,
                            message:revQuestion[i].message,
                            imageUrl:revQuestion[i].image,
                            time:revQuestion[i].time,
                            status:revQuestion[i].status
                        });
                       
                } catch (error) {
                    // setChatList();
                    // console.log(error.message);
                }
                 }
                 for (let h = 0; h < respondR.length; h++) {
                    storeMessage.push({
                                type:2,
                                image:respondR[h].type==="text"?0:respondR[h].type==="image"?1:2,
                                step:respondR.length,
                                message:respondR[h].reply,
                                imageUrl:respondR[h].image?imageUrl+respondR[h].image.url:'',
                                imagedata:{},
                                user_two:resp.data[j].user_two?.name,
                                long:respondR[h].type==="location"?JSON.parse(respondR[h].reply.split(",")[0]):'',
                                lat:respondR[h].type==="location"?JSON.parse(respondR[h].reply.split(",")[1]):'',
                                cat:respondR[h].type,
                                time:moment(respondR[h].time).format('YYYY-MM-DD HH:mm:ss'),
                                status:true
                            });
                }
               
            }
            setDefaultMessage(storeMessage);
                await _storeData(storeMessage,'chatmessage');
              setLoading(false);
        } catch (error) {
           
        }
    }

    const LoadDefault=async()=>{
        getChatBot();
       
        const token = await _retrieveData("token");
        try {
            const resp=await axios.get(baseUrl+"auto-messages",{
                headers: {
                   Authorization: `Bearer ${token}`
                }
            });
              await _storeData(resp.data,'chatbot');
              if(chatBot.length==0){
                  getChatBot();
              }
              setLoading(false);
        } catch (error) {
            // setLoading(false);
            // setisDialogueVisible(true);
            // setmessageDialogue(error.message);
        }
    }

    const sendConversation=async(data)=>{
        const token = await _retrieveData("token");
        const datetime=moment().format('x');
        try {
            var reverseData=data.reverse();
            let datat={
                user_one:getUser.id,
                ip:Constants.installationId,
                question:JSON.stringify(reverseData.filter(obj => obj.type ===0)),
                time:moment().format('YYYY-MM-DD HH:mm:ss'),
                status:1,
                ref:datetime
            }
          const resp=await axios.post(baseUrl+'conversations',datat,{
              headers:{
                  'Authorization':`Bearer ${token}`,
                  'Content-Type': 'application/json'
              }
          });
           
            var countsubmit=0; var replyData={};
            for (let i = 0; i < reverseData.length; i++) {
                if(countsubmit<11){
                if(reverseData[i].type==1&&reverseData[i].image==0){
                   replyData= await sendReply(reverseData[i].message,datetime,token,resp.data.id,null,reverseData[i].cat,reverseData[i].time,'');
                }
                else if(reverseData[i].type==1&&reverseData[i].image==1){
                    const respImg=await uploadImage(token,reverseData[i].imagedata);
                    replyData= await sendReply('',datetime,token,resp.data.id,respImg[0].id,reverseData[i].cat,reverseData[i].time,'');
                }
                else if(reverseData[i].type==1&&reverseData[i].image==2){
                    replyData= await sendReply(`${reverseData[i].long},${reverseData[i].lat}`,datetime,token,resp.data.id,null,reverseData[i].cat,reverseData[i].time,'');
                }
            }
                countsubmit++
            }
            // await sendReply(reverseData,datetime,token,resp.id);
            LoadMessages();
        } catch (error) {
            // console.log(error.message)
        }
    }

    const uploadImage = async(token,image) => {
            var formData = new FormData();
            setLoading(true);
            formData.append('files',
            {
               uri:image.uri,
               name:image.fileName,
               type:image.type
            });
            const resp=await axios.post(baseUrl+"upload/",formData,{
                headers: {
                   Authorization: `Bearer ${token}`,
                   'Content-Type': 'multipart/form-data'
                }
            })
            return resp.data;
      };

    const sendReply=async(message,datetime,token,convid,image,type,time,questId)=>{
        try {
            let datat={
                reply:message,
                ip:Constants.installationId,
                time:time,
                status:1,
                type:type,
                conversation:convid,
                image:image
            }
                const resp=await axios.post(baseUrl+"replies",datat,{
                    headers: {
                        'Authorization':`Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });   
                return resp.data;
        } catch (error) {
            // console.log(error.message);
        }
    }

    const setChatList=async()=>{
        // await _deleteData('chatmessage');
        // await _storeData(0,'chatstep');
        let chatmsgd=await _retrieveData("chatmessage");
        defaultStep()
       setDefaultMessage(chatmsgd!==null?chatmsgd:[]);
      
    }

    const defaultStep=async(step)=>{
        let chatstep=await _retrieveData("chatstep");
        setChatStep(chatstep);
        // alert(chatstep)
        if(chatstep == null || chatstep == 6){
            setChatStep(0);
            await _storeData(0,'chatstep');
        }
    }

      const imagePress=async()=>{
        let res = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.All,
            allowsEditing: true,
            aspect: [4, 3],
            quality: 1,
          });
          if (!res.cancelled) {
                const source = { uri: res.uri };
        let chatmsgd=await _retrieveData("chatmessage");
            let message=chatmsgd==null?[]:chatmsgd;
            message.push({
                type:1,
                image:1,
                step:getChatStep,
                message:'',
                imageUrl:res.uri,
                imagedata:res,
                cat:'image',
                time:moment().format('YYYY-MM-DD HH:mm:ss'),
                status:false
            });
            setChatMessage("");
            let statMess=await checkMessage(null,getChatStep);
            if(statMess){return false}
            await _storeData(message,'chatmessage');
            let chatmsg=await _retrieveData("chatmessage");
            setDefaultMessage(chatmsg);
            await addBotMessage(getChatStep,null);
            setLangModal(false);
        //         // var formData = new FormData();
        //         // setLoading(true);
        //         // formData.append('files',
        //         // {
        //         //    uri:response.assets[0].uri,
        //         //    name:response.assets[0].fileName,
        //         //    type:response.assets[0].type
        //         // });
            }
      }

      const locationPress=async()=>{
        const hasLocationPermission= await PermissionsAndroid.request(
            PermissionsAndroid.PERMISSIONS.ACCESS_COARSE_LOCATION,
            {
              title: "Pick your current location",
              message:
                " Give the app permission to pick " +
                "your current device location.",
              buttonNeutral: "Ask Me Later",
              buttonNegative: "Cancel",
              buttonPositive: "OK"
            }
          );
          if (hasLocationPermission&&Platform.OS==="android") {
            Alert.alert(
                "Pick Current Location",
                "Send my device current location",
                [
                  {
                    text: "Cancel",
                    onPress: () => console.log("Cancel Pressed"),
                    style: "cancel"
                  },
                  { text: "OK", onPress: () => {
                    Geolocation.getCurrentPosition(
                        async(position) => {
                    let chatmsgd=await _retrieveData("chatmessage");
                    let message=chatmsgd==null?[]:chatmsgd;
                    message.push({
                        type:1,
                        image:2,
                        step:getChatStep,
                        message:'',
                        imageUrl:null,
                        cat:'location',
                        lat:position.coords.latitude,
                        long:position.coords.longitude,
                        time:moment().format('YYYY-MM-DD HH:mm:ss'),
                        status:false
                    });
                    setChatMessage("");
                    let statMess=await checkMessage(null,getChatStep);
                    if(statMess){return false}
                    await _storeData(message,'chatmessage');
                    let chatmsg=await _retrieveData("chatmessage");
                    setDefaultMessage(chatmsg);
                   
                    await addBotMessage(getChatStep,null);
                        },
                        (error) => {
                            locationPress();
                          // See error code charts below.
                        //   console.log(error.code, error.message);
                        },
                        { enableHighAccuracy: true, timeout: 30000, maximumAge: 10000 }
                    );
                  } }
                ]
              );
           
          }
          else if(Platform.OS==="ios"){
            Alert.alert(
                "Pick Current Location",
                "Send my device current location",
                [
                  {
                    text: "Cancel",
                    onPress: () => console.log("Cancel Pressed"),
                    style: "cancel"
                  },
                  { text: "OK", onPress: () => {
                    Geolocation.getCurrentPosition(
                        async(position) => {
                    let chatmsgd=await _retrieveData("chatmessage");
                    let message=chatmsgd==null?[]:chatmsgd;
                    message.push({
                        type:1,
                        image:2,
                        step:getChatStep,
                        message:'',
                        imageUrl:null,
                        cat:'location',
                        lat:position.coords.latitude,
                        long:position.coords.longitude,
                        time:moment().format('YYYY-MM-DD HH:mm:ss'),
                        status:false
                    });
                    setChatMessage("");
                    let statMess=await checkMessage(null,getChatStep);
                    if(statMess){return false}
                    await _storeData(message,'chatmessage');
                    let chatmsg=await _retrieveData("chatmessage");
                    setDefaultMessage(chatmsg);
                   
                    await addBotMessage(getChatStep,null);
                        },
                        (error) => {
                            locationPress();
                          // See error code charts below.
                        //   console.log(error.code, error.message);
                        },
                        { enableHighAccuracy: true, timeout: 30000, maximumAge: 10000 }
                    );
                  } }
                ]
              );
          }
          else{
            Alert.alert(
                "Pick Current Location",
                "Send my device current location",
                [
                  {
                    text: "Cancel",
                    onPress: () => console.log("Cancel Pressed"),
                    style: "cancel"
                  },
                  { text: "OK", onPress: () => {
            navigator.geolocation.getCurrentPosition(async function(position) {
                let chatmsgd=await _retrieveData("chatmessage");
                    let message=chatmsgd==null?[]:chatmsgd;
                    message.push({
                        type:1,
                        image:2,
                        step:getChatStep,
                        message:'',
                        imageUrl:null,
                        cat:'location',
                        lat:position.coords.latitude,
                        long:position.coords.longitude,
                        time:moment().format('YYYY-MM-DD HH:mm:ss'),
                        status:false
                    });
                    setChatMessage("");
                    let statMess=await checkMessage(null,getChatStep);
                    if(statMess){return false}
                    await _storeData(message,'chatmessage');
                    let chatmsg=await _retrieveData("chatmessage");
                    setDefaultMessage(chatmsg);
                   
                    await addBotMessage(getChatStep,null);
               
                    });
                } }
                ]
            );
          }
      
      }

    const addBotMessage=async(step,messaged)=>{
        let chatbott=await _retrieveData("chatbot");
        let chatmsgd=await _retrieveData("chatmessage");
        let message=chatmsgd;
        if( chatmsgd !== null ){
            message.push({
                type:0,
                image:1,
                step:step,
                message:step==0?chatbott[step].question+"\n"+chatbott[step+1].question:chatbott[step].question,
                imageUrl:'',
                time:moment().format('YYYY-MM-DD HH:mm:ss'),
                status:false
            });
            await _storeData(message,'chatmessage');
            let chatmsg=await _retrieveData("chatmessage");
            setTimeout(async() => {  setDefaultMessage(chatmsg); 
                await _storeData(step==0?2:step+1,'chatstep');
                defaultStep();
            }, 1000);
        }
        let chatbottn=await _retrieveData("chatstep");
        if(step==5){
            await _storeData(0,'chatstep');
            let chatbott=await _retrieveData("chatstep");
            setChatStep(0);
          await  sendConversation(chatmsgd)
        }

    }

      const submitChat=async()=>{
         
          if(!chatmessage){
              alert(LangFile.error.entermessage);
              return false;
          }
          else{
            let chatmsgd=await _retrieveData("chatmessage");
            let message=chatmsgd==null?[]:chatmsgd;
            message.push({
                type:1,
                image:0,
                step:getChatStep,
                message:chatmessage,
                cat:'text',
                imageUrl:null,
                time:moment().format('YYYY-MM-DD HH:mm:ss'),
                status:false
            });
            setChatMessage("");
          let statMess=await checkMessage(chatmessage,getChatStep);
          if(statMess){return false}
            await _storeData(message,'chatmessage');
            let chatmsg=await _retrieveData("chatmessage");
            setDefaultMessage(chatmsg);
            await addBotMessage(getChatStep,chatmessage);
          }
      }

      const checkMessage=async(messaged,step)=>{
           let chatbott=await _retrieveData("chatbot");
        let chatmsgd=await _retrieveData("chatmessage");
        if(chatmsgd&&chatmsgd.length>1&&chatbott[step-1]?.expect=='number'&&isNaN(messaged)==true){
            alert('Please message should be a number');
            return true;
        }
        if(chatmsgd&&chatmsgd.length>1&&chatbott[step-1]?.expect=='text'&&isNaN(messaged)==false){
            alert('Please message should be a text and not number');
            return true;
        }
        if(chatmsgd&&chatmsgd.length>1&&chatbott[step-1]?.expect=='location'&&messaged!=null){
            alert('Please pick your location and not text');
            return true;
        }
        if(chatmsgd&&chatmsgd.length>1&&chatbott[step-1]?.expect=='image'&&messaged!=null){
            alert('Please select image and not text');
            return true;
        }
        // console.log(messaged)
      }

      const viewData=async(data)=>{
        if(data.type==0){ setModalShow(true);}
        else if(data.type==1){ setModalShow2(true);}
      
       setParseData(data);
    }
     

      const renderItem = ({ item,index }) => (
       <ChatWidget key={index} modalShow={viewData} statud={item.status} message={item.message} support={item?.user_two} lat={item.lat} time={item.time} long={item.long} type={item.type} image={item.image?item.image:false} imageUrl={item.image?item.imageUrl:null} />
          // <MenuImage onPress={()=>navigation.navigate('Detail', { datars: item,lang:getLang })} key={index} imageUrl={item.image?baseUrl+item.image.url:''} colorIcon={'#006400'} buttonText={item.name} /> 
          );
      return (
        <View style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
        <ImageBackground source={BackGroundImage} style={ChatListStyle.parent}>
        <View style={ChatListStyle.child}>
        <GoBack onPress={() => navigation.navigate('Home')} onAddPress={restartChat} add={true} addText={''} iconLeft={"refresh"} size={30} iconLeftColor={'red'} buttonText={`${LangFile.menu?" "+LangFile.menu.menu5:"Expert List"}` } />
        {/* <TabDetail  onPress={selectedTab} text1={LangFile.chat?LangFile.chat.newchat:"New Chat"} text2={LangFile.chat?LangFile.chat.previouschat:'Previous Chat'}  indexSelect={1} /> */}
      <SafeAreaView style={{height:height*0.83,marginTop: 0,backgroundColor: 'rgba(255,255,255,0.7)' }}>
      <ScrollView style={{ flex: 1, margin: 10 }}>
      <View style={ChatListStyle.containertop}>
      <Text style={ChatListStyle.senderTxt}>{LangFile.chat.welcome}</Text>
      </View>
      <FlatList
        inverted
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        data={defaultMessage}
        renderItem={renderItem}
        keyExtractor={item => item.id} 
        />  
  </ScrollView>
  <ChatBottom value={chatmessage} placeholder={LangFile.chat?LangFile.chat.placeholder:""} onChangeText={(e)=>{setChatMessage(e)}} locationPress={locationPress} imagePress={imagePress} submitChat={submitChat} />
  {Platform.OS==="ios"||Platform.OS==="android"&&<KeyboardSpacer topSpacing={40} />}
  </SafeAreaView>
      <Dialogue
        modalVisible={isDialogueVisible}
        close={LangFile.login?LangFile.login.close:''}
        onClosePress={(e) => onCloseDialoguePress()}
        message={messageDialogue}
      />
      <PickLang modalView={getLangModal} 
       close={LangFile.login?LangFile.login.close:""}
      render={ <RadioButton resData={resData} value={getLang} onSelectValue={onSelectValue}/>}
      onClosePress={onClosePress}/>

      <ModalLg
modalView={{ margin: 5,
    width:width/1.1,
    padding: 0,
    height:height/1.2,}}
          modalVisible={modalShow}
          render={
              <View>
                  <DescriptionText message={"Google Map"} />
                
                  <SafeAreaView style={{ flex: 1 ,width:width/1.2,height:300}}>
             {isLoading&&
                            <View style={{display:'flex',flexDirection:'column', justifyContent:'center',alignItems:'center'}}>
                            <ActivityIndicator animating={true} color={Colors.red800} />
                            <Text style={{ color: "#01122e",fontSize: 20,fontWeight:'bold',}}>... a moment</Text>
                          </View>}
                  <WebView
                  style={[{ flex: 1 ,height:height}]}
                      javaScriptEnabled={true}
                      javaScriptEnabledAndroid={true}
                      scalesPageToFit={false}
                      allowFileAccess={false}
                      originWhitelist={['*']}
                      source={{ uri:`https://www.google.com/maps/@${parseData.lat},${parseData.long},15z`}}
                      onLoadStart={()=>setLoading(true)}
                      onLoadEnd={()=>setLoading(false)}
                    />
                          
                    </SafeAreaView>
            <View >
            <Button  
      buttonText={LangFile.login.close}
      onPress={(e) => setModalShow(false)}
    />

    
    </View> 
    </View>
          }
        />

<ModalMd
modalView={{
    width:width/1.2,
    height:height/1.3,}}
          modalVisible={modalShow2}
          render={
              <View>
                  <DescriptionText message={"View Image"} />
                
                  <SafeAreaView style={{ flex: 1 ,width:width/1.2,height:300}}>
            
                            <View style={{display:'flex',flexDirection:'column', justifyContent:'center',alignItems:'center'}}>
                           <Image style={{width:width/1.5,height:height/2.3}}  source={{uri:`${parseData.url}`}}  />
                          </View>
                    </SafeAreaView>
            <View >
            <Button  
      buttonText={LangFile.login.close}
      onPress={(e) => setModalShow2(false)}
    />

    
    </View> 
    </View>
          }
        />
            </View>
            </ImageBackground>
          </View>
      )

}
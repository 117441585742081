import React, { useEffect, useState } from 'react'
import { ImageBackground, ScrollView,DatePickerAndroid, View, Text, Alert, Platform } from 'react-native';
import AddExpenseStyle from "./businessinfostyle";
import BackGroundImage from "../../../assets/farm.jpeg";
import { Button, GoBack, RadioSelect, SelectInputed, TextInputed } from '../../../Components';
import { checkProfile, pickLang, userProfile } from '../../../Auth';
import {igbo,english,hausa,yoruba} from '../../../Lang';
import moment from "moment";
import { _deleteData, _retrieveData, _storeData } from '../../../Service/function';
import axios from 'axios';
import { baseUrl, naira } from '../../../env';
import Toast from 'react-native-toast-message';
if(Platform.OS!=="web"){
  const KeyboardSpacer=require('react-native-keyboard-spacer');
}


export default function BusinessInfo({navigation,route}) {
    const [LangFile,setLangFile] = useState(english);
    const [inputs,setInputs]=useState({});
    const [isLoading,setLoading]=useState(false)
    const [total,setTotal]=useState(0);
    const [getUser,setUser]=useState({});
    const [date,setDate]=useState(moment());

    useEffect(() => {
        pickLang(setLangFile,english,hausa,igbo,yoruba);
        checkUser();
      },[]);

      // useEffect(()=>{
      //   setTotal(inputs.quantity&&inputs.value?parseFloat(inputs.value)*parseFloat(inputs.quantity):0)
      // },[inputs])

      const checkUser=async()=>{
        const resp=  await _retrieveData("userauth");
        setUser(resp);
      }

      const handleChange=(e)=>{
        const value= e.value;
  
        setInputs({...inputs, [e.name]:value});
    }

    useEffect(()=>{
        transInp();   
    },[])
  
const transInp=async()=>{
  let bizdata=await userProfile(setUser);
    setInputs(bizdata['business']);
}

    const submitRecord=async()=>{
        const token = await _retrieveData("token");
        inputs['user']=inputs['user'].id
        inputs['businessimage']=inputs['businessimage'].id;
        inputs['products']=null;
        inputs['role']=null;
        setLoading(true);
                   try {
                        const resp= await axios.put(baseUrl+`businesses/${getUser.business.id}`,inputs,{
                            headers:{
                                'Authorization':`Bearer ${token}`,
                                'Content-Type': 'application/json'
                            }
                        });
                        let bizdata=await _retrieveData("userauth");
                        bizdata['business']=resp.data;
                        await  _storeData(bizdata,"userauth");
                       await userProfile(setUser); 
                       setLoading(false);
                   } catch (error) {
                       setLoading(false);
                       alert(error.message);
                   }      
    }

    
      const deleteData=()=>{
         
      }
const ind=route.params?.index;
    return (
      <View style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
        <ImageBackground source={BackGroundImage} style={AddExpenseStyle.parent}>
            <View style={AddExpenseStyle.child}>
            <GoBack onPress={() => navigation.goBack()}  buttonText={LangFile.profile?.business_update} add={ind!=undefined?true:false} addText={''} iconLeft={"delete"} size={30} iconLeftColor={'red'}/>
            <ScrollView style={AddExpenseStyle.scrollView}>
    <View style={{display:'flex',flexDirection:'column',marginTop:20}}>
        <TextInputed
          showIcon1={true}
          showIcon2={false}
          iconName1={'plus-square'}
          // iconName2={'eye'}
          label={LangFile.permission?.business_name}
          placeholder={LangFile.permission?.business_name}
          onChangeText={(e) => handleChange({value:e,name:'name'})}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          defaultValue={inputs?.name}
          Secure={false}
        //   keyboardType={'numeric'}
        />
        <TextInputed
          showIcon1={true}
          showIcon2={false}
          iconName1={'plus-square'}
          label={LangFile.permission?.business_address}
          placeholder={LangFile.permission?.business_address}
          onChangeText={(e) => handleChange({value:e,name:'business_address'})}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          defaultValue={inputs?.business_address}
        
        />
        <TextInputed
          showIcon1={true}
          showIcon2={false}
          iconName1={'plus-square'}
          label={LangFile.permission?.business_phone}
          placeholder={LangFile.permission?.business_phone}
          onChangeText={(e) => {handleChange({value:e,name:'business_contact'});}}
          placeholderColor={'#282a355c'}
          defaultValue={inputs?.business_contact}
          textColor={'#000'}
          keyboardType={'numeric'}
        />
        <TextInputed
          showIcon1={true}
          showIcon2={false}
          iconName1={'plus-square'}
          label={LangFile.permission?.business_whatsapp}
          placeholder={LangFile.permission?.business_whatsapp}
          onChangeText={(e) => handleChange({value:e,name:'contact'})}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          defaultValue={inputs?.contact}
        />
         <TextInputed
          showIcon1={true}
          showIcon2={false}
          iconName1={'plus-square'}
          label={LangFile.permission?.business_email}
          placeholder={LangFile.permission?.business_email}
          onChangeText={(e) => handleChange({value:e,name:'business_email'})}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          defaultValue={inputs?.business_email}
        />
         <SelectInputed
          showIcon1={true}
          showIcon2={true}
          title={LangFile.permission?.opening_time}
          iconName1={'pencil-square-o'}
          render={<RadioSelect resData={[{id:"8am6pm",name:"8am-6pm"},{id:"9am6pm",name:'9am-6pm'},{id:"8am9pm",name:'8am-9pm'},{id:"9am9pm",name:'9am-9pm'},{id:"9am4pm",name:'9am-4pm'},{id:"8am4pm",name:'8am-4pm'}]} value={inputs?.units} onSelectValue={(e) => handleChange({value:JSON.parse(e).name,name:'units'})}/>}
          selectLabel={LangFile.permission?.opening_time}
          label={inputs?inputs.openingtime:LangFile.permission?.opening_time}
          iconName2={'angle-down'}
          Options={0}
        />
        <SelectInputed
          showIcon1={true}
          showIcon2={true}
          title={LangFile.permission?.opening_period}
          iconName1={'pencil-square-o'}
          render={<RadioSelect resData={[{id:"mondaysaturday",name:"Monday - Saturday"},{id:"mondaysunday",name:'Monday - Sunday'},{id:"mondayfriday",name:'Monday - Friday'}]} value={inputs?.openingperiod} onSelectValue={(e) => handleChange({value:JSON.parse(e).name,name:'units'})}/>}
          selectLabel={LangFile.permission?.opening_period}
          label={inputs?inputs.openingperiod:LangFile.permission?.opening_period}
          iconName2={'angle-down'}
          Options={0}
        />
         <Button
        buttonText={LangFile.sell_now?.submit}
        onPress={submitRecord}
        isLoading={isLoading}
        message={LangFile.login?LangFile.login.moment:''}
      />
       {(Platform.OS!=="web")&&<KeyboardSpacer topSpacing={40} />}
      </View>
      </ScrollView>
            </View>
        </ImageBackground>
        </View>
    )
}

import { StyleSheet,Dimensions,Platform, StatusBar } from 'react-native';
const {width,height}=Dimensions.get('screen');
const isSmallDevice = Dimensions.get('window').width < 768
export default StyleSheet.create({
    parent:{
        ...Platform.select({
            ios: {
                height:height,
                width:isSmallDevice?width:width/2.5
              },
              android: {
               height:height,
               width:isSmallDevice?width:width/2.5
              },
              default: {
                // other platforms, web for example
                height:height/1.2,
                width:isSmallDevice?width:width/2.5
              }
            }),
    },
    child: {
      display:'flex',
      flexDirection:'column',
        flex: 1,
        backgroundColor: 'rgba(255,255,255,0.8)'
      },
    container: {
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginTop: 0,
    },
    scrollView:{
      ...Platform.select({
        ios: {
          marginBottom:height*0.11
          },
          android: {
            marginBottom:height*0.11
          },
          default: {
            // other platforms, web for example
            marginBottom:0
          }
        }),
       
    },
    menu:{
        flexDirection:'row',
        backgroundColor: 'rgba(255,255,255,0.8)',
        shadowColor:'#dec',
        borderRadius: 10, 
        width:width*0.97,
        padding:20, 
        margin:2
    },
    icon:{
        marginRight:20,
        color:"#0d503a"
    },
    text:{
        fontSize:18,
        color:"black"
    }
    
})
import { StyleSheet,Dimensions,Platform } from 'react-native';
const {width,height}=Dimensions.get('screen');
const isSmallDevice = Dimensions.get('window').width < 768
export default StyleSheet.create({
    parent:{
        ...Platform.select({
          ios: {
              height:height,
              width:width
            },
            android: {
             height:height,
             width:width
            },
            default: {
              // other platforms, web for example
              height:height/1.2,
              width:isSmallDevice?width:width/2.5
            }
          }),
    },
    child: {
        flex: 1,
        backgroundColor: 'rgba(255,255,255,0.1)'
      },
      containertop:{
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        justifyContent:'center',
        margin:10,
        backgroundColor:'white',
        padding:10,
        borderTopRightRadius:10,
        borderBottomRightRadius:10,
        borderBottomLeftRadius:10
    },
    senderTxt:{
        color:"black",
        fontSize:16,
        fontWeight:'bold',
        marginRight:20
    },

      container:{
    marginBottom:height*0.1
     },
     bottomView:{
        flexDirection:'row',
        justifyContent:'center',
        margin:20
     },
     flatContainer:{
        display:'flex',
        flexDirection: 'row',
        alignItems:'center',
        justifyContent:'space-between',
        backgroundColor:"#ffff",
        padding: 15,
        marginBottom: 10
      },
      // contentView:{
      //   flex:1, 
      // }
  
});
import { StyleSheet,Dimensions } from 'react-native';
const {width,height}=Dimensions.get('screen');
export default StyleSheet.create({
    container:{
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        padding:0,
        backgroundColor: 'rgba(253,253,253,0.9)',
        height:50
    },
    containerTop:{
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        padding:5,
        backgroundColor: 'rgba(250,250,250,0.6)',
        height:50
    },

  

    status:{
        fontWeight:'bold',
        color:'gray',
        fontSize:25,
        paddingLeft:10,
        backgroundColor: 'rgba(253,253,253,0.9)',
    },
    statusTop:{
        fontWeight:'bold',
        color:'green',
        fontSize:25,
        paddingLeft:10,
        marginLeft:20,
    },
    statusLeft:{
        fontWeight:'bold',
        color:'white',
        fontSize:25,
    },
    inputStyle: {
        height: 50,
        backgroundColor: 'rgba(253,253,253,0.9)',
        color: "#000",
        marginLeft: 9,
        paddingRight: 5,
        fontSize: 14,
        alignSelf: "stretch",
        flex: 1,
        paddingTop: 14,
        paddingBottom: 8,
      },
      sendButton:{
        height: 50,
        backgroundColor:'green',
        padding:10,
        borderBottomRightRadius:10,
        borderTopRightRadius:10,
        alignItems:'center',
        justifyContent:'center'
      }

});
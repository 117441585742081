import React from 'react';
import Icon from 'react-native-vector-icons/FontAwesome';
import { Text, View,Image,StyleSheet,ActivityIndicator, TouchableOpacity,Dimensions,Platform } from 'react-native';
import ClipLoader from "react-spinners/ClipLoader";
const isSmallDevice = Dimensions.get('window').width < 768;
const {width,height}=Dimensions.get('screen');
export default function Button(props) {
    return (
        <View>
             {!props.isLoading&&
       <TouchableOpacity
        style={[styles.button1,props.altStyle]}
        onPress={() => props.onPress()}
      >
        {props.icon&&
         <Icon name={props.icon} style={{color:props.color,fontSize: 13,paddingRight: 5}}></Icon>}
        <Text style={[styles.btnText,props.altText]}>{props.buttonText}</Text>
      </TouchableOpacity>
}
      {props.isLoading&&
      <View style={{flexDirection:'row',alignItems:'center',justifyContent:'center'}}>
      {Platform.OS!=="web"&&
      <ActivityIndicator size="large" color={styles.btnText} animating={props.isLoading}/>}
      {Platform.OS==="web"&&
      <ClipLoader color={'green'} loading={props.isLoading}  size={50} />
      }
      <Text style={styles.btnText}>{props.message}</Text>
      </View>
        }
      </View>
    );
}
const styles=StyleSheet.create({
    button1: {
      display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center',
        borderColor: "#000",
        alignItems: "center",
        padding: 15,
        marginBottom: 20,
        backgroundColor:'rgba(255,255,255,0.7)',
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10,
        borderBottomLeftRadius: 10,
        marginLeft: 20,
        marginRight: 20,
        borderWidth: 2
      },
      btnText: {
        color: "#000",
        fontSize: 20,
        fontWeight:'bold',
      }, 
      iconStyle1: {
        color: "#000",
        fontSize: 20,
        paddingLeft: 12
      },
})






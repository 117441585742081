import React, { useEffect, useState } from 'react'
import { ImageBackground, ScrollView,DatePickerAndroid, View, Text, Alert, TouchableOpacity } from 'react-native';
import CalendarStyle from "./calendarstyle";
import BackGroundImage from "../../../assets/farm.jpeg";
import { Button, GoBack, RadioSelect, SelectInputed, TextInputed } from '../../../Components';
import { checkProfile, pickLang } from '../../../Auth';
import {igbo,english,hausa,yoruba} from '../../../Lang';
import moment from "moment";
import { _deleteData, _retrieveData, _storeData } from '../../../Service/function';
import axios from 'axios';
import { baseUrl } from '../../../env';
import Toast from 'react-native-toast-message';
import {Calendar, CalendarList, Agenda} from 'react-native-calendars';
import Icon from 'react-native-vector-icons/Ionicons';


export default function AddSales({navigation,route}) {
    const [LangFile,setLangFile] = useState(english);
    const [inputs,setInputs]=useState({});
    const [isLoading,setLoading]=useState(false)
    const [total,setTotal]=useState(0);
    const [getUser,setUser]=useState({});
    const [date,setDate]=useState(new Date());
    const [task,setTask]=useState([]);
    const [items,setItems]=useState([]);

    useEffect(() => {
        pickLang(setLangFile,english,hausa,igbo,yoruba);
        getAllSales();
      },[]);


const getAllSales=async()=>{
    const retSales=await _retrieveData("TaskRecord");
    if(retSales){
    setTask(retSales.reverse());
    }
}

const showDates=()=>{
  let objects={};
  for (let i = 0; i < task.length; i++) {
    objects[moment(task[i].date).format("YYYY-MM-DD")]= {selected: true,dotColor: 'red', activeOpacity: 0, marked: true, selectedColor: 'green'}
  }
  return objects;
}

const viewDate=(date)=>{
  let arrayRes=[];
 
  for (let i = 0; i < task.length; i++) {
    var dater=moment(task[i].date).format("YYYY-MM-DD");
      if(dater===date){
          arrayRes.push(task[i]);
      }
  }
  setItems(arrayRes);
}



      return (
        <ImageBackground source={BackGroundImage} style={CalendarStyle.parent}>
            <View style={CalendarStyle.child}>
            <GoBack onPress={() => navigation.goBack()} buttonText={LangFile.records?.viewCalendar}  iconLeftColor={'red'}/>
            <Calendar
            onDayPress={day => {
              viewDate(day.dateString)
            }}
            current={moment().format("YYYY-MM-DD")}
            markedDates={showDates()}
            />
              <ScrollView style={CalendarStyle.scrollView}>
           <View style={CalendarStyle.tableContainer}>
      {items.map((item,index)=>(
            <TouchableOpacity onPress={()=>navigation.navigate("AddTaskList",{datar:item,index:index})} key={index} style={moment(item.date).format("LL")===moment().format("LL")?CalendarStyle.itemContainerActive:CalendarStyle.itemContainer}>
            <View style={{padding:10}}>
             <Icon name={'notifications'} style={moment(item.date).format("LL")===moment().format("LL")?CalendarStyle.itemIconActive:CalendarStyle.itemIcon}></Icon>
             </View>
              <View style={CalendarStyle.itemMini}>
           <Text style={moment(item.date).format("LL")===moment().format("LL")?CalendarStyle.itemTitleActive:CalendarStyle.itemTitle}>{item.title}</Text>
           <Text style={moment(item.date).format("LL")===moment().format("LL")?CalendarStyle.itemDescActive:CalendarStyle.itemDesc}>{item.description}</Text>
           <View style={CalendarStyle.dateContainer}>
           <Icon name={"calendar"} style={moment(item.date).format("LL")===moment().format("LL")?CalendarStyle.dateIconActive:CalendarStyle.dateIcon}></Icon>
           <Text style={moment(item.date).format("LL")===moment().format("LL")?CalendarStyle.dateTextActive:CalendarStyle.dateText}>{moment(item.date).format("LL")}</Text>
           <Icon name={"time"} style={moment(item.date).format("LL")===moment().format("LL")?CalendarStyle.dateIconActive:CalendarStyle.dateIcon}></Icon>
           <Text style={moment(item.date).format("LL")===moment().format("LL")?CalendarStyle.dateTextActive:CalendarStyle.dateText}>{moment(item.time).format("LT")}</Text>
         </View>
           </View>
         </TouchableOpacity>
      ))}
     </View>
     </ScrollView>
        </View>
        </ImageBackground>
      )
}
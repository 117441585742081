import React from 'react';
import Icon from 'react-native-vector-icons/FontAwesome';
import { Text, View,Image, TouchableOpacity, StyleSheet } from 'react-native';


export default function TabDetail(props) {
    return (
        <View style={styles.header}>
          {props.text1&&
         <TouchableOpacity
        style={props.indexSelect==1?styles.buttonActive:styles.button1}
        onPress={() => props.onPress(1)}
      >
        <Text style={props.indexSelect==1?styles.btnTextSelect:styles.btnText}>{props.text1}</Text>
      </TouchableOpacity>}
      {props.text2&&
      <TouchableOpacity
        style={props.indexSelect==2?styles.buttonActive:styles.button1}
        onPress={() => props.onPress(2)}
      >
        <Text style={props.indexSelect==2?styles.btnTextSelect:styles.btnText}>{props.text2}</Text>
      </TouchableOpacity>}
      {props.text3&&
      <TouchableOpacity
        style={props.indexSelect==3?styles.buttonActive:styles.button1}
        onPress={() => props.onPress(3)}
      >
        <Text style={props.indexSelect==3?styles.btnTextSelect:styles.btnText}>{props.text3}</Text>
      </TouchableOpacity>}
      {props.text4&&
      <TouchableOpacity
        style={props.indexSelect==4?styles.buttonActive:styles.button1}
        onPress={() => props.onPress(4)}
      >
        <Text style={props.indexSelect==4?styles.btnTextSelect:styles.btnText}>{props.text4}</Text>
      </TouchableOpacity>}
      </View>

    );
}
const styles=StyleSheet.create({
  header: {
    justifyContent:'space-evenly',
    alignItems:'center',
     flexDirection:'row',
     margin: 0,
     paddingTop:10,
   },
   button1: {
    // borderColor: "#0d503a",
    alignItems: "center",
   padding:10,
    margin:5,
    backgroundColor:'white',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    marginLeft: 0,
    marginRight: 0,
    // borderWidth: 1
  },
  buttonActive: {
    // borderColor: "#0d503a",
    alignItems: "center",
   padding:10,
    margin:5,
    backgroundColor:'black',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    marginLeft: 0,
    marginRight: 0,
    // borderWidth: 1
  },
  btnText: {
    color: "#9A8E8E",
    fontSize: 17,
    fontWeight:'bold',
  },
  btnTextSelect: {
    color: "#fff",
    fontSize: 17,
    fontWeight:'bold',
  },  
})





import React from 'react';
import Icon from 'react-native-vector-icons/FontAwesome';
import { Text, View,Image,StyleSheet,Dimensions,ActivityIndicator, TouchableOpacity, Platform } from 'react-native';
import { Appbar } from 'react-native-paper';
import { StatusBar } from 'expo-status-bar';
// import styles from '../Styles/login';
const {width,height}=Dimensions.get('screen');
export default function GoBack(props) {
    return (
      //   <View  style={styles.button1} >   
      //     <View style={{display:'flex',flexDirection:'row',justifyContent:'space-between', margin:10}}>
      //  <Icon   onPress={() => props.onPress()}  name={'chevron-left'} style={styles.iconStyle1}></Icon>
      //   <Text style={styles.btnText}>{props.buttonText}</Text>
      // {props.add&&
      //  <View style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
      //   <Icon   onPress={() => props.onAddPress()}   name={'plus'} style={styles.iconStyle1}></Icon>
      //   <Text style={{fontSize:12,fontWeight:'bold'}}>{props.addText}</Text>
      //   </View>}
      //   </View>
      // {/* </TouchableOpacity> */}
      // </View>
      <>
      {/* <StatusBar style={'light'} /> */}
      <Appbar style={styles.bottom}>
     <Appbar.BackAction  size={30}  onPress={() => props.onPress()}/>
       <Appbar.Content title={props.buttonText.substring(0, 20).concat('...')}  />
       {props.add&&
        <View style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
       <Appbar.Action
    
       color={props.iconLeftColor}
       size={props.size?props.size:40}
         icon={props.iconLeft}
         onPress={() => props.onAddPress()}
       />
       <Appbar.Content color="#000" titleStyle={{fontSize:12,marginTop:-20}} title={props.addText}  />
       </View>}
     </Appbar>
     </>
    );
}
const styles=StyleSheet.create({
  bottom: {
    padding: 10,
    justifyContent:'space-between',
        backgroundColor:'rgba(255,255,255,0.1)',
        marginBottom: 0,
        marginLeft: 0,
        marginRight: 0,
        borderWidth: 0,
        marginTop:Platform.OS === 'ios'?30:0
  },
    button1: {
        borderColor: "rgba(255,255,255,0.7)",
        // alignItems: "center",
        padding: 0,
        backgroundColor:'rgba(255,255,255,0.7)',
        marginBottom: 0,
        marginLeft: 0,
        marginRight: 0,
        borderWidth: 2
      },
      btnText: {
        color: "#000",
        width:width,
        textAlign:'center',
        position:'absolute',
        fontSize:16,
        alignSelf:'center',
        fontWeight:'bold',
      }, 
      iconStyle1: {
        color: "#000",
        fontSize: 30,
        paddingLeft: 12,
      },
})






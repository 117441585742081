import React from 'react';
import Icon from 'react-native-vector-icons/FontAwesome';
import { Text, View,Image,StyleSheet,ActivityIndicator, TouchableOpacity } from 'react-native';

export default function LabelTouch(props) {
    return (
        <TouchableOpacity onPress={() => props.onPress()} style={{alignItems:'center',flexDirection: "row",padding:0}}>
        <Icon name={props.iconName} style={{color: props.colorIcon,fontSize: 13,paddingRight: 5}}></Icon>
      <Text style={{color: props.color,
        fontSize: props.fonts,
        width:props.width,
        fontWeight:'bold'}}
        // onPress={() => props.onPress()}
        >{props.message}</Text>
      </TouchableOpacity>
    );
}






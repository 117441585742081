import React, { useEffect, useState } from 'react'
import { ImageBackground, ScrollView,DatePickerAndroid, View, Text, Alert,Platform } from 'react-native';
import AddInputStyle from "./addinputstyle";
import BackGroundImage from "../../../../assets/farm.jpeg";
import { Button, GoBack, RadioSelect, SelectInputed, TextInputed } from '../../../../Components';
import { checkProfile, pickLang } from '../../../../Auth';
import {igbo,english,hausa,yoruba} from '../../../../Lang';
import moment from "moment";
import { _deleteData, _retrieveData, _storeData } from '../../../../Service/function';
import axios from 'axios';
import { baseUrl, naira } from '../../../../env';
import Toast from 'react-native-toast-message';

if(Platform.OS!=="web"){
  const KeyboardSpacer=require('react-native-keyboard-spacer');
}
if(Platform.OS==="web"){
  const DatePicker =require("react-datepicker");
                   require("react-datepicker/dist/react-datepicker.css");
 }

export default function AddInput({navigation,route}) {
    const [LangFile,setLangFile] = useState(english);
    const [inputs,setInputs]=useState({});
    const [isLoading,setLoading]=useState(false)
    const [total,setTotal]=useState(0);
    const [getUser,setUser]=useState({});
    const [date,setDate]=useState(moment());

    useEffect(() => {
        pickLang(setLangFile,english,hausa,igbo,yoruba);
        checkUser();
      },[]);

      useEffect(()=>{
        setTotal(inputs.quantity&&inputs.value?parseFloat(inputs.value)*parseFloat(inputs.quantity):0)
      },[inputs])

      const checkUser=async()=>{
        const resp=  await _retrieveData("userauth");
        setUser(resp);
      }

      const handleChange=(e)=>{
        const value= e.value;
        setInputs({...inputs, [e.name]:value});
    }

    useEffect(()=>{
        const idx=route.params?.datar;
        if(idx){
            setInputs(route.params.datar);
            setDate(moment(route.params.datar.date));
        }
    },[])
    const pickDate=async()=>{
        try {
            const {
              action,
              year,
              month,
              day
            } = await DatePickerAndroid.open({
              // Use `new Date()` for current date.
              // May 25 2020. Month 0 is January.
              date:date.toDate()
            });
            if (action !== DatePickerAndroid.dismissedAction) {
                setDate(moment(`${year}-${month.length>1?month + 1:"0"+(month+1)}-${day}`));
            }
          } catch ({ code, message }) {
            // console.warn('Cannot open date picker', message);
          }
    }
    const sendtoServer=async(inp,idk)=>{
        const token = await _retrieveData("token");
            let datat={
                    title:inp.expense,
                    date:inp.date,
                    content:inp,
                    type:'input',
                    ref:inp.id,
                    users:getUser.id
                   }
                   try {
                    const resp=await axios.post(baseUrl+'managers',datat,{
                        headers: {
                           Authorization: `Bearer ${token}`
                        }
                    });
                    var salesRec=await _retrieveData("InputRecord");
                    inp['status']=true;
                    salesRec.splice(idk,1,inp);
                    await _storeData(salesRec,'InputRecord');
                   } catch (error) {
                       
                   }      
    }
      const submitRecord=async()=>{
          var salesRec=await _retrieveData("InputRecord");
          if(!inputs.material||!inputs.farmname||!inputs.cropyear||!inputs.purpose||!inputs.rateamount||!inputs.notes){
            alert(LangFile.error.fillall);
            return false;
         }
          try {
            if(salesRec){
                const lastKey=salesRec.reverse();
                inputs['id']=moment().format('x')+Math.floor(Math.random() * 101);
                inputs['date']=date.format("YYYY-MM-DD");
                salesRec.push(inputs);
               await _storeData(salesRec,"InputRecord");
               Toast.show({
                type: 'success',
                text1: LangFile.success_message?.success,
                text2: '👋'
              });
              if(salesRec.length>5){
                  for (let i = 0; i < salesRec.length; i++) {
                    if(salesRec[i]&&salesRec[i].status){
                   await sendtoServer(salesRec[i],i);
                    }
                  }
              }
               
            }
            else{
               var salesDot=[];
              inputs['id']=moment().format('x')+Math.floor(Math.random() * 101);
              inputs['date']=date.format("YYYY-MM-DD");
              salesDot.push(inputs);
                await _storeData(salesDot,"InputRecord");
                Toast.show({
                    type: 'success',
                    text1: LangFile.success_message?.success,
                    text2: '👋'
                  });
          
            }
          navigation.goBack();
          } catch (error) {
            Toast.show({
                type: 'error',
                text1: error.message,
                text2: '👋'
              });
          }
        
      }

      const updateRecord=async()=>{
        const record=await _retrieveData("InputRecord");
        record.splice(route.params.index,1,inputs);
        await sendtoServer(inputs,route.params.index);
        await _storeData(record,'InputRecord');
        navigation.goBack();
      }

      const deleteData=()=>{
           Alert.alert("NissAgro",LangFile.error.deletesure, [
        {
          text: LangFile.error.cancel,
          onPress: () => null,
          style: "cancel"
        },
        { text: LangFile.error.yes, onPress: async() => {
            const record=await _retrieveData("InputRecord");
                record.splice(route.params.index, 1);
            await _storeData(record,'InputRecord');
            navigation.goBack();
        } }
      ]);
      }
const ind=route.params?.index;
    return (
      <View style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
        <ImageBackground source={BackGroundImage} style={AddInputStyle.parent}>
            <View style={AddInputStyle.child}>
            <GoBack onPress={() => navigation.goBack()} onAddPress={deleteData} buttonText={ind!=undefined?LangFile.records?.editinputrecord:LangFile.records?.addinputrecord} add={ind!=undefined?true:false} addText={''} iconLeft={"delete"} size={30} iconLeftColor={'red'}/>
            <ScrollView style={AddInputStyle.scrollView}>
    <View style={{display:'flex',flexDirection:'column',marginTop:20}}>
    {Platform.OS!=="web"&&
    <Button
    altStyle={AddInputStyle.buttonFilter}
    altText={AddInputStyle.altText}
    buttonText={ind!=undefined?LangFile.records?.edit+" "+LangFile.salerecord?.applydate+" "+date.format("DD-MM-YYYY"):LangFile.records?.add+" "+LangFile.salerecord?.applydate+" "+date.format("DD-MM-YYYY")}
    onPress={pickDate}
      />}
       {Platform.OS==="web"&&
        <DatePicker selected={date} onChange={(date) => setDate(date)} />}
        <TextInputed
          showIcon1={true}
          showIcon2={false}
          iconName1={'plus-square'}
          // iconName2={'eye'}
          label={ind!=undefined?LangFile.records?.edit+" "+LangFile.salerecord?.materialapplied:LangFile.records?.add+" "+LangFile.salerecord?.materialapplied}
          placeholder={LangFile.records?.add+" "+LangFile.salerecord?.materialapplied}
          onChangeText={(e) => handleChange({value:e,name:'material'})}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          defaultValue={inputs?.material}
          Secure={false}
        //   keyboardType={'numeric'}
        />
         <TextInputed
          showIcon1={true}
          showIcon2={false}
          iconName1={'plus-square'}
          // iconName2={'eye'}
          label={ind!=undefined?LangFile.records?.edit+" "+LangFile.salerecord?.farmname:LangFile.records?.add+" "+LangFile.salerecord?.farmname}
          placeholder={LangFile.records?.add+" "+LangFile.salerecord?.farmname}
          onChangeText={(e) => handleChange({value:e,name:'farmname'})}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          defaultValue={inputs?.farmname}
          Secure={false}
        //   keyboardType={'numeric'}
        />
        <TextInputed
          showIcon1={true}
          showIcon2={false}
          iconName1={'plus-square'}
          label={ind!=undefined?LangFile.records?.edit+" "+LangFile.salerecord?.parcelid:LangFile.records?.add+" "+LangFile.salerecord?.parcelid}
          placeholder={LangFile.records?.add+" "+LangFile.salerecord?.parcelid}
          onChangeText={(e) => handleChange({value:e,name:'parcelid'})}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          defaultValue={inputs?.parcelid}
          keyboardType={'numeric'}
        />
      <SelectInputed
          showIcon1={true}
          showIcon2={true}
          title={LangFile.salerecord?.cropyear}
          iconName1={'pencil-square-o'}
          render={<RadioSelect resData={[{id:moment().format("YYYY"),name:moment().format("YYYY")},{id:moment().subtract(1, "years").format("YYYY"),name:moment().subtract(1, "years").format("YYYY")},{id:moment().subtract(2, "years").format("YYYY"),name:moment().subtract(2, "years").format("YYYY")},{id:moment().subtract(3, "years").format("YYYY"),name:moment().subtract(3, "years").format("YYYY")},{id:moment().subtract(4, "years").format("YYYY"),name:moment().subtract(4, "years").format("YYYY")},{id:moment().subtract(5, "years").format("YYYY"),name:moment().subtract(5, "years").format("YYYY")}]} value={inputs?.cropyear} onSelectValue={(e) => handleChange({value:JSON.parse(e).name,name:'cropyear'})}/>}
          selectLabel={LangFile.salerecord?.cropyear}
          label={inputs?inputs.cropyear:LangFile.salerecord?.cropyear}
          iconName2={'angle-down'}
          Options={0}
        />
        <TextInputed
          showIcon1={true}
          showIcon2={false}
          iconName1={'plus-square'}
          label={ind!=undefined?LangFile.records?.edit+" "+LangFile.salerecord?.purpose:LangFile.records?.add+" "+LangFile.salerecord?.purpose}
          placeholder={LangFile.records?.add+" "+LangFile.salerecord?.purpose}
          onChangeText={(e) => {handleChange({value:e,name:'purpose'});}}
          placeholderColor={'#282a355c'}
          defaultValue={inputs?.purpose}
          textColor={'#000'}
        />
        <TextInputed
          showIcon1={true}
          showIcon2={false}
          iconName1={'plus-square'}
          label={ind!=undefined?LangFile.records?.edit+" "+LangFile.salerecord?.rateamount:LangFile.records?.add+" "+LangFile.salerecord?.rateamount}
          placeholder={LangFile.records?.add+" "+LangFile.salerecord?.rateamount}
          onChangeText={(e) => handleChange({value:e,name:'rateamount'})}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          defaultValue={inputs?.rateamount}
        />
         <TextInputed
          showIcon1={true}
          showIcon2={false}
          iconName1={'plus-square'}
          multiline={true}
          numberOfLines={4}
          label={ind!=undefined?LangFile.records?.edit+" "+LangFile.salerecord?.notesandobservation:LangFile.records?.add+" "+LangFile.salerecord?.notesandobservation}
          placeholder={LangFile.records?.add+" "+LangFile.salerecord?.notesandobservation}
          onChangeText={(e) => handleChange({value:e,name:'notes'})}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          defaultValue={inputs?.notes}
        />
         <Button
        buttonText={LangFile.sell_now?.submit}
        onPress={ind!=undefined?updateRecord:submitRecord}
        isLoading={isLoading}
        message={LangFile.login?LangFile.login.moment:''}
      />
      {Platform.OS!=="web"&&<KeyboardSpacer topSpacing={40} />}
      </View>
      </ScrollView>
            </View>
        </ImageBackground>
        </View>
    )
}

import { StyleSheet,Dimensions } from 'react-native';
const {width,height}=Dimensions.get('screen');
export default StyleSheet.create({
    parent:{
        width: width,
        height: height,
    },
    child: {
      display:'flex',
      flexDirection:'column',
        flex: 1,
        backgroundColor: 'rgba(255,255,255,0.7)'
      },
      buttonFilter:{
        padding: 15,
        marginBottom: 5,
        borderWidth:0
      },
      altText:{
        fontSize: 14,
      },
      tableContainer:{
        display:'flex',
        flexDirection:'row',
        // alignItems:'center',
        // justifyContent:'center',
        marginBottom:10,
        backgroundColor:'rgba(255,255,255,0.7)',
        padding:10,
        borderTopRightRadius:10,
        borderBottomRightRadius:10,
        borderBottomLeftRadius:10,
        marginBottom:40
    },
    Datatable:{
      marginBottom:50,
      width:width
    },
    Titletable:{
      fontSize:14,
      fontWeight:'bold',
    },
    senderTxt:{
        color:"black",
        fontSize:16,
        fontWeight:'bold',
        marginRight:20
    },
      scrollView:{
          marginBottom:height*0.11
      }
});
import { StyleSheet,Dimensions,Platform } from 'react-native';
const {width,height}=Dimensions.get('screen');
const isSmallDevice = Dimensions.get('window').width < 768

export default StyleSheet.create({
    parent:{
      ...Platform.select({
        ios: {
            height:height,
            width:width
          },
          android: {
           height:height,
           width:width
          },
          default: {
            // other platforms, web for example
            height:height/1.2,
            width:isSmallDevice?width:width/2.5
          }
        }),
    },
    child: {
        flex: 1,
        backgroundColor: 'rgba(255,255,255,0.7)'
      },

      container:{
        ...Platform.select({
          ios: {
            marginBottom:height*0.01
            },
            android: {
              marginBottom:height*0.1
           
            },
            default: {
              marginBottom:0
            }
          }),
    // marginBottom:Platform.OS === 'ios' ?height*0.01:height*0.1
     },
     bottomView:{
        flexDirection:'row',
        justifyContent:'center',
        margin:20
     },
     scrollView:{
      ...Platform.select({
        ios: {
          marginBottom:height*0.11
          },
          android: {
            marginBottom:height*0.11
         
          },
          default: {
            marginBottom:0
          }
        }),
      },
     
  weatherContainer:{
    display:'flex',
    marginLeft:10,
    marginRight:10,
    borderTopLeftRadius:10,
    borderTopRightRadius:10,
    flexDirection:'column',
    alignItems:'center',
    justifyContent:'space-evenly',
    backgroundColor:'rgba(255,255,255,0.7)'
  },
  
});
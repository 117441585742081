
// import styles from '../Styles/login';
import React,{useState} from 'react';
import Icon from 'react-native-vector-icons/FontAwesome';
import {baseUrl} from '../../env';
import { Text, View,Image,Modal,StyleSheet,Dimensions, TouchableOpacity, ScrollView, FlatList } from 'react-native';
import {Button,ModalLg,RadioButton,DescriptionText, TextInputed, ProductImage} from '../../Components'
// import styles from '../Styles/login';

const {width,height}=Dimensions.get('screen');

export default function ProductSearch(props) {
  
    const renderItem = ({ item,index }) => (
        <ProductImage onPress={()=>props.navigatePage(item)} key={index} imageUrl={item.image?baseUrl+item.image.url:''} colorIcon={'#006400'} price={'₦'+item.price+''} buttonText={item.name} /> 
    );

    return (
       <View>
       <ModalLg
          modalVisible={props.modalView}
          render={
              <View>
                  <DescriptionText message={props.title} />
                  <ScrollView style={styles.container} persistentScrollbar={true} contentContainerStyle={{paddingRight:10}}>
                  <TextInputed
          showIcon1={true}
          showIcon2={false}
          iconName1={'tag'}
          // iconName2={'eye'}
          placeholder={props.search}
          onChangeText={(e) => props.changeSearch(e)}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          Secure={false}
        />
         <FlatList
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        data={props.getProduct}
        renderItem={renderItem}
        keyExtractor={item => item.id} 
        />  
        </ScrollView>
            <View style={{marginTop: 100}}>
            <Button  
      buttonText={props.close}
      onPress={(e) => props.onClosePress()}
    />
    </View> 
    </View>
          }
        />
        </View> 
    );
}
const styles = StyleSheet.create({
    container:{
        height:height*0.75,
        width:width*0.8
    },
    centeredView: {
      flex: 1,
      height:height,
      width:width,
      backgroundColor:'#07090a8c',
      justifyContent: "center",
      alignItems: "center",
      marginTop: 22
    },
    modalView: {
      margin: 20,
      width:300,
      height:300,
      backgroundColor: "#dec",
      borderRadius: 20,
      padding: 35,
      alignItems: "center",
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 2
      },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      elevation: 5
    },
    button: {
      borderRadius: 20,
      padding: 10,
      elevation: 2
    },
    buttonOpen: {
      backgroundColor: "#F194FF",
    },
    buttonClose: {
      backgroundColor: "#2196F3",
    },
    textStyle: {
      color: "white",
      fontWeight: "bold",
      textAlign: "center"
    },
    modalText: {
      marginBottom: 15,
      fontSize:15,
      textAlign: "center"
    }
  });






import React, { useEffect, useState } from 'react';
import { ImageBackground,Linking, SafeAreaView, View,Dimensions, ScrollView, Text, Image } from 'react-native';
import StateDetailStyle from "./productdetailstyle";
import {igbo,english,hausa,yoruba} from '../../../Lang';
import BackGroundImage from "../../../assets/farm.jpeg"
import { GoBack, ProductImage, TabDetail } from '../../../Components';
import { disableBackButton, pickLang } from '../../../Auth';
import { FlatList, TouchableOpacity } from 'react-native-gesture-handler';
import stateLGA from '../../../State/state.json';
import Icon from 'react-native-vector-icons/FontAwesome';

import { baseUrl, imageUrl } from '../../../env';
import axios from "axios";
import { _retrieveData } from '../../../Service/function';
import MenuImage from '../../../Components/MenuImage';
import WebView from 'react-native-webview';
import ImageBack from "../../../assets/agrodealer.jpeg"
import SearchStore from '../../components/SearchStore/SearchStore';

const {width,height}=Dimensions.get('screen');
export default function ProductDetailScreen({navigation,route}) {
    const [LangFile,setLangFile] = useState(english);
    const [isLoading,setLoading]=useState(false)
    const [indexSel , setIndexSel]=useState(1);
    const [about,setAbout]=useState([]);
    const [scientist,setScientist]=useState([]);
    const [getLang,setLang]=useState(0);
    const [getProduct, setProduct] = useState([]);
    const [getState, setState] = useState(null);
    const [getLGA, setLGA] = useState(null);
    useEffect(() => {
        pickLang(setLangFile,english,hausa,igbo,yoruba);
        // generateGreetings(LangFile,setGreetings);
        disableBackButton();
      },[]);
const selectedTab=(ine)=>{
    setIndexSel(ine);
}

useEffect(() => {
    // RetLang();
    LoadAboutDetails()
}, [])


const RetLang=async()=>{
    const lang=await _retrieveData('lang');
    setLang(lang?lang:0);
}

const LoadAboutDetails=async()=>{
    const token = await _retrieveData("token");
    try {
        const resp=await axios.get(baseUrl+"products/"+route.params.datars.id,{
            headers: {
               Authorization: `Bearer ${token}`
            }
        });
          setProduct(resp.data.businesses);
          setLoading(false);
    } catch (error) {
        setLoading(false);
        // setisDialogueVisible(true);
        // setmessageDialogue(error.message);
    }
}

const setSearched=async(em)=>{
    // setBusiness();
    const token = await _retrieveData("token");
    try {
        const resp=await axios.get(baseUrl+`products/search/data?state=${em?.state}&lga=${em?.lga}&name=${route.params.datars.name}`,{
            headers: {
               Authorization: `Bearer ${token}`
            }
        });
        if(resp.statusCode){
            setLoading(false);
          setisDialogueVisible(true);
          setmessageDialogue(LangFile.error_message?LangFile.error_message.general_error:"");
          return false;
          }
          setProduct(resp.data);
          setLoading(false);
    //    await  _storeData(resp.data,"businessplace");
    } catch (error) {
        setLoading(false);
        setisDialogueVisible(true);
        setmessageDialogue(error.message);
    }
}
const renderItem = ({ item,index }) => (
    <MenuImage onPress={()=>navigation.navigate('StoreDetail', { datars: item,lang:getLang })} key={index} imageUrl={item.businessimage?imageUrl+item.businessimage.url:''} colorIcon={'#006400'} buttonText={item.name} buttonsubText={item.live_state}/> 
    );
    const renderItemScientist = ({ item,index }) => (
        <ProductImage bizshow={1} onPress={()=>navigation.navigate('StoreDetail', { datars: item,lang:LangFile })} data={item} /> 
          // <MenuImage onPress={()=>navigation.navigate('Detail', { datars: item,lang:getLang })} key={index} imageUrl={item.image?baseUrl+item.image.url:''} colorIcon={'#006400'} buttonText={item.name} /> 
          );
    return (
        <View style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
        <ImageBackground source={BackGroundImage} style={StateDetailStyle.parent}>
            <View style={StateDetailStyle.child}>
        <GoBack onPress={() => navigation.goBack()} buttonText={route.params.datars?.name } />
        <SafeAreaView style={{height:height/0.83,marginTop: 0,backgroundColor: 'rgba(255,255,255,0.7)' }}>
     <ScrollView style={{ flex: 1, marginBottom: height/2.6 }}>
     <View style={{display:'flex',flexDirection:'row',alignItems:'center',marginTop:10,backgroundColor:'white',paddingTop:15,paddingBottom:15}}>
     <Image style={{height: height*0.16,width:width*0.4,padding: 0,resizeMode:'contain',borderRadius:50,marginRight:10,marginLeft:10}} source={route.params.datars.image?{
          uri:imageUrl+route.params.datars.image.url}:ImageBack}/>
          <View style={{display:'flex',flexDirection:'column',maxWidth:width/1.8}}>
        <Text style={{color:'black',fontSize:14}}>{route.params.datars.description}</Text>
        <Text style={{color:'black',fontSize:13,fontWeight:'bold'}}>Company: {route.params.datars.company}</Text>
        {route.params.datars.url&&<TouchableOpacity onPress={()=>navigation.navigate("Browser",{datars:{name:route.params.datars.company,url:route.params.datars.url}})}><Text style={{color:'blue',fontSize:13,fontWeight:'bold',marginTop:5}}>
        <Icon  name={'globe'} ></Icon> Visit website</Text></TouchableOpacity>}
        {route.params.datars.phone&&<TouchableOpacity onPress={()=>Linking.openURL(`tel:${route.params.datars.phone}`)}>
        <Text style={{color:'blue',fontSize:13,fontWeight:'bold',marginTop:5}}> <Icon   onPress={() => console.log("")}  name={'phone'} ></Icon> Call</Text>
        </TouchableOpacity>}
          </View>
     </View>
     <View style={{display:'flex',flexDirection:'column',alignItems:'center',marginTop:10,backgroundColor:'white',paddingTop:15,paddingBottom:15}}> 
    <SearchStore stateChange={(e)=>{setSearched({state:e});setState(e);setLGA({})}} lgaChange={(e)=>{setSearched({lgs:e});setLGA(e)}} productChange={(e)=>{setSearched({product:e,state:getState,lga:getLGA});setState(e)}} LangFile={LangFile}  />
    <Text style={{color:"black",margin:10}}>Agro Dealers Selling Product</Text>
    <FlatList
        numColumns={3}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        data={getProduct}
        renderItem={renderItem}
        onEndReachedThreshold={0.01}
        // onEndReached={info => {
        //     retrieveProducts(info);
        // }}
        keyExtractor={item => item.id} 
        />  
     </View>
  </ScrollView>
  </SafeAreaView>
            </View>
        </ImageBackground>
        </View>
    )
}

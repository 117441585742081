import { BackHandler } from "react-native";
import { _retrieveData } from "../Service/function";

const checkProfile=async(navigation,setUser)=>{
    var showDat=0;var incT=0;
  const resp=  await _retrieveData("userauth");
      if(resp&&!resp.app_uuid){
        navigation.navigate('Login');
        return false;
      }
      setUser(resp);
      if(resp&&resp.id&&!resp.authorize){
        navigation.navigate('Authorization');
        return false;
      }
      if(resp&&resp.id&&!resp.location){
        navigation.navigate('Location');
        return false;
      }
      if(resp&&resp.id&&!resp.permission){
        navigation.navigate('Permission');
        return false;
      }
      if(resp&&resp.id&&resp.permission){
        if(showDat==0){
          navigation.navigate('Home');
          showDat=1;
          return false;
        }
      }
    }
    const userProfile=async(setUser)=>{
      const resp=  await _retrieveData("userauth");
      setUser(resp);
      return resp;
    }

    const userBusiness=async(setBusiness)=>{
      const resp=  await _retrieveData("business");
      setBusiness(resp);
      return resp;
    }


    const pickLang=async(setLangFile,english,hausa,igbo,yoruba)=>{
       let resp=await _retrieveData("lang");
            if(resp === null){
                return false;
              }
              if(resp==0){
                setLangFile(english);
              }
              else if(resp==1){
                setLangFile(hausa);
              }
              else if(resp==2){
                setLangFile(igbo);
              }
              else{
                setLangFile(yoruba);
              }
    }

    const backAction = () => {
      // Alert.alert("Hold on!", "Are you sure you want to go back?", [
      //   {
      //     text: "Cancel",
      //     onPress: () => null,
      //     style: "cancel"
      //   },
      //   { text: "YES", onPress: () => BackHandler.exitApp() }
      // ]);
      return true;
    };

    const disableBackButton=()=>{
      const backHandler = BackHandler.addEventListener(
        "hardwareBackPress",
        backAction
      );
      return () => backHandler.remove();
    }

    const generateGreetings=(LangFile,setGreetings)=>{
      var greeting;
      var time = new Date().getHours();
      if (time < 10) {
        greeting = 0;
      } else if (time < 20) {
        greeting = 1;
      } else {
        greeting = 2;
      }
      return greeting;
      // if (currentHour >= 3 && currentHour < 12){
      //     return LangFile.greetings?LangFile.greetings.morning:'';
      // } else if (currentHour >= 12 && currentHour < 15){
      //     return LangFile.greetings?LangFile.greetings.afternoon:'';
      // }   else if (currentHour >= 15 && currentHour < 20){
      //     return LangFile.greetings?LangFile.greetings.evening:'';
      // } else if (currentHour >= 20 && currentHour < 3){
      //     return LangFile.greetings?LangFile.greetings.night:'';
      // } else {
      //     return LangFile.greetings?LangFile.greetings.hello:''
      // }
    
    } 

    export {
        checkProfile,
        pickLang,
        disableBackButton,
        generateGreetings,
        userProfile,
        userBusiness
    }
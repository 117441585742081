import React, { useEffect, useState } from 'react'
import { ImageBackground, ScrollView,DatePickerAndroid, View, Text, Alert, Platform } from 'react-native';
import AddExpenseStyle from "./locationinfostyle";
import BackGroundImage from "../../../assets/farm.jpeg";
import { Button, GoBack, RadioSelect, SelectInputed, TextInputed } from '../../../Components';
import { checkProfile, pickLang, userProfile } from '../../../Auth';
import {igbo,english,hausa,yoruba} from '../../../Lang';
import moment from "moment";
import { _deleteData, _retrieveData, _storeData } from '../../../Service/function';
import axios from 'axios';
import { baseUrl, naira } from '../../../env';
import Toast from 'react-native-toast-message';
import stateLGA from '../../../State/state.json';
if(Platform.OS!=="web"){
  const KeyboardSpacer=require('react-native-keyboard-spacer');
}

export default function LocationInfo({navigation,route}) {
    const [LangFile,setLangFile] = useState(english);
    const [inputs,setInputs]=useState({});
    const [isLoading,setLoading]=useState(false)
    const [total,setTotal]=useState(0);
    const [getUser,setUser]=useState({});
    const [date,setDate]=useState(moment());
    const [getState, setState] = useState(null);
    const [getStater, setStater] = useState(null);
    const [getLGA, setLGA] = useState(null);
    const [getLGAR, setLGAR] = useState(null);
    const [getJsonLGA, setJsonLGA] = useState([]);
    const [getJsonLGAR, setJsonLGAR] = useState([]);

    useEffect(() => {
        pickLang(setLangFile,english,hausa,igbo,yoruba);
        checkUser();
      },[]);

     
      const checkUser=async()=>{
        const resp=  await _retrieveData("userauth");
        setUser(resp);
      }

      const handleChange=(e)=>{
        const value= e.value;
        setInputs({...inputs, [e.name]:value});
    }
    useEffect(()=>{
      transInp();   
  },[])

const transInp=async()=>{
let bizdata=await userProfile(setUser);
  setInputs(bizdata['business']);
}

const changeState=(e)=>{
  setState(JSON.parse(e).name);
  setJsonLGA(JSON.parse(e).lgas);
  // console.log(e);
}

const changeStater=(e)=>{
  setStater(JSON.parse(e).name);
  setJsonLGAR(JSON.parse(e).lgas);
  // console.log(e);
}

const submitRecord=async()=>{
  const token = await _retrieveData("token");
  inputs['user']=inputs['user'].id
  inputs['businessimage']=inputs['businessimage'].id;
  inputs['products']=null;
  inputs['role']=null;
  setLoading(true);
             try {
                  const resp= await axios.put(baseUrl+`businesses/${getUser.business.id}`,inputs,{
                      headers:{
                          'Authorization':`Bearer ${token}`,
                          'Content-Type': 'application/json'
                      }
                  });
                  let bizdata=await _retrieveData("userauth");
                  bizdata['business']=resp.data;
                  await  _storeData(bizdata,"userauth");
                 await userProfile(setUser); 
                 Toast.show({
                  type: 'success',
                  text1: LangFile.success_message?.success,
                  text2: '👋'
                });
                 setLoading(false);
             } catch (error) {
                 setLoading(false);
                 alert(error.message);
             }      
}

    
      const deleteData=()=>{
         
      }
const ind=route.params?.index;
    return (
      <View style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
        <ImageBackground source={BackGroundImage} style={AddExpenseStyle.parent}>
            <View style={AddExpenseStyle.child}>
            <GoBack onPress={() => navigation.goBack()}  buttonText={LangFile.profile?.change_location} add={ind!=undefined?true:false} addText={''} iconLeft={"delete"} size={30} iconLeftColor={'red'}/>
            <ScrollView style={AddExpenseStyle.scrollView}>
    <View style={{display:'flex',flexDirection:'column',marginTop:20}}>
    <SelectInputed
          showIcon1={true}
          title={LangFile.location?LangFile.location.state:''}
          showIcon2={true}
          iconName1={'globe'}
          render={<RadioSelect resData={stateLGA} value={inputs?.live_state} onSelectValue={(e) => {handleChange({value:JSON.parse(e).name,name:"live_state"});changeState(e)}}/>}
          selectLabel={LangFile.location?LangFile.location.state:''}
          label={inputs!=null?inputs?.live_state:LangFile.location?LangFile.location.state:''}
          iconName2={'angle-down'}
          Options={1}
        />
        <SelectInputed
          showIcon1={true}
          title={LangFile.location?LangFile.location.lga:''}
          showIcon2={true}
          iconName1={'globe'}
          render={<RadioSelect resData={getJsonLGA} value={inputs?.live_lga} onSelectValue={(e) => handleChange({value:JSON.parse(e).name,name:"live_lga"})}/>}
          selectLabel={LangFile.location?LangFile.location.lga:''}
          label={inputs?inputs?.live_lga:LangFile.location?LangFile.location.lga:''}
          iconName2={'angle-down'}
          Options={1}
        />
         <SelectInputed
          showIcon1={true}
          title={LangFile.permission?.business_state}
          showIcon2={true}
          iconName1={'globe'}
          render={<RadioSelect resData={stateLGA} value={inputs?.business_state} onSelectValue={(e) => {handleChange({value:JSON.parse(e).name,name:"business_state"});changeState(e)}}/>}
          selectLabel={LangFile.permission?.business_state}
          label={inputs?inputs?.business_state:LangFile.permission?.business_state}
          iconName2={'angle-down'}
          Options={1}
        />
        <SelectInputed
          showIcon1={true}
          title={LangFile.permission?.business_lga}
          showIcon2={true}
          iconName1={'globe'}
          render={<RadioSelect resData={getJsonLGA} value={inputs?.business_lga} onSelectValue={(e) => handleChange({value:JSON.parse(e).name,name:"business_lga"})}/>}
          selectLabel={LangFile.permission?.business_lga}
          label={inputs?inputs?.business_lga:LangFile.permission?.business_lga}
          iconName2={'angle-down'}
          Options={1}
        />
         <Button
        buttonText={LangFile.sell_now?.submit}
        onPress={submitRecord}
        isLoading={isLoading}
        message={LangFile.login?LangFile.login.moment:''}
      />
       {(Platform.OS!=="web")&&<KeyboardSpacer topSpacing={40} />}
      </View>
      </ScrollView>
            </View>
        </ImageBackground>
        </View>
    )
}

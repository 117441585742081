import React, { useState } from "react";
import { StyleSheet } from "react-native";
import { Document, Page } from "react-pdf";
import { SizeMe } from 'react-sizeme';

export default function AllPages(props) {
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const { pdf } = props;
  const styles = StyleSheet.create({
    section: {
      width: 200,
      '@media max-width: 400': {
        width: 300,
      },
      '@media orientation: landscape': {
        width: 400,
      },
    }
  });
  return (
    <SizeMe
  monitorHeight
  refreshRate={128}
  refreshMode={"debounce"}
  render={({ size }) => (
    <div>
    <Document
      file={pdf}
      options={{ workerSrc: "/pdf.worker.js" }}
      onLoadSuccess={onDocumentLoadSuccess}
    >
      {Array.from(new Array(numPages), (el, index) => (
        <Page key={`page_${index + 1}`} pageNumber={index + 1} width={size.width} />
      ))}
    </Document>
     </div>
  )}
  />
  );
}

import React from 'react'
import { Image, Text, View,Dimensions, ImageBackground } from 'react-native';
import ChatWidgetStyle from "./chatwidgetstyle";
import Icon from 'react-native-vector-icons/FontAwesome';
import { TouchableOpacity } from 'react-native-gesture-handler';
const {width,height}=Dimensions.get('screen');
const isSmallDevice = Dimensions.get('window').width < 768

export default function ChatWidget({modalShow,type,image,imageUrl,message,support,supported,time,lat,long,statud}) {
    return (
        <>
        {type==0&&
        <View style={ChatWidgetStyle.container}>
           <Image style={ChatWidgetStyle.leftImg} source={require('../../../assets/logo.png')}/>
          <View style={ChatWidgetStyle.column}>
              <Text style={ChatWidgetStyle.senderTxt}>NissBot</Text>
              <>
              <View style={ChatWidgetStyle.messageContainer}>
              <Text style={ChatWidgetStyle.headerTxt}>
           {message}
              </Text>
              </View>
              </>
          </View>
          {/* <Icon style={ChatWidgetStyle.status} name={'circle-o'} ></Icon> */}
        </View>
}
{type==2&&
        <View style={ChatWidgetStyle.container}>
           <Image style={ChatWidgetStyle.leftImg} source={require('../../../assets/logo.png')}/>
           <View style={ChatWidgetStyle.column}>
           <Text style={ChatWidgetStyle.senderTxt}>{supported}</Text>
              <View style={ChatWidgetStyle.messageContainer}>
              {image==2&&
              <ImageBackground style={ChatWidgetStyle.backContainer} source={require('../../../assets/map.jpeg')}>
              <TouchableOpacity style={ChatWidgetStyle.child} onPress={()=>modalShow({type:0,long:long,lat:lat})}>
                  <View style={{display:'flex',flexDirection:'row',}}>
              <Text style={ChatWidgetStyle.locateTxt}>Long: {lat?lat.toFixed(2):''}</Text>
              <Text style={ChatWidgetStyle.locateTxt}>Lat: {long?long.toFixed(2):''}</Text>
                </View>
              </TouchableOpacity>
              </ImageBackground>
              }

                  {image==1&&
                  <TouchableOpacity onPress={()=>modalShow({type:1,url:imageUrl})}>
                     <Image
                     style={{
                         width:isSmallDevice?width*0.40:width/4.4,
                         height:isSmallDevice?height*0.2:height/4.6,
                         borderColor:'#006400',
                         borderWidth:2,
                         borderTopLeftRadius: 20,
                         borderBottomLeftRadius: 20,
                         borderTopRightRadius: 20,
                         borderBottomRightRadius: 20,
                     }}
                     source={{uri:imageUrl}}
                      />
                      </TouchableOpacity>
                    }
                    {image==0&&
              <Text style={ChatWidgetStyle.headerTxt}>
            {message?message:""}
              </Text>}
              <View style={ChatWidgetStyle.subContainer}>
              <Text style={ChatWidgetStyle.subTxt}>{time}</Text>
               <Icon style={!statud?ChatWidgetStyle.status:ChatWidgetStyle.delivered} name={'check'} ></Icon>
               </View>
              </View>
          </View>
          {/* <Icon style={ChatWidgetStyle.status} name={'circle-o'} ></Icon> */}
        </View>
}
{type==1&&
        <View style={ChatWidgetStyle.leftcontainer}>
          <View style={ChatWidgetStyle.column}>
              <View style={ChatWidgetStyle.leftmessageContainer}>
              {image==2&&
              <ImageBackground style={ChatWidgetStyle.backContainer} source={require('../../../assets/map.jpeg')}>
              <TouchableOpacity style={ChatWidgetStyle.child} onPress={()=>modalShow({type:0,long:long,lat:lat})}>
                  <View style={{display:'flex',flexDirection:'row',}}>
              <Text style={ChatWidgetStyle.locateTxt}>Long: {lat?lat.toFixed(2):''}</Text>
              <Text style={ChatWidgetStyle.locateTxt}>Lat: {long?long.toFixed(2):''}</Text>
                </View>
              </TouchableOpacity>
              </ImageBackground>
              }

                  {image==1&&
                  <TouchableOpacity onPress={()=>modalShow({type:1,url:imageUrl})}>
                     <Image
                     style={{
                      width:isSmallDevice?width*0.40:width/4.4,
                         height:isSmallDevice?height*0.2:height/4.6,
                         borderColor:'#006400',
                         borderWidth:2,
                         borderTopLeftRadius: 20,
                         borderBottomLeftRadius: 20,
                         borderTopRightRadius: 20,
                         borderBottomRightRadius: 20,
                     }}
                     source={{uri:imageUrl}}
                      />
                      </TouchableOpacity>
                    }
                    {image==0&&
              <Text style={ChatWidgetStyle.headerTxt}>
            {message?message:""}
              </Text>}
              <View style={ChatWidgetStyle.subContainer}>
              <Text style={ChatWidgetStyle.subTxt}>{time}</Text>
               <Icon style={!statud?ChatWidgetStyle.status:ChatWidgetStyle.delivered} name={'check'} ></Icon>
               </View>
              </View>
          </View>
          {/* <Icon style={ChatWidgetStyle.status} name={'circle-o'} ></Icon> */}
        </View>
}
        </>
    )
}

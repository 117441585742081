import { StyleSheet,Dimensions,Platform } from 'react-native';
const {width,height}=Dimensions.get('screen');
const isSmallDevice = Dimensions.get('window').width < 768

export default StyleSheet.create({
    home:{
        display:'flex',
        flexDirection:'column',
        paddingTop:0,
        ...Platform.select({
            ios: {
                height:height,
                width:width
              },
              android: {
               height:height,
               width:width
              },
              default: {
                // other platforms, web for example
                height:height/1.2,
                width:isSmallDevice?width:width/2.5
              }
            }),
    },
    child: {
        flex: 1,
        backgroundColor: 'rgba(255,255,255,0.7)'
      },
     
  
});
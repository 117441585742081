import { StyleSheet,Dimensions,Platform } from 'react-native';
const {width,height}=Dimensions.get('screen');
const isSmallDevice = Dimensions.get('window').width < 768

export default StyleSheet.create({
    parent:{
      ...Platform.select({
        ios: {
            height:height,
            width:width
          },
          android: {
           height:height,
           width:width
          },
          default: {
            // other platforms, web for example
            height:height/1.2,
            width:isSmallDevice?width:width/2.5
          }
        }),
    },
    child: {
        flex: 1,
        backgroundColor: 'rgba(255,255,255,0.7)'
      },
      containertop:{
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        justifyContent:'center',
        marginBottom:10,
        backgroundColor:'#cee2f3',
        padding:10,
        borderTopRightRadius:10,
        borderBottomRightRadius:10,
        borderBottomLeftRadius:10
    },
    senderTxt:{
        color:"gray",
        fontSize:14,
        fontWeight:'bold'
  
    },

      container:{
    marginBottom:height*0.1
     },
     bottomView:{
        flexDirection:'row',
        justifyContent:'center',
        margin:20
     },
     flatContainer:{
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 10,
        marginBottom: 40
      },
      // contentView:{
      //   flex:1, 
      // }
  
});
import React, { useContext, useEffect, useState } from 'react'
import StoreDetailStyle from "./storedetailstyle";
import { FlatList, ImageBackground, Linking, ScrollView, StatusBar, Text, View } from 'react-native';
import BackGroundImage from "../../../assets/farm.jpeg"
import {Logo,Label, SpaceBottom, Button, Lang, RadioButton, TextInputed, Dialogue, LabelSmall, LangText, Avatar, MenuButton, GoBack, ProductImage, SearchLabel, LabelTouch, Banner, TabDetail} from '../../../Components';
import {igbo,english,hausa,yoruba} from '../../../Lang';
import PickLang from '../../../Modals/Language/PickLang';
import { _retrieveData, _retrieveToken, _storeData } from '../../../Service/function';
import { checkProfile, disableBackButton, generateGreetings, pickLang } from '../../../Auth';
import { AuthContext } from '../../../context/authContext/AuthContext';
import { baseUrl, imageUrl } from '../../../env';
import MenuImage from '../../../Components/MenuImage';
import axios from "axios";
import  Icon  from 'react-native-vector-icons/FontAwesome';


export default function StoreDetailScreen({navigation,route}) {
    const [getProduct, setProduct] = useState([]);
    const [getProductData, setProductData] = useState({});
    const [getLangModal,setLangModal] = useState(false);
    const [modalSetting,setModalSetting] = useState(false);
    const [getLang,setLang] = useState(0);
    const [LangFile,setLangFile] = useState(english);
    const [getSearch, setSearch] = useState(false);
    const [getProductSearch, setProductSearch] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [getGreetings,setGreetings]=useState(null);
    const [getBannerTab, setBannerTab] = useState(1);
    const [messageDialogue, setmessageDialogue] = useState("Hello");
    const [isDialogueVisible, setisDialogueVisible] = useState(false);
    const [getState, setState] = useState(null);
    const [getLGA, setLGA] = useState(null);
    //Context
    const {user,isFetching,dispatch}=useContext(AuthContext);

    const resData=[
        {
          key:0,
          text:'English'
      },
      {
          key:1,
          text:'Hausa'
      },
      {
          key:2,
          text:'Igbo'
      },
      {
          key:3,
          text:'Yoruba'
      }
    ];
    useEffect(() => {
        pickLang(setLangFile,english,hausa,igbo,yoruba);
        generateGreetings(LangFile,setGreetings);
        disableBackButton()
      },[]);

      useEffect(() => {
        LoadBusiness()
        // checkProfile(navigation,setUser); 
      },[]);

      const onCloseDialoguePress=()=>{
        setisDialogueVisible(false);
       }

       const changeTab=(it)=>{
        setBannerTab(it);
      }
      const onClosePress=()=>{
        // pickLang(setLangFile,english,hausa,igbo,yoruba);
        setLangModal(false);
       }

      const showLangModal=()=>{
        setLangModal(true);
       }

    const onSelectValue=async(val)=>{
        setLangFile(val==0?english:val==1?hausa:val==2?igbo:yoruba)
        setLang(val);
       await _storeData(val,'lang')
        setLangModal(false); 
    }

    const setBusiness=async()=>{
        let crop=await _retrieveData("businessplace");
        setCrop(crop);
    }

    const LoadBusiness=async()=>{
        setBusiness();
        const token = await _retrieveData("token");
        try {
            const resp=await axios.get(baseUrl+"businesses?business_type=agrodealer&business_state_contains=&business_lga_contains=",{
                headers: {
                   Authorization: `Bearer ${token}`
                }
            });
            if(resp.statusCode){
                setLoading(false);
              setisDialogueVisible(true);
              setmessageDialogue(LangFile.error_message?LangFile.error_message.general_error:"");
              return false;
              }
              setProduct(resp.data);
              setLoading(false);
           await  _storeData(resp.data,"businessplace");
        } catch (error) {
            setLoading(false);
            setisDialogueVisible(true);
            setmessageDialogue(error.message);
        }
    }

      const navPage=(item)=>{
        navigation.navigate('ProductEdit', { datars: item,lang:getLang })
      }
      
    //   const changeSearch=(name)=>{
    //     asyncGet('/businesses?name_contains='+name+'&_limit=50')
    //     .then((resp)=>{
    //       setProductSearch(resp);
    //       setLoading(false);
    //     })
    //     .catch((err)=>{
    //       setLoading(false);
    //     })
    //   }
  
const callContact=async(num)=>{
    await Linking.openURL('tel:'+num);
  }
  const emailContact=async(email)=>{
    await Linking.openURL('mailto:'+email);
  }
  const chatContact=async(phone)=>{
    await Linking.openURL('https://api.whatsapp.com/send/?phone='+phone);
  }
  const facebookContact=async (url)=>{
    await Linking.openURL(url);
  }

    // const renderItem = ({ item,index }) => (
    //     <ProductImage bizshow={1} onPress={()=>navigation.navigate('BuyDetail', { datars: item,lang:getLang })} data={item} /> 
    //       // <MenuImage onPress={()=>navigation.navigate('Detail', { datars: item,lang:getLang })} key={index} imageUrl={item.image?baseUrl+item.image.url:''} colorIcon={'#006400'} buttonText={item.name} /> 
    //       );

      return (
        <View style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
        <ImageBackground source={BackGroundImage} style={StoreDetailStyle.parent}>
        <View style={StoreDetailStyle.child}>
        <GoBack onPress={() => navigation.goBack()} buttonText={route.params.datars.name?route.params.datars.name:route.params.datars.user.name+" "+LangFile.search.store} />
       {/* <Text>{JSON.stringify(route.params.datars)}</Text> */}
     <Banner iconName1={'user'} iconName2={'map'} srcUrl={route.params.datars.businessimage?route.params.datars.businessimage.url:null} title={route.params.datars.name?route.params.datars.name:route.params.datars.user.name} subtitle={route.params.datars.business_address} />
     
      {/* <TabDetail onPress={changeTab}  text1={LangFile.buy_now?LangFile.buy_now.product_detail:""} text2={LangFile.buy_now?LangFile.buy_now.other_product:""} text3={LangFile.buy_now?LangFile.buy_now.dealer_product:""} indexSelect={getBannerTab} /> */}
      <ScrollView style={StoreDetailStyle.container} persistentScrollbar={true} contentContainerStyle={{paddingRight:10}}>

 <View style={StoreDetailStyle.columnContainer}>
   {route.params.datars.id&&
   <ScrollView>
    <LabelSmall message={route.params.lang.storedetail?route.params.lang.storedetail.openingtime:''} color={'black'} fonts={16} />
    <View style={{flexDirection:'row',padding:10,marginBottom:10}}>
    <Icon name={'clock-o'} size={20} style={{color:'green'}}></Icon>
    <View style={{flexDirection:'column'}}>
    <LabelSmall message={route.params.datars.openingperiod?route.params.datars.openingperiod:'Monday - Saturday'} color={'black'} fonts={13} />
    <LabelSmall message={route.params.datars.openingtime?route.params.datars.openingtime:'8am-6pm'} color={'black'} fonts={13} />
    </View>
    </View>
    <LabelSmall message={route.params.lang.storedetail?route.params.lang.storedetail.services:'Services'} color={'black'} fonts={16} />
    <View style={{flexDirection:'row',paddingLeft:10,paddingTop:0,marginTop:5,width:300,marginBottom:10}}>
    {/* <Icon name={'phone'} size={20} style={{color:'green'}}></Icon> */}
    <View style={{flexDirection:'column'}}>
    <LabelTouch  message={route.params.datars.service} color={'black'} fonts={13} />
    </View>
    </View>
    <LabelSmall message={route.params.lang.storedetail?route.params.lang.storedetail.contacts:'Contacts'} color={'black'} fonts={16} />
    <View style={{flexDirection:'row',paddingLeft:10,paddingTop:10,marginTop:10}}>
    <Icon name={'phone'} size={20} style={{color:'green'}}></Icon>
    <View style={{flexDirection:'column'}}>
    <LabelTouch onPress={()=>callContact(route.params.datars.business_contact)} message={route.params.datars.business_contact} color={'black'} fonts={13} />
    </View>
    </View>
    <View style={{flexDirection:'row',paddingLeft:10,paddingTop:7,marginTop:10}}>
    <Icon name={'inbox'} size={20} style={{color:'green'}}></Icon>
    <View style={{flexDirection:'column'}}>
    <LabelTouch onPress={()=>emailContact(route.params.datars.business_email)} message={route.params.datars.business_email} color={'black'} fonts={13} />
    </View>
    </View>
    <View style={{flexDirection:'row',paddingLeft:10,paddingTop:7,marginTop:10}}>
    <Icon name={'whatsapp'} size={20} style={{color:'green'}}></Icon>
    <View style={{flexDirection:'column'}}>
    <LabelTouch onPress={()=>chatContact(route.params.datars.business_contact)} message={route.params.datars.business_contact} color={'black'} fonts={13} />
    </View>
    </View>
    {route.params.datars.facebook!=null&&
    <View style={{flexDirection:'row',paddingLeft:10,paddingTop:7,marginTop:10}}>
    <Icon name={'facebook'} size={20} style={{color:'green'}}></Icon>
    <View style={{flexDirection:'column'}}>
    <LabelTouch onPress={()=>facebookContact(route.params.datars.facebook)} message={route.params.datars.facebook} color={'black'} fonts={13} />
    </View>
    </View>
}
    </ScrollView>} 
    </View>

      </ScrollView>
      <Dialogue
        modalVisible={isDialogueVisible}
        close={LangFile.login?LangFile.login.close:''}
        onClosePress={(e) => onCloseDialoguePress()}
        message={messageDialogue}
      />
      <PickLang modalView={getLangModal} 
       close={LangFile.login?LangFile.login.close:""}
      render={ <RadioButton resData={resData} value={getLang} onSelectValue={onSelectValue}/>}
      onClosePress={onClosePress}/>

            </View>
            </ImageBackground>
  </View>
      )

}
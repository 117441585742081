import { StyleSheet,Dimensions,Platform } from 'react-native';
const {width,height}=Dimensions.get('screen');
const isSmallDevice = Dimensions.get('window').width < 768
export default StyleSheet.create({
    parent:{
      ...Platform.select({
        ios: {
            height:height,
            width:width
          },
          android: {
           height:height,
           width:width
          },
          default: {
            // other platforms, web for example
            height:height/1.2,
            width:isSmallDevice?width:width/2.5
          }
        }),
    },
    child: {
      display:'flex',
      flexDirection:'column',
        flex: 1,
        backgroundColor: 'rgba(255,255,255,0.7)'
      },
      buttonFilter:{
        padding: 15,
        marginBottom: 5,
        borderWidth:0
      },
      altText:{
        fontSize: 14,
      },
      scrollView:{
          marginBottom:height*0.11
      }
});
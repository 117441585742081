import React from 'react';
import Icon from 'react-native-vector-icons/FontAwesome';
import { Text, View,Image,StyleSheet,ActivityIndicator, TouchableOpacity } from 'react-native';
// import styles from '../Styles/login';

export default function ButtonTwo(props) {
    return (
        <View>
             {!props.isLoading&&
             <View style={{flexDirection:'row'}}>
       <TouchableOpacity
        style={styles.button1}
        onPress={() => props.onPress()}
      >
        <Text style={styles.btnText}>{props.buttonText}</Text>
      </TouchableOpacity>
       <TouchableOpacity
       style={styles.button2}
       onPress={() => props.onPressed()}
     >
       <Text style={styles.btnText2}>{props.buttonText1}</Text>
     </TouchableOpacity>
     </View>
}
      {props.isLoading&&
      <View style={{flexDirection:'row',alignItems:'center',justifyContent:'center'}}>
      <ActivityIndicator size="large" color={styles.btnText} animating={props.isLoading}/>
      <Text style={styles.btnText}>{props.message}</Text>
      </View>
        }
      </View>
    );
}
const styles=StyleSheet.create({
    button1: {
        borderColor: "#0d503a",
        alignItems: "center",
        padding: 5,
        marginBottom: 20,
        width:120,
        backgroundColor:'#fff',
        borderTopLeftRadius: 15,
        borderTopRightRadius: 15,
        borderBottomRightRadius: 15,
        marginLeft: 20,
        marginRight: 20,
        borderWidth: 2
      },
      button2: {
        borderColor: "red",
        alignItems: "center",
        padding: 5,
        marginBottom: 20,
        width:120,
        backgroundColor:'#fff',
        borderTopLeftRadius: 15,
        borderTopRightRadius: 15,
        borderBottomRightRadius: 15,
        marginLeft: 20,
        marginRight: 20,
        borderWidth: 2
      },
      btnText: {
        color: "#0d503a",
        fontSize: 20,
        fontWeight:'bold',
      }, 
      btnText2: {
        color: "red",
        fontSize: 20,
        fontWeight:'bold',
      }, 
      iconStyle1: {
        color: "#000",
        fontSize: 20,
        paddingLeft: 12
      },
})






import { StyleSheet,Dimensions,Platform, StatusBar } from 'react-native';
const {width,height}=Dimensions.get('screen');
const isSmallDevice = Dimensions.get('window').width < 768
export default StyleSheet.create({
    parent:{
      ...Platform.select({
        ios: {
          height:height,
          width:isSmallDevice?width:width/2.5
        },
        android: {
         height:height,
         width:isSmallDevice?width:width/2.5
        },
        default: {
          // other platforms, web for example
          height:height/1.2,
          width:isSmallDevice?width:width/2.5
        }
        }),
    },
    child: {
      display:'flex',
      flexDirection:'column',
        flex: 1,
        backgroundColor: 'rgba(255,255,255,0.7)'
      },
      containerArea:{
        flex: 1,
        paddingTop: StatusBar.currentHeight,
      },
      documentArea:{
        width:isSmallDevice?width:width/2.5,
        padding:10
      },
    container: {
       
        marginHorizontal: 20,
    },
    pdf: {
       top:0,
        width:Dimensions.get('window').width,
        height:Dimensions.get('window').height,
    },
    buttonFilter:{
        padding: 15,
        marginBottom: 5,
        borderWidth:0
      },
      altText:{
        fontSize: 14,
      }
})
import React from 'react';
import Icon from 'react-native-vector-icons/FontAwesome';
import { Text, View,Image,StyleSheet,ActivityIndicator, TouchableOpacity } from 'react-native';


export default function LangText(props) {
    return (
        <TouchableOpacity style={{padding:30,alignItems:'center',flexDirection:'row'}}  onPress={(e) => props.onPress()}>
          <View style={{flexDirection:'row'}}>
          <View style={{flexDirection:'column'}}>
           <Image
        style={{width:30,height:30}}
        source={require('../assets/logo.png')}
         />
      <Text style={styles.headerTextN}>{props.title}</Text>
        </View>
         <Text style={{color: "#0d503a",
        fontSize: props.fonts,
        backgroundColor:'#dec',
        width:70,
        height:20,
        padding:0,
        borderRadius: 400/ 2,
        borderColor: "#dec",
        textAlign: 'center',
        fontWeight:'bold'}}
       
        >{props.message}</Text>
         </View>
      </TouchableOpacity>
    );
}
const styles=StyleSheet.create({
    header: {
      justifyContent:'center',
      alignItems:'center',
       flexDirection:'row',
       margin: 20,
       paddingTop:20,
       backgroundColor:'#fff'
     },
    tinyLogo: {
      width: 50,
      height: 50,
      marginBottom: 10,
      borderRadius: 400/ 2,
      borderColor: "#dec",
      backgroundColor: '#dec',
  
    },
    headerText: {
      fontSize: 50,
      marginBottom:10,
      fontWeight:'bold',
      marginLeft:10,
      color: "#0d503a"
    },
    headerTextW:{
      fontSize: 50,
      marginBottom:10,
      fontWeight:'bold',
      marginLeft:0,
      color: "#fff"
    },
    headerTextN:{
      fontSize: 12,
      marginBottom:10,
      fontWeight:'bold',
      marginLeft:0,
      color: "#0d503a"
    }
  })






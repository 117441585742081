import { StyleSheet,Dimensions } from 'react-native';
const {width,height}=Dimensions.get('screen');
export default StyleSheet.create({
    parent:{
        width: width,
        height: height,
    },
    child: {
      display:'flex',
      flexDirection:'column',
        flex: 1,
        backgroundColor: 'rgba(255,255,255,0.8)'
      },
    container: {
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginTop: 0,
    },
    tableContainer:{
        display:'flex',
        flexDirection:'column',
        height:height*0.7,
        margin:10,
        backgroundColor:'rgba(255,255,255,0.7)',
        padding:10,
        borderTopRightRadius:10,
        borderBottomRightRadius:10,
        borderBottomLeftRadius:10,
        marginBottom:40,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10,
        borderBottomLeftRadius: 10
    },
    scrollView:{
        marginBottom:height*0.11
    },
    menu:{
        flexDirection:'row',
        backgroundColor: 'rgba(255,255,255,0.8)',
        shadowColor:'#dec',
        borderRadius: 10, 
        width:width*0.97,
        padding:20, 
        margin:2
    },
    icon:{
        marginRight:20,
        color:"#0d503a"
    },
    text:{
        fontSize:18,
        color:"black"
    },
    itemContainer:{
        display:'flex',
        flexDirection:'row',
        alignItems:'center', 
        backgroundColor:'rgba(0,0,0,0.1)',
        padding:10, 
        marginBottom:10,
        borderRadius: 10,
    },
    itemContainerActive:{
        display:'flex',
        flexDirection:'row',
        alignItems:'center', 
        backgroundColor:'#0d503a',
        padding:10, 
        marginBottom:10,
        borderRadius: 10,
    },
    itemIcon:{
        color: '#0d503a',
        fontSize: 30,
        paddingRight: 0
    },
    itemIconActive:{
        color: '#fff',
        fontSize: 30,
        paddingRight: 0
    },
    itemMini:{
        display:'flex',
        flexDirection:'column',
        padding:10
    },
    itemTitle:{
        color:"black",
        fontWeight:'bold',
       
    },
    itemTitleActive:{
        color:"white",
        fontWeight:'bold'
    },
    itemDesc:{
        color:"black",
        marginRight:15
    },
    itemDescActive:{color:"white", marginRight:15},
    dateContainer:{
        display:'flex',
        flexDirection:'row',
        padding:10,
        alignItems:'center'
    },
    dateIcon:{
        color:"#0d503a",
        fontSize: 13,
        paddingRight: 5
    },
    dateIconActive:{
        color:"#fff",
        fontSize: 13,
        paddingRight: 5
    },
    dateText:{
        color:"#0d503a",
        fontWeight:'bold',
        paddingRight:10
    },
    dateTextActive:{
        color:"#fff",
        fontWeight:'bold',
        paddingRight:10
    }
    
})
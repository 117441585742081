import React, { Component,useState } from 'react';
import { View, TouchableOpacity, Text, StyleSheet } from 'react-native';
var resultSelect=[];
export default function MultiSelect(props) {
	const [getSelected, setSelected] = useState([]);
	// const parseSelect=(val)=>{
	// resultSelect.push(val);
	// }
	const changeServices=(e)=>{
		const {onSelectValue,value}=props;
		// console.log(value.split(","))
		if(resultSelect.includes(e)){
		 var sel=resultSelect.indexOf(e);
		 resultSelect.splice(sel,1);
		}
		else{
		resultSelect.push(e);
		}
		onSelectValue(resultSelect);
	  }
		return (
			<View>
				{props.resData.map((res,i) => {
					return (
						<TouchableOpacity key={i} style={styles.container}  onPress={() => changeServices(res.key)}>
							<Text style={styles.radioText}
                            onPress={() => changeServices(res.key)}
                            >{res.text}</Text>
							<TouchableOpacity
								style={styles.radioCircle}
								onPress={() => changeServices(res.key)}>
                                  { resultSelect.includes( res.key) && <View style={styles.selectedRb} />}
							</TouchableOpacity>
						</TouchableOpacity>
					);
				})}
				
			</View>
		);
	}

const styles = StyleSheet.create({
	container: {
        marginBottom: 15,
        alignItems: 'center',
        flexDirection: 'row',
		justifyContent: 'space-between',
	},
    radioText: {
		marginRight: 35,
		width:150,
        fontSize: 15,
        color: '#000',
        fontWeight: '700'
    },
	radioCircle: {
		height: 30,
		width: 30,
		borderRadius: 100,
		borderWidth: 2,
		borderColor: '#006400',
		alignItems: 'center',
		justifyContent: 'center',
	},
	selectedRb: {
		width: 15,
		height: 15,
		borderRadius: 50,
		backgroundColor: '#006400',
    },
    result: {
        marginTop: 20,
        color: 'white',
        fontWeight: '600',
        backgroundColor: '#F3FBFE',
    },
});
import React, { useEffect, useState } from 'react';
import { ImageBackground, SafeAreaView, View,Dimensions, ScrollView, Text } from 'react-native';
import StateListStyle from "./stateliststyle";
import {igbo,english,hausa,yoruba} from '../../../Lang';
import BackGroundImage from "../../../assets/farm.jpeg"
import { GoBack } from '../../../Components';
import { disableBackButton, pickLang } from '../../../Auth';
import { FlatList, TouchableOpacity } from 'react-native-gesture-handler';
import stateLGA from '../../../State/state.json';
import Icon from 'react-native-vector-icons/FontAwesome';

const {width,height}=Dimensions.get('screen');
export default function StateListScreen({navigation,route}) {
    const [LangFile,setLangFile] = useState(english);
    useEffect(() => {
        pickLang(setLangFile,english,hausa,igbo,yoruba);
        // generateGreetings(LangFile,setGreetings);
        disableBackButton()
      },[]);

      const renderItem = ({ item,index }) => (
     <TouchableOpacity onPress={()=>navigation.navigate("DetailInspection",{datar:item})} key={index} style={StateListStyle.flatContainer}>
         <View style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
          <Icon style={StateListStyle.senderTxt} name={'map-o'} ></Icon>
     <Text style={StateListStyle.senderTxt}>{item.name}</Text>
     </View>
     <Icon style={StateListStyle.senderTxt} name={'arrow-circle-right'} ></Icon>
     </TouchableOpacity>
           );
    return (
        <View style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
        <ImageBackground source={BackGroundImage} style={StateListStyle.parent}>
            <View style={StateListStyle.child}>
        <GoBack onPress={() => navigation.navigate('Home')} buttonText={`${LangFile.menu?" "+LangFile.menu.menu6:"Soil Inspection"}` } />
        <SafeAreaView style={{height:height*0.83,marginTop: 0,backgroundColor: 'rgba(255,255,255,0.7)' }}>
      <ScrollView style={{ flex: 1, margin: 10 }}>
      <View style={StateListStyle.containertop}>
      <Text style={StateListStyle.senderTxt}>{LangFile.inspection.inspectallstate}</Text>
      </View>
      <FlatList
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        data={stateLGA}
        renderItem={renderItem}
        keyExtractor={item => item.id} 
        />  
  </ScrollView>
  </SafeAreaView>
            </View>
        </ImageBackground>
        </View>
    )
}

import React, { useContext, useEffect, useState } from 'react'
import LoginScreenStyle from "./loginscreenstyle";
import { ImageBackground, Linking, ScrollView, StatusBar, View } from 'react-native';
import BackGroundImage from "../../../assets/farm.jpeg"
import {Logo,Label, SpaceBottom, Button, Lang, RadioButton, TextInputed} from '../../../Components';
import {igbo,english,hausa,yoruba} from '../../../Lang';
import PickLang from '../../../Modals/Language/PickLang';
import { _deleteData, _retrieveData, _storeData } from '../../../Service/function';
import { checkProfile, disableBackButton, pickLang } from '../../../Auth';
import { AuthContext } from '../../../context/authContext/AuthContext';
import { login } from '../../../context/authContext/apiCalls';


export default function LoginScreen({navigation,route}) {
    const [LangFile,setLangFile] = useState(english);
    const [getPhoneNumber, setPhoneNumber] = useState(_retrieveData("phone_number"));
    const [getPassword, setPassword] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [isSecure, setSecure] = useState(true);
    const [getUser, setUser] = useState({});
    const [secureIcon, setSecureIcon] = useState("eye");

    const [getLang,setLang] = useState(0);
    const [getLangModal,setLangModal] = useState(false);

    //Context
    const {isFetching,dispatch}=useContext(AuthContext);

    const resData=[
        {
          key:0,
          text:'English'
      },
      {
          key:1,
          text:'Hausa'
      },
      {
          key:2,
          text:'Igbo'
      },
      {
          key:3,
          text:'Yoruba'
      }
    ];
    useEffect(() => {
        pickLang(setLangFile,english,hausa,igbo,yoruba);
      },[]);

      useEffect(() => {
        checkProfile(navigation,setUser); 
        disableBackButton()
      },[]);

    const OpenHref= async ()=>{
        await Linking.openURL('https://niss.gov.ng');
      }

      const onClosePress=()=>{
        // pickLang(setLangFile,english,hausa,igbo,yoruba);
        setLangModal(false);
       }

      const showLangModal=()=>{
        setLangModal(true);
       }

    const onSelectValue=async(val)=>{
        setLangFile(val==0?english:val==1?hausa:val==2?igbo:yoruba)
        setLang(val);
        await _storeData(val,'lang')
        setLangModal(false); 
    }

    const changeSecure = () => {
        setSecure(isSecure ? false : true)
        setSecureIcon(isSecure ? 'eye-slash' : 'eye');
      }

    const LoginUser=async()=>{
      await _deleteData("token");
      await _deleteData("userauth");
      await _deleteData("business");
        await login({identifier:getPhoneNumber,password:getPassword},dispatch)
       .then(async(res)=>{
        if(res.jwt){
       
            await _storeData(res.jwt,'token')
            await _storeData(res.user,'userauth');
            await _storeData(res.business,"business");
           
            setPassword("");
          await checkProfile(navigation,setUser); 
             navigation.navigate('Home');
         }
       })
      }

    return (
      <View style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
        <ImageBackground source={BackGroundImage} style={LoginScreenStyle.login}>
           <StatusBar barStyle="dark-content" />
        <View style={LoginScreenStyle.child}>
        {getLang==0&&
   <Lang message="ENGLISH" onPress={()=>showLangModal()}/>
      }
     {getLang==1&&
   <Lang message="HAUSA" onPress={()=>showLangModal()}/>
      }
      {getLang==2&&
   <Lang message="IGBO" onPress={()=>showLangModal()}/>
      }
      {getLang==3&&
   <Lang message="YORUBA" onPress={()=>showLangModal()}/>
      }
        {/* <SpaceBottom height={20} /> */}
        <Logo />
        <Label color="#000" message={LangFile.login?LangFile.login.title:''} fonts={16} />
        <ScrollView style={LoginScreenStyle.container}>
        <TextInputed
          showIcon1={true}
          showIcon2={false}
          iconName1={'phone'}
          // iconName2={'eye'}
          placeholder={LangFile.login?LangFile.login.phone:''}
          onChangeText={(e) => setPhoneNumber(e)}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          Secure={false}
          keyboardType={'numeric'}
        />
        <TextInputed
          showIcon1={true}
          showIcon2={true}
          iconName1={'lock'}
          iconName2={secureIcon}
          placeholder={LangFile.login?LangFile.login.password:''}
          onPress={() => changeSecure()}
          onChangeText={(e) => setPassword(e)}
          placeholderColor={'#282a355c'}
          defaultValue={getPassword}
          textColor={'#000'}
          Secure={isSecure}
        // keyboardType={'numeric'}
        />
         <Button
        buttonText={LangFile.login?LangFile.welcome.login:''}
        onPress={LoginUser}
        isLoading={isFetching}
        message={LangFile.login?LangFile.login.moment:''}
      />
        <View style={LoginScreenStyle.bottomView} >
          <Label click={true} color="#000" message={LangFile.login?LangFile.login.create_account:''} onPress={() => navigation.navigate('Register')} fonts={16} />
          <Label click={true} color="#000" message={LangFile.login?LangFile.login.forgot_password:''} onPress={() => navigation.navigate('ForgetPassword')} fonts={16} />
        </View>
        <Label fonts={12} click={true} color="#000" onPress={OpenHref} message={LangFile.welcome?LangFile.welcome.project+"Nigeria Institute of Soil Science (NISS)":''}/>
       
      </ScrollView>
        </View>
        <PickLang modalView={getLangModal} 
       close={LangFile.login?LangFile.login.close:''}
      render={ <RadioButton resData={resData} value={getLang} onSelectValue={onSelectValue}/>}
      onClosePress={onClosePress}/>
        </ImageBackground>
        </View>
    )
}

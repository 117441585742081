import React from 'react';
import Icon from 'react-native-vector-icons/FontAwesome';
import Iconed from 'react-native-vector-icons/MaterialCommunityIcons';
import { Text, View,Image,StyleSheet,ActivityIndicator, TouchableOpacity } from 'react-native';


export default function MenuButton(props) {
    return (
        <View>
       <TouchableOpacity
        style={[styles.button1,props.styles]}
        onPress={() => props.onPress()}
      >
          {props.type&&
           <Iconed name={props.iconName} style={{color: props.colorIcon,fontSize: 35,padding: 5}}></Iconed>
          }
           {!props.type&&
           <Icon name={props.iconName} style={[{color: props.colorIcon,fontSize: 35,padding: 5},props.iconStyle]}></Icon>
           }
        <Text style={[styles.btnText,props.btnText]}>{props.buttonText}</Text>
      </TouchableOpacity>
      </View>
    );
}
const styles=StyleSheet.create({
    button1: {
        borderColor: "rgba(0,0,0,0.5)",
        alignItems: "center",
        padding: 15,
        width:100,
        height:100,
        marginBottom: 20,
        backgroundColor:'#fff',
        borderTopLeftRadius: 15,
        borderTopRightRadius: 15,
        borderBottomRightRadius: 15,
        marginLeft: 13,
        marginRight: 5,
       
      },
      btnText: {
        color: "#000",
        textAlign: 'center',
        fontSize: 12,
        width:100,
        fontWeight:'bold',
      }, 
      iconStyle1: {
        color: "teal",
        fontSize: 15,
        paddingLeft: 12
      },
})






import React, { useEffect, useState } from 'react'
import { ImageBackground, ScrollView,DatePickerAndroid, View, Text, Alert,Platform } from 'react-native';
import AddExpenseStyle from "./addexpensestyle";
import BackGroundImage from "../../../../assets/farm.jpeg";
import { Button, GoBack, RadioSelect, SelectInputed, TextInputed } from '../../../../Components';
import { checkProfile, pickLang } from '../../../../Auth';
import {igbo,english,hausa,yoruba} from '../../../../Lang';
import moment from "moment";
import { _deleteData, _retrieveData, _storeData } from '../../../../Service/function';
import axios from 'axios';
import { baseUrl, naira } from '../../../../env';
import Toast from 'react-native-toast-message';

if(Platform.OS!=="web"){
  const KeyboardSpacer=require("react-native-keyboard-spacer")
}


export default function AddSales({navigation,route}) {
    const [LangFile,setLangFile] = useState(english);
    const [inputs,setInputs]=useState({});
    const [isLoading,setLoading]=useState(false)
    const [total,setTotal]=useState(0);
    const [getUser,setUser]=useState({});
    const [date,setDate]=useState(moment());

    useEffect(() => {
        pickLang(setLangFile,english,hausa,igbo,yoruba);
        checkUser();
      },[]);

      useEffect(()=>{
        setTotal(inputs.quantity&&inputs.value?parseFloat(inputs.value)*parseFloat(inputs.quantity):0)
      },[inputs])

      const checkUser=async()=>{
        const resp=  await _retrieveData("userauth");
        setUser(resp);
      }

      const handleChange=(e)=>{
        const value= e.value;
        if(e.name==="value"){inputs['total']=inputs.quantity&&value?parseFloat(value)*parseFloat(inputs.quantity):0;}
        if(e.name==="quantity"){inputs['total']=inputs.value&&value?parseFloat(value)*parseFloat(inputs.value):0;}
        setInputs({...inputs, [e.name]:value});
    }

    useEffect(()=>{
        const idx=route.params?.datar;
        if(idx){
            setInputs(route.params.datar);
            setDate(moment(route.params.datar.date));
        }
    },[])
    const pickDate=async()=>{
        try {
            const {
              action,
              year,
              month,
              day
            } = await DatePickerAndroid.open({
              // Use `new Date()` for current date.
              // May 25 2020. Month 0 is January.
              date:date.toDate()
            });
            if (action !== DatePickerAndroid.dismissedAction) {
                setDate(moment(`${year}-${month.length>1?month + 1:"0"+(month+1)}-${day}`));
            }
          } catch ({ code, message }) {
            // console.warn('Cannot open date picker', message);
          }
    }
    const sendtoServer=async(inp,idk)=>{
        const token = await _retrieveData("token");
            let datat={
                    title:inp.expense,
                    date:inp.date,
                    content:inp,
                    type:'expenditure',
                    ref:inp.id,
                    users:getUser.id
                   }
                   try {
                    const resp=await axios.post(baseUrl+'managers',datat,{
                        headers: {
                           Authorization: `Bearer ${token}`
                        }
                    });
                    var salesRec=await _retrieveData("ExpenseRecord");
                    inp['status']=true;
                    salesRec.splice(idk,1,inp);
                    await _storeData(salesRec,'ExpenseRecord');
                   } catch (error) {
                       
                   }      
    }
      const submitRecord=async()=>{
          var salesRec=await _retrieveData("ExpenseRecord");
          if(!inputs.expense||!inputs.quantity||!inputs.value){
            alert(LangFile.error.fillall);
            return false;
         }
          try {
            if(salesRec){
                const lastKey=salesRec.reverse();
                inputs['id']=moment().format('x')+Math.floor(Math.random() * 101);
                inputs['date']=date.format("YYYY-MM-DD");
                salesRec.push(inputs);
               await _storeData(salesRec,"ExpenseRecord");
               Toast.show({
                type: 'success',
                text1: LangFile.success_message?.success,
                text2: '👋'
              });
              if(salesRec.length>5){
                  for (let i = 0; i < salesRec.length; i++) {
                    if(salesRec[i]&&salesRec[i].status){
                   await sendtoServer(salesRec[i],i);
                    }
                  }
              }
               
            }
            else{
               var salesDot=[];
              inputs['id']=moment().format('x')+Math.floor(Math.random() * 101);
              inputs['date']=date.format("YYYY-MM-DD");
              salesDot.push(inputs);
                await _storeData(salesDot,"ExpenseRecord");
                Toast.show({
                    type: 'success',
                    text1: LangFile.success_message?.success,
                    text2: '👋'
                  });
          
            }
          navigation.goBack();
          } catch (error) {
            Toast.show({
                type: 'error',
                text1: error.message,
                text2: '👋'
              });
          }
        
      }

      const updateRecord=async()=>{
        const record=await _retrieveData("ExpenseRecord");
        record.splice(route.params.index,1,inputs);
        await sendtoServer(inputs,route.params.index);
        await _storeData(record,'ExpenseRecord');
        navigation.goBack();
      }

      const deleteData=()=>{
           Alert.alert("NissAgro",LangFile.error.deletesure, [
        {
          text: LangFile.error.cancel,
          onPress: () => null,
          style: "cancel"
        },
        { text: LangFile.error.yes, onPress: async() => {
            const record=await _retrieveData("ExpenseRecord");
                record.splice(route.params.index, 1);
            await _storeData(record,'ExpenseRecord');
            navigation.goBack();
        } }
      ]);
      }
const ind=route.params?.index;
    return (
      <View style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
        <ImageBackground source={BackGroundImage} style={AddExpenseStyle.parent}>
            <View style={AddExpenseStyle.child}>
            <GoBack onPress={() => navigation.goBack()} onAddPress={deleteData} buttonText={ind!=undefined?LangFile.records?.editexpenserecord:LangFile.records?.addexpenserecord} add={ind!=undefined?true:false} addText={''} iconLeft={"delete"} size={30} iconLeftColor={'red'}/>
            <ScrollView style={AddExpenseStyle.scrollView}>
    <View style={{display:'flex',flexDirection:'column',marginTop:20}}>
    <Button
    altStyle={AddExpenseStyle.buttonFilter}
    altText={AddExpenseStyle.altText}
    buttonText={ind!=undefined?LangFile.records?.edit+" "+LangFile.salerecord?.date+" "+date.format("DD-MM-YYYY"):LangFile.records?.add+" "+LangFile.salerecord?.date+" "+date.format("DD-MM-YYYY")}
    onPress={pickDate}
      />
        
        <TextInputed
          showIcon1={true}
          showIcon2={false}
          iconName1={'plus-square'}
          // iconName2={'eye'}
          label={ind!=undefined?LangFile.records?.edit+" "+LangFile.salerecord?.expense:LangFile.records?.add+" "+LangFile.salerecord?.expense}
          placeholder={LangFile.records?.add+" "+LangFile.salerecord?.expense}
          onChangeText={(e) => handleChange({value:e,name:'expense'})}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          defaultValue={inputs?.expense}
          Secure={false}
        //   keyboardType={'numeric'}
        />
        <TextInputed
          showIcon1={true}
          showIcon2={false}
          iconName1={'plus-square'}
          label={ind!=undefined?LangFile.records?.edit+" "+LangFile.salerecord?.quantity:LangFile.records?.add+" "+LangFile.salerecord?.quantity}
          placeholder={LangFile.records?.add+" "+LangFile.salerecord?.quantity}
          onChangeText={(e) => handleChange({value:e,name:'quantity'})}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          defaultValue={inputs?.quantity}
        keyboardType={'numeric'}
        />
      {/* <SelectInputed
          showIcon1={true}
          showIcon2={true}
          title={LangFile.records?.selectquantityunit}
          iconName1={'pencil-square-o'}
          render={<RadioSelect resData={[{id:"kg",name:"KG"},{id:"grams",name:'Grams'},{id:"Litre",name:'Litre'},{id:"Feet",name:'Feet'},{id:"cm",name:'CM'},{id:"meter",name:'M'}]} value={inputs?.units} onSelectValue={(e) => handleChange({value:JSON.parse(e).name,name:'units'})}/>}
          selectLabel={LangFile.records?.selectquantityunit}
          label={inputs?inputs.units:LangFile.records?.selectquantityunit}
          iconName2={'angle-down'}
          Options={0}
        /> */}
        <TextInputed
          showIcon1={true}
          showIcon2={false}
          iconName1={'plus-square'}
          label={ind!=undefined?LangFile.records?.edit+" "+LangFile.salerecord?.value:LangFile.records?.add+" "+LangFile.salerecord?.value+` (${naira})`}
          placeholder={LangFile.records?.add+" "+LangFile.salerecord?.value}
          onChangeText={(e) => {handleChange({value:e,name:'value'});}}
          placeholderColor={'#282a355c'}
          defaultValue={inputs?.value}
          textColor={'#000'}
        keyboardType={'numeric'}
        />
        <TextInputed
          showIcon1={true}
          showIcon2={false}
          iconName1={'plus-square'}
          label={ind!=undefined?LangFile.records?.edit+" "+LangFile.salerecord?.total:LangFile.records?.add+" "+LangFile.salerecord?.total+` (${naira})`}
          placeholder={LangFile.records?.add+" "+LangFile.salerecord?.total}
          onChangeText={(e) => handleChange({value:e,name:'total'})}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          defaultValue={JSON.stringify(inputs?.total)}
          editable={false}
        keyboardType={'numeric'}
        />
         <Button
        buttonText={LangFile.sell_now?.submit}
        onPress={ind!=undefined?updateRecord:submitRecord}
        isLoading={isLoading}
        message={LangFile.login?LangFile.login.moment:''}
      />
      {Platform.OS!=="web"&&<KeyboardSpacer topSpacing={40} />}
      </View>
      </ScrollView>
            </View>
        </ImageBackground>
        </View>
    )
}

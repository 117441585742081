import React, { useContext, useEffect, useState } from 'react'
import DetailScreenStyle from "./detailscreenstyle";
import { FlatList, ImageBackground,Dimensions, Linking, SafeAreaView, ScrollView, StatusBar, Text, View,Platform } from 'react-native';
import BackGroundImage from "../../../assets/farm.jpeg"
import {Logo,Label, SpaceBottom, Button, Lang, RadioButton, TextInputed, Dialogue, LabelSmall, LangText, Avatar, MenuButton, GoBack, TabDetail} from '../../../Components';
import {igbo,english,hausa,yoruba} from '../../../Lang';
import PickLang from '../../../Modals/Language/PickLang';
import { _retrieveData, _retrieveToken, _storeData } from '../../../Service/function';
import { checkProfile, disableBackButton, generateGreetings, pickLang } from '../../../Auth';
import { AuthContext } from '../../../context/authContext/AuthContext';
import { baseUrl, imageUrl } from '../../../env';

import axios from "axios";
import WebView from 'react-native-webview';

const {width,height}=Dimensions.get('screen');
const isSmallDevice = Dimensions.get('window').width < 768
export default function DetailScreen({navigation,route}) {
    const [isSecure, setSecure] = useState(true);
    const [getLangModal,setLangModal] = useState(false);
    const [modalSetting,setModalSetting] = useState(false);
    const [getLang,setLang] = useState(0);
    const [LangFile,setLangFile] = useState(english);
    const [getUser,setUser]=useState({});
    const [getCrop, setCrop] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [getGreetings,setGreetings]=useState(null);
    const [messageDialogue, setmessageDialogue] = useState("Hello");
    const [isDialogueVisible, setisDialogueVisible] = useState(false);
    const [tabIndex,setTabIndex]=useState(1)
    //Context
    const {user,isFetching,dispatch}=useContext(AuthContext);

    const richText = React.useRef();
    const resData=[
        {
          key:0,
          text:'English'
      },
      {
          key:1,
          text:'Hausa'
      },
      {
          key:2,
          text:'Igbo'
      },
      {
          key:3,
          text:'Yoruba'
      }
    ];
    useEffect(() => {
        retLang();
        generateGreetings(LangFile,setGreetings);
        disableBackButton()
      },[]);

      const retLang=async()=>{
            let lang=await _retrieveData("lang");
            route.params.lang=lang?lang:0;
      }

      useEffect(() => {
        LoadCrops()
        // checkProfile(navigation,setUser); 
      },[]);

      const onCloseDialoguePress=()=>{
        setisDialogueVisible(false);
       }

    const OpenHref= async ()=>{
        await Linking.openURL('https://niss.gov.ng');
      }

      const onClosePress=()=>{
        // pickLang(setLangFile,english,hausa,igbo,yoruba);
        setLangModal(false);
       }

      const showLangModal=()=>{
        setLangModal(true);
       }

    const onSelectValue=async(val)=>{
        setLangFile(val==0?english:val==1?hausa:val==2?igbo:yoruba)
        setLang(val);
       await _storeData(val,'lang')
        setLangModal(false); 
    }

    const setCropper=async()=>{
        let crop=await _retrieveData("Crop");
        setCrop(crop);
    }

    const LoadCrops=async()=>{
        setCropper();
        const token = await _retrieveData("token");
        try {
            const resp=await axios.get(baseUrl+"crop-libraries?type=crop",{
                headers: {
                   Authorization: `Bearer ${token}`
                }
            });
            if(resp.statusCode){
                setLoading(false);
              setisDialogueVisible(true);
              setmessageDialogue(LangFile.error_message?LangFile.error_message.general_error:"");
              return false;
              }
              setCrop(resp.data);
              setLoading(false);
           await  _storeData(resp.data,"Crop");
        } catch (error) {
            setLoading(false);
            setisDialogueVisible(true);
            setmessageDialogue(error.message);
        }
    }

    const selectedTab=(idd)=>{
      setTabIndex(idd);
      }

      return (
        <View style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
        <ImageBackground source={BackGroundImage} style={DetailScreenStyle.parent}>
        <View style={DetailScreenStyle.child}>
        <GoBack onPress={() => navigation.goBack()} buttonText={route.params.datars.name+`${LangFile.tabinfo?" "+LangFile.tabinfo.information:" Information"}` } />
        <TabDetail  onPress={selectedTab} text1={LangFile.tabinfo?LangFile.tabinfo.about:"About"} text2={LangFile.tabinfo?LangFile.tabinfo.attack:'Attack'} text3={LangFile.tabinfo?LangFile.tabinfo.deficiency:'Deficiency'} text4={LangFile.tabinfo?LangFile.tabinfo.advice:'Advice'} indexSelect={tabIndex} />
      <SafeAreaView style={{ flex: 1,marginTop: 10,backgroundColor: 'rgba(255,255,255,0.7)' }}>
        <ScrollView style={{ flex: 1,margin: 10,  }}>
        {/* <Text>{JSON.stringify(route.params.datars?.advice)}</Text> */}
        {/* <HTMLView
        value={route.params.datars.about?route.params.datars.about:''}
        stylesheet={DetailScreenStyle.contentView}
      /> */}
     {tabIndex==1&&
      <>
          {route.params.lang==0&&
          <SafeAreaView style={{ flex: 1 ,width:width/1.07,height:height/1.35}}>
           <WebView
           scalesPageToFit={false}
           originWhitelist={['*']}
           source={{ html: `${route.params.datars.about?route.params.datars.about:''}` }}
         style={{padding:20}}
         />
         {Platform.OS==="web"&&
     <View style={{width:isSmallDevice?width:width/2.5,padding:20}} contentContainerStyle={{padding:20}}>
     <div style={{overflow:'auto'}} dangerouslySetInnerHTML={{ __html: route.params.datars.about?route.params.datars.about:'' }} />
     </View>
     }
         </SafeAreaView>
          }
          {route.params.lang==1&&
   <SafeAreaView style={{ flex: 1 ,width:width/1.07,height:height/1.35}}>
   <WebView
   scalesPageToFit={false}
   originWhitelist={['*']}
   source={{ html: `${route.params.datars.aboutHausa?route.params.datars.aboutHausa:''}` }}
 />
  {Platform.OS==="web"&&
     <View style={{width:isSmallDevice?width:width/2.5,padding:20}} contentContainerStyle={{padding:20}}>
     <div style={{overflow:'auto'}} dangerouslySetInnerHTML={{ __html: route.params.datars.aboutHausa?route.params.datars.aboutHausa:'' }} />
     </View>
     }
 </SafeAreaView>
          }
          {route.params.lang==2&&
   <SafeAreaView style={{ flex: 1 ,width:width/1.07,height:height/1.35}}>
   <WebView
   scalesPageToFit={false}
   originWhitelist={['*']}
   source={{ html: `${route.params.datars.aboutIgbo?route.params.datars.aboutIgbo:''}` }}
 />
 {Platform.OS==="web"&&
     <View style={{width:isSmallDevice?width:width/2.5,padding:20}} contentContainerStyle={{padding:20}}>
     <div style={{overflow:'auto'}} dangerouslySetInnerHTML={{ __html: route.params.datars.aboutIgbo?route.params.datars.aboutIgbo:'' }} />
     </View>
     }
 </SafeAreaView>
          }
       {route.params.lang==3&&
   <SafeAreaView style={{ flex: 1 ,width:width/1.07,height:height/1.35}}>
   <WebView
   scalesPageToFit={false}
   originWhitelist={['*']}
   source={{ html: `${route.params.datars.aboutYoruba?route.params.datars.aboutYoruba:''}` }}
 />
 {Platform.OS==="web"&&
     <View style={{width:isSmallDevice?width:width/2.5,padding:20}} contentContainerStyle={{padding:20}}>
     <div style={{overflow:'auto'}} dangerouslySetInnerHTML={{ __html: route.params.datars.aboutYoruba?route.params.datars.aboutYoruba:'' }} />
     </View>
     }
 </SafeAreaView>
          }
          </>}
          {tabIndex==2&&
          <>
           {route.params.lang==0&&
   <SafeAreaView style={{ flex: 1 ,width:width/1.07,height:height/1.35}}>
   <WebView
   scalesPageToFit={false}
   originWhitelist={['*']}
   source={{ html: `${route.params.datars.attack?route.params.datars.attack:''}` }}
 />
 {Platform.OS==="web"&&
     <View style={{width:isSmallDevice?width:width/2.5,padding:20}} contentContainerStyle={{padding:20}}>
     <div style={{overflow:'auto'}} dangerouslySetInnerHTML={{ __html: route.params.datars.attack?route.params.datars.attack:'' }} />
     </View>
     }
 </SafeAreaView>
          }
          {route.params.lang==1&&
   <SafeAreaView style={{ flex: 1 ,width:width/1.07,height:height/1.35}}>
   <WebView
   scalesPageToFit={false}
   originWhitelist={['*']}
   source={{ html: `${route.params.datars.attackHausa?route.params.datars.attackHausa:''}` }}
 />
 {Platform.OS==="web"&&
     <View style={{width:isSmallDevice?width:width/2.5,padding:20}} contentContainerStyle={{padding:20}}>
     <div style={{overflow:'auto'}} dangerouslySetInnerHTML={{ __html: route.params.datars.attackHausa?route.params.datars.attackHausa:'' }} />
     </View>
     }
 </SafeAreaView>
          }
          {route.params.lang==2&&
   <SafeAreaView style={{ flex: 1 ,width:width/1.07,height:height/1.35}}>
   <WebView
   scalesPageToFit={false}
   originWhitelist={['*']}
   source={{ html: `${route.params.datars.attackIgbo?route.params.datars.attackIgbo:''}` }}
 />
 {Platform.OS==="web"&&
     <View style={{width:isSmallDevice?width:width/2.5,padding:20}} contentContainerStyle={{padding:20}}>
     <div style={{overflow:'auto'}} dangerouslySetInnerHTML={{ __html: route.params.datars.attackIgbo?route.params.datars.attackIgbo:'' }} />
     </View>
     }
 </SafeAreaView>
          }
       {route.params.lang==3&&
    <SafeAreaView style={{ flex: 1 ,width:width/1.07,height:height/1.35}}>
    <WebView
    scalesPageToFit={false}
    originWhitelist={['*']}
    source={{ html: `${route.params.datars.attackYoruba?route.params.datars.attackYoruba:''}` }}
  />
  {Platform.OS==="web"&&
     <View style={{width:isSmallDevice?width:width/2.5,padding:20}} contentContainerStyle={{padding:20}}>
     <div style={{overflow:'auto'}} dangerouslySetInnerHTML={{ __html: route.params.datars.attackYoruba?route.params.datars.attackYoruba:'' }} />
     </View>
     }
  </SafeAreaView>
        }
          </>}
          {tabIndex==3&&
          <>
              {route.params.lang==0&&
   <SafeAreaView style={{ flex: 1 ,width:width/1.07,height:height/1.35}}>
   <WebView
   scalesPageToFit={false}
   originWhitelist={['*']}
   source={{ html: `${route.params.datars.deficiency?route.params.datars.deficiency:''}` }}
 />
 {Platform.OS==="web"&&
     <View style={{width:isSmallDevice?width:width/2.5,padding:20}} contentContainerStyle={{padding:20}}>
     <div style={{overflow:'auto'}} dangerouslySetInnerHTML={{ __html: route.params.datars.deficiency?route.params.datars.deficiency:'' }} />
     </View>
     }
 </SafeAreaView>
          }
          {route.params.lang==1&&
   <SafeAreaView style={{ flex: 1 ,width:width/1.07,height:height/1.35}}>
   <WebView
   scalesPageToFit={false}
   originWhitelist={['*']}
   source={{ html: `${route.params.datars.deficiencyHausa?route.params.datars.deficiencyHausa:''}` }}
 />
 {Platform.OS==="web"&&
     <View style={{width:isSmallDevice?width:width/2.5,padding:20}} contentContainerStyle={{padding:20}}>
     <div style={{overflow:'auto'}} dangerouslySetInnerHTML={{ __html: route.params.datars.deficiencyHausa?route.params.datars.deficiencyHausa:'' }} />
     </View>
     }
 </SafeAreaView>
          }
          {route.params.lang==2&&
    <SafeAreaView style={{ flex: 1 ,width:width/1.07,height:height/1.35}}>
    <WebView
    scalesPageToFit={false}
    originWhitelist={['*']}
    source={{ html: `${route.params.datars.deficiencyIgbo?route.params.datars.deficiencyIgbo:''}` }}
  />
  {Platform.OS==="web"&&
     <View style={{width:isSmallDevice?width:width/2.5,padding:20}} contentContainerStyle={{padding:20}}>
     <div style={{overflow:'auto'}} dangerouslySetInnerHTML={{ __html: route.params.datars.deficiencyIgbo?route.params.datars.deficiencyIgbo:'' }} />
     </View>
     }
  </SafeAreaView>
          }
       {route.params.lang==3&&
   <SafeAreaView style={{ flex: 1 ,width:width/1.07,height:height/1.35}}>
   <WebView
   scalesPageToFit={false}
   originWhitelist={['*']}
   source={{ html: `${route.params.datars.deficiencyYoruba?route.params.datars.deficiencyYoruba:''}` }}
 />
 {Platform.OS==="web"&&
     <View style={{width:isSmallDevice?width:width/2.5,padding:20}} contentContainerStyle={{padding:20}}>
     <div style={{overflow:'auto'}} dangerouslySetInnerHTML={{ __html: route.params.datars.deficiencyYoruba?route.params.datars.deficiencyYoruba:'' }} />
     </View>
     }
 </SafeAreaView>
        }
          </>}

          {tabIndex==4&&
          <>
    {route.params.lang==0&&
   <SafeAreaView style={{ flex: 1 ,width:width/1.07,height:height/1.35}}>
    
   <WebView
   scalesPageToFit={false}
   originWhitelist={['*']}
   source={{ html: `${route.params.datars?.advice}` }}
 />
 {Platform.OS==="web"&&
     <View style={{width:isSmallDevice?width:width/2.5,padding:20}} contentContainerStyle={{padding:20}}>
     <div style={{overflow:'auto'}} dangerouslySetInnerHTML={{ __html: route.params.datars.advice?route.params.datars.advice:'' }} />
     </View>
     }
 </SafeAreaView>
          }
          {route.params.lang==1&&
    <SafeAreaView style={{ flex: 1 ,width:width/1.07,height:height/1.35}}>
    <WebView
    scalesPageToFit={false}
    originWhitelist={['*']}
    source={{ html: `${route.params.datars.adviceHausa?route.params.datars.adviceHausa:''}` }}
  />
  {Platform.OS==="web"&&
     <View style={{width:isSmallDevice?width:width/2.5,padding:20}} contentContainerStyle={{padding:20}}>
     <div style={{overflow:'auto'}} dangerouslySetInnerHTML={{ __html: route.params.datars.adviceHausa?route.params.datars.adviceHausa:'' }} />
     </View>
     }
  </SafeAreaView>
          }
          {route.params.lang==2&&
    <SafeAreaView style={{ flex: 1 ,width:width/1.07,height:height/1.35}}>
    <WebView
    scalesPageToFit={false}
    originWhitelist={['*']}
    source={{ html: `${route.params.datars.adviceIgbo?route.params.datars.adviceIgbo:''}` }}
  />{Platform.OS==="web"&&
     <View style={{width:isSmallDevice?width:width/2.5,padding:20}} contentContainerStyle={{padding:20}}>
     <div style={{overflow:'auto'}} dangerouslySetInnerHTML={{ __html: route.params.datars.adviceIgbo?route.params.datars.adviceIgbo:'' }} />
     </View>
     }

  </SafeAreaView>
          }
       {route.params.lang==3&&
    <SafeAreaView style={{ flex: 1 ,width:width/1.07,height:height/1.35}}>
    <WebView
    scalesPageToFit={false}
    originWhitelist={['*']}
    source={{ html: `${route.params.datars.adviceYoruba?route.params.datars.adviceYoruba:''}` }}
  />
  {Platform.OS==="web"&&
     <View style={{width:isSmallDevice?width:width/2.5,padding:20}} contentContainerStyle={{padding:20}}>
     <div style={{overflow:'auto'}} dangerouslySetInnerHTML={{ __html: route.params.datars.adviceYoruba?route.params.datars.adviceYoruba:'' }} />
     </View>
     }
  </SafeAreaView>
        }
          </>}
  </ScrollView>
  </SafeAreaView>
      <Dialogue
        modalVisible={isDialogueVisible}
        close={LangFile.login?LangFile.login.close:''}
        onClosePress={(e) => onCloseDialoguePress()}
        message={messageDialogue}
      />
      <PickLang modalView={getLangModal} 
       close={LangFile.login?LangFile.login.close:""}
      render={ <RadioButton resData={resData} value={getLang} onSelectValue={onSelectValue}/>}
      onClosePress={onClosePress}/>
            </View>
            </ImageBackground>
        </View>
      )

}
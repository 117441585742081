import React, { useEffect, useState } from 'react';
import { ImageBackground, SafeAreaView, View,Dimensions, ScrollView, Text } from 'react-native';
import StateListStyle from "./publicationliststyle";
import {igbo,english,hausa,yoruba} from '../../Lang';
import BackGroundImage from "../../assets/farm.jpeg"
import { Button, GoBack } from '../../Components';
import { disableBackButton, pickLang } from '../../Auth';
import { FlatList, TouchableOpacity } from 'react-native-gesture-handler';
import stateLGA from '../../State/state.json';
import Icon from 'react-native-vector-icons/FontAwesome';
import axios from 'axios';
import { baseUrl } from '../../env';
import { updateStateArray, _retrieveData } from '../../Service/function';
import moment from "moment";

const {width,height}=Dimensions.get('screen');
export default function PublicationListScreen({navigation,route}) {
    const [LangFile,setLangFile] = useState(english);
    const [notification,setNotification]=useState([]);
    const [start,setStart]=useState(0);
    const [page,setPage]=useState(30);
    const [loading,setLoading]=useState(false);
    useEffect(() => {
        pickLang(setLangFile,english,hausa,igbo,yoruba);
        // generateGreetings(LangFile,setGreetings);
        disableBackButton()
      },[]);

      useEffect(()=>{
        getNotification()
      },[])

      const getNotification=async()=>{
        const token = await _retrieveData("token");
        // const UserD = await _retrieveData("userauth");
        try {
          // console.log(baseUrl+`publications?publication_category=${route.params.datars.id}&_start=${start}&_limit=${page}`)
            setLoading(true);
            let resp=await axios.get(baseUrl+`publications?publication_category.id=${route.params.datars.id}&_start=0&_limit=${page}`,{
              headers: {
                 Authorization: `Bearer ${token}`
              }
          });
         
          await updateStateArray(notification,resp.data,setNotification);
          setLoading(false);
          if(resp.data.length>0){setStart(start+page);}
        } catch (error) {
            setLoading(false);
        }
       
      }

      const renderItem = ({ item,index }) => (
        
         <TouchableOpacity onPress={()=>{item.image?navigation.navigate('ViewPdf', { datars: item,lang:LangFile,type:'online' }):item.url?navigation.navigate('Browser', { datars: item,lang:LangFile }):navigation.navigate('PublicationDetail',{ datars: item,lang:LangFile })}} key={index} style={StateListStyle.flatContainer}>
         <View style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
         <View style={{padding:10,borderRadius:50,backgroundColor:'black',marginRight:10}}>
          
            <Text style={{color:"white"}}>{item.name?item.name[0]:""}</Text>
         </View>
         <View style={{display:'flex',flexDirection:'column'}}>
     <Text style={StateListStyle.senderTxti}>{item.name}</Text>
     <Text style={StateListStyle.senderSubTxt}>{item.subtitle}</Text>
     </View>
     </View>
     {item.url&&
     <Icon style={StateListStyle.unreadIcon} name={'dot-circle-o'} ></Icon>
    }
     </TouchableOpacity>
           );

    return (
      <View style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
        <ImageBackground source={BackGroundImage} style={StateListStyle.parent}>
            <View style={StateListStyle.child}>
        <GoBack onPress={() => navigation.navigate('Home')} buttonText={`${route.params.datars?.name}` } />
        <SafeAreaView style={{height:height*0.83,marginTop: 0,backgroundColor: 'rgba(255,255,255,0.7)' }}>
      <ScrollView style={{ flex: 1, margin: 10 }}>
      <View style={StateListStyle.containertop}>
      <Text style={StateListStyle.senderTxt}>{LangFile.naming?.latestnews}</Text>
      </View>
      <FlatList
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        data={notification}
        renderItem={renderItem}
        keyExtractor={item => item.id} 
        />  
       
         <Button isLoading={loading} altStyle={{padding:5}} onPress={()=>getNotification()} altText={{fontSize:14}} buttonText={LangFile.naming?.loadmore} /> 
  </ScrollView>
  </SafeAreaView>
            </View>
        </ImageBackground>
        </View>
    )
}

import React, { useState } from 'react';
import { TextInput, View } from 'react-native';
import { Label, RadioSelect, SelectInputed } from '../../../Components';
import SearchStoreStyle from "./searchstorestyle";
import stateLGA from '../../../State/state.json';

export default function SearchStore({LangFile,lga,lgaChange,stateChange,productChange,product,header=LangFile.search?LangFile.search.searchLabel:''}) {
    const [getState, setState] = useState(null);
    const [getLGA, setLGA] = useState(null);
    const [getProduct, setProduct] = useState(null);
    const [getJsonLGA, setJsonLGA] = useState([]);

    const changeState=(e)=>{
        setState(JSON.parse(e).name);
        setJsonLGA(JSON.parse(e).lgas);
        stateChange(JSON.parse(e).name)
        // console.log(e);
    }

    const changeLga=(e)=>{
        setLGA(e);
        lgaChange(e)
    }

    const changeProduct=(e)=>{
        setProduct(e);
        productChange(e)
    }

    return (
        <View style={SearchStoreStyle.container}>
             <Label padding={10} click={false} color="#000" fonts={13}  message={header} />
              <View style={SearchStoreStyle.rowContainer}>
          <SelectInputed
          showIcon1={true}
          showIcon2={false}
          iconName1={'globe'}
          padding={20}
          style={SearchStoreStyle.selectInput}
          inputStyle={SearchStoreStyle.inputStyle}
          render={<RadioSelect resData={stateLGA} value={getState} onSelectValue={(e) => changeState(e)}/>}
          selectLabel={LangFile.search?LangFile.search.state:''}
          label={getState!=null?getState:LangFile.search?LangFile.search.state:''}
          iconName2={'angle-down'}
          Options={1}
        />
        {!lga&&
        <SelectInputed
          showIcon1={true}
          showIcon2={false}
          style={SearchStoreStyle.selectInput}
          inputStyle={SearchStoreStyle.inputStyle}
          iconName1={'globe'}
          padding={20}
          render={<RadioSelect resData={getJsonLGA} value={getLGA} onSelectValue={(e) => changeLga(JSON.parse(e).name)}/>}
          selectLabel={LangFile.search?LangFile.search.lga:''}
          label={getLGA!=null?getLGA:LangFile.search?LangFile.search.lga:''}
          iconName2={'angle-down'}
          Options={1}
        />}
       
        </View>
        {product&&
        <SelectInputed
          showIcon1={false}
          showIcon2={false}
          padding={0}
          style={SearchStoreStyle.selectInputLong}
          inputStyle={SearchStoreStyle.inputStyle}
          iconName1={'globe'}
          render={<RadioSelect resData={[{id:"Fertilizer",name:"Fertilizer"},{id:"Agro Chemicals",name:"Agro Chemicals"},{id:"Agro Seeds",name:"Agro Seeds"},{id:"Farm Machinery",name:"Farm Machinery"}]} value={getProduct} onSelectValue={(e) => changeProduct(JSON.parse(e).name)}/>}
          selectLabel={LangFile.search?LangFile.search.product:''}
          label={getProduct!=null?getProduct:LangFile.search?LangFile.search.product:''}
          iconName2={'angle-down'}
          Options={0}
        />}
        </View>
    )
}

import React from 'react';
import Icon from 'react-native-vector-icons/FontAwesome';
import { Text, View,Image,StyleSheet,ActivityIndicator, TouchableOpacity } from 'react-native';


export default function Label(props) {
    return (
        <View style={{alignItems:'center',justifyContent:'center',padding:props.padding??20}}>
      {props.click&&
      <Text style={{color: props.color,
        fontSize: props.fonts,
        width:props.width,
        fontWeight:'bold'}}
        onPress={() => props.onPress()}
        >{props.message}</Text>}
         {!props.click&&
      <Text style={{color: props.color,
        fontSize: props.fonts,
        width:props.width,
        fontWeight:'bold'}}
        >{props.message}</Text>}
      </View>
    );
}






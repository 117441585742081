import { StyleSheet,Dimensions } from 'react-native';
const {width,height}=Dimensions.get('screen');
export default StyleSheet.create({
    parent:{
        // width: width,
        // height: height,
        backgroundColor:"#000"
    },
    child: {
        flex: 1,
        backgroundColor: 'rgba(255,255,255,0.7)'
      },

      container:{
        // height:height,
     },
     bottomView:{
        flexDirection:'row',
        justifyContent:'center',
        margin:20
     },
     holdContainer:{
      display:'flex',
      flexDirection:'column',
      alignItems:'center',
      marginRight:20
     },
     label:{
       fontWeight:'bold',
       fontSize:12,
       color:"#0d503a"
     },
     greetings:{
       color:'#0d503a',
       fontWeight:'bold',
     },
     date:{
      color:'#000',
      fontWeight:'bold',
    }
  
});
import React, { useContext, useEffect, useState } from 'react'
import CropScreenStyle from "./cropscreenstyle";
import { FlatList, ImageBackground, Linking, ScrollView, StatusBar, Text, View } from 'react-native';
import BackGroundImage from "../../../assets/farm.jpeg"
import {Logo,Label, SpaceBottom, Button, Lang, RadioButton, TextInputed, Dialogue, LabelSmall, LangText, Avatar, MenuButton, GoBack, Loader} from '../../../Components';
import {igbo,english,hausa,yoruba} from '../../../Lang';
import PickLang from '../../../Modals/Language/PickLang';
import { _retrieveData, _retrieveToken, _storeData } from '../../../Service/function';
import { checkProfile, disableBackButton, generateGreetings, pickLang } from '../../../Auth';
import { AuthContext } from '../../../context/authContext/AuthContext';
import { baseUrl, imageUrl } from '../../../env';
import MenuImage from '../../../Components/MenuImage';
import axios from "axios";
import { useIsFocused } from '@react-navigation/native';

export default function CropScreen({navigation,route}) {
    const [isSecure, setSecure] = useState(true);
    const [getLangModal,setLangModal] = useState(false);
    const [modalSetting,setModalSetting] = useState(false);
    const [getLang,setLang] = useState(0);
    const [LangFile,setLangFile] = useState(english);
    const [getUser,setUser]=useState({});
    const [getCrop, setCrop] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [getGreetings,setGreetings]=useState(null);
    const [messageDialogue, setmessageDialogue] = useState("Hello");
    const [isDialogueVisible, setisDialogueVisible] = useState(false);
    //Context
    const {user,isFetching,dispatch}=useContext(AuthContext);
    const isFocused = useIsFocused();

    const resData=[
        {
          key:0,
          text:'English'
      },
      {
          key:1,
          text:'Hausa'
      },
      {
          key:2,
          text:'Igbo'
      },
      {
          key:3,
          text:'Yoruba'
      }
    ];
    useEffect(() => {
        pickLang(setLangFile,english,hausa,igbo,yoruba);
        generateGreetings(LangFile,setGreetings);
        disableBackButton()
      },[]);

      useEffect(() => {
        LoadCrops()
        // checkProfile(navigation,setUser); 
      },[isFocused]);

      const onCloseDialoguePress=()=>{
        setisDialogueVisible(false);
       }

    const OpenHref= async ()=>{
        await Linking.openURL('https://niss.gov.ng');
      }

      const onClosePress=()=>{
        // pickLang(setLangFile,english,hausa,igbo,yoruba);
        setLangModal(false);
       }

      const showLangModal=()=>{
        setLangModal(true);
       }

    const onSelectValue=async(val)=>{
        setLangFile(val==0?english:val==1?hausa:val==2?igbo:yoruba)
        setLang(val);
       await _storeData(val,'lang')
        setLangModal(false); 
    }

    const setCropper=async()=>{
        let crop=await _retrieveData("Crop");
        setCrop(crop);
    }

    const LoadCrops=async()=>{
        setCropper();
        const token = await _retrieveData("token");
        try {
          setLoading(true);
            const resp=await axios.get(baseUrl+"crop-libraries?type=crop",{
                headers: {
                   Authorization: `Bearer ${token}`
                }
            });
            if(resp.statusCode){
                setLoading(false);
              setisDialogueVisible(true);
              setmessageDialogue(LangFile.error_message?LangFile.error_message.general_error:"");
              return false;
              }
              setCrop(resp.data);
              setLoading(false);
           await  _storeData(resp.data,"Crop");
        } catch (error) {
            setLoading(false);
            setisDialogueVisible(true);
            setmessageDialogue(error.message);
        }
    }

      const renderItem = ({ item,index }) => (
        <MenuImage onPress={()=>navigation.navigate('Detail', { datars: item,lang:getLang })} key={index} imageUrl={item.image?imageUrl+item.image.url:''} colorIcon={'#006400'} buttonText={item.name} button1={{width:100}} /> 
        );

      return (
        <View style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
        <ImageBackground source={BackGroundImage} style={CropScreenStyle.parent}>
        <View style={CropScreenStyle.child}>
        <GoBack onPress={() => navigation.goBack()} buttonText={LangFile.menu?LangFile.menu.menu2:""} />
      <ScrollView style={CropScreenStyle.container} persistentScrollbar={true} contentContainerStyle={{paddingRight:10}}>
     <View style={{flexDirection:'column'}}>
     <View style={{flexDirection:'row'}}>
         <Label message={LangFile.menu?LangFile.menu.menu2:""} fonts={16} />
         </View>
         <View style={CropScreenStyle.flatContainer}>
         <FlatList
        // contentContainerStyle={{alignSelf: 'flex-start'}}
        // columnWrapperStyle={{alignItems: 'space-between'}}
        numColumns={3}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        data={getCrop}
        renderItem={renderItem}
        keyExtractor={item => item.id} 
        />  
         <Loader isLoading={isLoading} message={"...a moment"}  />
         </View>
        {/* <Text>{JSON.stringify(getCrop)}</Text> */}
     </View>
      </ScrollView>
      <Dialogue
        modalVisible={isDialogueVisible}
        close={LangFile.login?LangFile.login.close:''}
        onClosePress={(e) => onCloseDialoguePress()}
        message={messageDialogue}
      />
      <PickLang modalView={getLangModal} 
       close={LangFile.login?LangFile.login.close:""}
      render={ <RadioButton resData={resData} value={getLang} onSelectValue={onSelectValue}/>}
      onClosePress={onClosePress}/>

            </View>
            </ImageBackground>
        </View>
      )

}
import React, { useEffect, useState } from 'react'
import HomeScreenStyle from "./homescreenstyle";
import { Dimensions, ImageBackground, Linking, View } from 'react-native';
import BackGroundImage from "../../../assets/farm.jpeg"
import {Logo,Label, SpaceBottom, Button, Lang, RadioButton} from '../../../Components';
import  yoruba  from '../../../Lang/yoruba.json';
import igbo from '../../../Lang/igbo.json';
import english from '../../../Lang/english.json';
import hausa from '../../../Lang/hausa.json';
import PickLang from '../../../Modals/Language/PickLang';
import { _retrieveData, _storeData } from '../../../Service/function';
import { checkProfile, pickLang } from '../../../Auth';

const {width,height}=Dimensions.get('screen');
export default function HomeScreen({navigation,route}) {
    const [LangFile,setLangFile] = useState(english);
    const [getLang,setLang] = useState(0);
    const [getLangModal,setLangModal] = useState(true);
    const [user, setUser] = useState({});
    const resData=[
        {
          key:0,
          text:'English'
      },
      {
          key:1,
          text:'Hausa'
      },
      {
          key:2,
          text:'Igbo'
      },
      {
          key:3,
          text:'Yoruba'
      }
    ];
    useEffect(() => {
        pickLang(setLangFile,english,hausa,igbo,yoruba);
      },[]);

      useEffect(() => {
        checkProfile(navigation,setUser); 
      },[]);

    const OpenHref= async ()=>{
        await Linking.openURL('https://niss.gov.ng');
      }

      const onClosePress=()=>{
        pickLang(setLangFile,english,hausa,igbo,yoruba);
        setLangModal(false);
       }

      const showLangModal=()=>{
        
        setLangModal(true);
        alert('seyi')
       }

    const onSelectValue=(val)=>{
        setLangFile(val==0?english:val==1?hausa:val==2?igbo:yoruba)
        setLang(val);
        _storeData(val,'lang')
        setLangModal(false); 
    }
    return (
      <View style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
        <ImageBackground source={BackGroundImage} style={HomeScreenStyle.home}>
         <View style={HomeScreenStyle.child}>
        {getLang==0&&
   <Lang message="ENGLISH" onPress={()=>showLangModal()}/>
      }
     {getLang==1&&
   <Lang message="HAUSA" onPress={()=>showLangModal()}/>
      }
      {getLang==2&&
   <Lang message="IGBO" onPress={()=>showLangModal()}/>
      }
      {getLang==3&&
   <Lang message="YORUBA" onPress={()=>showLangModal()}/>
      }
        {/* <SpaceBottom height={height*0.00} /> */}
       
        <Logo />
        <Label fonts={15} color="#000" click={false} message={LangFile.welcome?LangFile.welcome.title:''}/>
        <SpaceBottom height={30} />
        <Button buttonText={LangFile.welcome?LangFile.welcome.login:''} onPress={() => navigation.navigate('Login')}/>
      
      <Button buttonText={LangFile.welcome?LangFile.welcome.register:''} onPress={() => navigation.navigate('Register')}/>
      <SpaceBottom height={30} />
      <Label fonts={12} click={true} color="#000" onPress={OpenHref} message={LangFile.welcome?LangFile.welcome.project+"Nigeria Institute of Soil Science (NISS)":''}/>
        </View>
        <PickLang modalView={getLangModal} 
       close={LangFile.login?LangFile.login.close:''}
      render={ <RadioButton resData={resData} value={getLang} onSelectValue={onSelectValue}/>}
      onClosePress={onClosePress}/>
  </ImageBackground>
  </View>
    )
}

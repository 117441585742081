import React, { useEffect, useState } from 'react'
import { ImageBackground, ScrollView,Dimensions,TouchableOpacity, View,DatePickerAndroid, Text, FlatList } from 'react-native';
import { pickLang } from '../../Auth';
import ExpenseListStyle from "./taskliststyle";
import {igbo,english,hausa,yoruba} from '../../Lang';
import { Button, GoBack, Label } from '../../Components';
import BackGroundImage from "../../assets/farm.jpeg";
import { DataTable,FAB } from 'react-native-paper';
import moment from 'moment';
import { naira } from '../../env';
import { addComma, getDataDateDiff, _retrieveData } from '../../Service/function';
import { useIsFocused } from '@react-navigation/native';
import Icon from 'react-native-vector-icons/Ionicons';
const {width,height}=Dimensions.get('window');
export default function TaskList({navigation,route}) {
    const [getLang,setLang] = useState(0);
    const [datefrom,setDateFrom]=useState(moment().subtract(7, "days"));
    const [dateto,setDateTo]=useState(moment());
    const [LangFile,setLangFile] = useState(english);
    const [task,setTask]=useState([]);
  
    const isFocused = useIsFocused();



    useEffect(() => {
        getAllSales();
        pickLang(setLangFile,english,hausa,igbo,yoruba);
       
      },[isFocused]);

const DateFrom=async()=>{
    try {
        const {
          action,
          year,
          month,
          day
        } = await DatePickerAndroid.open({
          // Use `new Date()` for current date.
          // May 25 2020. Month 0 is January.
          date: datefrom.toDate()
        });
        if (action !== DatePickerAndroid.dismissedAction) {
            setDateFrom(moment(`${year}-${month.length>1?month + 1:"0"+(month+1)}-${day}`))
        }
      } catch ({ code, message }) {
        // console.warn('Cannot open date picker', message);
      }
}

const DateTo=async()=>{
    try {
        const {
          action,
          year,
          month,
          day
        } = await DatePickerAndroid.open({
          // Use `new Date()` for current date.
          // May 25 2020. Month 0 is January.
          date: dateto.toDate()
        });
        if (action !== DatePickerAndroid.dismissedAction) {
            setDateTo(moment(`${year}-${month.length>1?month + 1:"0"+(month+1)}-${day}`))
          // Selected year, month (0-11), day
        }
      } catch ({ code, message }) {
        // console.warn('Cannot open date picker', message);
      }
}

const getAllSales=async()=>{
    const retSales=await _retrieveData("TaskRecord");
    if(retSales){
    setTask(retSales.reverse());
    }
}


const renderItem = ({ item,index }) => (
  <TouchableOpacity onPress={()=>navigation.navigate("AddTaskList",{datar:item,index:index})} key={index} style={moment(item.date).format("LL")===moment().format("LL")?ExpenseListStyle.itemContainerActive:ExpenseListStyle.itemContainer}>
   <View style={{padding:10}}>
    <Icon name={'notifications'} style={moment(item.date).format("LL")===moment().format("LL")?ExpenseListStyle.itemIconActive:ExpenseListStyle.itemIcon}></Icon>
    </View>
     <View style={ExpenseListStyle.itemMini}>
  <Text style={moment(item.date).format("LL")===moment().format("LL")?ExpenseListStyle.itemTitleActive:ExpenseListStyle.itemTitle}>{item.title}</Text>
  <Text style={moment(item.date).format("LL")===moment().format("LL")?ExpenseListStyle.itemDescActive:ExpenseListStyle.itemDesc}>{item.description}</Text>
  <View style={ExpenseListStyle.dateContainer}>
  <Icon name={"calendar"} style={moment(item.date).format("LL")===moment().format("LL")?ExpenseListStyle.dateIconActive:ExpenseListStyle.dateIcon}></Icon>
  <Text style={moment(item.date).format("LL")===moment().format("LL")?ExpenseListStyle.dateTextActive:ExpenseListStyle.dateText}>{moment(item.date).format("LL")}</Text>
  <Icon name={"time"} style={moment(item.date).format("LL")===moment().format("LL")?ExpenseListStyle.dateIconActive:ExpenseListStyle.dateIcon}></Icon>
  <Text style={moment(item.date).format("LL")===moment().format("LL")?ExpenseListStyle.dateTextActive:ExpenseListStyle.dateText}>{moment(item.time).format("LT")}</Text>
</View>
  </View>
 
</TouchableOpacity>
    );

    return (
        <ImageBackground source={BackGroundImage} style={ExpenseListStyle.parent}>
            <View style={ExpenseListStyle.child}>
            <GoBack onPress={() => navigation.goBack()} onAddPress={()=>navigation.navigate('AddTaskList')} buttonText={LangFile.menu?.menu8} add={true} addText={LangFile.records?.add} iconLeft={"plus"} iconLeftColor={'green'} />
            <View style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between'}}>
            <Label fonts={14} width={width/2} color="#000" click={false} message={LangFile.records?.aboutTask}/>
            <Button
            icon="calendar"
            color="green"
               altStyle={ExpenseListStyle.buttonFilter}
               altText={ExpenseListStyle.altText}
                buttonText={LangFile.records?.viewCalendar}
                onPress={()=>navigation.navigate("CalendarList")}
      />
          </View>
               <ScrollView style={ExpenseListStyle.scrollView}>
           <View style={ExpenseListStyle.tableContainer}>
           <FlatList
        numColumns={1}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        data={task}
        renderItem={renderItem}
        keyExtractor={item => item.id} 
        />  
      </View>
      </ScrollView>
            </View>
        </ImageBackground>
    )
}

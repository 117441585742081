import React, { useContext, useEffect, useState } from 'react'
import StoreScreenStyle from "./storestyle";
import { FlatList, ImageBackground,Dimensions, Linking, ScrollView, StatusBar, Text, View, TouchableOpacity } from 'react-native';
import BackGroundImage from "../../../assets/farm.jpeg"
import {Logo,Label, SpaceBottom, Button, Lang, RadioButton, TextInputed, Dialogue, LabelSmall, LangText, Avatar, MenuButton, GoBack, ProductImage, SearchLabel, Office, Loader} from '../../../Components';
import {igbo,english,hausa,yoruba} from '../../../Lang';
import PickLang from '../../../Modals/Language/PickLang';
import { _retrieveData, _retrieveToken, _storeData } from '../../../Service/function';
import { checkProfile, disableBackButton, generateGreetings, pickLang } from '../../../Auth';
import { AuthContext } from '../../../context/authContext/AuthContext';
import { baseUrl, imageUrl } from '../../../env';
import MenuImage from '../../../Components/MenuImage';
import axios from "axios";
import ProductSearch from '../../../Modals/Search/ProductSearch';
import SearchStore from '../../components/SearchStore/SearchStore';
import Icon from 'react-native-vector-icons/FontAwesome';
const {width,height}=Dimensions.get('screen');

export default function StoreScreen({navigation,route}) {
    const [isSecure, setSecure] = useState(true);
    const [getLangModal,setLangModal] = useState(false);
    const [modalSetting,setModalSetting] = useState(false);
    const [getLang,setLang] = useState(0);
    const [LangFile,setLangFile] = useState(english);
    const [getProduct, setProduct] = useState([]);
    const [getSearch, setSearch] = useState(false);
    const [category, setCategory] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [getGreetings,setGreetings]=useState(null);
    const [getBannerTab, setBannerTab] = useState(1);
    const [messageDialogue, setmessageDialogue] = useState("Hello");
    const [isDialogueVisible, setisDialogueVisible] = useState(false);
    const [getState, setState] = useState(null);
    const [getLGA, setLGA] = useState(null);
    //Context
    const {user,isFetching,dispatch}=useContext(AuthContext);

    const resData=[
        {
          key:0,
          text:'English'
      },
      {
          key:1,
          text:'Hausa'
      },
      {
          key:2,
          text:'Igbo'
      },
      {
          key:3,
          text:'Yoruba'
      }
    ];
  
    useEffect(() => {
        pickLang(setLangFile,english,hausa,igbo,yoruba);
        generateGreetings(LangFile,setGreetings);
        disableBackButton()
      },[]);

      useEffect(() => {
        LoadBusiness()
        currentLocation();
      },[]);

      const onCloseDialoguePress=()=>{
        setisDialogueVisible(false);
       }

    const OpenHref= async ()=>{
        await Linking.openURL('https://niss.gov.ng');
      }

      const onClosePress=()=>{
        // pickLang(setLangFile,english,hausa,igbo,yoruba);
        setLangModal(false);
       }

      const showLangModal=()=>{
        setLangModal(true);
       }

    const onSelectValue=async(val)=>{
        setLangFile(val==0?english:val==1?hausa:val==2?igbo:yoruba)
        setLang(val);
       await _storeData(val,'lang')
        setLangModal(false); 
    }

    const setBusiness=async()=>{
        let crop=await _retrieveData("offices");
        setProduct(crop);
    }

    const LoadBusiness=async()=>{
        setBusiness();
        setLoading(true);
        const token = await _retrieveData("token");
        try {
            const resp=await axios.get(baseUrl+"offices",{
                headers: {
                   Authorization: `Bearer ${token}`
                }
            });
            const respcat=await axios.get(baseUrl+"scientist-categories",{
              headers: {
                 Authorization: `Bearer ${token}`
              }
          });
          setCategory(respcat.data)
            
            // if(resp.statusCode){
            //     setLoading(false);
            //   setisDialogueVisible(true);
            //   setmessageDialogue(LangFile.error_message?LangFile.error_message.general_error:"");
            //   return false;
            //   }
              setProduct(resp.data);
              setLoading(false);
           await  _storeData(resp.data,"offices");
        } catch (error) {
            setLoading(false);
            setisDialogueVisible(true);
            setmessageDialogue(error.message);
        }
    }



    const currentLocation=async()=>{
      let location=await _retrieveData("business");
      setState(location.live_state);
      setLGA(location.live_lga);
      
    }

    const searchModel=()=>{
        setSearch(true);
      }
      const closeSearch=()=>{
        setSearch(false);
      }
      const navPage=(item)=>{
        navigation.navigate('ProductEdit', { datars: item,lang:getLang })
      }
      
    //   const changeSearch=(name)=>{
    //     asyncGet('/businesses?name_contains='+name+'&_limit=50')
    //     .then((resp)=>{
    //       setProductSearch(resp);
    //       setLoading(false);
    //     })
    //     .catch((err)=>{
    //       setLoading(false);
    //     })
    //   }
      const changeTab=(it)=>{
        setBannerTab(it);
        if(it==1){
          LoadProducts();
        }
        else if(it==2){
          LoadProductsSeed();
        }
        else if(it==3){
          LoadProductsChem();
        }
        
      }
       

    const renderItem = ({ item,index }) => (
        <Office bizshow={1} onPress={()=>navigation.navigate('OfficeDetail', { datars: item,lang:LangFile })} icontitle={item.name[0]} title={item.name} subtitle={item.address} /> 
          );
          const renderCat = ({ item,index }) => (
            <TouchableOpacity onPress={()=>navigation.navigate("ScientistList",{datars:item})} key={index} style={StoreScreenStyle.flatContainer}>
                <View style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                 <Icon style={StoreScreenStyle.senderTxt} name={'map-o'} ></Icon>
            <Text style={StoreScreenStyle.senderTxt}>{item.name}</Text>
            </View>
            <Icon style={StoreScreenStyle.senderTxt} name={'arrow-circle-right'} ></Icon>
            </TouchableOpacity>
                  );
      return (
        <View style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
        <ImageBackground source={BackGroundImage} style={StoreScreenStyle.parent}>
        <View style={StoreScreenStyle.child}>
        <GoBack onPress={() => navigation.goBack()} buttonText={LangFile.menu?LangFile.menu.menu1:""} />
    
      <View style={{flexDirection:'column',backgroundColor:"rgba(255,255,255,0.7)"}}>
   {/* <SearchStore stateChange={(e)=>{setSearched({state:e});setState(e)}} lgaChange={(e)=>{setSearched({lgs:e});setLGA(e)}} productChange={(e)=>{setSearched({product:e,state:getState,lga:getLGA});setState(e)}} LangFile={LangFile}  /> */}
   <View style={{display:'flex',flexDirection:'column',margin:10}}>
     <Text style={{color:'black',fontWeight:'bold',fontSize:15}}>{LangFile.naming?LangFile.naming.ouroffice:"Our Offices"}</Text>
     <ScrollView horizontal>
     <FlatList
        // contentContainerStyle={{alignSelf: 'flex-start'}}
        // columnWrapperStyle={{alignItems: 'space-between'}}
        horizontal
        numRows={3}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        data={getProduct}
        renderItem={renderItem}
        keyExtractor={item => item.id} 
        />  
      
     </ScrollView>
   </View>
   <View style={{display:'flex',flexDirection:'column',margin:10}}>
     <Text style={{color:'black',fontWeight:'bold',fontSize:15,marginBottom:10}}>{LangFile.naming?LangFile.naming.soilsciencecat:"Soil Scientist Categories"}</Text>
   <ScrollView style={StoreScreenStyle.scrollView} >
   <FlatList
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        data={category}
        renderItem={renderCat}
        keyExtractor={item => item.id} 
        />  
          <Loader isLoading={isLoading} message={"...a moment"}  />
         </ScrollView>
        </View>
     </View>
     
     
      <Dialogue
        modalVisible={isDialogueVisible}
        close={LangFile.login?LangFile.login.close:''}
        onClosePress={(e) => onCloseDialoguePress()}
        message={messageDialogue}
      />
      <PickLang modalView={getLangModal} 
       close={LangFile.login?LangFile.login.close:""}
      render={ <RadioButton resData={resData} value={getLang} onSelectValue={onSelectValue}/>}
      onClosePress={onClosePress}/>

            </View>
            </ImageBackground>
            </View>

      )

}
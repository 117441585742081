import React, { useContext, useEffect, useState } from 'react'
import PermissionScreenStyle from './permissionscreenstyle';
import { ImageBackground, Linking, ScrollView, StatusBar, View,Platform } from 'react-native';
import BackGroundImage from "../../../assets/farm.jpeg"
import {Logo,Label, SpaceBottom, Button, Lang, RadioButton, TextInputed, TabStep, SelectInputed, RadioSelect, Dialogue} from '../../../Components';
import {igbo,english,hausa,yoruba} from '../../../Lang';
import PickLang from '../../../Modals/Language/PickLang';
import { _retrieveData, _storeData } from '../../../Service/function';
import { checkProfile, disableBackButton, pickLang } from '../../../Auth';
import { AuthContext } from '../../../context/authContext/AuthContext';
import stateLGA from '../../../State/state.json';
import { baseUrl } from '../../../env';
import MultiSelect from '../../../Components/MultiSelect';
import axios from "axios";
if(Platform.OS==="ios"||Platform.OS==="android"){
  const KeyboardSpacer = require('react-native-keyboard-spacer');
}

export default function PermissionScreen({navigation,route}) {
    const [getState, setState] = useState(null);
    const [getLGA, setLGA] = useState(null);
    const [getRole, setRole] = useState({});
    const [getJsonLGA, setJsonLGA] = useState([]);
    const [getLanguage, setLanguage] = useState(null);
    const [getFarmSize, setFarmSize] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [isSecure, setSecure] = useState(true);
    const [getServices, setServices] = useState(null);
    const [messageDialogue, setmessageDialogue] = useState("Hello");
    const [isDialogueVisible, setisDialogueVisible] = useState(false);
  const [getLangModal,setLangModal] = useState(false);
  const [getLang,setLang] = useState(0);
  const [LangFile,setLangFile] = useState(english);
  const [getSpecific,setSpecific] = useState(null);
  const [getFarmAddress,setFarmAddress] = useState(null);
  const [getBusinessName,setBusinessName] = useState(null);
  const [getBusinessAddress,setBusinessAddress] = useState(null);
  const [getBusinessPhone,setBusinessPhone] = useState(null);
  const [getProductSale,setProductSale] = useState(null);
  const [getInstitution,setInstitution] = useState(null);
  const [getRegNo,setRegNo] = useState(null);
  const [getContact,setContact] = useState(null);
  const [getEmail,setEmail] = useState(null);
  const [getUser,setUser]=useState({});
  const [confirm,setConfirm]=useState(false);
  const [category,setCategory]=useState([]);
  const [getType,setType]=useState({});
  
  const resData=[
    {
        key:0,
        text:'English'
    },
    {
        key:1,
        text:'Hausa'
    },
    {
        key:2,
        text:'Igbo'
    },
    {
        key:3,
        text:'Yoruba'
    }
];


useEffect(() => {
  setLoading(false);
    pickLang(setLangFile,english,hausa,igbo,yoruba);
  },[]);

  useEffect(() => {
    loadRssMemberCat();
    checkProfile(navigation,setUser); 
    disableBackButton();
  },[]);

const OpenHref= async ()=>{
    await Linking.openURL('https://niss.gov.ng');
  }

  const onClosePress=()=>{
    // pickLang(setLangFile,english,hausa,igbo,yoruba);
    setLangModal(false);
   }

  const showLangModal=()=>{
    setLangModal(true);
   }

    const onSelectValue=async(val)=>{
    setLangFile(val==0?english:val==1?hausa:val==2?igbo:yoruba)
    setLang(val);
    await _storeData(val,'lang')
    setLangModal(false); 
    }

    const loadRssMemberCat=async()=>{
      const token = await _retrieveData("token");
      try {
        let resp=await axios.get(baseUrl+`scientist-categories`,{
          headers: {
             Authorization: `Bearer ${token}`
          }
      });
      setCategory(resp.data);
      } catch (error) {
        loadRssMemberCat();
      }
    }

    const changeState=(e)=>{
        setState(JSON.parse(e).name);
        setJsonLGA(JSON.parse(e).lgas);
        // console.log(e);
    }

    const checkUserID=async(e)=>{
      setRegNo(e);
      const token = await _retrieveData("token");
      setLoading(true)
      const resp=await axios.get(baseUrl+`members?identity=${e}`,{
        headers: {
           Authorization: `Bearer ${token}`
        }
    });
    if(resp.data.length>0&&getUser.name.includes(resp.data[0].name)){
      setConfirm(true);
    }
    else{
      setConfirm(false);
    }
    setLoading(false)
    }

    const Permission =async () => {
        const token = await _retrieveData("token");
        if(getRole.name&&getRole.name==="Farmer"){
            if(!getFarmSize||!getState||!getLGA||!getSpecific||!getBusinessAddress){
              setisDialogueVisible(true);
              setmessageDialogue(LangFile.error_message?LangFile.error_message.fill_all:"");
              return false;
            }
          }
          else if(getRole.name&&getRole.name==="Agro Dealer"){
            if(!getBusinessName||!getState||!getLGA||!getBusinessAddress||!getBusinessPhone||!getProductSale){
              setisDialogueVisible(true);
              setmessageDialogue(LangFile.error_message?LangFile.error_message.fill_all:"");
              return false;
            }
          }
          else if(getRole.name&&getRole.name==="Extension Agent"){
            if(!getInstitution||!getState||!getLGA||!getBusinessPhone){
              setisDialogueVisible(true);
              setmessageDialogue(LangFile.error_message?LangFile.error_message.fill_all:"");
              return false;
            }
          }
          else if(getRole.name&&getRole.name==="RSS Member"){
            if(!getState||!getLGA||!getType||!getBusinessPhone||!getEmail||!getServices){
              setisDialogueVisible(true);
              setmessageDialogue(LangFile.error_message?LangFile.error_message.fill_all:"");
              return false;
            }
          }
          else if(getRole.name&&getRole.name==="Government agent"){
            if(!getInstitution||!getBusinessPhone||!getEmail){
              setisDialogueVisible(true);
              setmessageDialogue(LangFile.error_message?LangFile.error_message.fill_all:"");
              return false;
            }
          }
          else if(getRole.name&&getRole.name==="Agronomist"){
            if(!getInstitution||!getBusinessPhone||!getEmail){
              setisDialogueVisible(true);
              setmessageDialogue(LangFile.error_message?LangFile.error_message.fill_all:"");
              return false;
            }
          }
          else if(getRole.name&&getRole.name==="Scholars/NGO"){
            if(!getInstitution||!getEmail){
              setisDialogueVisible(true);
              setmessageDialogue(LangFile.error_message?LangFile.error_message.fill_all:"");
              return false;
            }
          }
          else if(getRole.name&&getRole.name==="Researcher"){
            if(!getInstitution||!getEmail){
              setisDialogueVisible(true);
              setmessageDialogue(LangFile.error_message?LangFile.error_message.fill_all:"");
              return false;
            }
          }
          else if(getRole.name&&getRole.name==="Others"){
            if(!getInstitution||!getEmail){
              setisDialogueVisible(true);
              setmessageDialogue(LangFile.error_message?LangFile.error_message.fill_all:"");
              return false;
            }
          }
        setLoading(true);
        let datat={
          role:getRole.id,
  farmsize:getFarmSize,
  state:getState,
  lga:getLGA,
  specific:getSpecific,
  businessphone:getBusinessPhone,
businessname:getBusinessName,
type:getType.id,
businessaddress:getBusinessAddress,
productsale:getProductSale,
regno:getRegNo,
institute:getInstitution,
email:getEmail,
services:getServices,
confirm:confirm
        }
      
        try {
            const resp=await axios.post(baseUrl+'businesses/update',datat,{
                headers: {
                   Authorization: `Bearer ${token}`
                }
            });
            if(resp.data.statusCode){
                setLoading(false);
              setisDialogueVisible(true);
              setmessageDialogue(LangFile.error_message?LangFile.error_message.general_error:"");
              return false;
              }
              await _storeData(resp.data.user,"userauth");
               await _storeData(resp.data.business,"business");
              setLoading(false);
              navigation.navigate('Home');
            
        } catch (error) {
            setLoading(false);
            setisDialogueVisible(true);
            setmessageDialogue(error.message);
        }
    }

  const pressNull=()=>{
  return false;
}

const onCloseDialoguePress=()=>{
    setisDialogueVisible(false);
   }


    return (
      <View style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
        <ImageBackground source={BackGroundImage} style={PermissionScreenStyle.parent}>
              <View style={PermissionScreenStyle.child}>
              {getLang==0&&
   <Lang message="ENGLISH" onPress={()=>showLangModal()}/>
      }
     {getLang==1&&
   <Lang message="HAUSA" onPress={()=>showLangModal()}/>
      }
      {getLang==2&&
   <Lang message="IGBO" onPress={()=>showLangModal()}/>
      }
      {getLang==3&&
   <Lang message="YORUBA" onPress={()=>showLangModal()}/>
      }
     {/* <Logo /> */}
     <TabStep onPress={pressNull} text1={'Authorization'} text2={'Location'} text3={'Permission'} indexSelect={3} />
     <Label onPress={pressNull} message={LangFile.authorization?LangFile.authorization.welcome+getUser?getUser.name:''+LangFile.location.message:''} fonts={16} />
     
      <ScrollView style={PermissionScreenStyle.container} persistentScrollbar={true} contentContainerStyle={{paddingRight:0}}>
      <SelectInputed
          showIcon1={true}
          showIcon2={true}
          title={LangFile.permission?LangFile.permission.role+"(*)":''}
          iconName1={'user'}
          render={<RadioSelect resData={[{id:1,name:"Farmer"},{id:3,name:"Extension Agent"},
          {id:4,name:"RSS Member"},{id:9,name:"Agronomist"},{id:5,name:"Government agent"},{id:6,name:"Scholars/NGO"},{id:8,name:"Researcher"},{id:7,name:"Others"}]} value={getRole.name} onSelectValue={(e) => setRole(JSON.parse(e))}/>}
          selectLabel={LangFile.permission?LangFile.permission.role:''}
          label={getRole.name?getRole.name:LangFile.permission?LangFile.permission.role:''}
          iconName2={'angle-down'}
          Options={1}
        />
        {getRole.id==1&&
        <View>
           
        <TextInputed
          showIcon1={true}
          showIcon2={false}
          iconName1={'tint'}
          label={LangFile.permission?LangFile.permission.farm_size:''}
          // iconName2={'eye'}
          placeholder={LangFile.permission?LangFile.permission.farm_size:''}
          onChangeText={(e) => setFarmSize(e)}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          Secure={false}
          keyboardType={'numeric'}
        />
        <SelectInputed
          showIcon1={true}
          showIcon2={true}
          iconName1={'pied-piper'}
          title={LangFile.permission?LangFile.permission.farm_fertilizer:''}
          render={<RadioSelect resData={[{id:"Yes",name:"Yes"},{id:"No",name:"No"}]} value={getSpecific} onSelectValue={(e) => setSpecific(JSON.parse(e).name)}/>}
          selectLabel={LangFile.permission?LangFile.permission.farm_fertilizer:''}
          label={getSpecific!=null?getSpecific:LangFile.permission?LangFile.permission.farm_fertilizer:''}
          iconName2={'angle-down'}
          Options={1}
        />
        <SelectInputed
          showIcon1={true}
          showIcon2={true}
          iconName1={'globe'}
          title={LangFile.permission?LangFile.permission.farm_state:''}
          render={<RadioSelect resData={stateLGA} value={getState} onSelectValue={(e) => changeState(e)}/>}
          selectLabel={LangFile.permission?LangFile.permission.farm_state:''}
          label={getState!=null?getState:LangFile.permission?LangFile.permission.farm_state:''}
          iconName2={'angle-down'}
          Options={1}
        />
        <SelectInputed
          showIcon1={true}
          showIcon2={true}
          iconName1={'globe'}
          title={LangFile.permission?LangFile.permission.farm_lga:''}
          render={<RadioSelect resData={getJsonLGA} value={getLGA} onSelectValue={(e) => setLGA(JSON.parse(e).name)}/>}
          selectLabel={LangFile.permission?LangFile.permission.farm_lga:''}
          label={getLGA!=null?getLGA:LangFile.permission?LangFile.permission.farm_lga:''}
          iconName2={'angle-down'}
          Options={1}
        />
         <TextInputed
          showIcon1={true}
          showIcon2={false}
          iconName1={'map'}
          // iconName2={'eye'}
          label={LangFile.permission?LangFile.permission.farm_address:''}
          placeholder={LangFile.permission?LangFile.permission.farm_address:''}
          onChangeText={(e) => setBusinessAddress(e)}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          Secure={false}
        />
        </View>
}
{getRole.id==2&&
        <View>
        <TextInputed
          showIcon1={true}
          showIcon2={false}
          iconName1={'tint'}
          // iconName2={'eye'}
          placeholder={LangFile.permission?LangFile.permission.business_name:''}
          onChangeText={(e) => setBusinessName(e)}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          Secure={false}
        />
        <TextInputed
          showIcon1={true}
          showIcon2={false}
          iconName1={'phone'}
          // iconName2={'eye'}
          placeholder={LangFile.permission?LangFile.permission.business_phone:''}
          onChangeText={(e) => setBusinessPhone(e)}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          Secure={false}
          keyboardType={'numeric'}
        />
         <TextInputed
          showIcon1={false}
          showIcon2={false}
          iconName1={'email'}
          // iconName2={'eye'}
          placeholder={LangFile.permission?LangFile.permission.extention_email:''}
          onChangeText={(e) => setEmail(e)}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          Secure={false}
          keyboardType={'email-address'}
        />
         <TextInputed
          showIcon1={true}
          showIcon2={false}
          iconName1={'map'}
          // iconName2={'eye'}
          placeholder={LangFile.permission?LangFile.permission.business_address:''}
          onChangeText={(e) => setBusinessAddress(e)}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          Secure={false}
        />
        <SelectInputed
          showIcon1={true}
          showIcon2={true}
          iconName1={'globe'}
          render={<RadioSelect resData={stateLGA} value={getState} onSelectValue={(e) => changeState(e)}/>}
          selectLabel={LangFile.permission?LangFile.permission.business_state:''}
          label={getState!=null?getState:LangFile.permission?LangFile.permission.business_state:''}
          iconName2={'angle-down'}
          Options={1}
        />
        <SelectInputed
          showIcon1={true}
          showIcon2={true}
          iconName1={'globe'}
          render={<RadioSelect resData={getJsonLGA} value={getLGA} onSelectValue={(e) => setLGA(JSON.parse(e).name)}/>}
          selectLabel={LangFile.permission?LangFile.permission.business_lga:''}
          label={getLGA!=null?getLGA:LangFile.permission?LangFile.permission.business_lga:''}
          iconName2={'angle-down'}
          Options={1}
        />
         <SelectInputed
          showIcon1={true}
          showIcon2={true}
          iconName1={'globe'}
          render={<RadioSelect resData={[{id:"Fertilizer",name:"Fertilizer"},{id:"AgroChemical",name:"AgroChemical"},{id:"Seeds",name:"Seeds"},
        {id:"Fertilizer and AgroChemical",name:"Fertilizer and AgroChemical"},{id:"Fertilizer and Seeds",name:"Fertilizer and Seeds"},{id:"Agrochemical and Seeds",name:"Agrochemical and Seeds"},
    {id:"Fertilizer, Agrochemical and Seeds",name:"Fertilizer, Agrochemical and Seeds"}]} value={getProductSale} onSelectValue={(e) => setProductSale(JSON.parse(e).name)}/>}
          selectLabel={LangFile.permission?LangFile.permission.business_product:''}
          label={getProductSale!=null?getProductSale:LangFile.permission?LangFile.permission.business_product:''}
          iconName2={'angle-down'}
          Options={1}
        />
        </View>
}
{getRole.id==3&&
        <View>
            <TextInputed
          showIcon1={true}
          showIcon2={false}
          iconName1={'building'}
          label={LangFile.permission?LangFile.permission.extention_institute+"(*)":''}
          // iconName2={'eye'}
          placeholder={LangFile.permission?LangFile.permission.extention_institute:''}
          onChangeText={(e) => setInstitution(e)}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          Secure={false}
        />
        <TextInputed
          showIcon1={true}
          showIcon2={false}
          iconName1={'file-text'}
          // iconName2={'eye'}
          label={LangFile.permission?LangFile.permission.extention_regno:''}
          placeholder={LangFile.permission?LangFile.permission.extention_regno:''}
          onChangeText={(e) => setRegNo(e)}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          Secure={false}
        />
         <SelectInputed
          showIcon1={true}
          showIcon2={true}
          iconName1={'globe'}
          title={LangFile.permission?LangFile.permission.business_state+"(*)":''}
          render={<RadioSelect resData={stateLGA} value={getState} onSelectValue={(e) => changeState(e)}/>}
          selectLabel={LangFile.permission?LangFile.permission.business_state:''}
          label={getState!=null?getState:LangFile.permission?LangFile.permission.business_state:''}
          iconName2={'angle-down'}
          Options={1}
        />
        <SelectInputed
          showIcon1={true}
          showIcon2={true}
          iconName1={'globe'}
          title={LangFile.permission?LangFile.permission.business_lga+"(*)":''}
          render={<RadioSelect resData={getJsonLGA} value={getLGA} onSelectValue={(e) => setLGA(JSON.parse(e).name)}/>}
          selectLabel={LangFile.permission?LangFile.permission.business_lga:''}
          label={getLGA!=null?getLGA:LangFile.permission?LangFile.permission.business_lga:''}
          iconName2={'angle-down'}
          Options={1}
        />
        <TextInputed
          showIcon1={true}
          showIcon2={false}
          iconName1={'phone'}
          // iconName2={'eye'}
          label={LangFile.permission?LangFile.permission.extention_contact+"(*)":''}
          placeholder={LangFile.permission?LangFile.permission.extention_contact:''}
          onChangeText={(e) => setBusinessPhone(e)}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          Secure={false}
          keyboardType={'numeric'}
        />
        <TextInputed
          showIcon1={false}
          showIcon2={false}
          iconName1={'email'}
          // iconName2={'eye'}
          label={LangFile.permission?LangFile.permission.extention_email:''}
          placeholder={LangFile.permission?LangFile.permission.extention_email:''}
          onChangeText={(e) => setEmail(e)}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          Secure={false}
          keyboardType={'email-address'}
        />
        </View>
}
{getRole.id==5&&
        <View>
            <TextInputed
          showIcon1={true}
          showIcon2={false}
          iconName1={'building'}
          label={LangFile.permission?LangFile.permission.extention_institute:''}
          // iconName2={'eye'}
          placeholder={LangFile.permission?LangFile.permission.extention_institute:''}
          onChangeText={(e) => setInstitution(e)}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          Secure={false}
        />
        {/* <TextInputed
          showIcon1={true}
          showIcon2={false}
          iconName1={'file-text'}
          // iconName2={'eye'}
          label={LangFile.permission?LangFile.permission.extention_regno:''}
          placeholder={LangFile.permission?LangFile.permission.extention_regno:''}
          onChangeText={(e) => setRegNo(e)}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          Secure={false}
        /> */}
        <TextInputed
          showIcon1={true}
          showIcon2={false}
          iconName1={'phone'}
          // iconName2={'eye'}
          label={LangFile.permission?LangFile.permission.extention_contact:''}
          placeholder={LangFile.permission?LangFile.permission.extention_contact:''}
          onChangeText={(e) => setBusinessPhone(e)}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          Secure={false}
          keyboardType={'numeric'}
        />
        <TextInputed
          showIcon1={false}
          showIcon2={false}
          iconName1={'email'}
          // iconName2={'eye'}
          label={LangFile.permission?LangFile.permission.extention_email:''}
          placeholder={LangFile.permission?LangFile.permission.extention_email:''}
          onChangeText={(e) => setEmail(e)}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          Secure={false}
          keyboardType={'email-address'}
        />
        </View>
}
{getRole.id==9&&
        <View>
            <TextInputed
          showIcon1={true}
          showIcon2={false}
          iconName1={'building'}
          label={LangFile.permission?LangFile.permission.extention_institute:''}
          // iconName2={'eye'}
          placeholder={LangFile.permission?LangFile.permission.extention_institute:''}
          onChangeText={(e) => setInstitution(e)}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          Secure={false}
        />
        {/* <TextInputed
          showIcon1={true}
          showIcon2={false}
          iconName1={'file-text'}
          // iconName2={'eye'}
          label={LangFile.permission?LangFile.permission.extention_regno:''}
          placeholder={LangFile.permission?LangFile.permission.extention_regno:''}
          onChangeText={(e) => setRegNo(e)}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          Secure={false}
        /> */}
        <TextInputed
          showIcon1={true}
          showIcon2={false}
          iconName1={'phone'}
          // iconName2={'eye'}
          label={LangFile.permission?LangFile.permission.extention_contact:''}
          placeholder={LangFile.permission?LangFile.permission.extention_contact:''}
          onChangeText={(e) => setBusinessPhone(e)}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          Secure={false}
          keyboardType={'numeric'}
        />
        <TextInputed
          showIcon1={false}
          showIcon2={false}
          iconName1={'email'}
          // iconName2={'eye'}
          label={LangFile.permission?LangFile.permission.extention_email:''}
          placeholder={LangFile.permission?LangFile.permission.extention_email:''}
          onChangeText={(e) => setEmail(e)}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          Secure={false}
          keyboardType={'email-address'}
        />
        </View>
}
{getRole.id==4&&
        <View>
            {/* <TextInputed
          showIcon1={true}
          showIcon2={false}
          iconName1={'building'}
          // iconName2={'eye'}
          placeholder={LangFile.permission?LangFile.permission.extention_institute:''}
          onChangeText={(e) => setInstitution(e)}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          Secure={false}close
        /> */}
        <TextInputed
          showIcon1={true}
          showIcon2={true}
          iconName1={'file-text'}
          iconName2={confirm?'check':'close'}
          loader={isLoading}
          iconStyle2={{color:confirm?"green":"red"}}
          label={LangFile.permission?LangFile.permission.extention_regno:''}
          placeholder={LangFile.permission?LangFile.permission.extention_regno:''}
          onChangeText={(e) => checkUserID(e)}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          Secure={false}
        />
        <TextInputed
          showIcon1={true}
          showIcon2={false}
          iconName1={'phone'}
          label={LangFile.permission?LangFile.permission.business_whatsapp:''}
          // iconName2={'eye'}
          placeholder={LangFile.permission?LangFile.permission.business_whatsapp:''}
          onChangeText={(e) => setBusinessPhone(e)}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          Secure={false}
          keyboardType={'numeric'}
        />
        <TextInputed
          showIcon1={false}
          showIcon2={false}
          iconName1={'email'}
          // iconName2={'eye'}
          label={LangFile.permission?LangFile.permission.business_email:''}
          placeholder={LangFile.permission?LangFile.permission.business_email:''}
          onChangeText={(e) => setEmail(e)}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          Secure={false}
          keyboardType={'email-address'}
        />
         <SelectInputed
          showIcon1={true}
          showIcon2={true}
          iconName1={'globe'}
          title={LangFile.permission?LangFile.permission.choose_type+"(*)":''}
          render={<RadioSelect resData={category?category:[
            {id:1,name:"Soil Pedalogist"},
            {id:2,name:"Soil Physics and Water Management"},
            {id:3,name:"Soil Chemistry"},
            {id:4,name:"Soil Biology and Micro-Biology"},
            {id:2,name:"Soil Fertlity and Plant Nutrition"},
            {id:2,name:"Soil Survey and Climatology"},
          ]} value={getType?.name} onSelectValue={(e) => setType(JSON.parse(e))}/>}
          selectLabel={LangFile.permission?LangFile.permission.choose_type:''}
          label={getType.name!=null?getType.name:LangFile.permission?LangFile.permission.choose_type:''}
          iconName2={'angle-down'}
          Options={1}
        />
        <SelectInputed
          showIcon1={true}
          showIcon2={true}
          iconName1={'gears'}
          title={LangFile.permission?LangFile.permission.business_service:''}
          render={<MultiSelect resData={[{key:"Soil Survey",text:"Soil Survey"},{key:"Soil Sampling",text:"Soil Sampling"},{key:"Soil Related Issues",text:"Soil Related Issues"},{key:"Advisory Service",text:"Advisory Service"},{key:"Technical Service",text:"Technical Service"},{key:"Machinery Service",text:"Machinery Service"},{key:"Fertilizer Usage and Policy",text:"Fertilizer Usage and Policy"},{key:"Farm Pest and Disease Control",text:"Farm Pest and Disease Control"}]} value={getServices} onSelectValue={(e) => setServices(e.join())}/>}
          selectLabel={LangFile.permission?LangFile.permission.business_service:''}
          label={getServices!=null?getServices:LangFile.permission?LangFile.permission.business_service:''}
          iconName2={'angle-down'}
          Options={1}
        />
         <SelectInputed
          showIcon1={true}
          showIcon2={true}
          iconName1={'globe'}
          title={LangFile.permission?LangFile.permission.business_state+"(*)":''}
          render={<RadioSelect resData={stateLGA} value={getState} onSelectValue={(e) => changeState(e)}/>}
          selectLabel={LangFile.permission?LangFile.permission.business_state:''}
          label={getState!=null?getState:LangFile.permission?LangFile.permission.business_state:''}
          iconName2={'angle-down'}
          Options={1}
        />
        <SelectInputed
          showIcon1={true}
          showIcon2={true}
          title={LangFile.permission?LangFile.permission.business_lga+"(*)":''}
          iconName1={'globe'}
          render={<RadioSelect resData={getJsonLGA} value={getLGA} onSelectValue={(e) => setLGA(JSON.parse(e).name)}/>}
          selectLabel={LangFile.permission?LangFile.permission.business_lga:''}
          label={getLGA!=null?getLGA:LangFile.permission?LangFile.permission.business_lga:''}
          iconName2={'angle-down'}
          Options={1}
        />
        </View>
}
{/* {getRole.id==5&&
        <View>
            <TextInputed
          showIcon1={true}
          showIcon2={false}
          iconName1={'building'}
          // iconName2={'eye'}
          placeholder={LangFile.permission?LangFile.permission.extention_institute:''}
          onChangeText={(e) => setInstitution(e)}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          Secure={false}
        />
        <TextInputed
          showIcon1={true}
          showIcon2={false}
          iconName1={'file-text'}
          // iconName2={'eye'}
          placeholder={LangFile.permission?LangFile.permission.extention_regno:''}
          onChangeText={(e) => setRegNo(e)}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          Secure={false}
        />
        <TextInputed
          showIcon1={true}
          showIcon2={false}
          iconName1={'phone'}
          // iconName2={'eye'}
          placeholder={LangFile.permission?LangFile.permission.extention_contact:''}
          onChangeText={(e) => setContact(e)}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          Secure={false}
          keyboardType={'numeric'}
        />
        <TextInputed
          showIcon1={false}
          showIcon2={false}
          iconName1={'email'}
          // iconName2={'eye'}
          placeholder={LangFile.permission?LangFile.permission.extention_email:''}
          onChangeText={(e) => setEmail(e)}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          Secure={false}
          keyboardType={'email-address'}
        />
        </View>
} */}
{getRole.id==6&&
        <View>
            <TextInputed
          showIcon1={true}
          showIcon2={false}
          iconName1={'building'}
          // iconName2={'eye'}
          label={LangFile.permission?LangFile.permission.extention_institute+"(*)":''}
          placeholder={LangFile.permission?LangFile.permission.extention_institute:''}
          onChangeText={(e) => setInstitution(e)}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          Secure={false}
        />
        
        <TextInputed
          showIcon1={false}
          showIcon2={false}
          iconName1={'email'}
          // iconName2={'eye'}
          label={LangFile.permission?LangFile.permission.extention_email+"(*)":''}
          placeholder={LangFile.permission?LangFile.permission.extention_email:''}
          onChangeText={(e) => setEmail(e)}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          Secure={false}
          keyboardType={'email-address'}
        />
        </View>
}
{getRole.id==8&&
        <View>
            <TextInputed
          showIcon1={true}
          showIcon2={false}
          iconName1={'building'}
          // iconName2={'eye'}
          label={LangFile.permission?LangFile.permission.extention_institute+"(*)":''}
          placeholder={LangFile.permission?LangFile.permission.extention_institute:''}
          onChangeText={(e) => setInstitution(e)}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          Secure={false}
        />
        
        <TextInputed
          showIcon1={false}
          showIcon2={false}
          iconName1={'email'}
          // iconName2={'eye'}
          label={LangFile.permission?LangFile.permission.extention_email+"(*)":''}
          placeholder={LangFile.permission?LangFile.permission.extention_email:''}
          onChangeText={(e) => setEmail(e)}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          Secure={false}
          keyboardType={'email-address'}
        />
        </View>
}
{getRole.id==7&&
        <View>
            <TextInputed
          showIcon1={true}
          showIcon2={false}
          iconName1={'building'}
          // iconName2={'eye'}
          label={LangFile.permission?LangFile.permission.extention_institute+"(*)":''}
          placeholder={LangFile.permission?LangFile.permission.extention_institute:''}
          onChangeText={(e) => setInstitution(e)}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          Secure={false}
        />
        {/* <TextInputed
          showIcon1={true}
          showIcon2={false}
          iconName1={'file-text'}
          // iconName2={'eye'}
          placeholder={LangFile.permission?LangFile.permission.extention_regno:''}
          onChangeText={(e) => setFarmSize(e)}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          Secure={false}
        /> */}
        {/* <TextInputed
          showIcon1={true}
          showIcon2={false}
          iconName1={'phone'}
          // iconName2={'eye'}
          placeholder={LangFile.permission?LangFile.permission.extention_contact:''}
          onChangeText={(e) => setFarmSize(e)}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          Secure={false}
          keyboardType={'numeric'}
        /> */}
        <TextInputed
          showIcon1={false}
          showIcon2={false}
          iconName1={'email'}
          // iconName2={'eye'}
          label={LangFile.permission?LangFile.permission.extention_email+"(*)":''}
          placeholder={LangFile.permission?LangFile.permission.extention_email:''}
          onChangeText={(e) => setEmail(e)}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          Secure={false}
          keyboardType={'email-address'}
        />
        </View>
}
        {/* <SelectInputed
          showIcon1={true}
          showIcon2={true}
          iconName1={'globe'}
          render={<RadioSelect resData={[{id:1,name:"Farmer"},{id:2,name:"Agro Dealer"},{id:3,name:"Extension Agent"},
        {id:4,name:"RSS Member"},{id:5,name:"Government agent"},{id:6,name:"Scholars & NGO"}]} value={getLGA} onSelectValue={(e) => setLGA(JSON.parse(e).name)}/>}
          selectLabel={LangFile.location?LangFile.location.lga:''}
          label={getLGA!=null?getLGA:LangFile.location?LangFile.location.lga:''}
          iconName2={'angle-down'}
          Options={1}
        />
         <TextInputed
          showIcon1={true}
          showIcon2={false}
          iconName1={'map-marker'}
          // iconName2={'eye'}
          placeholder={LangFile.location?LangFile.location.address:''}
          onChangeText={(e) => setPhoneNumber(e)}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          Secure={false}
          keyboardType={'numeric'}
        />
        <SelectInputed
          showIcon1={true}
          showIcon2={true}
          iconName1={'language'}
          render={
          <RadioSelect resData={[{id:"english",name:"English"},{id:"hausa",name:"Hausa"},
          {id:"igbo",name:"Igbo"},{id:"yoruba",name:"Yoruba"},{id:"english_hausa",name:"English and Hausa"},
          {id:"english_igbo",name:"English and Igbo"},{id:"english_yoruba",name:"English and Yoruba"},
          {id:"english_hausa_igbo",name:"English, Hausa and Igbo"},{id:"english_hausa_yoruba",name:"English, Hausa and Yoruba"},
          {id:"english_igbo_yoruba",name:"English, Igbo and Yoruba"},{id:"yoruba_hausa_igbo",name:"Yoruba, Hausa and Igbo"},
          {id:"english_hausa_igbo_yoruba",name:"English, Hausa, Igbo and Yoruba"}]} value={getLanguage} onSelectValue={(e) => setLanguage(JSON.parse(e).name)}/>}
          selectLabel={LangFile.location?LangFile.location.language:''}
          label={getLanguage!=null?getLanguage:LangFile.location?LangFile.location.language:''}
          iconName2={'angle-down'}
          Options={1}
        /> */}
       
        {/* <View style={styles.bottomView} >
          <Label message={LangFile.login?LangFile.login.create_account:''} onPress={() => navigation.navigate('Register')} fontSize={20} />
          <Label message={LangFile.login?LangFile.login.forgot_password:''} onPress={() => navigation.navigate('ForgetPassword')} fontSize={20} />
        </View> */}
        {/* <Label fonts={12} color="#c5e6a4" onPress={OpenHref} message={LangFile.welcome?LangFile.welcome.project+"National Institute of Soil Science Nigeria (NISS)":''}/> */}
       {Platform.OS==="ios"||Platform.OS==="android"&&<KeyboardSpacer topSpacing={20} />}
        <Button
        buttonText={LangFile.permission?LangFile.permission.complete:''}
        onPress={Permission}
        isLoading={isLoading}
        message={LangFile.login?LangFile.login.moment:''}
      />
      
      </ScrollView>
      <Dialogue
        modalVisible={isDialogueVisible}
        close={LangFile.login?LangFile.login.close:''}
        onClosePress={(e) => onCloseDialoguePress()}
        message={messageDialogue}
      />
      <PickLang modalView={getLangModal} 
       close={LangFile.login?LangFile.login.close:''}
      render={ <RadioButton resData={resData} value={getLang} onSelectValue={onSelectValue}/>}
      onClosePress={onClosePress}/>       
        </View>
        </ImageBackground>
        </View>
    )
}

import { StyleSheet,Dimensions } from 'react-native';
const {width,height}=Dimensions.get('screen');
export default StyleSheet.create({
    parent:{
        width: width,
        height: height,
    },
    child: {
      display:'flex',
      flexDirection:'column',
        flex: 1,
        backgroundColor: 'rgba(255,255,255,0.7)'
      },
      dateFilter:{
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        justifyContent:'space-evenly',
        margin:0
      },
      buttonFilter:{
        padding: 5,
        marginBottom: 5,
       
      },
      submitbuttonFilter:{
        padding: 5,
        marginBottom: 5,
        backgroundColor:'teal',
        marginLeft:-5
      },
      altText:{
        fontSize: 12,
      },
      tableContainer:{
        display:'flex',
        flexDirection:'row',
        // alignItems:'center',
        // justifyContent:'center',
        marginBottom:10,
        backgroundColor:'rgba(255,255,255,0.7)',
        padding:10,
        borderTopRightRadius:10,
        borderBottomRightRadius:10,
        borderBottomLeftRadius:10,
        marginBottom:40
    },
    Datatable:{
      marginBottom:50,
      width:width
    },
    Titletable:{
      fontSize:14,
      fontWeight:'bold',
    },
    senderTxt:{
        color:"black",
        fontSize:16,
        fontWeight:'bold',
        marginRight:20
    },

      container:{
    marginBottom:height*0.1
     },
     bottomView:{
        flexDirection:'row',
        justifyContent:'center',
        margin:20
     },
     flatContainer:{
        display:'flex',
        flexDirection: 'row',
        alignItems:'center',
        justifyContent:'space-between',
        backgroundColor:"#ffff",
        padding: 15,
        marginBottom: 10
      },
      fab: {
        position: 'absolute',
        margin: 16,
        right: 0,
        bottom: 0,
      },
});
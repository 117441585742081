import React from 'react';
import Icon from 'react-native-vector-icons/FontAwesome';
import { Text, View,Image,Dimensions,StyleSheet,TextInput,ActivityIndicator, TouchableOpacity } from 'react-native';

// import styles from '../Styles/login';
// import Icon from "react-native-vector-icons/Ionicons";
import Iconed from "react-native-vector-icons/MaterialCommunityIcons";
import {FontAwesomeSpin} from 'react-native-vector-icons';
const {width,height}=Dimensions.get('screen');

function TextInputed(props) {
    return (
      <>
      {props.label&&
      <Text style={{fontSize:15,fontWeight:'bold',marginLeft:22,marginBottom:5,color:"black"}}>{props.label}</Text>
      }
       <View style={[styles.container, props.style]}>
             {props.showIcon1&&
      <Icon name={props.iconName1} style={styles.iconStyle1}></Icon>
             }
             {!props.showIcon1&&
      <Iconed name={props.iconName1} style={styles.iconStyle1}></Iconed>
             }
      <TextInput 
      placeholder={props.placeholder} 
      onChangeText={text => props.onChangeText(text)}
      placeholderTextColor={props.placeholderColor}
      color={props.textColor}
      keyboardType = {props.keyboardType}
      secureTextEntry={props.Secure}
      multiline={props.multiline}
      numberOfLines={props.numberOfLines}
      defaultValue={props.defaultValue}
      editable={props.editable}
      style={[styles.inputStyle,props.inputStyle]}></TextInput>
      {props.showIcon2&&!props.loader&&
      <Icon name={props.iconName2} onPress={()=>props.onPress()} style={[styles.iconStyle,props.iconStyle2]}></Icon>
    }
     {props.loader&&
    <ActivityIndicator size={30} color="#000" />
     }
    </View>
    </>
    );
}
const styles = StyleSheet.create({
    container: {
      backgroundColor: "rgba(253,253,253,0.9)",
      marginLeft:width*0.06,
      marginRight:width*0.06,
      height:60,
      marginBottom:20,
      flexDirection: "row",
      alignItems: "center",
      shadowColor: "#000",
      shadowOffset: { width: 0, height: 1 },
      shadowRadius: 5,
      shadowOpacity: 5,
      elevation: 10,
      borderRadius: 4,
      borderColor: '#000',
    },
    iconStyle: {
      color: "#616161",
      fontSize: 24,
      paddingRight: 8
    },
    iconStyle1: {
      color: "#000",
      fontSize: 20,
      paddingLeft: 12
    },
    inputStyle: {
      // height: 50,
      backgroundColor: 'rgba(253,253,253,0.9)',
      color: "#000",
      marginLeft: 16,
      paddingRight: 5,
      fontSize: 16,
      alignSelf: "stretch",
      flex: 1,
      paddingTop: 14,
      paddingBottom: 8,
    }
  });

  export default TextInputed;



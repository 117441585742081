import moment from "moment";
import React from "react";
import {AsyncStorage,Platform} from "react-native";

const _deleteData = async (key) => {
  try {
    if(Platform.OS==="ios"||Platform.OS==="android"){
      await AsyncStorage.removeItem('@Agro9ja:'+key);
    }
    else{
      localStorage.removeItem('@Agro9ja:'+key);
    }
  } catch (error) {
    // Error saving data
  }
};

const _storeData = async (item,key) => {
    try {
      if(Platform.OS==="ios"||Platform.OS==="android"){
      await AsyncStorage.setItem(
        "@Agro9ja:"+key,
        JSON.stringify(item)
      );
      }
      else{
        localStorage.setItem(
          "@Agro9ja:"+key,
          JSON.stringify(item)
        );
      }
    } catch (error) {
      
      // Error saving data
    }
  };
  const _retrieveData = async (key) => {
    try {
      if(Platform.OS==="ios"||Platform.OS==="android"){
      const jsonValue = await AsyncStorage.getItem("@Agro9ja:"+key);
      return jsonValue != null ? JSON.parse(jsonValue) : null;
      }
      else{
        const jsonValue=localStorage.getItem("@Agro9ja:"+key);
        return jsonValue != null ? JSON.parse(jsonValue) : null;
      }
          } catch (error) {
      return false;
      }
  };

  const _retrieveToken = async () => {
    try {
      if(Platform.OS==="ios"||Platform.OS==="android"){
      const jsonValue = await AsyncStorage.getItem("@Agro9ja:token");
      return jsonValue != null ? JSON.parse(jsonValue) : null;
      }
      else{
        const jsonValue=localStorage.getItem("@Agro9ja:token");
        return jsonValue != null ? JSON.parse(jsonValue) : null;
      }
    } catch (error) {
      return null;
    }
  };

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const addComma=(num)=>{
    return '₦'+num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const addCommaN=(num)=>{
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

async function getDataDateDiff(array,start,end){
  start = new Date(start);
   end= new Date(end);var newArray=[];
  for (let i = 0; i < array.length; i++) {
   var check=new Date(array[i].date);
   if (check.getTime() <= end.getTime()&& check.getTime() >= start.getTime()) {
      newArray.push(array[i]);
    }
  }
  return newArray;
}

async function uniqueArray(array,object){
  const result = [];
  const map = new Map();
  for (const item of array) {
      if(!map.has(item[object])){
          map.set(item[object], true);    // set any value to Map
          result.push({
              name: item[object]
          });
      }
    }
    return result;
}

async function sumAllValue(array,summer){
  var result = 0;
  for (const item of array) {
        if(!isNaN(item[summer])){
          result+=parseFloat(item[summer]);
        }
    }
    return result;
}

async function sumValue(array,object,finder,summer){
  var result = 0;
  for (const item of array) {
      if(item[object]===finder){
        if(!isNaN(item[summer])){
          result+=parseFloat(item[summer]);
        }
      }
    }
    return result;
}

async function sumValueDay(array,object,summer){
  var dataResult=[];
  for (let i = 0; i < 7; i++) {
    var result = 0;
      for (let m = 0; m < array.length; m++) {
        if(moment(array[m][object]).isSame(moment().subtract(i,'days'), 'days')){
        if(!isNaN(array[m][summer])){
          result+=parseFloat(array[m][summer]);
        }
        else{
          result=0; 
       }
      }   
    }
         
      dataResult.push(result)  
  }
    return dataResult;
}
async function sumValueMonth(array,object,summer){
  var dataResult=[];
  for (let i = 0; i < 12; i++) {
    var result = 0;
      for (let m = 0; m < array.length; m++) {
        if(moment(array[m][object]).isSame(moment().subtract(i,'months'), 'months')){
        if(!isNaN(array[m][summer])){
          result+=parseFloat(array[m][summer]);
        }
        else{
          result=0; 
       }
      }   
    }
      dataResult.push(result)  
  }
    return dataResult;
}

const updateStateArray=async(oldArray,newArray,setArray)=>{
  let data=oldArray;
  newArray.map((user)=>{
    let find=oldArray.findIndex(emp => emp.id === user.id);
    if(find==-1){
      data.push(user);
    }
  })
  setArray(data);
}


function statusMessage(ToastAndroid,message){
  ToastAndroid.showWithGravityAndOffset(
    message,
    ToastAndroid.LONG,
    ToastAndroid.BOTTOM,
    25,
    50
  );
}

function reversed(array){
  return array.map((item,idx) => array[array.length-1-idx])
}

  export {
      _storeData,
      _retrieveData,
      _retrieveToken,
      useQuery,
      addComma,
      statusMessage,
      _deleteData,
      getDataDateDiff,
      addCommaN,
      uniqueArray,
      sumValue,
      sumAllValue,
      sumValueDay,
      sumValueMonth,
      updateStateArray,
      reversed
  }
import React from 'react';
import Icon from 'react-native-vector-icons/FontAwesome';
import { Text, View,Image,StyleSheet,ActivityIndicator, TouchableOpacity } from 'react-native';

export default function DescriptionText(props) {
    return (
        <View style={{alignItems:'center',justifyContent:'center',padding:20}}>
      <Text style={{color: "#006400",
        fontSize: 24,
        fontWeight:'bold'}}
        onPress={() => props.onPress()}
        >{props.message}</Text>
      </View>
    );
}






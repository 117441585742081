import React from 'react';
import Icon from 'react-native-vector-icons/FontAwesome';
import { Text, View,Image,StyleSheet,ActivityIndicator, TouchableOpacity } from 'react-native';

export default function Loader(props) {
    return (
        <View>
      {props.isLoading&&
      <View style={{flexDirection:'row',alignItems:'center',justifyContent:'center'}}>
      <ActivityIndicator size="large" color={styles.btnText} animating={props.isLoading}/>
      <Text style={styles.btnText}>{props.message}</Text>
      </View>
        }
      </View>
    );
}
const styles=StyleSheet.create({
    button1: {
      display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center',
        borderColor: "#000",
        alignItems: "center",
        padding: 15,
        marginBottom: 20,
        backgroundColor:'rgba(255,255,255,0.7)',
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10,
        borderBottomLeftRadius: 10,
        marginLeft: 20,
        marginRight: 20,
        borderWidth: 2
      },
      btnText: {
        color: "#000",
        fontSize: 20,
        fontWeight:'bold',
      }, 
      iconStyle1: {
        color: "#000",
        fontSize: 20,
        paddingLeft: 12
      },
})






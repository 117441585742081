import React, { useEffect, useState } from 'react';
import { ImageBackground, SafeAreaView, View,Dimensions, ScrollView, Text, Image, PermissionsAndroid,Platform } from 'react-native';
import StateDetailStyle from "./statedetailstyle";
import {igbo,english,hausa,yoruba} from '../../../Lang';
import BackGroundImage from "../../../assets/farm.jpeg"
import { Button, Dialogue, GoBack, ProductImage, RadioSelect, Scientist, SelectInputed, TabDetail, TextInputed } from '../../../Components';
import { disableBackButton, pickLang, userBusiness, userProfile } from '../../../Auth';
import { FlatList, TouchableOpacity } from 'react-native-gesture-handler';
import Icon from 'react-native-vector-icons/FontAwesome';

import {NigeriaSvg} from "../../../map/Nigeriasvg";
import { baseUrl } from '../../../env';
import axios from "axios";
import { updateStateArray, _retrieveData } from '../../../Service/function';
import MenuImage from '../../../Components/MenuImage';
import stateLGA from '../../../State/state.json';
import WebView from 'react-native-webview';
import Geolocation from 'react-native-geolocation-service';
if(Platform.OS==="ios"||Platform.OS==="android"){
  const KeyboardSpacer=require('react-native-keyboard-spacer');
  const SvgXml=require('react-native-svg');
}
const isSmallDevice = Dimensions.get('window').width < 768
const {width,height}=Dimensions.get('screen');
export default function StateDetailScreen({navigation,route}) {
    const [getState, setState] = useState(null);
    const [getUser, setUser] = useState({});
    const [getAddress, setAddress] = useState(null);
    const [getJsonLGA, setJsonLGA] = useState([]);
    const [LangFile,setLangFile] = useState(english);
    const [isLoading,setLoading]=useState(false)
    const [indexSel , setIndexSel]=useState(1);
    const [about,setAbout]=useState([]);
    const [scientist,setScientist]=useState([]);
    const [getLang,setLang]=useState(0);
    const [start,setStart]=useState(0);
    const [page,setPage]=useState(10);
    const [latitude,setLatitude]=useState(null);
    const [longitude,setLongitude]=useState(null);
    const [fields, setFields] = React.useState({});
    const [messageDialogue, setmessageDialogue] = useState("Hello");
    const [isDialogueVisible, setisDialogueVisible] = useState(false);
    useEffect(() => {
        pickLang(setLangFile,english,hausa,igbo,yoruba);
        // generateGreetings(LangFile,setGreetings);
        disableBackButton();
      },[]);
const selectedTab=(ine)=>{
    setIndexSel(ine);
}

useEffect(() => {
    RetLang();
    userBusiness(setUser);
    LoadAboutDetails()
}, [])

useEffect(() => {
LoadPScientistDetails();
}, []);

const pickCoordinate=async()=>{
    const hasLocationPermission= await PermissionsAndroid.request(
      PermissionsAndroid.PERMISSIONS.ACCESS_COARSE_LOCATION,
      {
        title: "Pick your current location",
        message:
          " Give the app permission to pick " +
          "your current device location.",
        buttonNeutral: "Ask Me Later",
        buttonNegative: "Cancel",
        buttonPositive: "OK"
      }
    );
    if (hasLocationPermission&&Platform.OS==="android") {
      Geolocation.getCurrentPosition(
          (position) => {
            setLatitude(position.coords.latitude);
            setLongitude(position.coords.longitude);
          },
          (error) => {
            // See error code charts below.
            console.log(error.code, error.message);
          },
          { enableHighAccuracy: true, timeout: 15000, maximumAge: 10000 }
      );
    }
    else if(Platform.OS==="ios"){
      Geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (error) => {
          // See error code charts below.
          console.log(error.code, error.message);
        },
        { enableHighAccuracy: true, timeout: 15000, maximumAge: 10000 }
    );
    }
    else{
      navigator.geolocation.getCurrentPosition(async function(position) {
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
      })
    }
    
   }

const RetLang=async()=>{
    const lang=await _retrieveData('lang');
    setLang(lang?lang:0);
}

const LoadAboutDetails=async()=>{
    const token = await _retrieveData("token");
    try {
        const resp=await axios.get(baseUrl+"soil-inspections?state.name="+route.params.datar.name,{
            headers: {
               Authorization: `Bearer ${token}`
            }
        });
        // if(resp.statusCode){
        //     setLoading(false);
        //   setisDialogueVisible(true);
        //   setmessageDialogue(LangFile.error_message?LangFile.error_message.general_error:"");
        //   return false;
        //   }
          setAbout(resp.data[0]);
          setLoading(false);
       await  _storeData(resp.data,route.params.datar.name.split(" ")[0]+"about");
    } catch (error) {
        setLoading(false);
        // setisDialogueVisible(true);
        // setmessageDialogue(error.message);
    }
}

const LoadPScientistDetails=async()=>{
    const token = await _retrieveData("token");
    try {
        setStart(0);
        const resp=await axios.get(baseUrl+`businesses?business_type=rssmember&business_state=${route.params.datar.name}&confirm=true&_limit=${page}`,{
            headers: {
               Authorization: `Bearer ${token}`
            }
        });
          setScientist(resp.data);
          setLoading(false);
       await  _storeData(resp.data,route.params.datar.name.split(" ")[0]+"about");
    } catch (error) {
        setLoading(false);
        // setisDialogueVisible(true);
        // setmessageDialogue(error.message);
    }
}

const retrieveScientist=async()=>{
    const token = await _retrieveData("token");
    
    try {
        setLoading(true);
        // console.log(start);
        const resp=await axios.get(baseUrl+`businesses?business_state_contains=${route.params.datar.name}&confirm=true&_limit=${page}&_start=${start}`,{
            headers: {
               Authorization: `Bearer ${token}`
            }
        });
       await updateStateArray(scientist,resp.data,setScientist);
       if(resp.data.length>0){setStart(start+page);}
        setLoading(false)
    } catch (error) {
        setLoading(false);
        // errorMessage(toast)
    }
}

const handleChangeInput=(e)=>{
    const value= e.value;
    setFields({...fields, [e.name]:value});
  }

  const changeState=(e)=>{
    setState(JSON.parse(e).name);
    setJsonLGA(JSON.parse(e).lgas);
    // console.log(e);
}

const submitContent=async()=>{
    const token = await _retrieveData("token");
    if(longitude&&latitude){
        fields['longitude']=longitude;
    fields['latitude']=latitude;
    }
    else if(fields.coordinate){
        fields['longitude']=fields.coordinate.split(",")[0];
        fields['latitude']=fields.coordinate.split(",")[1];
    }
    fields['state']=route.params.datar.name;
    if(!fields['state']||!fields['lga']||!fields['longitude']||!fields['latitude']||!fields['P']||!fields['K']||!fields['PH']){
        setisDialogueVisible(true);
        setmessageDialogue("Fill all required fields");
        return false;
    }
    let newArray=new Array([],[
        fields['state'],fields['lga'],fields['longitude'],fields['latitude'],fields['location'],fields['P'],
        fields['K'],fields['PH'],fields['S'],fields['N']
    ]);
    var formData = {
        json:JSON.stringify(newArray)
      }
    setLoading(true);
    try {
      const resp=await axios.post(baseUrl+"soil-mappings/",formData,{
        headers: {
           Authorization: `Bearer ${token}`
        }
    });
    setLoading(false);
    if(resp.data.status==="error"){
        setisDialogueVisible(true);
        setmessageDialogue(resp.data.message);
        return false;
    }
    setisDialogueVisible(true);
    setmessageDialogue(resp.data.message);
    setFields({});
} catch (error) {
    setLoading(false);
    setisDialogueVisible(true);
    setmessageDialogue(error.message);
}
}

const renderItem = ({ item,index }) => (
    <Scientist bizshow={1} onPress={()=>navigation.navigate('StoreDetail', { datars: item,lang:LangFile })} image={item.user.avatar?item.user.avatar.url:null} icontitle={item.name} title={item.name} subtitle={item.live_lga} /> 
    );
    const renderItemScientist = ({ item,index }) => (
        <Scientist bizshow={1} onPress={()=>navigation.navigate('StoreDetail', { datars: item,lang:LangFile })} image={item.user.avatar?item.user.avatar.url:null} icontitle={item.name} title={item.name} subtitle={item.live_lga} /> 
          // <MenuImage onPress={()=>navigation.navigate('Detail', { datars: item,lang:getLang })} key={index} imageUrl={item.image?baseUrl+item.image.url:''} colorIcon={'#006400'} buttonText={item.name} /> 
          );
    return (
      <View style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
        <ImageBackground source={BackGroundImage} style={StateDetailStyle.parent}>
            <View style={StateDetailStyle.child}>
        <GoBack onPress={() => navigation.goBack()} buttonText={`${LangFile.menu?route.params.datar.name+" "+LangFile.menu.menu6:route.params.datar.name+" Soil Inspection"}` } />
        <SafeAreaView  style={[StateDetailStyle.parent,{marginTop: 0,backgroundColor: 'rgba(255,255,255,0.7)' }]}>
        <TabDetail  onPress={selectedTab} text1={LangFile.tabinfo?LangFile.tabinfo.soilhistory:"Soil History"} text2={LangFile.tabinfo?LangFile.tabinfo.plantation:'Plantation'} text3={LangFile.tabinfo?LangFile.tabinfo.moreinfo:'Soil Scientist'} text4={getUser.confirm&&getUser.business_type==="rssmember"?LangFile.tabinfo?LangFile.tabinfo.soilresearch:'Soil Research':getUser.confirm&&getUser.business_type==="agronomist"?LangFile.tabinfo?LangFile.tabinfo.soilresearch:'Soil Research':null}  indexSelect={indexSel} />
     <ScrollView style={{ flex: 1}}>
      {/* <View style={StateDetailStyle.containertop}>
      <Text style={StateDetailStyle.senderTxt}>{LangFile.inspection.inspectallstate}</Text>
      </View> */}
      {/* <Text>{route.params.datar.svg}</Text> */}
      {indexSel==1&&
      <View>
      {Platform.OS==="ios"||Platform.OS==="android"&&<SvgXml width="100%" height="300" xml={NigeriaSvg(route.params.datar.name)} />}
      {getLang==0&&
       <SafeAreaView style={{ flex: 1 ,width:width,height:height/1.35}}>
       <WebView
       scalesPageToFit={false}
       originWhitelist={['*']}
       source={{ html: `${about?.about}` }}
     />
     {Platform.OS==="web"&&
     <View horizontal style={{width:isSmallDevice?width:width/2.5,padding:20}} contentContainerStyle={{padding:20}}>
     <div style={{overflow:'auto'}} dangerouslySetInnerHTML={{ __html: about?.about }} />
     </View>
     }
     </SafeAreaView>
 
          }
          {getLang==1&&
   <SafeAreaView style={{ flex: 1 ,width:width,height:height/1.35}}>
   <WebView
   scalesPageToFit={false}
   originWhitelist={['*']}
   source={{ html: `${about?.aboutHausa}` }}
 />
 {Platform.OS==="web"&&
     <View horizontal style={{width:isSmallDevice?width:width/2.5,padding:20}} contentContainerStyle={{padding:20}}>
     <div style={{overflow:'auto'}} dangerouslySetInnerHTML={{ __html: about?.aboutHausa }} />
     </View>
     }
 </SafeAreaView>
          }
           {getLang==2&&
   <SafeAreaView style={{ flex: 1 ,width:width,height:height/1.35}}>
   <WebView
   scalesPageToFit={false}
   originWhitelist={['*']}
   source={{ html: `${about?.aboutIgbo}` }}
 />
 {Platform.OS==="web"&&
     <View horizontal style={{width:isSmallDevice?width:width/2.5,padding:20}} contentContainerStyle={{padding:20}}>
     <div style={{overflow:'auto'}} dangerouslySetInnerHTML={{ __html: about?.aboutIgbo }} />
     </View>
     }
 </SafeAreaView>
          }
          {getLang==3&&
    <SafeAreaView style={{ flex: 1 ,width:width,height:height/1.35}}>
    <WebView
    scalesPageToFit={false}
    originWhitelist={['*']}
    source={{ html: `${about?.aboutYoruba}` }}
  />
  {Platform.OS==="web"&&
     <View horizontal style={{width:isSmallDevice?width:width/2.5,padding:20}} contentContainerStyle={{padding:20}}>
     <div style={{overflow:'auto'}} dangerouslySetInnerHTML={{ __html: about?.about }} />
     </View>
     }
  </SafeAreaView>
          }
      </View>
}
{indexSel==2&&
      <View>
            <FlatList
        // contentContainerStyle={{alignSelf: 'flex-start'}}
        // columnWrapperStyle={{alignItems: 'space-between'}}
        numColumns={3}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        data={about?.crop_libraries}
        renderItem={renderItem}
        keyExtractor={item => item.id} 
        /> 
         {getLang==0&&
   <SafeAreaView style={{ flex: 1 ,width:width,height:height/1.35}}>
   <WebView
   scalesPageToFit={false}
   originWhitelist={['*']}
   source={{ html: `${about?.plantation}` }}
 />
 {Platform.OS==="web"&&
     <View horizontal style={{width:isSmallDevice?width:width/2.5,padding:20}} contentContainerStyle={{padding:20}}>
     <div style={{overflow:'auto'}} dangerouslySetInnerHTML={{ __html: about?.plantation }} />
     </View>
     }
 </SafeAreaView>
          }
          {getLang==1&&
   <SafeAreaView style={{ flex: 1 ,width:width,height:height/1.35}}>
   <WebView
   scalesPageToFit={false}
   originWhitelist={['*']}
   source={{ html: `${about?.plantationHausa}` }}
 />
 {Platform.OS==="web"&&
     <View horizontal style={{width:isSmallDevice?width:width/2.5,padding:20}} contentContainerStyle={{padding:20}}>
     <div style={{overflow:'auto'}} dangerouslySetInnerHTML={{ __html: about?.plantationHausa }} />
     </View>
     }
 </SafeAreaView>
          }
           {getLang==2&&
   <SafeAreaView style={{ flex: 1 ,width:width,height:height/1.35}}>
   <WebView
   scalesPageToFit={false}
   originWhitelist={['*']}
   source={{ html: `${about?.plantationIgbo}` }}
 />
 {Platform.OS==="web"&&
     <View horizontal style={{width:isSmallDevice?width:width/2.5,padding:20}} contentContainerStyle={{padding:20}}>
     <div style={{overflow:'auto'}} dangerouslySetInnerHTML={{ __html: about?.plantationIgbo }} />
     </View>
     }
 </SafeAreaView>
          }
          {getLang==3&&
   <SafeAreaView style={{ flex: 1 ,width:width,height:height/1.35}}>
   <WebView
   scalesPageToFit={false}
   originWhitelist={['*']}
   source={{ html: `${about?.plantationYoruba}` }}
 />
 {Platform.OS==="web"&&
     <View horizontal style={{width:isSmallDevice?width:width/2.5,padding:20}} contentContainerStyle={{padding:20}}>
     <div style={{overflow:'auto'}} dangerouslySetInnerHTML={{ __html: about?.plantationYoruba }} />
     </View>
     }
 </SafeAreaView>
          } 
      </View>
}
{indexSel==3&&
      <View>
           <View style={StateDetailStyle.containertop}>
      <Text style={StateDetailStyle.senderTxt}>{LangFile.inspection.soilscientisttab}</Text>
      </View>
     <FlatList
        numColumns={3}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        data={scientist}
        renderItem={renderItemScientist}
        keyExtractor={item => item.id} 
        /> 
          <Button isLoading={isLoading} altStyle={{padding:5}} onPress={()=>retrieveScientist()} altText={{fontSize:14}} buttonText={LangFile.naming?.loadmore} />
      </View>
}
{indexSel==4&&
     <View>
     <View style={StateDetailStyle.containertop}>
<Text style={StateDetailStyle.senderTxt}>{LangFile.inspection.soilresearchproject}</Text>
</View>
<ScrollView style={StateDetailStyle.container}>

        <SelectInputed
          showIcon1={true}
          showIcon2={true}
          iconName1={'globe'}
          render={<RadioSelect resData={route.params.datar.lgas} value={fields.lga} onSelectValue={(e) => handleChangeInput({value:JSON.parse(e).name,name:"lga"})}/>}
          selectLabel={'Choose research LGA'}
          label={fields.lga!=null?fields.lga:'Choose research LGA'}
          iconName2={'angle-down'}
          Options={1}
        />
  <TextInputed
          showIcon1={true}
          showIcon2={false}
          iconName1={'plus'}
          label={"Enter or Pick Location Coordinate"}
          defaultValue={longitude?`${longitude.toFixed(7)} , ${latitude.toFixed(7)}`:null}
          placeholder={`longitude , Latitude`}
          onChangeText={(e) => handleChangeInput({value:e,name:"coordinate"})}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          keyboardType={'numeric'}
        />   
<Button
        buttonText={'Pick Location Coordinate'}
        altStyle={{padding:5}}
        altText={{fontSize:14}}
        onPress={pickCoordinate}
        isLoading={isLoading}
        message={LangFile.login?LangFile.login.moment:''}
      />
      <TextInputed
          showIcon1={true}
          showIcon2={false}
          label={"Enter Landmark or Address"}
          iconName1={'plus'}
          placeholder={'Location'}
          defaultValue={fields?.location}
          onChangeText={(e) => handleChangeInput({value:e,name:"location"})}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
        />
        <View style={StateDetailStyle.containertop}>
<Text style={StateDetailStyle.senderTxt}>{"Add your soil data information below"}</Text>
</View>
      <TextInputed
          showIcon1={true}
          showIcon2={false}
          iconName1={'plus'}
          label={"Phosphorus:"}
          defaultValue={fields?.P??""}
          placeholder={"P"}
          onChangeText={(e) => handleChangeInput({value:e,name:"P"})}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          keyboardType={'numeric'}
        />
        <TextInputed
          showIcon1={true}
          showIcon2={false}
          iconName1={'plus'}
          label="Pottasium: "
          // iconName2={'eye'}
          placeholder={"K"}
          defaultValue={fields?.K??""}
          onChangeText={(e) => handleChangeInput({value:e,name:"K"})}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          keyboardType={'numeric'}
        />
        <TextInputed
          showIcon1={true}
          showIcon2={false}
          label="Soil PH"
          iconName1={'plus'}
          placeholder={'PH'}
          defaultValue={fields?.PH??""}
          onChangeText={(e) => handleChangeInput({value:e,name:"PH"})}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
        keyboardType={'numeric'}
        />
        <TextInputed
          showIcon1={true}
          showIcon2={false}
          label="Sulphur:"
          iconName1={'plus'}
          placeholder={'S'}
          defaultValue={fields?.S??""}
          onChangeText={(e) => handleChangeInput({value:e,name:"S"})}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
        keyboardType={'numeric'}
        />
        <TextInputed
          showIcon1={true}
          showIcon2={false}
          label="Nitrogen:"
          iconName1={'plus'}
          defaultValue={fields?.N??""}
          placeholder={'N'}
          onChangeText={(e) => handleChangeInput({value:e,name:"N"})}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
        keyboardType={'numeric'}
        />
         <Button
        buttonText={'Submit'}
        onPress={submitContent}
        isLoading={isLoading}
        message={LangFile.login?LangFile.login.moment:''}
      />
      {Platform.OS==="ios"||Platform.OS==="android"&&<KeyboardSpacer topSpacing={20} />}
      </ScrollView>
</View>
}
  </ScrollView>
  </SafeAreaView>
            </View>
            <Dialogue
        modalVisible={isDialogueVisible}
        close={LangFile.login?LangFile.login.close:''}
        onClosePress={(e) =>  setisDialogueVisible(false)}
        message={messageDialogue}
      />
     
        </ImageBackground>
        </View>
    )
}


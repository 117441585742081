import React, { useContext, useEffect, useState } from 'react'
import FarmRecordStyle from "./farmrecordstyle";
import { ImageBackground,Dimensions, Linking, ScrollView, StatusBar, Text, View } from 'react-native';
import BackGroundImage from "../../assets/farm.jpeg"
import {Logo,Label, SpaceBottom, Button, Lang, RadioButton, TextInputed, Dialogue, LabelSmall, LangText, Avatar, MenuButton, GoBack} from '../../Components';
import {igbo,english,hausa,yoruba} from '../../Lang';
import PickLang from '../../Modals/Language/PickLang';
import { _retrieveData, _storeData } from '../../Service/function';
import { checkProfile, disableBackButton, generateGreetings, pickLang } from '../../Auth';
import { AuthContext } from '../../context/authContext/AuthContext';
import { baseUrl } from '../../env';
import Weather from '../components/weather/Weather';
import Setting from '../../Modals/Setting/Setting';
const {width,height}=Dimensions.get('screen');

export default function FarmRecordScreen({navigation,route}) {
    const [isSecure, setSecure] = useState(true);
    const [getLangModal,setLangModal] = useState(false);
    const [modalSetting,setModalSetting] = useState(false);
    const [getLang,setLang] = useState(0);
    const [LangFile,setLangFile] = useState(english);
    const [getUser,setUser]=useState({});
    const [getGreetings,setGreetings]=useState(null);
    //Context
    const {user,isFetching,dispatch}=useContext(AuthContext);

    const resData=[
        {
          key:0,
          text:'English'
      },
      {
          key:1,
          text:'Hausa'
      },
      {
          key:2,
          text:'Igbo'
      },
      {
          key:3,
          text:'Yoruba'
      }
    ];
    useEffect(() => {
        pickLang(setLangFile,english,hausa,igbo,yoruba);
        generateGreetings(LangFile,setGreetings);
       
      },[]);

      useEffect(()=>{
        disableBackButton()
      },[])

      useEffect(() => {
        // checkProfile(navigation,setUser); 
      },[]);

    const OpenHref= async ()=>{
        await Linking.openURL('https://niss.gov.ng');
      }

      const onClosePress=()=>{
        // pickLang(setLangFile,english,hausa,igbo,yoruba);
        setLangModal(false);
       }

      const showLangModal=()=>{
        setLangModal(true);
       }

    const onSelectValue=async(val)=>{
        setLangFile(val==0?english:val==1?hausa:val==2?igbo:yoruba)
        setLang(val);
       await _storeData(val,'lang')
        setLangModal(false); 
    }

    const Logout=async()=>{
       await _storeData(null,"token");
       await _storeData(null,"user");
        navigation.navigate('Login');
      
      }
    return (
        <ImageBackground source={BackGroundImage} style={FarmRecordStyle.parent}>
        <View style={FarmRecordStyle.child}>
        <GoBack onPress={() => navigation.goBack()} buttonText={LangFile.menu.menu7} />
    
      <ScrollView style={FarmRecordStyle.container} persistentScrollbar={true} contentContainerStyle={{paddingRight:10}}>
     <View style={{flexDirection:'column'}}>
     <View style={{flexDirection:'row'}}>
         <Label color="#0d503a" message={LangFile.home?LangFile.home.quick_menu:"Quick Menu"} fonts={16} />
     </View>
         <View style={{flexDirection:'row',justifyContent:'space-between'}}>
        <MenuButton onPress={()=>navigation.navigate('SaleList')} styles={{width:width*0.4,height:width*0.4}} iconStyle={{fontSize:width*0.12,padding:width*0.05}} btnText={{}}  iconName={'shopping-bag'} colorIcon={'#0d503a'} buttonText={LangFile.servicemenu?LangFile.servicemenu.salesrecord:''} />
        <MenuButton onPress={()=>navigation.navigate("ExpenseList")} styles={{width:width*0.4,height:width*0.4}} iconStyle={{fontSize:width*0.12,padding:width*0.05}} btnText={{}}  iconName={'wpforms'} colorIcon={'#0d503a'} buttonText={LangFile.servicemenu?LangFile.servicemenu.expenditurerecord:''} />
         </View>
         <View style={{flexDirection:'row',justifyContent:'space-between'}}>
         <MenuButton onPress={()=>navigation.navigate("InputList")} styles={{width:width*0.4,height:width*0.4}} iconStyle={{fontSize:width*0.12,padding:width*0.05}} btnText={{}}  iconName={'list-alt'} colorIcon={'#0d503a'} buttonText={LangFile.servicemenu?LangFile.servicemenu.inputrecord:''} />
        <MenuButton onPress={()=>navigation.navigate("ProductionList")} styles={{width:width*0.4,height:width*0.4}} iconStyle={{fontSize:width*0.12,padding:width*0.05}} btnText={{}}  iconName={'table'} colorIcon={'#0d503a'} buttonText={LangFile.servicemenu?LangFile.servicemenu.productionrecord:''} />
         </View>
         <View style={{flexDirection:'row',justifyContent:'space-between'}}>
         <MenuButton onPress={()=>navigation.navigate("IncomeList")} styles={{width:width*0.4,height:width*0.4}} iconStyle={{fontSize:width*0.12,padding:width*0.05}} btnText={{}}  iconName={'file-text-o'} colorIcon={'#0d503a'} buttonText={LangFile.servicemenu?LangFile.servicemenu.farmincome:''} />
        <MenuButton onPress={()=>navigation.navigate("StockList")} styles={{width:width*0.4,height:width*0.4}} iconStyle={{fontSize:width*0.12,padding:width*0.05}} btnText={{}}  iconName={'th-list'} colorIcon={'#0d503a'} buttonText={LangFile.servicemenu?LangFile.servicemenu.stockrecord:''} />
         </View>
         <View style={{flexDirection:'row',justifyContent:'space-between'}}>
         <MenuButton  onPress={()=>navigation.navigate("Summary")} styles={{width:width*0.4,height:width*0.4}} iconStyle={{fontSize:width*0.12,padding:width*0.05}} btnText={{}}  iconName={'book'} colorIcon={'#0d503a'} buttonText={LangFile.servicemenu?LangFile.servicemenu.recordsummary:''} />
        <MenuButton onPress={()=>navigation.navigate("Reports")} styles={{width:width*0.4,height:width*0.4}} iconStyle={{fontSize:width*0.12,padding:width*0.05}} btnText={{}}  iconName={'bar-chart-o'} colorIcon={'#0d503a'} buttonText={LangFile.servicemenu?LangFile.servicemenu.reports:''} />
     </View>
     </View>
      </ScrollView>
    
      <PickLang modalView={getLangModal} 
      close={LangFile.login?LangFile.login.close:""}
      render={ <RadioButton resData={resData} value={getLang} onSelectValue={onSelectValue}/>}
      onClosePress={onClosePress}/>
      {/* <Setting modalView={modalSetting} title={"Settings"}
      render={
          <Text>bchbdbcvdb</Text>
      }
      onClosePress={()=>setModalSetting(false)}
      /> */}
</View>
</ImageBackground>
    )
}

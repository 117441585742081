import { StyleSheet,Dimensions } from 'react-native';
const {width,height}=Dimensions.get('screen');
export default StyleSheet.create({
    parent:{
        width: width,
        height: height,
    },
    child: {
      display:'flex',
      flexDirection:'column',
        flex: 1,
        backgroundColor: 'rgba(255,255,255,0.7)'
      },
      buttonFilter:{
        padding: 15,
        marginBottom: 5,
        borderWidth:0
      },
      altText:{
        fontSize: 14,
      },
      scrollView:{
          marginBottom:height*0.11
      }
});
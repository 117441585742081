import React, { useEffect, useState } from 'react';
import { ImageBackground, SafeAreaView, View,Dimensions, ScrollView, Text } from 'react-native';
import StateListStyle from "./publicationliststyle";
import {igbo,english,hausa,yoruba} from '../../Lang';
import BackGroundImage from "../../assets/farm.jpeg"
import { GoBack } from '../../Components';
import { disableBackButton, pickLang } from '../../Auth';
import { FlatList, TouchableOpacity } from 'react-native-gesture-handler';
import stateLGA from '../../State/state.json';
import Icon from 'react-native-vector-icons/FontAwesome';
import axios from 'axios';
import { baseUrl } from '../../env';
import { _retrieveData } from '../../Service/function';
import moment from "moment";
import WebView from 'react-native-webview';

const {width,height}=Dimensions.get('screen');
export default function PublicationDetail({navigation,route}) {
    const [LangFile,setLangFile] = useState(english);
    const [notification,setNotification]=useState([]);
    useEffect(() => {
        pickLang(setLangFile,english,hausa,igbo,yoruba);
        // generateGreetings(LangFile,setGreetings);
        disableBackButton();
      },[]);

      useEffect(()=>{
        getNotification()
      },[])

      const getNotification=async()=>{
        const token = await _retrieveData("token");
        const UserD = await _retrieveData("userauth");
          let resp=await axios.get(baseUrl+`notifications?type_contains=all&type_contains=${UserD.user_type}&enddate_gte=${moment().format("YYYY-MM-DD")}`,{
            headers: {
               Authorization: `Bearer ${token}`
            }
        });
        setNotification(resp.data);
      }

      const renderItem = ({ item,index }) => (
     <TouchableOpacity onPress={()=>{item.url?navigation.navigate('Browser', { datars: item,lang:LangFile }):null}} key={index} style={StateListStyle.flatContainer}>
         <View style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
         <View style={{padding:10,borderRadius:50,backgroundColor:'black',marginRight:10}}>
          
            <Text style={{color:"white"}}>{item.name[0]}</Text>
         </View>
         <View style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
     <Text style={StateListStyle.senderTxti}>{item.subtitle}</Text>
     <Text style={StateListStyle.senderSubTxt}>{item.description}</Text>
     </View>
     </View>
     {item.url&&
     <Icon style={StateListStyle.unreadIcon} name={'dot-circle-o'} ></Icon>
    }
     </TouchableOpacity>
           );
    return (
      <View style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
        <ImageBackground source={BackGroundImage} style={StateListStyle.parent}>
            <View style={StateListStyle.child}>
        <GoBack onPress={() => navigation.navigate('Home')} buttonText={`${route.params.datars?.name}` } />
        <SafeAreaView style={{height:height*0.83,marginTop: 0,backgroundColor: 'rgba(255,255,255,0.7)' }}>
      <ScrollView style={{ flex: 1, margin: 10 }}>
      <View style={{flexDirection:'column'}}>
     <SafeAreaView style={{ flex: 1 ,width:width/1.07,height:height/1.35}}>
   <WebView
   scalesPageToFit={false}
   originWhitelist={['*']}
   source={{ html: `${route.params.datars.description}` }}
    />
 </SafeAreaView>
     </View>
  </ScrollView>
  </SafeAreaView>
            </View>
        </ImageBackground>
        </View>
    )
}

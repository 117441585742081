import React, { useContext, useEffect, useState } from 'react'
import LocationScreenStyle from './locationscreenstyle';
import { ImageBackground, Linking, ScrollView, StatusBar, View,Platform } from 'react-native';
import BackGroundImage from "../../../assets/farm.jpeg"
import {Logo,Label, SpaceBottom, Button, Lang, RadioButton, TextInputed, TabStep, SelectInputed, RadioSelect, Dialogue} from '../../../Components';
import {igbo,english,hausa,yoruba} from '../../../Lang';
import PickLang from '../../../Modals/Language/PickLang';
import { _retrieveData, _storeData } from '../../../Service/function';
import { checkProfile, disableBackButton, pickLang } from '../../../Auth';
import { AuthContext } from '../../../context/authContext/AuthContext';
import stateLGA from '../../../State/state.json';
import { baseUrl } from '../../../env';
import axios from "axios";
if(Platform.OS==="ios"||Platform.OS==="android"){
  const KeyboardSpacer = require('react-native-keyboard-spacer');
}

export default function LocationScreen({navigation,route}) {
    const [getState, setState] = useState(null);
    const [getLGA, setLGA] = useState(null);
    const [getAddress, setAddress] = useState(null);
    const [getJsonLGA, setJsonLGA] = useState([]);
    const [getLanguage, setLanguage] = useState(null);
    const [getGender, setGender] = useState("Male");
    const [isLoading, setLoading] = useState(false);
    const [isSecure, setSecure] = useState(true);
    const [secureIcon, setSecureIcon] = useState("eye");
    const [messageDialogue, setmessageDialogue] = useState("Hello");
    const [isDialogueVisible, setisDialogueVisible] = useState(false);
    const [getUser,setUser]=useState({});
  const [getLangModal,setLangModal] = useState(false);
  const [getLang,setLang] = useState(0);
  const [LangFile,setLangFile] = useState(english);
  const resData=[
    {
        key:0,
        text:'English'
    },
    {
        key:1,
        text:'Hausa'
    },
    {
        key:2,
        text:'Igbo'
    },
    {
        key:3,
        text:'Yoruba'
    }
];


useEffect(() => {
  setLoading(false);
    pickLang(setLangFile,english,hausa,igbo,yoruba);
  },[]);

  useEffect(() => {
    checkProfile(navigation,setUser); 
    disableBackButton();
  },[]);

const OpenHref= async ()=>{
    await Linking.openURL('https://niss.gov.ng');
  }

  const onClosePress=()=>{
    // pickLang(setLangFile,english,hausa,igbo,yoruba);
    setLangModal(false);
   }

  const showLangModal=()=>{
    setLangModal(true);
   }

    const onSelectValue=async(val)=>{
    setLangFile(val==0?english:val==1?hausa:val==2?igbo:yoruba)
    setLang(val);
    await _storeData(val,'lang')
    setLangModal(false); 
    }

    const changeState=(e)=>{
        setState(JSON.parse(e).name);
        setJsonLGA(JSON.parse(e).lgas);
        // console.log(e);
    }

    const Location =async () => {
        const token = await _retrieveData("token");
        if(!getGender||!getState||!getLGA||!getLanguage){
          setisDialogueVisible(true);
              setmessageDialogue(LangFile.register?LangFile.register.fill_fields:"");
              return false;
        }
        setLoading(true)
        try {
            const resp=await axios.post(baseUrl+'businesses',{
                gender:getGender,
                state:getState,
                lga:getLGA,
                address:getAddress,
                lang:getLanguage
              },{
                headers: {
                   Authorization: `Bearer ${token}`
                }
            });
            // if(resp.statusCode){
            //     setLoading(false);
            //   setisDialogueVisible(true);
            //   setmessageDialogue(LangFile.error_message?LangFile.error_message.general_error:"");
            //   return false;
            //   }
              await _storeData(resp.data.user,"userauth");
              setLoading(false);
              navigation.navigate('Permission');
            
        } catch (error) {
            setLoading(false);
            setisDialogueVisible(true);
            setmessageDialogue(error.message);
        }
    }

  const pressNull=()=>{
  return false;
}

const onCloseDialoguePress=()=>{
    setisDialogueVisible(false);
   }


    return (
      <View style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
        <ImageBackground source={BackGroundImage} style={LocationScreenStyle.parent}>
              <View style={LocationScreenStyle.child}>
              {getLang==0&&
   <Lang message="ENGLISH" onPress={()=>showLangModal()}/>
      }
     {getLang==1&&
   <Lang message="HAUSA" onPress={()=>showLangModal()}/>
      }
      {getLang==2&&
   <Lang message="IGBO" onPress={()=>showLangModal()}/>
      }
      {getLang==3&&
   <Lang message="YORUBA" onPress={()=>showLangModal()}/>
      }
     {/* <Logo /> */}
     <TabStep onPress={pressNull} text1={'Authorization'} text2={'Location'} text3={'Permission'} indexSelect={2} />
     <Label onPress={pressNull} message={LangFile.authorization?LangFile.authorization.welcome+getUser?getUser.name:''+LangFile.location.message:''} fonts={16} />
     
      <ScrollView style={LocationScreenStyle.container} persistentScrollbar={true} contentContainerStyle={{paddingRight:0}}>
      <SelectInputed
          showIcon1={true}
          title={LangFile.permission?LangFile.permission.gender+"(*)":''}
          showIcon2={true}
          iconName1={'group'}
          render={<RadioSelect resData={[{id:"Male",name:"Male"},{id:"Female",name:"Female"}]} value={getGender} onSelectValue={(e) => setGender(JSON.parse(e).id)}/>}
          selectLabel={LangFile.permission?LangFile.permission.gender:''}
          label={getGender!=null?getGender:LangFile.permission?LangFile.permission.gender:''}
          iconName2={'angle-down'}
          Options={0}
        />
        <SelectInputed
          showIcon1={true}
          showIcon2={true}
          title={LangFile.location?LangFile.location.state+"(*)":''}
          iconName1={'globe'}
          render={<RadioSelect resData={stateLGA} value={getState} onSelectValue={(e) => changeState(e)}/>}
          selectLabel={LangFile.location?LangFile.location.state:''}
          label={getState!=null?getState:LangFile.location?LangFile.location.state:''}
          iconName2={'angle-down'}
          Options={1}
        />
        <SelectInputed
          showIcon1={true}
          showIcon2={true}
          title={LangFile.location?LangFile.location.lga+"(*)":''}
          iconName1={'globe'}
          render={<RadioSelect resData={getJsonLGA} value={getLGA} onSelectValue={(e) => setLGA(JSON.parse(e).name)}/>}
          selectLabel={LangFile.location?LangFile.location.lga:''}
          label={getLGA!=null?getLGA:LangFile.location?LangFile.location.lga:''}
          iconName2={'angle-down'}
          Options={1}
        />
         <TextInputed
          showIcon1={true}
          showIcon2={false}
          iconName1={'map-marker'}
          // iconName2={'eye'}
          label={LangFile.location?LangFile.location.address:''}
          placeholder={LangFile.location?LangFile.location.address:''}
          onChangeText={(e) => setAddress(e)}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          Secure={false}
        />
        <SelectInputed
          showIcon1={true}
          showIcon2={true}
          iconName1={'language'}
          title={LangFile.location?LangFile.location.language+"(*)":''}
          render={
          <RadioSelect resData={[{id:"english",name:"English"},{id:"hausa",name:"Hausa"},
          {id:"igbo",name:"Igbo"},{id:"yoruba",name:"Yoruba"},{id:"english_hausa",name:"English and Hausa"},
          {id:"english_igbo",name:"English and Igbo"},{id:"english_yoruba",name:"English and Yoruba"},
          {id:"english_hausa_igbo",name:"English, Hausa and Igbo"},{id:"english_hausa_yoruba",name:"English, Hausa and Yoruba"},
          {id:"english_igbo_yoruba",name:"English, Igbo and Yoruba"},{id:"yoruba_hausa_igbo",name:"Yoruba, Hausa and Igbo"},
          {id:"english_hausa_igbo_yoruba",name:"English, Hausa, Igbo and Yoruba"}]} value={getLanguage} onSelectValue={(e) => setLanguage(JSON.parse(e).name)}/>}
          selectLabel={LangFile.location?LangFile.location.language:''}
          label={getLanguage!=null?getLanguage:LangFile.location?LangFile.location.language:''}
          iconName2={'angle-down'}
          Options={1}
        />
       
        {/* <View style={styles.bottomView} >
          <Label message={LangFile.login?LangFile.login.create_account:''} onPress={() => navigation.navigate('Register')} fontSize={20} />
          <Label message={LangFile.login?LangFile.login.forgot_password:''} onPress={() => navigation.navigate('ForgetPassword')} fontSize={20} />
        </View> */}
        {/* <Label fonts={12} color="#c5e6a4" onPress={OpenHref} message={LangFile.welcome?LangFile.welcome.project+"National Institute of Soil Science Nigeria (NISS)":''}/> */}
        <Button
        buttonText={LangFile.authorization?LangFile.authorization.next:''}
        onPress={Location}
        isLoading={isLoading}
        message={LangFile.login?LangFile.login.moment:''}
      />
     {Platform.OS==="ios"||Platform.OS==="android"&&<KeyboardSpacer topSpacing={20} />}
      </ScrollView>
      <Dialogue
        modalVisible={isDialogueVisible}
        close={LangFile.login?LangFile.login.close:''}
        onClosePress={(e) => onCloseDialoguePress()}
        message={messageDialogue}
      />
      <PickLang modalView={getLangModal} 
       close={LangFile.login?LangFile.login.close:''}
      render={ <RadioButton resData={resData} value={getLang} onSelectValue={onSelectValue}/>}
      onClosePress={onClosePress}/>       
        </View>
        </ImageBackground>
        </View>
    )
}

import React from 'react';
import Icon from 'react-native-vector-icons/FontAwesome';
import { Text, View,Image,StyleSheet,ActivityIndicator, TouchableOpacity } from 'react-native';


export default function Lang(props) {
    return (
        <TouchableOpacity style={{padding:40,alignItems:'center',flexDirection:'row',}}  onPress={(e) => props.onPress()}>
           <Image
        style={{width:40,height:40,resizeMode:'cover'}}
        source={require('../assets/logo.png')}
         />
      <Text style={{color: "#006400",
        fontSize: props.fonts,
        backgroundColor:'#dec',
        width:70,
        padding:0,
        borderRadius: 400/ 2,
        borderColor: "#dec",
        textAlign: 'center',
        fontWeight:'bold'}}
       
        >{props.message}</Text>
      </TouchableOpacity>
    );
}






import React, { createContext, useEffect, useReducer } from "react";
import AuthReducer from "./AuthReducer";
import { _retrieveData, _storeData } from "../../Service/function";


const getData = async () => {
    try {
      const jsonValue = await _retrieveData('user')
      return jsonValue != null ? JSON.parse(jsonValue) : null;
    } catch(e) {
      // error reading value
    }
  }
const INITIAL_STATE =  {
    user:getData() || null,
    isFetching:false,
    error:false,
};

export const AuthContext = createContext (INITIAL_STATE);

export const AuthContextProvider = ({children}) => {
    const [state,dispatch]= useReducer(AuthReducer, INITIAL_STATE);

    useEffect(() => {
      saveUser();
    }, [state.user])

    const saveUser=async()=>{
     _storeData(state.user,'user');
    }
    return (
        <AuthContext.Provider value={{
            user: state.user,
            isFetching: state.isFetching,
            error: state.error,
            dispatch
        }}
        >
            {children}
        </AuthContext.Provider>
    )
}
import React, { useContext, useEffect, useState } from 'react'
import HomeStyle from "./homestyle";
import { FlatList, ImageBackground,Dimensions, Linking, ScrollView, StatusBar, Text, View, Platform } from 'react-native';
import BackGroundImage from "../../assets/farm.jpeg"
import {Logo,Label, SpaceBottom, Button, Lang,Avatar, RadioButton, TextInputed, Dialogue, LabelSmall, LangText, MenuButton, ProductImage, External,Market, Loader} from '../../Components';
import {igbo,english,hausa,yoruba} from '../../Lang';
import PickLang from '../../Modals/Language/PickLang';
import { _retrieveData, _storeData } from '../../Service/function';
import { checkProfile,userBusiness, disableBackButton, generateGreetings, pickLang } from '../../Auth';
import { AuthContext } from '../../context/authContext/AuthContext';
import { baseUrl, imageUrl } from '../../env';
import Weather from '../components/weather/Weather';
import Setting from '../../Modals/Setting/Setting';
import axios from "axios";
import { useIsFocused } from '@react-navigation/native';
import Icon from "react-native-vector-icons/FontAwesome"
import { TouchableOpacity } from 'react-native-gesture-handler';
import moment from "moment";
import TextTicker from 'react-native-text-ticker'
// import { Avatar } from 'react-native-paper';

const {width,height}=Dimensions.get('screen');

export default function HomeScreen({navigation,route}) {
    const [isSecure, setSecure] = useState(true);
    const [getLangModal,setLangModal] = useState(false);
    const [modalSetting,setModalSetting] = useState(false);
    const [getLang,setLang] = useState(0);
    const [LangFile,setLangFile] = useState(english);
    const [getUser,setUser]=useState({});
    const [getBusiness,setBusiness]=useState({});
    const [getGreetings,setGreetings]=useState(null);
    const [external,setExternal]=useState([]);
    const [product,setProduct]=useState([]);
    const [noticount,setNoticount]=useState(0);
    const [announce, setAnnounce]=useState(null);
    const [publication,setPublication]=useState([]);
    const [loading,setLoading]=useState(false);
    //Context
    const {user,isFetching,dispatch}=useContext(AuthContext);
    const isFocused = useIsFocused();

    const resData=[
        {
          key:0,
          text:'English'
      },
      {
          key:1,
          text:'Hausa'
      },
      {
          key:2,
          text:'Igbo'
      },
      {
          key:3,
          text:'Yoruba'
      }
    ];
    useEffect(() => {
        pickLang(setLangFile,english,hausa,igbo,yoruba);
        // generateGreetings(LangFile,setGreetings);
       
      },[]);

     

      useEffect(()=>{
        disableBackButton()
      },[])

      useEffect(() => {
       loadDef()
      },[]);

      useEffect(() => {
        getExternal();
      },[external.length==0?isFocused:null]);

      useEffect(()=>{
        const getProductCat=async()=>{
          const token = await _retrieveData("token");
          const resproduct=await axios.get(baseUrl+`product-categories`,{
            headers: {
               Authorization: `Bearer ${token}`
            }
        });
        setProduct(resproduct.data);
        }
        getProductCat();
      },[product.length==0?isFocused:null]);

      useEffect(()=>{
        const getPubCat=async()=>{
          const token = await _retrieveData("token");
        const pub=await axios.get(baseUrl+`publication-categories`,{
          headers: {
             Authorization: `Bearer ${token}`
          }
      });
      setPublication(pub.data);
    }
    getPubCat();
      },[publication.length==0?isFocused:null])

      const loadDef=async()=>{
       await checkProfile(navigation,setUser); 
       await userBusiness(setBusiness);
      }

    const OpenHref= async ()=>{
        await Linking.openURL('https://niss.gov.ng');
      }

      const onClosePress=()=>{
        // pickLang(setLangFile,english,hausa,igbo,yoruba);
        setLangModal(false);
       }

      const showLangModal=()=>{
        setLangModal(true);
       }

    const onSelectValue=async(val)=>{
        setLangFile(val==0?english:val==1?hausa:val==2?igbo:yoruba)
        setLang(val);
       await _storeData(val,'lang');
      
        setLangModal(false); 
    }

    const Logout=async()=>{
       await _storeData(null,"token");
       await _storeData(null,"user");
        navigation.navigate('Login');
      
      }
      const localLink=async()=>{
        var link=await _retrieveData("ExternalLink");
        if(link){
          setExternal(link);
        }
      }

      const localAnnounce=async()=>{
        var ann=await _retrieveData("Announce");
        if(ann){
          setAnnounce(ann.description);
        }
      }
      const getExternal=async()=>{
        const token = await _retrieveData("token");
        const UserD = await _retrieveData("userauth");
       await localLink();
       await localAnnounce();
        try {
          setLoading(true);
          const urlExt=baseUrl+`externals`;
            const resp=await axios.get(urlExt,{
                headers: {
                   Authorization: `Bearer ${token}`
                }
            });
           
          const rescount=await axios.get(baseUrl+`notifications/count?type_contains=all&type_contains=${UserD.user_type}&enddate_gte=${moment().format("YYYY-MM-DD")}`,{
            headers: {
               Authorization: `Bearer ${token}`
            }
            
        });
        const annoucer=await axios.get(baseUrl+`announcement`,{
          headers: {
             Authorization: `Bearer ${token}`
          }
          
      });
    
    
            await _storeData(resp.data,"ExternalLink");
            await _storeData(annoucer.data,"Announce");
            setLoading(false);
            setExternal(resp.data);
            
           
            setNoticount(rescount.data);
            setAnnounce(annoucer.data.description);
        } catch (error) {
          
        }

      }
      

      const renderMarket = ({ item,index }) => (
        <Market bizshow={1} onPress={()=>navigation.navigate('ProductList', { datars: item,lang:LangFile })} data={item} /> 
          // <MenuImage onPress={()=>navigation.navigate('Detail', { datars: item,lang:getLang })} key={index} imageUrl={item.image?baseUrl+item.image.url:''} colorIcon={'#006400'} buttonText={item.name} /> 
          );

          const renderPublication = ({ item,index }) => (
            <Market bizshow={1} onPress={()=>navigation.navigate('PublicationList', { datars: item,lang:LangFile })} data={item} /> 
              // <MenuImage onPress={()=>navigation.navigate('Detail', { datars: item,lang:getLang })} key={index} imageUrl={item.image?baseUrl+item.image.url:''} colorIcon={'#006400'} buttonText={item.name} /> 
              );

      const renderItem = ({ item,index }) => (
        <External bizshow={1} onPress={()=>Linking.openURL(item.url)} data={item} /> 
         
        // <MenuImage onPress={()=>navigation.navigate('Detail', { datars: item,lang:getLang })} key={index} imageUrl={item.image?baseUrl+item.image.url:''} colorIcon={'#006400'} buttonText={item.name} /> 
          );
    return (
      <View style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
        <ImageBackground source={BackGroundImage} style={HomeStyle.parent}>
        <View style={HomeStyle.child}>
        <View style={{flexDirection:'row',justifyContent:'space-between',paddingRight:20,marginTop:Platform.OS === 'ios'?25:0}}>
      {getLang==0&&
   <LangText title="NISSAGRO" message="ENGLISH" onPress={()=>showLangModal()}/>
      }
     {getLang==1&&
   <LangText title="NISSAGRO" message="HAUSA" onPress={()=>showLangModal()}/>
      }
      {getLang==2&&
   <LangText title="NISSAGRO" message="IGBO" onPress={()=>showLangModal()}/>
      }
      {getLang==3&&
   <LangText title="NISSAGRO" message="YORUBA" onPress={()=>showLangModal()}/>
      }
     
      <View style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center'}}>
      <TouchableOpacity onPress={()=>navigation.navigate("NotificationList")} style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:"center"}}>
      <View style={{display:'flex',flexDirection:'row', paddingRight: 20}}>
      <Icon name={'bell'} style={{color:'green',fontSize: 25}}></Icon>
     <Text style={{color:'red',fontSize: 13,fontWeight:'bold'}}>{noticount}</Text>
      </View>
      </TouchableOpacity>
     <Avatar editprofile={LangFile.profile?.editprofile} onPress={()=> navigation.navigate('ProfileList')} colorIcon={'rgba(0,0,0,0.7)'} iconSize={60} image={getUser.avatar?imageUrl+getUser.avatar.url:''} iconName={'user-circle-o'} type={1}  />
     </View>
    
      </View>
      <View style={HomeStyle.weatherContainer}>
      <Weather LangFile={LangFile} business={getBusiness} user={getUser} />
      </View>
      {announce&&
      <View style={{backgroundColor:"#0d503a",marginLeft:10,marginRight:10}}>
      <TextTicker
          style={{ fontSize: 15,color:'white',padding:5 }}
          duration={10000}
          loop
          bounce
          repeatSpacer={50}
          marqueeDelay={1000}
        >
         {announce}
        </TextTicker>
      </View>}
      {/* <View style={{flexDirection:'row',alignItems:'center', justifyContent:'space-between',paddingBottom:15,paddingRight:15}}>
      <View style={{flexDirection:'column',padding:20}}>
      <LabelSmall message={getGreetings} fonts={13} />
      <LabelSmall color={'#0d503a'} message={getUser?getUser.name:''} fonts={13} />
      </View>
      <View>
      
      <Avatar onPress={()=> navigation.navigate('ProfileList')} colorIcon={'rgba(0,0,0,0.7)'} iconSize={60} image={getUser.avatar?imageUrl+getUser.avatar.url:''} iconName={'user-circle-o'} type={1}  />
      </View>
      </View> */}
      <ScrollView style={HomeStyle.container} persistentScrollbar={true} contentContainerStyle={{paddingRight:10}}>
     
     <View style={{flexDirection:'column'}}>
    
     <View style={{display:'flex',flexDirection:'column',justifyContent:'center',padding:10}}>
        <Text style={{color:"#0d503a",fontWeight:"bold",marginLeft:10,marginBottom:5}}>{LangFile.naming?.knowledge}</Text>
         <View style={{flexDirection:'row',justifyContent:'space-evenly'}}>
        <MenuButton onPress={()=> navigation.navigate('Product')} iconName={'user-md'} colorIcon={'#0d503a'} buttonText={LangFile.menu?LangFile.menu.menu1:''} />
           {/* {getUser.roles&&getUser.roles.roleId==2&&
        <MenuButton onPress={()=> navigation.navigate('ProductList')} iconName={'shopping-cart'} colorIcon={'#0d503a'} buttonText={LangFile.menu?LangFile.menu.menu1_1:''} />
           } */}
        <MenuButton onPress={()=> navigation.navigate('Crop')} iconName={'leaf'} colorIcon={'#0d503a'} buttonText={LangFile.menu?LangFile.menu.menu2:''} />
        <MenuButton onPress={()=> navigation.navigate('Livestock')} iconName={'dog'} type={1} colorIcon={'#0d503a'} buttonText={LangFile.menu?LangFile.menu.menu3:''} />
         </View>
         <Text style={{color:"#0d503a",fontWeight:"bold",marginLeft:10,marginBottom:5}}>{LangFile.naming?.services}</Text>
         <View style={{flexDirection:'row',justifyContent:'space-evenly'}}>
        <MenuButton onPress={()=>navigation.navigate('FarmService')} iconName={'phone'} colorIcon={'#0d503a'} buttonText={LangFile.menu?LangFile.menu.menu4:''} />
        {getBusiness&&!getBusiness.confirm&&
        <MenuButton onPress={()=>navigation.navigate('ChatList')} iconName={'comments-o'} colorIcon={'#0d503a'} buttonText={LangFile.menu?LangFile.menu.menu5:''} />
        }
         {getBusiness&&getBusiness.confirm&&
        <MenuButton onPress={()=>navigation.navigate('ReplyList')} iconName={'comments-o'} colorIcon={'#0d503a'} buttonText={LangFile.menu?LangFile.menu.menu10:''} />
        }
        <MenuButton onPress={()=>navigation.navigate('Inspection')} iconName={'flask'} colorIcon={'#0d503a'} buttonText={LangFile.menu?LangFile.menu.menu6:''} />
         </View>
         {Platform.OS!=="web"&&
         <>
         <Text style={{color:"#0d503a",fontWeight:"bold",marginLeft:10,marginBottom:5}}>{LangFile.naming?.tools}</Text>
         <View style={{flexDirection:'row',justifyContent:'space-evenly'}}>
        <MenuButton onPress={()=>navigation.navigate('FarmRecord')} iconName={'table'} colorIcon={'#0d503a'} buttonText={LangFile.menu?LangFile.menu.menu7:''} />
        <MenuButton onPress={()=>navigation.navigate('Task')} iconName={'calendar-plus-o'} colorIcon={'#0d503a'} buttonText={LangFile.menu?LangFile.menu.menu8:''} />
        <MenuButton onPress={()=>navigation.navigate('InvoiceList')} iconName={'file-pdf-o'} colorIcon={'#0d503a'} buttonText={LangFile.menu?LangFile.menu.menu9:''} />
         </View>
         </>}
         </View>
         <View style={{flexDirection:'row'}}>
         <Label color="#0d503a" message={LangFile.records?LangFile.records.agromart:"Agro Market"} fonts={16} />
         </View>
         <ScrollView  persistentScrollbar={true} contentContainerStyle={{paddingRight:10}}>
      <View style={{flexDirection:'row'}}>
         <FlatList
        // contentContainerStyle={{alignSelf: 'flex-start'}}
        // columnWrapperStyle={{alignItems: 'space-between'}}
  pagingEnabled={true}
  showsHorizontalScrollIndicator={false}
  numColumns={2}
        showsVerticalScrollIndicator={false}
       
        data={product}
        renderItem={renderMarket}
        keyExtractor={item => item.id} 
        style={{width:width}}
        />  
     </View>
      </ScrollView>
      <View style={{flexDirection:'row'}}>
         <Label color="#0d503a" message={LangFile.naming?.publications} fonts={16} />
         </View>
         <ScrollView  persistentScrollbar={true} contentContainerStyle={{paddingRight:10}}>
      <View style={{flexDirection:'row'}}>
   
         <FlatList
        // contentContainerStyle={{alignSelf: 'flex-start'}}
        // columnWrapperStyle={{alignItems: 'space-between'}}
  pagingEnabled={true}
  showsHorizontalScrollIndicator={false}
  numColumns={2}
        showsVerticalScrollIndicator={false}
       
        data={publication}
        renderItem={renderPublication}
        keyExtractor={item => item.id} 
        style={{width:width}}
        />  
     </View>
      </ScrollView>
         <View style={{flexDirection:'row'}}>
         <Label color="#0d503a" message={LangFile.home?LangFile.home.external_link:"External Links"} fonts={16} />
         </View>
         <ScrollView style={HomeStyle.scrollView} persistentScrollbar={true} contentContainerStyle={{paddingRight:10}}>
      <View style={{flexDirection:'column'}}>
         <FlatList
        // contentContainerStyle={{alignSelf: 'flex-start'}}
        // columnWrapperStyle={{alignItems: 'space-between'}}
        numColumns={2}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        data={external}
        renderItem={renderItem}
        keyExtractor={item => item.id} 
        />  
         {/* </View> */}
        
     </View>
     
      </ScrollView>
     </View>
      </ScrollView>
    
      <PickLang modalView={getLangModal} 
      close={LangFile.login?LangFile.login.close:""}
      render={ <RadioButton resData={resData} value={getLang} onSelectValue={onSelectValue}/>}
      onClosePress={onClosePress}/>
      {/* <Setting modalView={modalSetting} title={"Settings"}
      render={
          <Text>bchbdbcvdb</Text>
      }
      onClosePress={()=>setModalSetting(false)}
      /> */}
</View>
</ImageBackground>
</View>
    )
}

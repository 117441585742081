import React from 'react';
import Icon from 'react-native-vector-icons/FontAwesome';
import { Text, View,Image, TouchableOpacity, StyleSheet } from 'react-native';



export default function LogoName(props) {
    return (
        <View style={styles.header}>
        <Image
        style={styles.tinyLogo}
        source={require('../assets/logo.png')}
         />
          <Text style={styles.headerTextN}>NISSAGRO</Text>
      </View>

    );
}
const styles=StyleSheet.create({
  header: {
    justifyContent:'center',
    alignItems:'center',
     flexDirection:'row',
     margin: 5,
     paddingTop:0,
    //  backgroundColor:'#fff'
   },
  tinyLogo: {
    width: 70,
    height: 70,
    marginBottom: 0,
    borderColor: "#0d503a",
    resizeMode:'cover'

  },
  headerText: {
    fontSize: 50,
    marginBottom:10,
    fontWeight:'bold',
    marginLeft:10,
    color: "#0d503a"
  },
  headerTextW:{
    fontSize: 30,
    marginBottom:0,
    fontWeight:'bold',
    marginLeft:0,
    color: "#fff"
  },
  headerTextN:{
    fontSize: 30,
    marginBottom:0,
    fontWeight:'bold',
    marginLeft:0,
    color: "#000"
  }
})





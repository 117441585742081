import React from 'react';
import Icon from 'react-native-vector-icons/FontAwesome';
import Iconed from 'react-native-vector-icons/MaterialCommunityIcons';
import { Text, View,Image,StyleSheet,Dimensions,ActivityIndicator, TouchableOpacity } from 'react-native';
const {width,height}=Dimensions.get('screen');
import ImageBack from "../assets/agrodealer.jpeg"
import { imageUrl } from '../env';

export default function ProductImage(props) {
    return (
       <TouchableOpacity
        style={styles.button1}
        onPress={() => props.onPress()}
      >
          {/* {props.type&&
           <Iconed name={props.iconName} style={{color: props.colorIcon,fontSize: 35,padding: 5}}></Iconed>
          }
           {!props.type&&
           <Icon name={props.iconName} style={{color: props.colorIcon,fontSize: 35,padding: 5}}></Icon>
           } */}
           <Image style={{height: height*0.1,width:width*0.46,padding: 0,resizeMode:'cover',borderRadius:5}} source={props.data&&props.data.businessimage?{
          uri:imageUrl+props.data.businessimage.url}:ImageBack}/>
        <Text style={styles.btnText}>{props.data&&props.data.name?props.data.name:props.data.user.name}</Text>
        <Text style={styles.btnsmText}>{props.data.business_address}</Text>
      </TouchableOpacity>
    );
}
const styles=StyleSheet.create({
    button1: {
        flex:0.5,
        borderColor: "rgba(255,255,255,0.7)",
        display:'flex',
        flexDirection:'column',
        alignItems: "center",
        padding: 10,
        marginBottom: 20,
        backgroundColor:'rgba(255,255,255,1)',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 15,
        borderBottomRightRadius: 10,
        borderBottomLeftRadius: 10,
        marginLeft: 10,
        marginRight: 0,
        // borderWidth: 2
      },
      btnText: {
        color: "#000",
        bottom:0,
        padding:5,
        textAlign: 'center',
        fontSize: 13,
        fontWeight:'bold',
      }, 
      btnsmText: {
        color: "gray",
        bottom:0,
        padding:1,
        textAlign: 'center',
        fontSize: 12,
        fontWeight:'bold',
      }, 
      iconStyle1: {
        color: "#000",
        fontSize: 15,
        paddingLeft: 12
      },
})






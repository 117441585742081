import React, { useContext, useEffect, useState } from 'react'
import ServiceSearchStyle from "./servicesearchstyle";
import { FlatList, ImageBackground, Linking, ScrollView, StatusBar, Text, View } from 'react-native';
import BackGroundImage from "../../../assets/farm.jpeg"
import {Logo,Label, SpaceBottom, Button, Lang, RadioButton, TextInputed, Dialogue, LabelSmall, LangText, Avatar, MenuButton, GoBack, ProductImage, SearchLabel, Scientist} from '../../../Components';
import {igbo,english,hausa,yoruba} from '../../../Lang';
import PickLang from '../../../Modals/Language/PickLang';
import { updateStateArray, _retrieveData, _retrieveToken, _storeData } from '../../../Service/function';
import { checkProfile, disableBackButton, generateGreetings, pickLang } from '../../../Auth';
import { AuthContext } from '../../../context/authContext/AuthContext';
import { baseUrl, imageUrl } from '../../../env';
import MenuImage from '../../../Components/MenuImage';
import axios from "axios";
import ProductSearch from '../../../Modals/Search/ProductSearch';
import SearchStore from '../../components/SearchStore/SearchStore';

export default function ServiceSearchScreen({navigation,route}) {
    const [isSecure, setSecure] = useState(true);
    const [getLangModal,setLangModal] = useState(false);
    const [modalSetting,setModalSetting] = useState(false);
    const [getLang,setLang] = useState(0);
    const [LangFile,setLangFile] = useState(english);
    const [getProduct, setProduct] = useState([]);
    const [getSearch, setSearch] = useState(false);
    const [getProductSearch, setProductSearch] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [getGreetings,setGreetings]=useState(null);
    const [getBannerTab, setBannerTab] = useState(1);
    const [messageDialogue, setmessageDialogue] = useState("Hello");
    const [isDialogueVisible, setisDialogueVisible] = useState(false);
    const [getState, setState] = useState(null);
    const [getLGA, setLGA] = useState(null);
    const [start,setStart]=useState(0);
    const [page,setPage]=useState(10);
    //Context
    const {user,isFetching,dispatch}=useContext(AuthContext);

    const resData=[
        {
          key:0,
          text:'English'
      },
      {
          key:1,
          text:'Hausa'
      },
      {
          key:2,
          text:'Igbo'
      },
      {
          key:3,
          text:'Yoruba'
      }
    ];
    useEffect(() => {
        pickLang(setLangFile,english,hausa,igbo,yoruba);
        generateGreetings(LangFile,setGreetings);
        disableBackButton()
      },[]);

      useEffect(() => {
        currentLocation()
        // checkProfile(navigation,setUser); 
      },[]);

      const onCloseDialoguePress=()=>{
        setisDialogueVisible(false);
       }

    const OpenHref= async ()=>{
        await Linking.openURL('https://niss.gov.ng');
      }

      const onClosePress=()=>{
        // pickLang(setLangFile,english,hausa,igbo,yoruba);
        setLangModal(false);
       }

      const showLangModal=()=>{
        setLangModal(true);
       }

    const onSelectValue=async(val)=>{
        setLangFile(val==0?english:val==1?hausa:val==2?igbo:yoruba)
        setLang(val);
       await _storeData(val,'lang')
        setLangModal(false); 
    }

    const setBusiness=async()=>{
        let crop=await _retrieveData("businessplace");
        setProduct(crop);
    }

    const LoadBusiness=async(state)=>{
        setBusiness();
        const token = await _retrieveData("token");
        setStart(0);
        try {
            const resp=await axios.get(baseUrl+`businesses?service_contains=${route.params.name}&business_state_contains=${state}&confirm=true&_limit=${page}`,{
                headers: {
                   Authorization: `Bearer ${token}`
                }
            });
            // if(resp.statusCode){
            //     setLoading(false);
            //   setisDialogueVisible(true);
            //   setmessageDialogue(LangFile.error_message?LangFile.error_message.general_error:"");
            //   return false;
            //   }
              setProduct(resp.data);
              setLoading(false);
           await  _storeData(resp.data,"businessplace");
        } catch (error) {
            setLoading(false);
            setisDialogueVisible(true);
            setmessageDialogue(error.message);
        }
    }


    const retrieveScientist=async()=>{
      const token = await _retrieveData("token");
      
      try {
          setLoading(true);
          // console.log(start);
          const resp=await axios.get(baseUrl+`businesses?service_contains=${route.params.name}&business_state_contains=${getState}&confirm=true&_limit=${page}&_start=${start}`,{
              headers: {
                 Authorization: `Bearer ${token}`
              }
          });
         await updateStateArray(getProduct,resp.data,setProduct);
         if(resp.data.length>0){setStart(start+page);}
          setLoading(false)
      } catch (error) {
          setLoading(false);
          // errorMessage(toast)
      }
  }

    const setSearched=async(em)=>{
      setStart(0);
        const token = await _retrieveData("token");
        try {
            const url=baseUrl+`businesses?service_contains=${route.params.name}&business_state_contains=${em.state?em.state:''}&confirm=true&_limit=${page}`;
            const resp=await axios.get(url,{
                headers: {
                   Authorization: `Bearer ${token}`
                }
            });
            
              setProduct(resp.data);
              setState(em.state?em.state:'');
              setLoading(false);
        //    await  _storeData(resp.data,"businessplace");
        } catch (error) {
            setLoading(false);
            // setisDialogueVisible(true);
            // setmessageDialogue(error.message);
        }
    }

    const searchModel=()=>{
        setSearch(true);
      }
      const closeSearch=()=>{
        setSearch(false);
      }
      const navPage=(item)=>{
        navigation.navigate('ProductEdit', { datars: item,lang:getLang })
      }
      
    //   const changeSearch=(name)=>{
    //     asyncGet('/businesses?name_contains='+name+'&_limit=50')
    //     .then((resp)=>{
    //       setProductSearch(resp);
    //       setLoading(false);
    //     })
    //     .catch((err)=>{
    //       setLoading(false);
    //     })
    //   }
      const currentLocation=async()=>{
        let location=await _retrieveData("business");
        LoadBusiness(location.live_state);
        setState(location.live_state);
        setLGA(location.live_lga);
        return location;
        
      }
       

    const renderItem = ({ item,index }) => (
      <Scientist bizshow={1} onPress={()=>navigation.navigate('StoreDetail', { datars: item,lang:LangFile })} image={item.user.avatar?item.user.avatar.url:null} icontitle={item.name} title={item.name} subtitle={item.live_lga} /> 
        // <ProductImage bizshow={1} onPress={()=>navigation.navigate('StoreDetail', { datars: item,lang:LangFile })} data={item} /> 
          // <MenuImage onPress={()=>navigation.navigate('Detail', { datars: item,lang:getLang })} key={index} imageUrl={item.image?baseUrl+item.image.url:''} colorIcon={'#006400'} buttonText={item.name} /> 
          );

      return (
        <View style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
        <ImageBackground source={BackGroundImage} style={ServiceSearchStyle.parent}>
        <View style={ServiceSearchStyle.child}>
        <GoBack onPress={() => navigation.goBack()} buttonText={route.params.name} />
      <ScrollView style={ServiceSearchStyle.container} persistentScrollbar={true} contentContainerStyle={{paddingRight:10}}>
      <View style={{flexDirection:'column'}}>
   <SearchStore lga={true} stateChange={(e)=>{setSearched({state:e});setState(e)}} lgaChange={(e)=>{setSearched({lgs:e});setLGA(e)}} productChange={(e)=>{setSearched({product:e,state:getState,lga:getLGA});setState(e)}} LangFile={LangFile} header={LangFile.search?LangFile.search.searchServiceheader:''} product={false} />
   <SpaceBottom height={10} />
  
   <Label padding={10} click={false} color="#000" fonts={16}  message={LangFile.search?route.params.name.replace("Service", "")+" "+LangFile.search.searchService+` ${getState} `:''} />
         <FlatList
        // contentContainerStyle={{alignSelf: 'flex-start'}}
        // columnWrapperStyle={{alignItems: 'space-between'}}
        numColumns={2}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        data={getProduct}
        renderItem={renderItem}
        keyExtractor={item => item.id} 
        />  
         {/* </View> */}
         <Button isLoading={isLoading} altStyle={{padding:5}} onPress={()=>retrieveScientist()} altText={{fontSize:14}} buttonText={LangFile.naming?.loadmore} />
     </View>
     
      </ScrollView>
      <Dialogue
        modalVisible={isDialogueVisible}
        close={LangFile.login?LangFile.login.close:''}
        onClosePress={(e) => onCloseDialoguePress()}
        message={messageDialogue}
      />
      <PickLang modalView={getLangModal} 
       close={LangFile.login?LangFile.login.close:""}
      render={ <RadioButton resData={resData} value={getLang} onSelectValue={onSelectValue}/>}
      onClosePress={onClosePress}/>

            </View>
            </ImageBackground>
        </View>
      )

}
import React, { useEffect, useState } from 'react'
import { View,PermissionsAndroid, Text,Platform } from 'react-native'
import { LabelSmall } from '../../../Components';
import axios from "axios";
import WeatherStyle from "./weatherstyle";
import Geolocation from 'react-native-geolocation-service';
import { baseUrl } from '../../../env';
import  Icon  from 'react-native-vector-icons/Ionicons';
import IconFont from "react-native-vector-icons/FontAwesome";
import { generateGreetings, pickLang } from '../../../Auth';
import moment from 'moment';
import { useIsFocused } from '@react-navigation/native';
import {igbo,hausa,yoruba,english} from "../../../Lang"

export default function Weather({LangFile,user,business}) {
    const [latitude,setLatitude]=useState(9.11);
    const [longitude,setLongitude]=useState(7.28);
    const [result,setResult]=useState({});
    const [getGreetings,setGreetings]=useState(null);
  
    const [getLang,setLang] = useState(0);
    const isFocused = useIsFocused();
   const pickCoordinate=async()=>{
    const hasLocationPermission= await PermissionsAndroid.request(
      PermissionsAndroid.PERMISSIONS.ACCESS_COARSE_LOCATION,
      {
        title: "Pick your current location",
        message:
          " Give the app permission to pick " +
          "your current device location.",
        buttonNeutral: "Ask Me Later",
        buttonNegative: "Cancel",
        buttonPositive: "OK"
      }
    );
    if (hasLocationPermission&&Platform.OS==="android") {
      Geolocation.getCurrentPosition(
          (position) => {
            // alert(position.coords.latitude)
            setLatitude(position.coords.latitude);
            setLongitude(position.coords.longitude);
            getWeather(position.coords.latitude,position.coords.longitude);
          },
          (error) => {
            // See error code charts below.
            console.log(error.code, error.message);
          },
          { enableHighAccuracy: true, timeout: 15000, maximumAge: 10000 }
      );
    }
    else if(Platform.OS==="ios"){
      Geolocation.getCurrentPosition(
        (position) => {
          // alert(position.coords.latitude)
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
          getWeather(position.coords.latitude,position.coords.longitude);
        },
        (error) => {
          // See error code charts below.
          console.log(error.code, error.message);
        },
        { enableHighAccuracy: true, timeout: 15000, maximumAge: 10000 }
    );
    }
    else{
      navigator.geolocation.getCurrentPosition(function(position) {
        setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
          getWeather(position.coords.latitude,position.coords.longitude);
       
      });
    }
   
   }
   
    useEffect(() => {
        pickCoordinate();
        // pickLang(setLangFile,english,hausa,igbo,yoruba);
    },[isFocused]);
    
    useEffect(()=>{
      setGreetings(
      generateGreetings(LangFile,setGreetings)==0?LangFile.greetings?LangFile.greetings.morning:'':
      generateGreetings(LangFile,setGreetings)==1?LangFile.greetings?LangFile.greetings.afternoon:'':
      LangFile.greetings?LangFile.greetings.evening:''
      );
    },[LangFile])

    const getWeather=async(lat,long)=>{
        try {
            const resp=await axios.get(`${baseUrl}services/weather/report?lat=${lat.toFixed(6)}&lon=${long.toFixed(6)}`)
                    setResult(resp.data);
        } catch (error) {
          // getWeather();
        }
    }

    return (
      <View style={{display:'flex', flexDirection:'column',justifyContent:'space-between'}}>
      <View style={{display:'flex', flexDirection:'row',paddingTop:10, justifyContent:'space-evenly',alignItems:'center'}}>
      <View style={{display:'flex', flexDirection:'column'}}>
      <Text style={WeatherStyle.greetings}>{getGreetings}</Text>
      <Text style={WeatherStyle.date}>{user?.name} {business.confirm&&<Icon name={'shield-checkmark'} style={{color:'green',fontSize: 20, paddingRight: 5}}></Icon>}</Text>
      </View>
      <View style={{display:'flex', flexDirection:'column'}}>
      <Text style={WeatherStyle.date}>{moment().format("LL")}</Text>
      <Text style={WeatherStyle.date}>{result.report&&result.report.sys.country==="NG"?result.report.sys.country+"-":''}{result.report&&result.report.sys.country==="NG"?result.report.name:''}</Text>
      </View>
      </View>
        <View style={{display:'flex', flexDirection:'row',padding:30,justifyContent:'space-evenly',alignItems:'center'}}>
          <View style={WeatherStyle.holdContainer}>
          <Icon name={'sunny-outline'} style={{color:'black',fontSize: 20, paddingRight: 5}}></Icon>
         <Text style={WeatherStyle.label}>{result.report?result.report.main.temp+"'C":"25'C"}</Text>
         <Text style={WeatherStyle.label}>{LangFile.weather?.temperature}</Text>
          </View>
          <View style={WeatherStyle.holdContainer}>
          <Icon name={'cloudy-night-outline'} style={{color:'black',fontSize: 20, paddingRight: 5}}></Icon>
         <Text style={WeatherStyle.label}>{result.report?result.report.clouds.all+"mm":'15mm'}</Text>
         <Text style={WeatherStyle.label}>{LangFile.weather?.precipitation}</Text>
          </View>
          <View style={WeatherStyle.holdContainer}>
          <Icon name={'md-speedometer-outline'} style={{color:'black',fontSize: 20, paddingRight: 5}}></Icon>
         <Text style={WeatherStyle.label}>{result.report?result.report.main.humidity+"%":'25%'}</Text>
         <Text style={WeatherStyle.label}>{LangFile.weather?.humidity}</Text>
          </View>
          <View style={WeatherStyle.holdContainer}>
          <IconFont name={'superpowers'} style={{color:'black',fontSize: 20, paddingRight: 5}}></IconFont>
         <Text style={WeatherStyle.label}>{result.report?result.report.wind.speed+"Km/h":'2Km/h'}</Text>
         <Text style={WeatherStyle.label}>{LangFile.weather?.wind}</Text>
          </View>
        {/* <LabelSmall colorIcon={'#000'} iconName={'thermometer-4'} message={`Temperature: ${result.report?result.report.main.temp:'25'}'C`} fonts={13} />
        <LabelSmall colorIcon={'#000'} iconName={'mixcloud'} message={`Precipitation: ${result.report?result.report.wind.deg:'15'}%`} fonts={13} />
        <LabelSmall colorIcon={'#000'} iconName={'tachometer'} message={`Humidity: ${result.report?result.report.main.humidity:'25'}%`} fonts={13} />
        <LabelSmall colorIcon={'#000'} iconName={'superpowers'} message={`Wind: ${result.report?result.report.wind.speed:'15'}Km/h`} fonts={13} /> */}
        </View>
        </View>
    )
}

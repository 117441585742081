import { StyleSheet,Dimensions,Platform } from 'react-native';
const {width,height}=Dimensions.get('screen');
const isSmallDevice = Dimensions.get('window').width < 768

export default StyleSheet.create({

    login:{
       display:'flex',
       flexDirection:'column',
      
        backgroundColor:"#000",
        ...Platform.select({
          ios: {
              height:height,
              width:width
            },
            android: {
             height:height,
             width:width
            },
            default: {
              // other platforms, web for example
              height:height/1.2,
              width:isSmallDevice?width:width/2.5
            }
          }),
    },
    child: {
        flex: 1,
        backgroundColor: 'rgba(255,255,255,0.7)'
      },
      container:{
        // height:height,
     },
     bottomView:{
        flexDirection:'row',
        justifyContent:'center',
        margin:20
     }
  
});
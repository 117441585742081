import React, { useContext, useEffect, useState } from 'react'
import AuthorizationScreenStyle from './authorizationscreenstyle';
import { ImageBackground, Linking, ScrollView, StatusBar, View } from 'react-native';
import BackGroundImage from "../../../assets/farm.jpeg"
import {Logo,Label, SpaceBottom, Button, Lang, RadioButton, TextInputed, TabStep, SelectInputed, RadioSelect, Dialogue} from '../../../Components';
import {igbo,english,hausa,yoruba} from '../../../Lang';
import PickLang from '../../../Modals/Language/PickLang';
import { _retrieveData, _retrieveToken, _storeData } from '../../../Service/function';
import { checkProfile, disableBackButton, pickLang } from '../../../Auth';
import { AuthContext } from '../../../context/authContext/AuthContext';
import { baseUrl } from '../../../env';
import axios from "axios";

export default function AuthorizationScreen({navigation,route}) {
    const [getQuestion, setQuestion] = useState(null);
    const [getAnswer, setAnswer] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [isSecure, setSecure] = useState(true);
    const [secureIcon, setSecureIcon] = useState("eye");
    const [messageDialogue, setmessageDialogue] = useState("");
    const [isDialogueVisible, setisDialogueVisible] = useState(false);
  const [getLangModal,setLangModal] = useState(false);
  const [getUser,setUser]=useState({});
  const [getLang,setLang] = useState(0);
  const [LangFile,setLangFile] = useState(english);
  
  const resData=[
    {
      key:0,
      text:'English'
  },
  {
      key:1,
      text:'Hausa'
  },
  {
      key:2,
      text:'Igbo'
  },
  {
      key:3,
      text:'Yoruba'
  }
];

useEffect(() => {
  setLoading(false);
    pickLang(setLangFile,english,hausa,igbo,yoruba);
  },[]);

  useEffect(() => {
    checkProfile(navigation,setUser); 
    disableBackButton();
  },[]);

const OpenHref= async ()=>{
    await Linking.openURL('https://niss.gov.ng');
  }

  const onClosePress=()=>{
    // pickLang(setLangFile,english,hausa,igbo,yoruba);
    setLangModal(false);
   }

  const showLangModal=()=>{
    setLangModal(true);
   }

    const onSelectValue=async(val)=>{
    setLangFile(val==0?english:val==1?hausa:val==2?igbo:yoruba)
    setLang(val);
    await _storeData(val,'lang')
    setLangModal(false); 
    }

    const changeSecure = () => {
    setSecure(isSecure ? false : true)
    setSecureIcon(isSecure ? 'eye-slash' : 'eye');
  }

  const pressNull=()=>{
  return false;
}

const onCloseDialoguePress=()=>{
    setisDialogueVisible(false);
   }

    const Recovery=async()=>{
        const token = await _retrieveData("token");
        if(!getQuestion||!getAnswer){
          setisDialogueVisible(true);
          setmessageDialogue(LangFile.register?LangFile.register.fill_fields:"");
          return false;
        }
        setLoading(true)
        try {
            const resp=await axios.post(baseUrl+'recoveries',{
                question:getQuestion,
                answer:getAnswer
              },{
                headers: {
                   Authorization: `Bearer ${token}`
                }
            });
            await  _storeData(resp.data.user,"userauth");
              setLoading(false);
              navigation.navigate('Location');
            
        } catch (error) {
            setLoading(false);
            setisDialogueVisible(true);
            setmessageDialogue(error.message);
        }
    }
    return (
      <View style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
        <ImageBackground source={BackGroundImage} style={AuthorizationScreenStyle.parent}>
              <View style={AuthorizationScreenStyle.child}>
              {getLang==0&&
   <Lang message="ENGLISH" onPress={()=>showLangModal()}/>
      }
     {getLang==1&&
   <Lang message="HAUSA" onPress={()=>showLangModal()}/>
      }
      {getLang==2&&
   <Lang message="IGBO" onPress={()=>showLangModal()}/>
      }
      {getLang==3&&
   <Lang message="YORUBA" onPress={()=>showLangModal()}/>
      }
     {/* <Logo /> */}
     <TabStep onPress={pressNull} text1={'Authorization'} text2={'Location'} text3={'Permission'} indexSelect={1} />
      {/* <Label  message={LangFile.authorization?LangFile.authorization.welcome+getUser?getUser.name:''+' '+LangFile.authorization.message:''} fonts={16} />
      */}
      <SpaceBottom height={20} />
      <ScrollView style={AuthorizationScreenStyle.container}>
      <SelectInputed
          showIcon1={true}
          showIcon2={true}
          title={LangFile.authorization?LangFile.authorization.question+"(*)":''}
          iconName1={'pencil-square-o'}
          render={<RadioSelect resData={[{id:"What is your nickname",name:LangFile.authorization?LangFile.authorization.author_1:''},{id:"What is your favourite food",name:LangFile.authorization?LangFile.authorization.author_2:''},{id:"What is your favourite color",name:LangFile.authorization?LangFile.authorization.author_3:''},{id:"What is the name of your primary school",name:LangFile.authorization?LangFile.authorization.author_4:''},{id:"What is the name of your favourite teacher",name:LangFile.authorization?LangFile.authorization.author_5:''}]} value={getQuestion} onSelectValue={(e) => setQuestion(JSON.parse(e).name)}/>}
          selectLabel={LangFile.authorization?LangFile.authorization.example_question:''}
          label={getQuestion!=null?getQuestion:LangFile.authorization?LangFile.authorization.example_question:''}
          iconName2={'angle-down'}
          Options={0}
        />
        {/* <TextInputed
          showIcon1={true}
          showIcon2={false}
          iconName1={''}
          // iconName2={'eye'}
          placeholder={LangFile.authorization?LangFile.authorization.example_question:''}
          onChangeText={(e) => setQuestion(e)}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
          Secure={false}
          // keyboardType={'numeric'}
        /> */}
         {/* <Label onPress={pressNull} color="#000" message=  fonts={16}/> */}
        <TextInputed
          showIcon1={true}
          showIcon2={false}
          label={LangFile.authorization?LangFile.authorization.answer+"(*)":''}
          iconName1={'check-square-o'}
          iconName2={secureIcon}
          placeholder={LangFile.authorization?LangFile.authorization.example_answer:''}
          // onPress={() => changeSecure()}
          onChangeText={(e) => setAnswer(e)}
          placeholderColor={'#282a355c'}
          textColor={'#000'}
        // keyboardType={'numeric'}
        />
        {/* <View style={styles.bottomView} >
          <Label message={LangFile.login?LangFile.login.create_account:''} onPress={() => navigation.navigate('Register')} fontSize={20} />
          <Label message={LangFile.login?LangFile.login.forgot_password:''} onPress={() => navigation.navigate('ForgetPassword')} fontSize={20} />
        </View> */}
        {/* <Label fonts={12} color="#c5e6a4" onPress={OpenHref} message={LangFile.welcome?LangFile.welcome.project+"National Institute of Soil Science Nigeria (NISS)":''}/> */}
        <Button
        buttonText={LangFile.authorization?LangFile.authorization.next:''}
        onPress={Recovery}
        isLoading={isLoading}
        message={LangFile.login?LangFile.login.moment:''}
      />
      </ScrollView>
      <Dialogue
        modalVisible={isDialogueVisible}
        close={LangFile.login?LangFile.login.close:''}
        onClosePress={(e) => onCloseDialoguePress()}
        message={messageDialogue}
      />
      <PickLang modalView={getLangModal} 
       close={LangFile.login?LangFile.login.close:''}
      render={ <RadioButton resData={resData} value={getLang} onSelectValue={onSelectValue}/>}
      onClosePress={onClosePress}/>
     </View>
     </ImageBackground>
     </View>
    )
}

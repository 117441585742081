import React from 'react';
import Icon from 'react-native-vector-icons/FontAwesome';
import Iconed from 'react-native-vector-icons/MaterialCommunityIcons';
import { Text, View,Image,StyleSheet,ActivityIndicator,Dimensions, TouchableOpacity, ImageBackground } from 'react-native';
import { baseUrl, imageUrl } from '../env';
import ImageBack from "../assets/agrodealer.jpeg";
import Userdemo from "../assets/user.png"
// import styles from '../Styles/login';
const {width,height}=Dimensions.get('screen');
export default function Banner(props) {
  const addComma=(num)=>{
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
    return (
      <ImageBackground source={props.srcUrl?{uri:imageUrl+props.srcUrl}:ImageBack} style={{ height:height*0.32,backgroundColor:"rgba(255,255,255,0.7)",borderBottomRightRadius: 20,borderBottomLeftRadius:20,marginBottom:10}}>
     <View style={{flex: 1,
        backgroundColor: 'rgba(0,0,0,0.7)',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',}}>
     <Image
        style={{ width:width/2,height:height/7,resizeMode:'contain',borderRadius:200}}
        source={props.srcUrl?{uri:imageUrl+props.srcUrl}:Userdemo}
         /> 
         <View style={{flexDirection:'row',marginLeft:-28, paddingTop:10,}}>
         <Icon name={props.iconName1} style={styles.iconStyle1}></Icon>
          <Text style={styles.btnText}>{props.title}</Text>
          </View>
          <View style={{flexDirection:'row', paddingTop:5,}}>
          <Icon name={props.iconName2} style={styles.iconStyle1}></Icon>
          <Text style={styles.btnTextGray}>{props.subtitle}</Text>
          </View>
        </View>
      </ImageBackground>
    );
}
const styles=StyleSheet.create({
  button: {
    flex:0.5,
    borderColor: "#dec",
    // alignItems: "center",
    padding: 5,
    width:width*0.9,
    height:height*0.1,
    marginBottom: 10,
    backgroundColor:'#fff',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    marginLeft: 13,
    marginRight: 5,
    borderWidth: 2
  },
    button1: {
        flexDirection:'row',
      },
      btnText: {
        flexShrink: 1,
        color: "white",
        width:width*0.80,
        fontSize: 15,
        fontWeight:'bold',
      }, 
      btnTextGray: {
        flexShrink: 1,
        color: "white",
        width:width*0.87,
        fontSize: 15,
        fontWeight:'bold',
      }, 
      btnPrice:{
        flexShrink: 1,
        paddingLeft:0,
        paddingTop:height*0.010,
        color: "white",
        fontSize: 13,
        fontWeight:'bold',
      },
      iconStyle1: {
        color: "white",
        fontSize: 15,
        paddingTop:5,
        marginRight: 12
      },
})






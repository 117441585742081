import { StyleSheet,Dimensions } from 'react-native';
const {width,height}=Dimensions.get('screen');
export default StyleSheet.create({
    parent:{
        width: width,
        height: height,
        backgroundColor:"#000"
    },
    
    child: {
        flex: 1,
        backgroundColor: 'rgba(255,255,255,0.7)'
      },

      container:{
    marginBottom:height*0.1
     },
     
     bottomView:{
        flexDirection:'row',
        justifyContent:'center',
        margin:20
     },
     scrollView:{
      marginBottom:height*0.15
        },
        InvoicescrollView:{
            marginBottom:height*0.06,
            width:width*0.8
        },
    
        columnContainer:{
            display:"flex",
            flexDirection:'column',
            padding:10,
            margin:10,
            backgroundColor:"white",
            borderRadius:15
        },
        rowHeaderContainer:{
            display:"flex",
            flexDirection:'row',
            // alignItems:'center',
            justifyContent:"space-between"
        },
        rowInvoiceHeaderContainer:{
            display:"flex",
            flexDirection:'row',
            // alignItems:'center',
            justifyContent:"space-between",
            marginBottom:30
        },
        rightColumn:{
            display:"flex",
            flexDirection:'column',
            width:width/2.1,
            marginRight:10,
            marginBottom:10
        },
        imageLogo:{
            height:70,
            width:70,
            borderRadius:100,
            resizeMode:'cover'
        },
        buttonFilter:{
            padding: 5,
            marginBottom: 5,
           
          },
          buttonSubmit:{
            padding: 10,
            marginBottom: 5,
           
          },
          buttondelSubmit:{
            backgroundColor:"red",
            marginTop:20
          },
          buttondownSubmit:{
            backgroundColor:"green",
            marginTop:20
          },
          altText:{
            fontSize: 12,
          },
          altTextSubmit:{
            fontSize: 15,
            fontWeight:'bold'
          },
          altdownTextSubmit:{
            fontSize: 15,
            fontWeight:'bold',
            color:"white"
          },
          altTextdelSubmit:{
            fontSize: 15,
            fontWeight:'bold',
            color:'white'
          },
          invoiceText:{
             fontSize:18,
             fontWeight:'bold' 
          }
});
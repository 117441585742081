import React, { useContext, useEffect, useState } from 'react'
import FarmServiceStyle from "./farmservicestyle";
import { ImageBackground, Linking, ScrollView, StatusBar, Text, View } from 'react-native';
import BackGroundImage from "../../assets/farm.jpeg"
import {Logo,Label, SpaceBottom, Button, Lang, RadioButton, TextInputed, Dialogue, LabelSmall, LangText, Avatar, MenuButton, GoBack} from '../../Components';
import {igbo,english,hausa,yoruba} from '../../Lang';
import PickLang from '../../Modals/Language/PickLang';
import { _retrieveData, _storeData } from '../../Service/function';
import { checkProfile, disableBackButton, generateGreetings, pickLang } from '../../Auth';
import { AuthContext } from '../../context/authContext/AuthContext';
import { baseUrl } from '../../env';
import Weather from '../components/weather/Weather';
import Setting from '../../Modals/Setting/Setting';

export default function FarmServiceScreen({navigation,route}) {
    const [isSecure, setSecure] = useState(true);
    const [getLangModal,setLangModal] = useState(false);
    const [modalSetting,setModalSetting] = useState(false);
    const [getLang,setLang] = useState(0);
    const [LangFile,setLangFile] = useState(english);
    const [getUser,setUser]=useState({});
    const [getGreetings,setGreetings]=useState(null);
    //Context
    const {user,isFetching,dispatch}=useContext(AuthContext);

    const resData=[
        {
          key:0,
          text:'English'
      },
      {
          key:1,
          text:'Hausa'
      },
      {
          key:2,
          text:'Igbo'
      },
      {
          key:3,
          text:'Yoruba'
      }
    ];
    useEffect(() => {
        pickLang(setLangFile,english,hausa,igbo,yoruba);
        generateGreetings(LangFile,setGreetings);
       
      },[]);

      useEffect(()=>{
        disableBackButton()
      },[])

      useEffect(() => {
        // checkProfile(navigation,setUser); 
      },[]);

    const OpenHref= async ()=>{
        await Linking.openURL('https://niss.gov.ng');
      }

      const onClosePress=()=>{
        // pickLang(setLangFile,english,hausa,igbo,yoruba);
        setLangModal(false);
       }

      const showLangModal=()=>{
        setLangModal(true);
       }

    const onSelectValue=async(val)=>{
        setLangFile(val==0?english:val==1?hausa:val==2?igbo:yoruba)
        setLang(val);
       await _storeData(val,'lang')
        setLangModal(false); 
    }

    const Logout=async()=>{
       await _storeData(null,"token");
       await _storeData(null,"user");
        navigation.navigate('Login');
      
      }
    return (
      <View style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
        <ImageBackground source={BackGroundImage} style={FarmServiceStyle.parent}>
        <View style={FarmServiceStyle.child}>
        <GoBack onPress={() => navigation.goBack()} buttonText={LangFile.menu.menu4} />
    
      <ScrollView style={FarmServiceStyle.container} persistentScrollbar={true} contentContainerStyle={{paddingRight:10}}>
     <View style={{flexDirection:'column'}}>
     <View style={{flexDirection:'row'}}>
         <Label color="#0d503a" message={LangFile.home?LangFile.home.quick_menu:"Quick Menu"} fonts={16} />
     </View>
         <View style={{flexDirection:'row',justifyContent:'space-evenly'}}>
        <MenuButton styles={{width:150,height:150}} iconStyle={{fontSize:50,padding:20}} btnText={{}} onPress={()=> navigation.navigate('ServiceSearch', { name:'Soil Survey',lang:LangFile })} iconName={'search'} colorIcon={'#0d503a'} buttonText={LangFile.servicemenu?LangFile.servicemenu.soilsurvey:''} />
        <MenuButton styles={{width:150,height:150}} iconStyle={{fontSize:50,padding:20}} btnText={{}} onPress={()=> navigation.navigate('ServiceSearch', { name:'Soil Sampling',lang:LangFile })} iconName={'random'} colorIcon={'#0d503a'} buttonText={LangFile.servicemenu?LangFile.servicemenu.soilsampling:''} />
         </View>
         <View style={{flexDirection:'row',justifyContent:'space-evenly'}}>
         <MenuButton styles={{width:150,height:150}} iconStyle={{fontSize:50,padding:20}} btnText={{}} onPress={()=> navigation.navigate('ServiceSearch', { name:'Advisory Service',lang:LangFile })} iconName={'thumbs-o-up'} colorIcon={'#0d503a'} buttonText={LangFile.servicemenu?LangFile.servicemenu.advisoryservice:''} />
        <MenuButton styles={{width:150,height:150}} iconStyle={{fontSize:50,padding:20}} btnText={{}} onPress={()=> navigation.navigate('ServiceSearch', { name:'Technical Service',lang:LangFile })} iconName={'wrench'} colorIcon={'#0d503a'} buttonText={LangFile.servicemenu?LangFile.servicemenu.technicalservice:''} />
         </View>
         <View style={{flexDirection:'row',justifyContent:'space-evenly'}}>
         <MenuButton styles={{width:150,height:150}} iconStyle={{fontSize:50,padding:20}} btnText={{}} onPress={()=> navigation.navigate('ServiceSearch', { name:'Laboratory Service',lang:LangFile })} iconName={'flask'} colorIcon={'#0d503a'} buttonText={LangFile.servicemenu?LangFile.servicemenu.laboratoryservice:''} />
        <MenuButton styles={{width:150,height:150}} iconStyle={{fontSize:50,padding:20}} btnText={{}} onPress={()=> navigation.navigate('ServiceSearch', { name:'Machinery Service',lang:LangFile })} iconName={'truck'} colorIcon={'#0d503a'} buttonText={LangFile.servicemenu?LangFile.servicemenu.machineryservice:''} />
     </View>
     </View>
      </ScrollView>
    
      <PickLang modalView={getLangModal} 
      close={LangFile.login?LangFile.login.close:""}
      render={ <RadioButton resData={resData} value={getLang} onSelectValue={onSelectValue}/>}
      onClosePress={onClosePress}/>
      {/* <Setting modalView={modalSetting} title={"Settings"}
      render={
          <Text>bchbdbcvdb</Text>
      }
      onClosePress={()=>setModalSetting(false)}
      /> */}
</View>
</ImageBackground>
</View>
    )
}

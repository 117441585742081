import React, { useEffect, useState } from 'react';
import { ImageBackground, SafeAreaView, View,Dimensions, ScrollView, Text, TextInput } from 'react-native';
import StateListStyle from "./productliststyle";
import {igbo,english,hausa,yoruba} from '../../../Lang';
import BackGroundImage from "../../../assets/farm.jpeg"
import { GoBack, Market,errorMessage, Button } from '../../../Components';
import { disableBackButton, pickLang } from '../../../Auth';
import { FlatList, TouchableOpacity } from 'react-native-gesture-handler';
import stateLGA from '../../../State/state.json';
import Icon from 'react-native-vector-icons/FontAwesome';
import MenuImage from '../../../Components/MenuImage';
import { baseUrl, imageUrl } from '../../../env';
import { updateStateArray, _retrieveData } from '../../../Service/function';
import axios from "axios";

const {width,height}=Dimensions.get('screen');
export default function ProductListScreen({navigation,route}) {
    const [LangFile,setLangFile] = useState(english);
    const [products,setProducts]=useState([]);
    const [start,setStart]=useState(0);
    const [page,setPage]=useState(30);
    const [loading,setLoading]=useState(false);
    
    useEffect(() => {
        pickLang(setLangFile,english,hausa,igbo,yoruba);
        setValues();
        // generateGreetings(LangFile,setGreetings);
        disableBackButton()
      },[]);
    useEffect(()=>{
        retrieveProducts();
    },[])

      const setValues=async()=>{
          setProducts(route.params.datars.products);
      }

      const retrieveProducts=async()=>{
        const token = await _retrieveData("token");
        try {
            setLoading(true);
            const resp=await axios.get(baseUrl+`products?product_category=${route.params.datars?.name}&_limit=${page}&_start=${start}`,{
                headers: {
                   Authorization: `Bearer ${token}`
                }
            });
            await updateStateArray(products,resp.data,setProducts);
            setLoading(false);
            if(resp.data.length>0){setStart(start+page);}
        } catch (error) {
            setLoading(false);
            // errorMessage(toast)
        }
      }

      const searchProducts=async(name)=>{
        const token = await _retrieveData("token");
        try {
            setLoading(true);
            const query = JSON.stringify({ _where: { _or: [{ name_contains: name }, { company_contains: name }] } });
            const resp=await axios.get(baseUrl+`products?_where[_or][0][name_contains]=${name}&_where[_or][1][company_contains]=${name}&_limit=50&product_category.name=${route.params.datars?.name}`,{
                headers: {
                   Authorization: `Bearer ${token}`
                }
            });
            setProducts(resp.data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            // errorMessage(toast)
        }
      }

    //   const renderItem = ({ item,index }) => (
    //  <TouchableOpacity onPress={()=>navigation.navigate("DetailProduct",{datar:item})} key={index} style={StateListStyle.flatContainer}>
    //      <View style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
    //       <Icon style={StateListStyle.senderTxt} name={'map-o'} ></Icon>
    //  <Text style={StateListStyle.senderTxt}>{item.name}</Text>
    //  </View>
    //  <Icon style={StateListStyle.senderTxt} name={'arrow-circle-right'} ></Icon>
    //  </TouchableOpacity>
    //        );
    const renderProduct = ({ item,index }) => (
        // <Market bizshow={1} onPress={()=>navigation.navigate('ProductList', { datars: item,lang:LangFile })} data={item} /> 
          <MenuImage onPress={()=>navigation.navigate('ProductDetail', { datars: item })} key={index} imageUrl={item.image?imageUrl+item.image.url:''} colorIcon={'#006400'} buttonText={item.name} /> 
          );
    return (
        <View style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
        <ImageBackground source={BackGroundImage} style={StateListStyle.parent}>
            <View style={StateListStyle.child}>
        <GoBack onPress={() => navigation.navigate('Home')} buttonText={`${route.params.datars?.name}` } />
        <SafeAreaView style={{height:height*0.83,marginTop: 0,backgroundColor: 'rgba(255,255,255,0.7)' }}>
      <ScrollView style={{ flex: 1, margin: 10 }}>
      <View style={StateListStyle.containertop}>
     <TextInput  onChangeText={(e)=>searchProducts(e)} placeholder={LangFile.naming?.typeproductname} />
      </View>
      <FlatList
        numColumns={3}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        data={products}
        renderItem={renderProduct}
    
        keyExtractor={item => item.id} 
        /> 
        
         <Button isLoading={loading} altStyle={{padding:5}} onPress={()=>retrieveProducts()} altText={{fontSize:14}} buttonText={LangFile.naming?.loadmore} /> 
  </ScrollView>
  </SafeAreaView>
            </View>
        </ImageBackground>
        </View>
    )
}

import React, { useState,useEffect } from 'react';
import { StyleSheet,AsyncStorage, Text, View, TouchableOpacity,ScrollView,Dimensions, Image, Linking, BackHandler, FlatList, ImageBackground, Alert} from 'react-native';
import ProfileListStyle from "./profileliststyle";
import {TextInputed,GoBack,TabDetail,ProfileBanner, Logo,Button,Label,DescriptionText,SpaceBottom,Lang,RadioButton, TabStep, SelectInputed, RadioSelect, LabelSmall, LangText, Avatar, MenuButton, Dialogue, ProductImage, SearchLabel, Banner, LabelTouch} from '../../Components';
import BackGroundImage from "../../assets/farm.jpeg";
import PickLang from '../../Modals/Language/PickLang';
import  {yoruba,igbo,hausa,english}  from '../../Lang';
import stateLGA from '../../State/state.json';
import {baseUrl, imageUrl} from '../../env';
import MenuImage from '../../Components/MenuImage';
import { StackActions, useFocusEffect,useIsFocused } from '@react-navigation/native';
import ProductSearch from '../../Modals/Search/ProductSearch';
import * as ImagePicker from 'expo-image-picker';
import  Icon  from 'react-native-vector-icons/FontAwesome';
import { _deleteData, _retrieveData, _storeData } from '../../Service/function';
import { pickLang, userProfile } from '../../Auth';
import axios from 'axios';


const {width,height}=Dimensions.get('screen');
var countResult=0; var CropSet=[];
export default function ProfileListScreen({navigation, route}) {
  const isFocused = useIsFocused();
    const [getProfilePhoto, setProfilePhoto] = useState(null);
    const [getBackgroundPhoto, setBackgroundPhoto] = useState(null);
    const [getProductSearch, setProductSearch] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [getBannerTab, setBannerTab] = useState(1);
    const [secureIcon, setSecureIcon] = useState("eye");
    const [messageDialogue, setmessageDialogue] = useState("Hello");
    const [isDialogueVisible, setisDialogueVisible] = useState(false);
  const [getLangModal,setLangModal] = useState(false);
  const [getLang,setLang] = useState(0);
  const [LangFile,setLangFile] = useState(english);
  const [getUser,setUser]=useState({});
  const [getGreetings,setGreetings]=useState(null);
  const resData=[
    {
        key:0,
        text:'English'
    },
    {
        key:1,
        text:'Hausa'
    },
    {
        key:2,
        text:'Igbo'
    },
    {
        key:3,
        text:'Yoruba'
    }
];
  useEffect(() => {
    pickLang(setLangFile,english,hausa,igbo,yoruba);
  },[]);



  useEffect(() => {
    userProfile(setUser); 
  },[]);

const OpenHref= async ()=>{
    await Linking.openURL('https://niss.gov.ng');
  }

  const onClosePress=()=>{
    // pickLang(setLangFile,english,hausa,igbo,yoruba);
    setLangModal(false);
   }

  const showLangModal=()=>{
    setLangModal(true);
   }

const onSelectValue=async(val)=>{
    setLangFile(val==0?english:val==1?hausa:val==2?igbo:yoruba)
    setLang(val);
   await _storeData(val,'lang')
    setLangModal(false); 
}

const uploadImage = async(token,image) => {
    var formData = new FormData();
    setLoading(true);
    formData.append('files',
    {
       uri:image.uri,
       name:image.fileName,
       type:image.type
    });
    const resp=await axios.post(baseUrl+"upload/",formData,{
        headers: {
           Authorization: `Bearer ${token}`,
           'Content-Type': 'multipart/form-data'
        }
    })
    return resp.data;
};

const handleChooseProfilePhoto = async() => {
    const token = await _retrieveData("token");
    let res = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });
    if (!res.cancelled) {
      const resp=await uploadImage(token,res);
       await uploadProfile(resp[0].id,token);
      }
  };
  const uploadProfile = async(image,token) => {
    setLoading(true);
    let datat={
        avatar:image
      }
      try {
        const resp= await axios.put(baseUrl+`users/${getUser.id}`,datat,{
            headers:{
                'Authorization':`Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        await  _storeData(resp.data,"userauth");
       await userProfile(setUser); 
       setLoading(false);
      } catch (error) {
          setLoading(false);
          alert(error.message);
      }
   
  }

  const handleChooseBackgroundPhoto = async() => {
    const token = await _retrieveData("token");
    let res = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });
    if (!res.cancelled) {
      const resp=await uploadImage(token,res);
      await uploadBackground(resp[0].id,token)
    }
  };

  const uploadBackground = async(image,token) => {
    setLoading(true);
    let datat={
        businessimage:image
      }
      try {
        const resp= await axios.put(baseUrl+`businesses/${getUser.business.id}`,datat,{
            headers:{
                'Authorization':`Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        let bizdata=await _retrieveData("userauth");
        bizdata['business']=resp.data;
        await  _storeData(bizdata,"userauth");
       await userProfile(setUser); 
       setLoading(false);
      } catch (error) {
          setLoading(false);
          alert(error.message);
      }
  }

  const logOut=async()=>{
    Alert.alert("NissAgro",LangFile.error.logout, [
        {
          text: LangFile.error.cancel,
          onPress: () => null,
          style: "cancel"
        },
        { text: LangFile.error.yes, onPress: async() => {
      await _deleteData("token");
      await _deleteData("userauth");
      await _deleteData("business");
      navigation.dispatch(StackActions.popToTop())
        }}])
  }

  return (
    <View style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
    <ImageBackground source={BackGroundImage} style={ProfileListStyle.parent}>
       <View style={ProfileListStyle.child}>
      <GoBack onPress={() => navigation.goBack()} buttonText={LangFile.profile?LangFile.profile.profile_update:""} />
     <ProfileBanner  avatar={getUser.avatar?imageUrl+getUser.avatar.url:null} background={getUser.business?getUser.business.businessimage?imageUrl+getUser.business.businessimage.url:null:null} loading={isLoading} moment={LangFile.login?LangFile.login.moment:''}/>
    <ScrollView style={ProfileListStyle.scrollView} persistentScrollbar={true} contentContainerStyle={{paddingRight:10}}>
  {/* <Text>{JSON.stringify()}</Text> */}
   <TouchableOpacity onPress={handleChooseProfilePhoto} style={ProfileListStyle.menu}>
   <Icon name={'user-o'} size={25} style={ProfileListStyle.icon}></Icon>
    <Text style={ProfileListStyle.text}>{LangFile.profile?LangFile.profile.profile_image:""}</Text>
   </TouchableOpacity>
   <TouchableOpacity onPress={handleChooseBackgroundPhoto} style={ProfileListStyle.menu}>
   <Icon name={'address-card-o'} size={25} style={ProfileListStyle.icon}></Icon>
   {getUser&&getUser.user_type==="agrodealer"&&
    <Text style={ProfileListStyle.text}>{LangFile.profile?LangFile.profile.shop_image:""}</Text>
   }
   {getUser&&getUser.user_type==="farmer"&&
    <Text style={ProfileListStyle.text}>{LangFile.profile?LangFile.profile.farm_image:""}</Text>
   }
   {getUser&&getUser.user_type!=="farmer"&&getUser.user_type!=="agrodealer"&&
    <Text style={ProfileListStyle.text}>{LangFile.profile?LangFile.profile.orgnisation_logo:""}</Text>
   }
  
   </TouchableOpacity>
   <TouchableOpacity onPress={() => navigation.navigate("UserInfo")}  style={ProfileListStyle.menu}>
   <Icon name={'user-circle-o'} size={25} style={ProfileListStyle.icon}></Icon>
    <Text style={ProfileListStyle.text}>{LangFile.profile?LangFile.profile.profile_update:""}</Text>
   </TouchableOpacity>
   <TouchableOpacity onPress={() => navigation.navigate("BusinessInfo")} style={ProfileListStyle.menu}>
   <Icon name={'building'} size={25} style={ProfileListStyle.icon}></Icon>
    <Text style={ProfileListStyle.text}>{LangFile.profile?LangFile.profile.business_update:""}</Text>
   </TouchableOpacity>
   <TouchableOpacity onPress={() => navigation.navigate("LocationInfo")} style={ProfileListStyle.menu}>
   <Icon name={'map'} size={25} style={ProfileListStyle.icon}></Icon>
    <Text style={ProfileListStyle.text}>{LangFile.profile?LangFile.profile.change_location:""}</Text>
   </TouchableOpacity>
   <TouchableOpacity onPress={() => navigation.navigate("PasswordInfo")} style={ProfileListStyle.menu}>
   <Icon name={'key'} size={25} style={ProfileListStyle.icon}></Icon>
    <Text style={ProfileListStyle.text}>{LangFile.profile?LangFile.profile.change_password:""}</Text>
   </TouchableOpacity>
   <TouchableOpacity onPress={() => logOut()} style={ProfileListStyle.menu}>
   <Icon name={'power-off'} size={25} style={ProfileListStyle.icon}></Icon>
    <Text style={ProfileListStyle.text}>{LangFile.profile?LangFile.profile.logout:""}</Text>
   </TouchableOpacity>
      </ScrollView>
     
      <Dialogue
        modalVisible={isDialogueVisible}
        close={LangFile.login?LangFile.login.close:''}
        onClosePress={(e) => onCloseDialoguePress()}
        message={messageDialogue}
      />
      <PickLang modalView={getLangModal} 
       close={LangFile.login?LangFile.login.close:""}
      render={ <RadioButton resData={resData} value={getLang} onSelectValue={onSelectValue}/>}
      onClosePress={onClosePress}/>
      </View>
  </ImageBackground>
  </View>
  );
}


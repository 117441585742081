import React from 'react';
import Icon from 'react-native-vector-icons/FontAwesome';
import Iconed from 'react-native-vector-icons/MaterialCommunityIcons';
import { Text, View,Image,StyleSheet,Dimensions,ActivityIndicator, TouchableOpacity } from 'react-native';
import ImageBack from "../assets/agrodealer.jpeg"

const {width,height}=Dimensions.get('screen');

export default function MenuImage(props) {
    return (
        <View>
       <TouchableOpacity
        style={[styles.button1,props.button1]}
        onPress={() => props.onPress()}
      >
          {/* {props.type&&
           <Iconed name={props.iconName} style={{color: props.colorIcon,fontSize: 35,padding: 5}}></Iconed>
          }
           {!props.type&&
           <Icon name={props.iconName} style={{color: props.colorIcon,fontSize: 35,padding: 5}}></Icon>
           } */}
           <Image style={{height:70,width:70,margin: 15,resizeMode:'cover',borderRadius:100}} source={props.imageUrl?{
          uri: props.imageUrl,
        }:ImageBack}/>
        <Text style={styles.btnText}>{props.buttonText}</Text>
        <Text style={styles.btnsubText}>{props.buttonsubText}</Text>
      </TouchableOpacity>
      </View>
    );
}
const styles=StyleSheet.create({
    button1: {
        // flex:0.5,
        borderColor: "#000",
        alignItems: "center",
        padding: 10,
        width:100,
        marginBottom: 20,
        backgroundColor:'rgba(255,255,255,1)',
        borderTopLeftRadius: 15,
        borderTopRightRadius: 15,
        borderBottomRightRadius: 15,
        marginLeft: 10,
        marginRight: 0,
        borderWidth: 2
      },
      btnText: {
        color: "#000",
        textAlign: 'center',
        fontSize: 13,
        fontWeight:'bold',
      }, 
      btnsubText: {
        color: "gray",
        textAlign: 'center',
        fontSize: 12,
        fontWeight:'bold',
      }, 
      iconStyle1: {
        color: "#000",
        fontSize: 15,
        paddingLeft: 12
      },
})





